import { emptyRootAPI as api } from './emptyRootAPI'

export const addTagTypes = [
  'Vehicles',
  'Organizations',
  'Users',
  'Vehicle Schedules',
  'Routes',
  'Location of Interest',
  'Integrations',
  'Fleets',
  'Alarms',
  'Trips',
  'EntityEvents',
  'Vehicle Faults',
  'Tile',
  'Notifications',
  'Customers',
  'Chart',
  'Charger Commissioning Events',
  'Smart Charging',
  'Sites (Depots)',
  'Chargers',
  'Authorization Tags',
  'OCPP Configurations API',
  'Charging Groups',
  'OcppDestination',
  'Charging Transactions',
  'Tiles API',
  'Reports',
  'OCPP Logs',
  'Utilization',
  'Charger/Connector Summary',
  'Charger Faults',
  'Charger Capabilities',
  'Site Grid Profiles',
  'Scheduled Vehicle Trips',
  'Grid Event Integration Accounts',
  'Grid',
  'Schedule',
  'Charger Events',
  'Telemetry Exclusion Periods',
  'Site Feature Configurations',
  'Optimization',
  'Load Balancer Events',
  'Transaction Profile Mapping',
  'Profile History Records',
  'Synop Link',
  'Grid Event Integrations',
  'Price Controller',
  'Alerts',
  'Workplace Charging',
  'User',
  'Subscriptions',
  'Products',
  'OCPI',
  'Invoices',
  'Fleet Billing',
  'Billing',
  'Stripe Events',
  'Reimbursements',
  'Fleet Assistant',
  'Quotas',
  'Cost Explorer',
  'Simulator manager API',
  'Simulator manager proxy API'
] as const
const injectedRtkApi = api
  .enhanceEndpoints({
    addTagTypes
  })
  .injectEndpoints({
    endpoints: (build) => ({
      getVehicles: build.query<GetVehiclesResponse, GetVehiclesArgs>({
        query: (queryArg) => ({ url: `/api/vehicles`, params: { ocppTags: queryArg.ocppTags } }),
        providesTags: ['Vehicles']
      }),
      updateVehicle: build.mutation<UpdateVehicleResponse, UpdateVehicleArgs>({
        query: (queryArg) => ({ url: `/api/vehicles`, method: 'PUT', body: queryArg.editVehicleModel }),
        invalidatesTags: ['Vehicles']
      }),
      createVehicleWithMetadata: build.mutation<CreateVehicleWithMetadataResponse, CreateVehicleWithMetadataArgs>({
        query: (queryArg) => ({ url: `/api/vehicles`, method: 'POST', body: queryArg.createVehicleModel }),
        invalidatesTags: ['Vehicles']
      }),
      updateUser: build.mutation<UpdateUserResponse, UpdateUserArgs>({
        query: (queryArg) => ({ url: `/api/users`, method: 'PUT', body: queryArg.userModel }),
        invalidatesTags: ['Organizations', 'Users', 'Vehicles']
      }),
      createUser: build.mutation<CreateUserResponse, CreateUserArgs>({
        query: (queryArg) => ({
          url: `/api/users`,
          method: 'POST',
          body: queryArg.createUserModel,
          params: { isFederated: queryArg.isFederated }
        }),
        invalidatesTags: ['Organizations', 'Users']
      }),
      signOutUser: build.mutation<SignOutUserResponse, SignOutUserArgs>({
        query: (queryArg) => ({ url: `/api/users/${queryArg.id}/signout`, method: 'PUT' }),
        invalidatesTags: ['Users']
      }),
      resend: build.mutation<ResendResponse, ResendArgs>({
        query: (queryArg) => ({ url: `/api/users/${queryArg.id}/resend`, method: 'PUT' }),
        invalidatesTags: ['Users']
      }),
      resetPassword: build.mutation<ResetPasswordResponse, ResetPasswordArgs>({
        query: (queryArg) => ({ url: `/api/users/${queryArg.id}/password`, method: 'PUT' }),
        invalidatesTags: ['Users']
      }),
      enableUser: build.mutation<EnableUserResponse, EnableUserArgs>({
        query: (queryArg) => ({ url: `/api/users/${queryArg.id}/enable`, method: 'PUT' }),
        invalidatesTags: ['Users']
      }),
      disableUser: build.mutation<DisableUserResponse, DisableUserArgs>({
        query: (queryArg) => ({ url: `/api/users/${queryArg.id}/disable`, method: 'PUT' }),
        invalidatesTags: ['Users']
      }),
      updateScheduledRoute: build.mutation<UpdateScheduledRouteResponse, UpdateScheduledRouteArgs>({
        query: (queryArg) => ({
          url: `/api/scheduled-routes/${queryArg.scheduledRouteId}`,
          method: 'PUT',
          body: queryArg.scheduledRouteUpdateModel
        }),
        invalidatesTags: ['Vehicle Schedules']
      }),
      cancelScheduledRoute: build.mutation<CancelScheduledRouteResponse, CancelScheduledRouteArgs>({
        query: (queryArg) => ({ url: `/api/scheduled-routes/${queryArg.scheduledRouteId}`, method: 'DELETE' }),
        invalidatesTags: ['Vehicle Schedules']
      }),
      getRoutesForFleet: build.query<GetRoutesForFleetResponse, GetRoutesForFleetArgs>({
        query: (queryArg) => ({ url: `/api/routes`, params: { fleetId: queryArg.fleetId } }),
        providesTags: ['Routes']
      }),
      updateRoute: build.mutation<UpdateRouteResponse, UpdateRouteArgs>({
        query: (queryArg) => ({ url: `/api/routes`, method: 'PUT', body: queryArg.route }),
        invalidatesTags: ['Routes']
      }),
      createRoute: build.mutation<CreateRouteResponse, CreateRouteArgs>({
        query: (queryArg) => ({ url: `/api/routes`, method: 'POST', body: queryArg.createRouteRequest }),
        invalidatesTags: ['Routes']
      }),
      updateOrganization: build.mutation<UpdateOrganizationResponse, UpdateOrganizationArgs>({
        query: (queryArg) => ({ url: `/api/organizations`, method: 'PUT', body: queryArg.organizationModel }),
        invalidatesTags: ['Organizations', 'Users']
      }),
      updateFleetTerms: build.mutation<UpdateFleetTermsResponse, UpdateFleetTermsArgs>({
        query: (queryArg) => ({ url: `/api/organizations/terms`, method: 'PUT', body: queryArg.organizationTerms }),
        invalidatesTags: ['Organizations']
      }),
      saveFleetTerms: build.mutation<SaveFleetTermsResponse, SaveFleetTermsArgs>({
        query: (queryArg) => ({ url: `/api/organizations/terms`, method: 'POST', body: queryArg.organizationTerms }),
        invalidatesTags: ['Organizations']
      }),
      getLocationsOfInterestForOrganization: build.query<
        GetLocationsOfInterestForOrganizationResponse,
        GetLocationsOfInterestForOrganizationArgs
      >({
        query: (queryArg) => ({ url: `/api/locations`, params: { organizationId: queryArg.organizationId } }),
        providesTags: ['Location of Interest']
      }),
      updateLocationOfInterest: build.mutation<UpdateLocationOfInterestResponse, UpdateLocationOfInterestArgs>({
        query: (queryArg) => ({ url: `/api/locations`, method: 'PUT', body: queryArg.locationOfInterestUpdateModel }),
        invalidatesTags: ['Location of Interest']
      }),
      createLocationOfInterest: build.mutation<CreateLocationOfInterestResponse, CreateLocationOfInterestArgs>({
        query: (queryArg) => ({ url: `/api/locations`, method: 'POST', body: queryArg.locationOfInterestCreateModel }),
        invalidatesTags: ['Location of Interest']
      }),
      enableOrganizationIntegration: build.mutation<
        EnableOrganizationIntegrationResponse,
        EnableOrganizationIntegrationArgs
      >({
        query: (queryArg) => ({ url: `/api/integrations/${queryArg.id}/enable`, method: 'PUT' }),
        invalidatesTags: ['Integrations']
      }),
      disableIntegration: build.mutation<DisableIntegrationResponse, DisableIntegrationArgs>({
        query: (queryArg) => ({ url: `/api/integrations/${queryArg.id}/disable`, method: 'PUT' }),
        invalidatesTags: ['Integrations']
      }),
      updateFleet: build.mutation<UpdateFleetResponse, UpdateFleetArgs>({
        query: (queryArg) => ({ url: `/api/fleets`, method: 'PUT', body: queryArg.fleetModel }),
        invalidatesTags: ['Fleets', 'Organizations']
      }),
      createFleet1: build.mutation<CreateFleet1Response, CreateFleet1Args>({
        query: (queryArg) => ({ url: `/api/fleets`, method: 'POST', body: queryArg.fleetCreateModel }),
        invalidatesTags: ['Fleets', 'Organizations']
      }),
      assignVehicleToFleet: build.mutation<AssignVehicleToFleetResponse, AssignVehicleToFleetArgs>({
        query: (queryArg) => ({
          url: `/api/fleets/${queryArg.fleetId}/vehicles/${queryArg.vehicleId}/assign`,
          method: 'PUT'
        }),
        invalidatesTags: ['Fleets', 'Organizations']
      }),
      updateAlarm: build.mutation<UpdateAlarmResponse, UpdateAlarmArgs>({
        query: (queryArg) => ({ url: `/api/alarms/${queryArg.id}`, method: 'PUT', body: queryArg.alarmModel }),
        invalidatesTags: ['Alarms']
      }),
      enableAlarmsForOrganization: build.mutation<EnableAlarmsForOrganizationResponse, EnableAlarmsForOrganizationArgs>(
        {
          query: (queryArg) => ({
            url: `/api/alarms/organization/${queryArg.id}/enable`,
            method: 'PUT',
            params: { defaultAlarmId: queryArg.defaultAlarmId }
          }),
          invalidatesTags: ['Alarms']
        }
      ),
      disableAlarmsForOrganization: build.mutation<
        DisableAlarmsForOrganizationResponse,
        DisableAlarmsForOrganizationArgs
      >({
        query: (queryArg) => ({
          url: `/api/alarms/organization/${queryArg.id}/disable`,
          method: 'PUT',
          params: { defaultAlarmId: queryArg.defaultAlarmId }
        }),
        invalidatesTags: ['Alarms']
      }),
      addVehicleEvent: build.mutation<AddVehicleEventResponse, AddVehicleEventArgs>({
        query: (queryArg) => ({ url: `/api/vehicles/events`, method: 'POST', body: queryArg.vehicleEvent }),
        invalidatesTags: ['Vehicles']
      }),
      initiateMfaSetup: build.query<InitiateMfaSetupResponse, InitiateMfaSetupArgs>({
        query: () => ({ url: `/api/users/setup-mfa` }),
        providesTags: ['Users']
      }),
      verifySoftwareToken: build.mutation<VerifySoftwareTokenResponse, VerifySoftwareTokenArgs>({
        query: (queryArg) => ({ url: `/api/users/setup-mfa`, method: 'POST', params: { userCode: queryArg.userCode } }),
        invalidatesTags: ['Users']
      }),
      createTripNote: build.mutation<CreateTripNoteResponse, CreateTripNoteArgs>({
        query: (queryArg) => ({ url: `/api/trips/notes`, method: 'POST', body: queryArg.tripNote }),
        invalidatesTags: ['Trips']
      }),
      getScheduledRoutes: build.query<GetScheduledRoutesResponse, GetScheduledRoutesArgs>({
        query: (queryArg) => ({
          url: `/api/scheduled-routes`,
          params: {
            vehicleId: queryArg.vehicleId,
            siteId: queryArg.siteId,
            ocppTag: queryArg.ocppTag,
            scheduledRouteType: queryArg.scheduledRouteType,
            departFrom: queryArg.departFrom,
            departTo: queryArg.departTo,
            arriveFrom: queryArg.arriveFrom,
            arriveTo: queryArg.arriveTo,
            page: queryArg.page,
            size: queryArg.size
          }
        }),
        providesTags: ['Vehicle Schedules']
      }),
      createScheduledRoute: build.mutation<CreateScheduledRouteResponse, CreateScheduledRouteArgs>({
        query: (queryArg) => ({ url: `/api/scheduled-routes`, method: 'POST', body: queryArg.scheduleRouteRequest }),
        invalidatesTags: ['Vehicle Schedules']
      }),
      assignVehicle: build.mutation<AssignVehicleResponse, AssignVehicleArgs>({
        query: (queryArg) => ({
          url: `/api/organizations/${queryArg.id}/vehicles/${queryArg.vehicleId}/assign`,
          method: 'POST'
        }),
        invalidatesTags: ['Organizations']
      }),
      onboardOrganizationToStripe: build.mutation<OnboardOrganizationToStripeResponse, OnboardOrganizationToStripeArgs>(
        {
          query: (queryArg) => ({
            url: `/api/organizations/${queryArg.id}/stripe/onboard`,
            method: 'POST',
            body: queryArg.urlRedirectModel
          }),
          invalidatesTags: ['Organizations']
        }
      ),
      saveOrganizationLogo: build.mutation<SaveOrganizationLogoResponse, SaveOrganizationLogoArgs>({
        query: (queryArg) => ({ url: `/api/organizations/${queryArg.id}/logo`, method: 'POST', body: queryArg.body }),
        invalidatesTags: ['Organizations']
      }),
      deleteOrganizationLogo: build.mutation<DeleteOrganizationLogoResponse, DeleteOrganizationLogoArgs>({
        query: (queryArg) => ({ url: `/api/organizations/${queryArg.id}/logo`, method: 'DELETE' }),
        invalidatesTags: ['Organizations']
      }),
      getApiKeysByOrganizationId: build.query<GetApiKeysByOrganizationIdResponse, GetApiKeysByOrganizationIdArgs>({
        query: (queryArg) => ({ url: `/api/organizations/${queryArg.id}/keys` }),
        providesTags: ['Organizations']
      }),
      provisionApiKeyByOrganizationId: build.mutation<
        ProvisionApiKeyByOrganizationIdResponse,
        ProvisionApiKeyByOrganizationIdArgs
      >({
        query: (queryArg) => ({ url: `/api/organizations/${queryArg.id}/keys`, method: 'POST' }),
        invalidatesTags: ['Organizations']
      }),
      getIntegrationsByOrganizationId: build.query<
        GetIntegrationsByOrganizationIdResponse,
        GetIntegrationsByOrganizationIdArgs
      >({
        query: (queryArg) => ({ url: `/api/organizations/${queryArg.id}/integrations` }),
        providesTags: ['Organizations']
      }),
      saveOrganizationIntegration: build.mutation<SaveOrganizationIntegrationResponse, SaveOrganizationIntegrationArgs>(
        {
          query: (queryArg) => ({
            url: `/api/organizations/${queryArg.id}/integrations`,
            method: 'POST',
            body: queryArg.organizationIntegration
          }),
          invalidatesTags: ['Organizations']
        }
      ),
      saveOrganizationIcon: build.mutation<SaveOrganizationIconResponse, SaveOrganizationIconArgs>({
        query: (queryArg) => ({ url: `/api/organizations/${queryArg.id}/icon`, method: 'POST', body: queryArg.body }),
        invalidatesTags: ['Organizations']
      }),
      deleteOrganizationIcon: build.mutation<DeleteOrganizationIconResponse, DeleteOrganizationIconArgs>({
        query: (queryArg) => ({ url: `/api/organizations/${queryArg.id}/icon`, method: 'DELETE' }),
        invalidatesTags: ['Organizations']
      }),
      getOrganizationCustomers: build.query<GetOrganizationCustomersResponse, GetOrganizationCustomersArgs>({
        query: (queryArg) => ({
          url: `/api/organizations/${queryArg.id}/customers`,
          params: { skipEnhanceData: queryArg.skipEnhanceData }
        }),
        providesTags: ['Organizations']
      }),
      createFleet: build.mutation<CreateFleetResponse, CreateFleetArgs>({
        query: (queryArg) => ({
          url: `/api/organizations/${queryArg.id}/customers`,
          method: 'POST',
          body: queryArg.organization
        }),
        invalidatesTags: ['Organizations']
      }),
      publishToSns: build.mutation<PublishToSnsResponse, PublishToSnsArgs>({
        query: (queryArg) => ({
          url: `/api/events`,
          method: 'POST',
          body: queryArg.body,
          params: { subEntity: queryArg.subEntity, event: queryArg.event }
        }),
        invalidatesTags: ['EntityEvents']
      }),
      assignVehicleToBillingOrganization: build.mutation<
        AssignVehicleToBillingOrganizationResponse,
        AssignVehicleToBillingOrganizationArgs
      >({
        query: (queryArg) => ({
          url: `/api/billing/organizations/${queryArg.id}/vehicles/${queryArg.vehicleId}/assign`,
          method: 'POST'
        }),
        invalidatesTags: ['Organizations']
      }),
      saveNotificationMappingForAlarms: build.mutation<
        SaveNotificationMappingForAlarmsResponse,
        SaveNotificationMappingForAlarmsArgs
      >({
        query: (queryArg) => ({ url: `/api/alarms/notifications`, method: 'POST', body: queryArg.body }),
        invalidatesTags: ['Alarms']
      }),
      deleteNotificationMappingForAlarms: build.mutation<
        DeleteNotificationMappingForAlarmsResponse,
        DeleteNotificationMappingForAlarmsArgs
      >({
        query: (queryArg) => ({
          url: `/api/alarms/notifications`,
          method: 'DELETE',
          body: queryArg.alarmNotificationMapping
        }),
        invalidatesTags: ['Alarms']
      }),
      getVehicle: build.query<GetVehicleResponse, GetVehicleArgs>({
        query: (queryArg) => ({ url: `/api/vehicles/${queryArg.id}` }),
        providesTags: ['Vehicles']
      }),
      getLatestVehicleWeatherForecast: build.query<
        GetLatestVehicleWeatherForecastResponse,
        GetLatestVehicleWeatherForecastArgs
      >({
        query: (queryArg) => ({ url: `/api/vehicles/${queryArg.id}/weather` }),
        providesTags: ['Vehicles']
      }),
      getTrips: build.query<GetTripsResponse, GetTripsArgs>({
        query: (queryArg) => ({
          url: `/api/vehicles/${queryArg.id}/trips`,
          params: { from: queryArg['from'], to: queryArg.to, source: queryArg.source }
        }),
        providesTags: ['Vehicles']
      }),
      getTrip: build.query<GetTripResponse, GetTripArgs>({
        query: (queryArg) => ({ url: `/api/vehicles/${queryArg.id}/trips/${queryArg.tripId}` }),
        providesTags: ['Vehicles']
      }),
      getVehicleTelematics: build.query<GetVehicleTelematicsResponse, GetVehicleTelematicsArgs>({
        query: (queryArg) => ({
          url: `/api/vehicles/${queryArg.id}/telematics`,
          params: { from: queryArg['from'], to: queryArg.to }
        }),
        providesTags: ['Vehicles']
      }),
      getDailySummaryForVehicle: build.query<GetDailySummaryForVehicleResponse, GetDailySummaryForVehicleArgs>({
        query: (queryArg) => ({
          url: `/api/vehicles/${queryArg.id}/summary`,
          params: { date: queryArg.date, isCurrentDate: queryArg.isCurrentDate }
        }),
        providesTags: ['Vehicles']
      }),
      getServiceableRangeForVehicle: build.query<
        GetServiceableRangeForVehicleResponse,
        GetServiceableRangeForVehicleArgs
      >({
        query: (queryArg) => ({ url: `/api/vehicles/${queryArg.id}/range` }),
        providesTags: ['Vehicles']
      }),
      getMetricsForVehicle: build.query<GetMetricsForVehicleResponse, GetMetricsForVehicleArgs>({
        query: (queryArg) => ({ url: `/api/vehicles/${queryArg.id}/metrics`, params: { date: queryArg.date } }),
        providesTags: ['Vehicles']
      }),
      getVehicleFaults: build.query<GetVehicleFaultsResponse, GetVehicleFaultsArgs>({
        query: (queryArg) => ({
          url: `/api/vehicles/${queryArg.id}/faults`,
          params: {
            page: queryArg.page,
            count: queryArg.count,
            sort: queryArg.sort,
            searchString: queryArg.searchString,
            from: queryArg['from'],
            to: queryArg.to
          }
        }),
        providesTags: ['Vehicle Faults']
      }),
      getAssetEvents: build.query<GetAssetEventsResponse, GetAssetEventsArgs>({
        query: (queryArg) => ({
          url: `/api/vehicles/${queryArg.id}/events/`,
          params: { type: queryArg['type'], from: queryArg['from'], to: queryArg.to }
        }),
        providesTags: ['Vehicles']
      }),
      getVehicleStatusBreakdownForVehicles: build.query<
        GetVehicleStatusBreakdownForVehiclesResponse,
        GetVehicleStatusBreakdownForVehiclesArgs
      >({
        query: (queryArg) => ({
          url: `/api/vehicles/vehicle-status-timespan-data`,
          params: {
            from: queryArg['from'],
            to: queryArg.to,
            organizationId: queryArg.organizationId,
            fleetId: queryArg.fleetId
          }
        }),
        providesTags: ['Vehicles']
      }),
      getTimespanDataForVehicles: build.query<GetTimespanDataForVehiclesResponse, GetTimespanDataForVehiclesArgs>({
        query: (queryArg) => ({
          url: `/api/vehicles/timespan-data`,
          params: {
            from: queryArg['from'],
            to: queryArg.to,
            organizationId: queryArg.organizationId,
            fleetId: queryArg.fleetId
          }
        }),
        providesTags: ['Vehicles']
      }),
      getVehiclesBySite: build.query<GetVehiclesBySiteResponse, GetVehiclesBySiteArgs>({
        query: (queryArg) => ({ url: `/api/vehicles/site/${queryArg.id}` }),
        providesTags: ['Vehicles']
      }),
      getVehicleReport: build.query<GetVehicleReportResponse, GetVehicleReportArgs>({
        query: (queryArg) => ({
          url: `/api/vehicles/report`,
          params: {
            from: queryArg['from'],
            to: queryArg.to,
            organizationId: queryArg.organizationId,
            fleetId: queryArg.fleetId,
            siteId: queryArg.siteId
          }
        }),
        providesTags: ['Vehicles']
      }),
      getMetricsForVehicles: build.query<GetMetricsForVehiclesResponse, GetMetricsForVehiclesArgs>({
        query: (queryArg) => ({
          url: `/api/vehicles/metrics`,
          params: {
            from: queryArg['from'],
            to: queryArg.to,
            organizationId: queryArg.organizationId,
            fleetId: queryArg.fleetId,
            siteId: queryArg.siteId
          }
        }),
        providesTags: ['Vehicles']
      }),
      getLatestVehicleStatuses: build.query<GetLatestVehicleStatusesResponse, GetLatestVehicleStatusesArgs>({
        query: (queryArg) => ({ url: `/api/vehicles/latest-statuses`, params: { siteId: queryArg.siteId } }),
        providesTags: ['Vehicles']
      }),
      getEnergyBreakdownDataForVehicles: build.query<
        GetEnergyBreakdownDataForVehiclesResponse,
        GetEnergyBreakdownDataForVehiclesArgs
      >({
        query: (queryArg) => ({
          url: `/api/vehicles/energy-timespan-data`,
          params: {
            from: queryArg['from'],
            to: queryArg.to,
            organizationId: queryArg.organizationId,
            fleetId: queryArg.fleetId
          }
        }),
        providesTags: ['Vehicles']
      }),
      getMetricsForVehicles1: build.query<GetMetricsForVehicles1Response, GetMetricsForVehicles1Args>({
        query: (queryArg) => ({
          url: `/api/vehicles/aggregate-timespan-data`,
          params: {
            from: queryArg['from'],
            to: queryArg.to,
            organizationId: queryArg.organizationId,
            fleetId: queryArg.fleetId
          }
        }),
        providesTags: ['Vehicles']
      }),
      getVehicleFaults1: build.query<GetVehicleFaults1Response, GetVehicleFaults1Args>({
        query: (queryArg) => ({
          url: `/api/v2/vehicles/${queryArg.id}/faults`,
          params: {
            page: queryArg.page,
            count: queryArg.count,
            sort: queryArg.sort,
            searchString: queryArg.searchString,
            from: queryArg['from'],
            to: queryArg.to
          }
        }),
        providesTags: ['Vehicle Faults']
      }),
      getUser: build.query<GetUserResponse, GetUserArgs>({
        query: (queryArg) => ({
          url: `/api/users/${queryArg.id}`,
          params: { skipEnhanceUserData: queryArg.skipEnhanceUserData }
        }),
        providesTags: ['Users']
      }),
      getUserTagMappings: build.query<GetUserTagMappingsResponse, GetUserTagMappingsArgs>({
        query: (queryArg) => ({ url: `/api/users/tags`, params: { ocppTags: queryArg.ocppTags } }),
        providesTags: ['Users']
      }),
      getDropdownOptions: build.query<GetDropdownOptionsResponse, GetDropdownOptionsArgs>({
        query: () => ({ url: `/api/ui/options` }),
        providesTags: ['Users']
      }),
      getTripNotes: build.query<GetTripNotesResponse, GetTripNotesArgs>({
        query: (queryArg) => ({ url: `/api/trips/${queryArg.id}/notes` }),
        providesTags: ['Trips']
      }),
      getTripReport: build.query<GetTripReportResponse, GetTripReportArgs>({
        query: (queryArg) => ({
          url: `/api/trips/report`,
          params: {
            from: queryArg['from'],
            to: queryArg.to,
            organizationId: queryArg.organizationId,
            fleetId: queryArg.fleetId,
            siteId: queryArg.siteId
          }
        }),
        providesTags: ['Trips']
      }),
      getVehicleUtilization: build.query<GetVehicleUtilizationResponse, GetVehicleUtilizationArgs>({
        query: (queryArg) => ({
          url: `/api/tiles/vehicle-utilization`,
          params: {
            from: queryArg['from'],
            to: queryArg.to,
            organizationId: queryArg.organizationId,
            fleetId: queryArg.fleetId,
            siteId: queryArg.siteId
          }
        }),
        providesTags: ['Tile']
      }),
      getVehicleBreakdown: build.query<GetVehicleBreakdownResponse, GetVehicleBreakdownArgs>({
        query: (queryArg) => ({
          url: `/api/tiles/vehicle-breakdown`,
          params: {
            from: queryArg['from'],
            to: queryArg.to,
            organizationId: queryArg.organizationId,
            fleetId: queryArg.fleetId,
            siteId: queryArg.siteId
          }
        }),
        providesTags: ['Tile']
      }),
      getRouteById: build.query<GetRouteByIdResponse, GetRouteByIdArgs>({
        query: (queryArg) => ({ url: `/api/routes/${queryArg.id}` }),
        providesTags: ['Routes']
      }),
      deleteRoute: build.mutation<DeleteRouteResponse, DeleteRouteArgs>({
        query: (queryArg) => ({ url: `/api/routes/${queryArg.id}`, method: 'DELETE' }),
        invalidatesTags: ['Routes']
      }),
      getRouteSchedule: build.query<GetRouteScheduleResponse, GetRouteScheduleArgs>({
        query: (queryArg) => ({
          url: `/api/routes/${queryArg.id}/schedule`,
          params: { from: queryArg['from'], to: queryArg.to }
        }),
        providesTags: ['Routes']
      }),
      getStatusOfGeneratedReport: build.query<GetStatusOfGeneratedReportResponse, GetStatusOfGeneratedReportArgs>({
        query: (queryArg) => ({ url: `/api/report/${queryArg.id}` }),
        providesTags: ['Notifications']
      }),
      getOrganization: build.query<GetOrganizationResponse, GetOrganizationArgs>({
        query: (queryArg) => ({ url: `/api/organizations/${queryArg.id}` }),
        providesTags: ['Organizations']
      }),
      getVehiclesForOrganization: build.query<GetVehiclesForOrganizationResponse, GetVehiclesForOrganizationArgs>({
        query: (queryArg) => ({ url: `/api/organizations/${queryArg.id}/vehicles` }),
        providesTags: ['Organizations', 'Vehicles']
      }),
      getOrganizationUsers: build.query<GetOrganizationUsersResponse, GetOrganizationUsersArgs>({
        query: (queryArg) => ({
          url: `/api/organizations/${queryArg.id}/users`,
          params: { skipEnhanceData: queryArg.skipEnhanceData }
        }),
        providesTags: ['Organizations']
      }),
      getTrips1: build.query<GetTrips1Response, GetTrips1Args>({
        query: (queryArg) => ({
          url: `/api/organizations/${queryArg.id}/trips`,
          params: { from: queryArg['from'], to: queryArg.to, vehicleIds: queryArg.vehicleIds, source: queryArg.source }
        }),
        providesTags: ['Organizations']
      }),
      getOrganizationTerms: build.query<GetOrganizationTermsResponse, GetOrganizationTermsArgs>({
        query: (queryArg) => ({ url: `/api/organizations/${queryArg.id}/terms` }),
        providesTags: ['Organizations']
      }),
      loginToStripe: build.query<LoginToStripeResponse, LoginToStripeArgs>({
        query: (queryArg) => ({ url: `/api/organizations/${queryArg.id}/stripe/login` }),
        providesTags: ['Organizations']
      }),
      getStripeIntentDetail: build.query<GetStripeIntentDetailResponse, GetStripeIntentDetailArgs>({
        query: (queryArg) => ({ url: `/api/organizations/${queryArg.id}/secret` }),
        providesTags: ['Organizations']
      }),
      getRoutesForOrganization: build.query<GetRoutesForOrganizationResponse, GetRoutesForOrganizationArgs>({
        query: (queryArg) => ({ url: `/api/organizations/${queryArg.id}/routes` }),
        providesTags: ['Organizations']
      }),
      getIntegrationsByOrganizationIdAndIntegrationType: build.query<
        GetIntegrationsByOrganizationIdAndIntegrationTypeResponse,
        GetIntegrationsByOrganizationIdAndIntegrationTypeArgs
      >({
        query: (queryArg) => ({ url: `/api/organizations/${queryArg.id}/integrations/${queryArg.integrationId}` }),
        providesTags: ['Organizations']
      }),
      getAllOcppTags: build.query<GetAllOcppTagsResponse, GetAllOcppTagsArgs>({
        query: (queryArg) => ({ url: `/api/organizations/${queryArg.id}/id-tags` }),
        providesTags: ['Organizations']
      }),
      getFleets: build.query<GetFleetsResponse, GetFleetsArgs>({
        query: (queryArg) => ({ url: `/api/organizations/${queryArg.id}/fleets` }),
        providesTags: ['Organizations']
      }),
      getAllOrganizationCustomers: build.query<GetAllOrganizationCustomersResponse, GetAllOrganizationCustomersArgs>({
        query: (queryArg) => ({
          url: `/api/organizations/${queryArg.id}/customers/all`,
          params: { depth: queryArg.depth }
        }),
        providesTags: ['Organizations']
      }),
      getOrganizationConfig: build.query<GetOrganizationConfigResponse, GetOrganizationConfigArgs>({
        query: (queryArg) => ({ url: `/api/organizations/${queryArg.id}/config` }),
        providesTags: ['Organizations']
      }),
      getOrganizationApiKeyByApiKey: build.query<
        GetOrganizationApiKeyByApiKeyResponse,
        GetOrganizationApiKeyByApiKeyArgs
      >({
        query: (queryArg) => ({ url: `/api/organizations/key/${queryArg.id}` }),
        providesTags: ['Organizations']
      }),
      getCurrentOrganization: build.query<GetCurrentOrganizationResponse, GetCurrentOrganizationArgs>({
        query: () => ({ url: `/api/organizations/current` }),
        providesTags: ['Organizations']
      }),
      getLocationOfInterest: build.query<GetLocationOfInterestResponse, GetLocationOfInterestArgs>({
        query: (queryArg) => ({ url: `/api/locations/${queryArg.id}` }),
        providesTags: ['Location of Interest']
      }),
      deleteLocationOfInterest: build.mutation<DeleteLocationOfInterestResponse, DeleteLocationOfInterestArgs>({
        query: (queryArg) => ({ url: `/api/locations/${queryArg.id}`, method: 'DELETE' }),
        invalidatesTags: ['Location of Interest']
      }),
      getIntegrations: build.query<GetIntegrationsResponse, GetIntegrationsArgs>({
        query: () => ({ url: `/api/integrations` }),
        providesTags: ['Integrations']
      }),
      getFleet: build.query<GetFleetResponse, GetFleetArgs>({
        query: (queryArg) => ({ url: `/api/fleets/${queryArg.fleetId}` }),
        providesTags: ['Fleets']
      }),
      getCustomers: build.query<GetCustomersResponse, GetCustomersArgs>({
        query: (queryArg) => ({
          url: `/api/customers`,
          params: {
            organizationId: queryArg.organizationId,
            page: queryArg.page,
            count: queryArg.count,
            depth: queryArg.depth
          }
        }),
        providesTags: ['Customers']
      }),
      getChartValues: build.query<GetChartValuesResponse, GetChartValuesArgs>({
        query: (queryArg) => ({
          url: `/api/chart/${queryArg.id}`,
          params: { from: queryArg['from'], to: queryArg.to, attributes: queryArg.attributes }
        }),
        providesTags: ['Chart']
      }),
      getAlarmsForFleet: build.query<GetAlarmsForFleetResponse, GetAlarmsForFleetArgs>({
        query: (queryArg) => ({ url: `/api/alarms`, params: { organizationId: queryArg.organizationId } }),
        providesTags: ['Alarms']
      }),
      getNotificationMappingForAlarms: build.query<
        GetNotificationMappingForAlarmsResponse,
        GetNotificationMappingForAlarmsArgs
      >({
        query: (queryArg) => ({ url: `/api/alarms/${queryArg.id}/notifications` }),
        providesTags: ['Alarms']
      }),
      getTriggeredAlarms: build.query<GetTriggeredAlarmsResponse, GetTriggeredAlarmsArgs>({
        query: (queryArg) => ({ url: `/api/alarms/triggered`, params: { from: queryArg['from'], to: queryArg.to } }),
        providesTags: ['Alarms']
      }),
      getServiceEvent: build.query<GetServiceEventResponse, GetServiceEventArgs>({
        query: (queryArg) => ({ url: `/api/service-events/${queryArg.eventId}` }),
        providesTags: ['Charger Commissioning Events']
      }),
      updateServiceEvent: build.mutation<UpdateServiceEventResponse, UpdateServiceEventArgs>({
        query: (queryArg) => ({
          url: `/api/service-events/${queryArg.eventId}`,
          method: 'PUT',
          body: queryArg.updateServiceEventModel
        }),
        invalidatesTags: ['Charger Commissioning Events']
      }),
      deleteServiceEvent: build.mutation<DeleteServiceEventResponse, DeleteServiceEventArgs>({
        query: (queryArg) => ({ url: `/api/service-events/${queryArg.eventId}`, method: 'DELETE' }),
        invalidatesTags: ['Charger Commissioning Events']
      }),
      getChargeProfiles: build.query<GetChargeProfilesResponse, GetChargeProfilesArgs>({
        query: (queryArg) => ({
          url: `/api/profiles`,
          params: {
            fleetId: queryArg.fleetId,
            depotId: queryArg.depotId,
            effectiveAfter: queryArg.effectiveAfter,
            orgDepth: queryArg.orgDepth,
            page: queryArg.page,
            count: queryArg.count
          }
        }),
        providesTags: ['Smart Charging']
      }),
      updateChargeProfile: build.mutation<UpdateChargeProfileResponse, UpdateChargeProfileArgs>({
        query: (queryArg) => ({
          url: `/api/profiles`,
          method: 'PUT',
          body: queryArg.chargeProfileUpdateModel,
          params: { force: queryArg.force, fleetId: queryArg.fleetId }
        }),
        invalidatesTags: ['Smart Charging']
      }),
      saveChargeProfile: build.mutation<SaveChargeProfileResponse, SaveChargeProfileArgs>({
        query: (queryArg) => ({
          url: `/api/profiles`,
          method: 'POST',
          body: queryArg.chargeProfileCreateModel,
          params: { fleetId: queryArg.fleetId }
        }),
        invalidatesTags: ['Smart Charging']
      }),
      clearChargingProfile: build.mutation<ClearChargingProfileResponse, ClearChargingProfileArgs>({
        query: (queryArg) => ({
          url: `/api/profiles`,
          method: 'DELETE',
          body: queryArg.chargeProfileActionRequest,
          params: { fleetId: queryArg.fleetId }
        }),
        invalidatesTags: ['Smart Charging']
      }),
      getDepots: build.query<GetDepotsResponse, GetDepotsArgs>({
        query: (queryArg) => ({
          url: `/api/depots`,
          params: {
            fleetId: queryArg.fleetId,
            organizationId: queryArg.organizationId,
            createdSince: queryArg.createdSince,
            updatedSince: queryArg.updatedSince,
            page: queryArg.page,
            count: queryArg.count
          }
        }),
        providesTags: ['Sites (Depots)']
      }),
      updateDepot: build.mutation<UpdateDepotResponse, UpdateDepotArgs>({
        query: (queryArg) => ({ url: `/api/depots`, method: 'PUT', body: queryArg.depotMetaDataUpdateModel }),
        invalidatesTags: ['Sites (Depots)']
      }),
      createDepot: build.mutation<CreateDepotResponse, CreateDepotArgs>({
        query: (queryArg) => ({ url: `/api/depots`, method: 'POST', body: queryArg.depotCreationModel }),
        invalidatesTags: ['Sites (Depots)']
      }),
      getDepotEnergyLimits: build.query<GetDepotEnergyLimitsResponse, GetDepotEnergyLimitsArgs>({
        query: (queryArg) => ({ url: `/api/depots/${queryArg.depotId}/limits` }),
        providesTags: ['Sites (Depots)']
      }),
      updateDepotEnergyLimits: build.mutation<UpdateDepotEnergyLimitsResponse, UpdateDepotEnergyLimitsArgs>({
        query: (queryArg) => ({
          url: `/api/depots/${queryArg.depotId}/limits`,
          method: 'PUT',
          body: queryArg.depotEnergyLimitsUpdateModel
        }),
        invalidatesTags: ['Sites (Depots)']
      }),
      getChargers: build.query<GetChargersResponse, GetChargersArgs>({
        query: (queryArg) => ({
          url: `/api/chargers`,
          params: {
            fleetId: queryArg.fleetId,
            organizationId: queryArg.organizationId,
            depotId: queryArg.depotId,
            siteIds: queryArg.siteIds,
            minimumPower: queryArg.minimumPower,
            availableFrom: queryArg.availableFrom,
            availableTo: queryArg.availableTo,
            createdSince: queryArg.createdSince,
            updatedSince: queryArg.updatedSince,
            chargerStatus: queryArg.chargerStatus,
            operationalStatus: queryArg.operationalStatus,
            connectivityStatus: queryArg.connectivityStatus,
            hasConnectorsPreparing: queryArg.hasConnectorsPreparing,
            hasConnectorsCharging: queryArg.hasConnectorsCharging,
            hasConnectorsFaulted: queryArg.hasConnectorsFaulted,
            hasConnectorsUnavailable: queryArg.hasConnectorsUnavailable,
            hasConnectorsAvailable: queryArg.hasConnectorsAvailable,
            hasConnectorsFinishing: queryArg.hasConnectorsFinishing,
            hasConnectorsSuspendedEV: queryArg.hasConnectorsSuspendedEv,
            hasConnectorsSuspendedEVSE: queryArg.hasConnectorsSuspendedEvse,
            heardSince: queryArg.heardSince,
            notHeardSince: queryArg.notHeardSince,
            searchString: queryArg.searchString,
            commissioningStates: queryArg.commissioningStates,
            orgDepth: queryArg.orgDepth,
            page: queryArg.page,
            count: queryArg.count,
            sortBy: queryArg.sortBy,
            order: queryArg.order
          }
        }),
        providesTags: ['Chargers']
      }),
      updateCharger: build.mutation<UpdateChargerResponse, UpdateChargerArgs>({
        query: (queryArg) => ({ url: `/api/chargers`, method: 'PUT', body: queryArg.chargerUpdateModel }),
        invalidatesTags: ['Chargers']
      }),
      createCharger: build.mutation<CreateChargerResponse, CreateChargerArgs>({
        query: (queryArg) => ({ url: `/api/chargers`, method: 'POST', body: queryArg.chargerCreationModel }),
        invalidatesTags: ['Chargers']
      }),
      getServiceEvents: build.query<GetServiceEventsResponse, GetServiceEventsArgs>({
        query: (queryArg) => ({
          url: `/api/service-events`,
          params: {
            chargerIds: queryArg.chargerIds,
            from: queryArg['from'],
            to: queryArg.to,
            page: queryArg.page,
            count: queryArg.count,
            sortBy: queryArg.sortBy,
            order: queryArg.order
          }
        }),
        providesTags: ['Charger Commissioning Events']
      }),
      createServiceEvent: build.mutation<CreateServiceEventResponse, CreateServiceEventArgs>({
        query: (queryArg) => ({ url: `/api/service-events`, method: 'POST', body: queryArg.createServiceEventModel }),
        invalidatesTags: ['Charger Commissioning Events']
      }),
      bulkCommissionDecommissionChargers: build.mutation<
        BulkCommissionDecommissionChargersResponse,
        BulkCommissionDecommissionChargersArgs
      >({
        query: (queryArg) => ({
          url: `/api/service-events/bulk-commission`,
          method: 'POST',
          body: queryArg.bulkCommissionActionRequestModel
        }),
        invalidatesTags: ['Charger Commissioning Events']
      }),
      setChargeProfile: build.mutation<SetChargeProfileResponse, SetChargeProfileArgs>({
        query: (queryArg) => ({
          url: `/api/profiles/set`,
          method: 'POST',
          body: queryArg.chargeProfileActionRequest,
          params: { fleetId: queryArg.fleetId }
        }),
        invalidatesTags: ['Smart Charging']
      }),
      cmsOcppTagsGetTags: build.query<CmsOcppTagsGetTagsResponse, CmsOcppTagsGetTagsArgs>({
        query: (queryArg) => ({
          url: `/api/organizations/tags`,
          params: {
            organizationId: queryArg.organizationId,
            orgDepth: queryArg.orgDepth,
            page: queryArg.page,
            count: queryArg.count
          }
        }),
        providesTags: ['Authorization Tags']
      }),
      cmsOcppTagsAddTags: build.mutation<CmsOcppTagsAddTagsResponse, CmsOcppTagsAddTagsArgs>({
        query: (queryArg) => ({ url: `/api/organizations/tags`, method: 'POST', body: queryArg.addTagsInputModel }),
        invalidatesTags: ['Authorization Tags']
      }),
      cmsOcppConfigurationChangeConfiguration: build.mutation<
        CmsOcppConfigurationChangeConfigurationResponse,
        CmsOcppConfigurationChangeConfigurationArgs
      >({
        query: (queryArg) => ({
          url: `/api/ocpp-configurations/${queryArg.chargerId}/change-configuration`,
          method: 'POST',
          body: queryArg.changeConfigurationKeyRequestModel
        }),
        invalidatesTags: ['OCPP Configurations API']
      }),
      cmsOcppConfigurationRefreshConfigurations: build.mutation<
        CmsOcppConfigurationRefreshConfigurationsResponse,
        CmsOcppConfigurationRefreshConfigurationsArgs
      >({
        query: (queryArg) => ({
          url: `/api/ocpp-configurations/refresh`,
          method: 'POST',
          body: queryArg.refreshChargerConfigurationParams
        }),
        invalidatesTags: ['OCPP Configurations API']
      }),
      cmsChargersGetChargingGroups: build.query<CmsChargersGetChargingGroupsResponse, CmsChargersGetChargingGroupsArgs>(
        {
          query: (queryArg) => ({
            url: `/api/groups/`,
            params: {
              organizationId: queryArg.organizationId,
              organizationIds: queryArg.organizationIds,
              orgDepth: queryArg.orgDepth,
              siteIds: queryArg.siteIds,
              page: queryArg.page,
              count: queryArg.count,
              sortBy: queryArg.sortBy,
              order: queryArg.order
            }
          }),
          providesTags: ['Charging Groups']
        }
      ),
      cmsChargersCreateChargingGroup: build.mutation<
        CmsChargersCreateChargingGroupResponse,
        CmsChargersCreateChargingGroupArgs
      >({
        query: (queryArg) => ({ url: `/api/groups/`, method: 'POST', body: queryArg.chargingGroupCreateModel }),
        invalidatesTags: ['Charging Groups']
      }),
      stopChargingTransaction: build.mutation<StopChargingTransactionResponse, StopChargingTransactionArgs>({
        query: (queryArg) => ({
          url: `/api/chargers/${queryArg.chargerId}/stop`,
          method: 'POST',
          params: {
            chargingSessionId: queryArg.chargingSessionId,
            chargingTransactionId: queryArg.chargingTransactionId
          }
        }),
        invalidatesTags: ['Chargers']
      }),
      startChargingTransaction: build.mutation<StartChargingTransactionResponse, StartChargingTransactionArgs>({
        query: (queryArg) => ({
          url: `/api/chargers/${queryArg.chargerId}/start`,
          method: 'POST',
          body: queryArg.chargingTransactionRequest
        }),
        invalidatesTags: ['Chargers']
      }),
      resetCharger: build.mutation<ResetChargerResponse, ResetChargerArgs>({
        query: (queryArg) => ({
          url: `/api/chargers/${queryArg.chargerId}/reset`,
          method: 'POST',
          params: { type: queryArg['type'] }
        }),
        invalidatesTags: ['Chargers']
      }),
      cmsOcppDestinationGetMultipleOcppDestinations: build.query<
        CmsOcppDestinationGetMultipleOcppDestinationsResponse,
        CmsOcppDestinationGetMultipleOcppDestinationsArgs
      >({
        query: (queryArg) => ({
          url: `/api/chargers/ocpp-destination/`,
          params: {
            ids: queryArg.ids,
            destinationIds: queryArg.destinationIds,
            page: queryArg.page,
            count: queryArg.count
          }
        }),
        providesTags: ['OcppDestination']
      }),
      cmsOcppDestinationCreateOcppDestination: build.mutation<
        CmsOcppDestinationCreateOcppDestinationResponse,
        CmsOcppDestinationCreateOcppDestinationArgs
      >({
        query: (queryArg) => ({
          url: `/api/chargers/ocpp-destination/`,
          method: 'POST',
          body: queryArg.createOcppDestinationModel
        }),
        invalidatesTags: ['OcppDestination']
      }),
      cmsChargersGetChargingGroup: build.query<CmsChargersGetChargingGroupResponse, CmsChargersGetChargingGroupArgs>({
        query: (queryArg) => ({ url: `/api/groups/${queryArg.id}` }),
        providesTags: ['Charging Groups']
      }),
      cmsChargersDeleteChargingGroup: build.mutation<
        CmsChargersDeleteChargingGroupResponse,
        CmsChargersDeleteChargingGroupArgs
      >({
        query: (queryArg) => ({ url: `/api/groups/${queryArg.id}`, method: 'DELETE' }),
        invalidatesTags: ['Charging Groups']
      }),
      cmsChargersUpdateChargingGroup: build.mutation<
        CmsChargersUpdateChargingGroupResponse,
        CmsChargersUpdateChargingGroupArgs
      >({
        query: (queryArg) => ({
          url: `/api/groups/${queryArg.id}`,
          method: 'PATCH',
          body: queryArg.chargingGroupUpdateModel
        }),
        invalidatesTags: ['Charging Groups']
      }),
      cmsOcppDestinationGetOcppDestination: build.query<
        CmsOcppDestinationGetOcppDestinationResponse,
        CmsOcppDestinationGetOcppDestinationArgs
      >({
        query: (queryArg) => ({ url: `/api/chargers/ocpp-destination/${queryArg.id}` }),
        providesTags: ['OcppDestination']
      }),
      cmsOcppDestinationDeleteOcppDestination: build.mutation<
        CmsOcppDestinationDeleteOcppDestinationResponse,
        CmsOcppDestinationDeleteOcppDestinationArgs
      >({
        query: (queryArg) => ({ url: `/api/chargers/ocpp-destination/${queryArg.id}`, method: 'DELETE' }),
        invalidatesTags: ['OcppDestination']
      }),
      cmsOcppDestinationUpdateOcppDestination: build.mutation<
        CmsOcppDestinationUpdateOcppDestinationResponse,
        CmsOcppDestinationUpdateOcppDestinationArgs
      >({
        query: (queryArg) => ({
          url: `/api/chargers/ocpp-destination/${queryArg.id}`,
          method: 'PATCH',
          body: queryArg.updateOcppDestinationModel
        }),
        invalidatesTags: ['OcppDestination']
      }),
      getChargingTransactions: build.query<GetChargingTransactionsResponse, GetChargingTransactionsArgs>({
        query: (queryArg) => ({
          url: `/api/transactions`,
          params: {
            chargerId: queryArg.chargerId,
            depotId: queryArg.depotId,
            organizationId: queryArg.organizationId,
            tagId: queryArg.tagId,
            startTimeFrom: queryArg.startTimeFrom,
            startTimeTo: queryArg.startTimeTo,
            stopTimeFrom: queryArg.stopTimeFrom,
            stopTimeTo: queryArg.stopTimeTo,
            status: queryArg.status,
            updatedSince: queryArg.updatedSince,
            page: queryArg.page,
            count: queryArg.count,
            order: queryArg.order
          }
        }),
        providesTags: ['Charging Transactions']
      }),
      getTransactionById: build.query<GetTransactionByIdResponse, GetTransactionByIdArgs>({
        query: (queryArg) => ({ url: `/api/transactions/${queryArg.id}` }),
        providesTags: ['Charging Transactions']
      }),
      getTransactionMeterValues: build.query<GetTransactionMeterValuesResponse, GetTransactionMeterValuesArgs>({
        query: (queryArg) => ({ url: `/api/transactions/${queryArg.id}/meter-values` }),
        providesTags: ['Charging Transactions']
      }),
      cmsChargingTransactionsGetMeterValueIntervalData: build.query<
        CmsChargingTransactionsGetMeterValueIntervalDataResponse,
        CmsChargingTransactionsGetMeterValueIntervalDataArgs
      >({
        query: (queryArg) => ({
          url: `/api/transactions/${queryArg.id}/meter-data`,
          params: {
            from: queryArg['from'],
            to: queryArg.to,
            intervalPeriod: queryArg.intervalPeriod,
            measureNames: queryArg.measureNames,
            truncateToTxnBounds: queryArg.truncateToTxnBounds
          }
        }),
        providesTags: ['Charging Transactions']
      }),
      getPowerUtilization: build.query<GetPowerUtilizationResponse, GetPowerUtilizationArgs>({
        query: (queryArg) => ({
          url: `/api/tiles/power-utilization`,
          params: {
            from: queryArg['from'],
            to: queryArg.to,
            intervalPeriod: queryArg.intervalPeriod,
            fleetId: queryArg.fleetId,
            orgDepth: queryArg.orgDepth
          }
        }),
        providesTags: ['Tiles API']
      }),
      getChargingSummary: build.query<GetChargingSummaryResponse, GetChargingSummaryArgs>({
        query: (queryArg) => ({
          url: `/api/tiles/charging-summary`,
          params: {
            fleetId: queryArg.fleetId,
            depotIds: queryArg.depotIds,
            from: queryArg['from'],
            to: queryArg.to,
            orgDepth: queryArg.orgDepth
          }
        }),
        providesTags: ['Tiles API']
      }),
      getServiceEventAudit: build.query<GetServiceEventAuditResponse, GetServiceEventAuditArgs>({
        query: (queryArg) => ({
          url: `/api/service-events-audit/${queryArg.eventId}`,
          params: {
            from: queryArg['from'],
            to: queryArg.to,
            page: queryArg.page,
            count: queryArg.count,
            sortBy: queryArg.sortBy,
            order: queryArg.order
          }
        }),
        providesTags: ['Charger Commissioning Events']
      }),
      getTransactionsReport: build.query<GetTransactionsReportResponse, GetTransactionsReportArgs>({
        query: (queryArg) => ({
          url: `/api/reports/transactions`,
          params: {
            from: queryArg['from'],
            to: queryArg.to,
            organizationId: queryArg.organizationId,
            siteId: queryArg.siteId,
            chargerId: queryArg.chargerId,
            commissioningStates: queryArg.commissioningStates,
            orgDepth: queryArg.orgDepth
          }
        }),
        providesTags: ['Reports']
      }),
      cmsReportsGetStatusOfTransactionReport: build.query<
        CmsReportsGetStatusOfTransactionReportResponse,
        CmsReportsGetStatusOfTransactionReportArgs
      >({
        query: (queryArg) => ({ url: `/api/reports/transactions/${queryArg.id}/status` }),
        providesTags: ['Reports']
      }),
      getMeterValueIntervalsReport: build.query<GetMeterValueIntervalsReportResponse, GetMeterValueIntervalsReportArgs>(
        {
          query: (queryArg) => ({
            url: `/api/reports/meter-value-intervals`,
            params: {
              siteId: queryArg.siteId,
              commissioningStates: queryArg.commissioningStates,
              intervalPeriod: queryArg.intervalPeriod,
              from: queryArg['from'],
              to: queryArg.to
            }
          }),
          providesTags: ['Reports']
        }
      ),
      getMeterValueIntervalsReportStatus: build.query<
        GetMeterValueIntervalsReportStatusResponse,
        GetMeterValueIntervalsReportStatusArgs
      >({
        query: (queryArg) => ({ url: `/api/reports/meter-value-intervals/${queryArg.id}/status` }),
        providesTags: ['Reports']
      }),
      getChargeProfile: build.query<GetChargeProfileResponse, GetChargeProfileArgs>({
        query: (queryArg) => ({ url: `/api/profiles/${queryArg.profileId}`, params: { fleetId: queryArg.fleetId } }),
        providesTags: ['Smart Charging']
      }),
      cmsOcppConfigurationGetLatestConfigurationsForCharger: build.query<
        CmsOcppConfigurationGetLatestConfigurationsForChargerResponse,
        CmsOcppConfigurationGetLatestConfigurationsForChargerArgs
      >({
        query: (queryArg) => ({ url: `/api/ocpp-configurations/${queryArg.chargerId}` }),
        providesTags: ['OCPP Configurations API']
      }),
      cmsOcppConfigurationGetRefreshStatusForCharger: build.query<
        CmsOcppConfigurationGetRefreshStatusForChargerResponse,
        CmsOcppConfigurationGetRefreshStatusForChargerArgs
      >({
        query: (queryArg) => ({ url: `/api/ocpp-configurations/${queryArg.chargerId}/refresh-status/` }),
        providesTags: ['OCPP Configurations API']
      }),
      cmsOcppConfigurationGetRefreshStatus: build.query<
        CmsOcppConfigurationGetRefreshStatusResponse,
        CmsOcppConfigurationGetRefreshStatusArgs
      >({
        query: (queryArg) => ({ url: `/api/ocpp-configurations/refresh-status/${queryArg.id}` }),
        providesTags: ['OCPP Configurations API']
      }),
      cmsOcppConfigurationGetLatestConfigurations: build.query<
        CmsOcppConfigurationGetLatestConfigurationsResponse,
        CmsOcppConfigurationGetLatestConfigurationsArgs
      >({
        query: (queryArg) => ({
          url: `/api/ocpp-configurations/`,
          params: {
            chargerIds: queryArg.chargerIds,
            siteIds: queryArg.siteIds,
            orgId: queryArg.orgId,
            orgDepth: queryArg.orgDepth
          }
        }),
        providesTags: ['OCPP Configurations API']
      }),
      getLiveUtilization: build.query<GetLiveUtilizationResponse, GetLiveUtilizationArgs>({
        query: (queryArg) => ({
          url: `/api/fleets/${queryArg.fleetId}/status`,
          params: { orgDepth: queryArg.orgDepth }
        }),
        providesTags: ['Fleets']
      }),
      getDepot: build.query<GetDepotResponse, GetDepotArgs>({
        query: (queryArg) => ({ url: `/api/depots/${queryArg.depotId}`, params: { include: queryArg.include } }),
        providesTags: ['Sites (Depots)']
      }),
      deleteDepot: build.mutation<DeleteDepotResponse, DeleteDepotArgs>({
        query: (queryArg) => ({ url: `/api/depots/${queryArg.depotId}`, method: 'DELETE' }),
        invalidatesTags: ['Sites (Depots)']
      }),
      getDepotUtilization: build.query<GetDepotUtilizationResponse, GetDepotUtilizationArgs>({
        query: (queryArg) => ({
          url: `/api/depots/${queryArg.depotId}/utilization`,
          params: {
            from: queryArg['from'],
            to: queryArg.to,
            intervalPeriod: queryArg.intervalPeriod,
            measureNames: queryArg.measureNames
          }
        }),
        providesTags: ['Sites (Depots)']
      }),
      getDepotUptime: build.query<GetDepotUptimeResponse, GetDepotUptimeArgs>({
        query: (queryArg) => ({
          url: `/api/depots/${queryArg.depotId}/uptime`,
          params: { from: queryArg['from'], to: queryArg.to }
        }),
        providesTags: ['Sites (Depots)']
      }),
      getDepotStatus: build.query<GetDepotStatusResponse, GetDepotStatusArgs>({
        query: (queryArg) => ({ url: `/api/depots/${queryArg.depotId}/status` }),
        providesTags: ['Sites (Depots)']
      }),
      getCharger: build.query<GetChargerResponse, GetChargerArgs>({
        query: (queryArg) => ({ url: `/api/chargers/${queryArg.chargerId}` }),
        providesTags: ['Chargers']
      }),
      deleteCharger: build.mutation<DeleteChargerResponse, DeleteChargerArgs>({
        query: (queryArg) => ({ url: `/api/chargers/${queryArg.chargerId}`, method: 'DELETE' }),
        invalidatesTags: ['Chargers']
      }),
      getChargerUtilizationTimeline: build.query<
        GetChargerUtilizationTimelineResponse,
        GetChargerUtilizationTimelineArgs
      >({
        query: (queryArg) => ({
          url: `/api/chargers/${queryArg.chargerId}/utilization`,
          params: {
            from: queryArg['from'],
            to: queryArg.to,
            intervalPeriod: queryArg.intervalPeriod,
            measureNames: queryArg.measureNames
          }
        }),
        providesTags: ['Chargers']
      }),
      getChargerUptime: build.query<GetChargerUptimeResponse, GetChargerUptimeArgs>({
        query: (queryArg) => ({
          url: `/api/chargers/${queryArg.chargerId}/uptime`,
          params: { from: queryArg['from'], to: queryArg.to }
        }),
        providesTags: ['Chargers']
      }),
      getMappedChargerProfiles: build.query<GetMappedChargerProfilesResponse, GetMappedChargerProfilesArgs>({
        query: (queryArg) => ({
          url: `/api/chargers/${queryArg.chargerId}/profiles`,
          params: { connectorId: queryArg.connectorId }
        }),
        providesTags: ['Chargers', 'Smart Charging']
      }),
      getChargerLogsReport: build.query<GetChargerLogsReportResponse, GetChargerLogsReportArgs>({
        query: (queryArg) => ({
          url: `/api/chargers/${queryArg.chargerId}/ocpp-logs`,
          params: { from: queryArg['from'], to: queryArg.to, ocppMessageTypes: queryArg.ocppMessageTypes }
        }),
        providesTags: ['OCPP Logs']
      }),
      getChargerFaults: build.query<GetChargerFaultsResponse, GetChargerFaultsArgs>({
        query: (queryArg) => ({
          url: `/api/chargers/${queryArg.chargerId}/faults`,
          params: { from: queryArg['from'], to: queryArg.to, page: queryArg.page, count: queryArg.count }
        }),
        providesTags: ['Chargers']
      }),
      getChargerConnectors: build.query<GetChargerConnectorsResponse, GetChargerConnectorsArgs>({
        query: (queryArg) => ({ url: `/api/chargers/${queryArg.chargerId}/connectors` }),
        providesTags: ['Chargers']
      }),
      cmsChargersGetConfigurationKeys: build.query<
        CmsChargersGetConfigurationKeysResponse,
        CmsChargersGetConfigurationKeysArgs
      >({
        query: (queryArg) => ({ url: `/api/chargers/${queryArg.chargerId}/configuration-keys` }),
        providesTags: ['Chargers']
      }),
      getCapabilitySet: build.query<GetCapabilitySetResponse, GetCapabilitySetArgs>({
        query: (queryArg) => ({ url: `/api/chargers/${queryArg.chargerId}/capability-set` }),
        providesTags: ['Chargers']
      }),
      cmsUtilizationGetUtilization: build.query<CmsUtilizationGetUtilizationResponse, CmsUtilizationGetUtilizationArgs>(
        {
          query: (queryArg) => ({
            url: `/api/chargers/utilization`,
            params: { organizationId: queryArg.organizationId, siteIds: queryArg.siteIds, orgDepth: queryArg.orgDepth }
          }),
          providesTags: ['Utilization']
        }
      ),
      getChargersTableData: build.query<GetChargersTableDataResponse, GetChargersTableDataArgs>({
        query: (queryArg) => ({
          url: `/api/chargers/timespan/table-data`,
          params: {
            organizationId: queryArg.organizationId,
            orgDepth: queryArg.orgDepth,
            siteIds: queryArg.siteIds,
            fromTs: queryArg.fromTs,
            toTs: queryArg.toTs,
            page: queryArg.page,
            count: queryArg.count,
            order: queryArg.order,
            sortBy: queryArg.sortBy,
            searchString: queryArg.searchString
          }
        }),
        providesTags: ['Charger/Connector Summary']
      }),
      getSummaryData: build.query<GetSummaryDataResponse, GetSummaryDataArgs>({
        query: (queryArg) => ({
          url: `/api/chargers/timespan/summary-data`,
          params: {
            organizationId: queryArg.organizationId,
            orgDepth: queryArg.orgDepth,
            siteIds: queryArg.siteIds,
            fromTs: queryArg.fromTs,
            toTs: queryArg.toTs
          }
        }),
        providesTags: ['Charger/Connector Summary']
      }),
      cmsTimespanGetHistoricalStatuses: build.query<
        CmsTimespanGetHistoricalStatusesResponse,
        CmsTimespanGetHistoricalStatusesArgs
      >({
        query: (queryArg) => ({
          url: `/api/chargers/timespan/statuses`,
          params: {
            organizationId: queryArg.organizationId,
            siteIds: queryArg.siteIds,
            chargerIds: queryArg.chargerIds,
            orgDepth: queryArg.orgDepth,
            fromTs: queryArg.fromTs,
            toTs: queryArg.toTs
          }
        }),
        providesTags: ['Charger/Connector Summary']
      }),
      cmsChargersGetChargerStatus: build.query<CmsChargersGetChargerStatusResponse, CmsChargersGetChargerStatusArgs>({
        query: (queryArg) => ({
          url: `/api/chargers/status/`,
          params: {
            organizationIds: queryArg.organizationIds,
            siteIds: queryArg.siteIds,
            organizationId: queryArg.organizationId,
            orgDepth: queryArg.orgDepth,
            chargerIds: queryArg.chargerIds,
            connectorIds: queryArg.connectorIds,
            statuses: queryArg.statuses,
            from: queryArg['from'],
            to: queryArg.to,
            page: queryArg.page,
            count: queryArg.count,
            sortBy: queryArg.sortBy,
            order: queryArg.order
          }
        }),
        providesTags: ['Chargers']
      }),
      cmsOcppLogsGetStatusOfOcppLogsRequest: build.query<
        CmsOcppLogsGetStatusOfOcppLogsRequestResponse,
        CmsOcppLogsGetStatusOfOcppLogsRequestArgs
      >({
        query: (queryArg) => ({ url: `/api/chargers/ocpp-logs/${queryArg.id}/status` }),
        providesTags: ['OCPP Logs']
      }),
      cmsMeterDataIntervals: build.query<CmsMeterDataIntervalsResponse, CmsMeterDataIntervalsArgs>({
        query: (queryArg) => ({
          url: `/api/chargers/meter-data/intervals`,
          params: {
            from: queryArg['from'],
            to: queryArg.to,
            siteIds: queryArg.siteIds,
            organizationId: queryArg.organizationId,
            orgDepth: queryArg.orgDepth
          }
        }),
        providesTags: ['Charging Transactions']
      }),
      getLatestStatusSummary: build.query<GetLatestStatusSummaryResponse, GetLatestStatusSummaryArgs>({
        query: (queryArg) => ({
          url: `/api/chargers/latest-statuses`,
          params: {
            organizationId: queryArg.organizationId,
            siteIds: queryArg.siteIds,
            orgDepth: queryArg.orgDepth,
            includeConnectorsByCharger: queryArg.includeConnectorsByCharger,
            includeConnectorsBySite: queryArg.includeConnectorsBySite,
            includeChargersBySite: queryArg.includeChargersBySite
          }
        }),
        providesTags: ['Chargers']
      }),
      cmsChargerFaultsGetFaults: build.query<CmsChargerFaultsGetFaultsResponse, CmsChargerFaultsGetFaultsArgs>({
        query: (queryArg) => ({
          url: `/api/chargers/faults`,
          params: {
            chargerIds: queryArg.chargerIds,
            siteIds: queryArg.siteIds,
            organizationId: queryArg.organizationId,
            occurredAfter: queryArg.occurredAfter,
            occurredBefore: queryArg.occurredBefore,
            searchString: queryArg.searchString,
            page: queryArg.page,
            count: queryArg.count,
            sortBy: queryArg.sortBy,
            order: queryArg.order
          }
        }),
        providesTags: ['Charger Faults']
      }),
      getManufacturers: build.query<GetManufacturersResponse, GetManufacturersArgs>({
        query: (queryArg) => ({
          url: `/api/charger-manufacturers`,
          params: { page: queryArg.page, count: queryArg.count }
        }),
        providesTags: ['Charger Capabilities']
      }),
      getManufacturerMakeModels: build.query<GetManufacturerMakeModelsResponse, GetManufacturerMakeModelsArgs>({
        query: (queryArg) => ({
          url: `/api/charger-manufacturers/${queryArg.manufacturerId}/make-models`,
          params: { page: queryArg.page, count: queryArg.count }
        }),
        providesTags: ['Charger Capabilities']
      }),
      getSiteGridProfiles: build.query<GetSiteGridProfilesResponse, GetSiteGridProfilesArgs>({
        query: (queryArg) => ({
          url: `/api/site-grid-profiles`,
          params: { gridEventIntegrationAccountId: queryArg.gridEventIntegrationAccountId }
        }),
        providesTags: ['Site Grid Profiles']
      }),
      updateSiteGridProfile: build.mutation<UpdateSiteGridProfileResponse, UpdateSiteGridProfileArgs>({
        query: (queryArg) => ({
          url: `/api/site-grid-profiles`,
          method: 'PUT',
          body: queryArg.updateSiteGridProfileRequestParams
        }),
        invalidatesTags: ['Site Grid Profiles']
      }),
      createSiteGridProfile: build.mutation<CreateSiteGridProfileResponse, CreateSiteGridProfileArgs>({
        query: (queryArg) => ({
          url: `/api/site-grid-profiles`,
          method: 'POST',
          body: queryArg.createSiteGridProfileRequestParams
        }),
        invalidatesTags: ['Site Grid Profiles']
      }),
      getScheduledTrips: build.query<GetScheduledTripsResponse, GetScheduledTripsArgs>({
        query: (queryArg) => ({
          url: `/api/scheduled-vehicle-trips`,
          params: {
            siteId: queryArg.siteId,
            vehicleId: queryArg.vehicleId,
            startsBefore: queryArg.startsBefore,
            startsAfter: queryArg.startsAfter,
            endsBefore: queryArg.endsBefore,
            endsAfter: queryArg.endsAfter,
            page: queryArg.page,
            count: queryArg.count,
            order: queryArg.order
          }
        }),
        providesTags: ['Scheduled Vehicle Trips']
      }),
      updateTrip: build.mutation<UpdateTripResponse, UpdateTripArgs>({
        query: (queryArg) => ({ url: `/api/scheduled-vehicle-trips`, method: 'PUT', body: queryArg.body }),
        invalidatesTags: ['Scheduled Vehicle Trips']
      }),
      createTrip: build.mutation<CreateTripResponse, CreateTripArgs>({
        query: (queryArg) => ({
          url: `/api/scheduled-vehicle-trips`,
          method: 'POST',
          body: queryArg.scheduledVehicleTripCreateModel
        }),
        invalidatesTags: ['Scheduled Vehicle Trips']
      }),
      deleteTrip: build.mutation<DeleteTripResponse, DeleteTripArgs>({
        query: (queryArg) => ({
          url: `/api/scheduled-vehicle-trips`,
          method: 'DELETE',
          body: queryArg.scheduledVehicleTripDeleteModel
        }),
        invalidatesTags: ['Scheduled Vehicle Trips']
      }),
      getRecurrenceSchedule: build.query<GetRecurrenceScheduleResponse, GetRecurrenceScheduleArgs>({
        query: (queryArg) => ({ url: `/api/scheduled-trip-recurrences/${queryArg.scheduleId}` }),
        providesTags: ['Scheduled Vehicle Trips']
      }),
      updateRecurrenceSchedule: build.mutation<UpdateRecurrenceScheduleResponse, UpdateRecurrenceScheduleArgs>({
        query: (queryArg) => ({
          url: `/api/scheduled-trip-recurrences/${queryArg.scheduleId}`,
          method: 'PUT',
          body: queryArg.scheduledTripRecurrenceUpdateModel,
          params: { vehicleIds: queryArg.vehicleIds, occurrenceIndex: queryArg.occurrenceIndex }
        }),
        invalidatesTags: ['Scheduled Vehicle Trips']
      }),
      deleteRecurrenceSchedule: build.mutation<DeleteRecurrenceScheduleResponse, DeleteRecurrenceScheduleArgs>({
        query: (queryArg) => ({
          url: `/api/scheduled-trip-recurrences/${queryArg.scheduleId}`,
          method: 'DELETE',
          params: { vehicleIds: queryArg.vehicleIds, occurrenceIndex: queryArg.occurrenceIndex }
        }),
        invalidatesTags: ['Scheduled Vehicle Trips']
      }),
      updateTripOccurrences: build.mutation<UpdateTripOccurrencesResponse, UpdateTripOccurrencesArgs>({
        query: (queryArg) => ({
          url: `/api/scheduled-trip-recurrences/${queryArg.scheduleId}/update-occurrences`,
          method: 'PUT',
          body: queryArg.scheduledTripRecurrenceUpdateOccurrencesModel,
          params: { vehicleIds: queryArg.vehicleIds, occurrenceIndex: queryArg.occurrenceIndex }
        }),
        invalidatesTags: ['Scheduled Vehicle Trips']
      }),
      getGridEventIntegrationAccounts: build.query<
        GetGridEventIntegrationAccountsResponse,
        GetGridEventIntegrationAccountsArgs
      >({
        query: (queryArg) => ({
          url: `/api/grid-event-integration-accounts`,
          params: { organizationId: queryArg.organizationId, gridEventIntegrationId: queryArg.gridEventIntegrationId }
        }),
        providesTags: ['Grid Event Integration Accounts']
      }),
      updateGridEventIntegrationAccount: build.mutation<
        UpdateGridEventIntegrationAccountResponse,
        UpdateGridEventIntegrationAccountArgs
      >({
        query: (queryArg) => ({
          url: `/api/grid-event-integration-accounts`,
          method: 'PUT',
          body: queryArg.updateGridEventIntegrationsAccountRequestParams
        }),
        invalidatesTags: ['Grid Event Integration Accounts']
      }),
      createGridEventIntegrationAccount: build.mutation<
        CreateGridEventIntegrationAccountResponse,
        CreateGridEventIntegrationAccountArgs
      >({
        query: (queryArg) => ({
          url: `/api/grid-event-integration-accounts`,
          method: 'POST',
          body: queryArg.createGridEventIntegrationsAccountRequestParams
        }),
        invalidatesTags: ['Grid Event Integration Accounts']
      }),
      getDepotEventsByDepotId: build.query<GetDepotEventsByDepotIdResponse, GetDepotEventsByDepotIdArgs>({
        query: (queryArg) => ({
          url: `/api/depots/${queryArg.depotId}/events`,
          params: { from: queryArg['from'], to: queryArg.to }
        }),
        providesTags: ['Grid']
      }),
      updateDepotEvent: build.mutation<UpdateDepotEventResponse, UpdateDepotEventArgs>({
        query: (queryArg) => ({
          url: `/api/depots/${queryArg.depotId}/events`,
          method: 'PUT',
          body: queryArg.depotEventModel,
          params: { isRecurrence: queryArg.isRecurrence }
        }),
        invalidatesTags: ['Grid']
      }),
      saveDepotEvent: build.mutation<SaveDepotEventResponse, SaveDepotEventArgs>({
        query: (queryArg) => ({
          url: `/api/depots/${queryArg.depotId}/events`,
          method: 'POST',
          body: queryArg.depotEventModel
        }),
        invalidatesTags: ['Grid']
      }),
      getScheduledChargerSession: build.query<GetScheduledChargerSessionResponse, GetScheduledChargerSessionArgs>({
        query: (queryArg) => ({ url: `/api/chargers/${queryArg.chargerId}/events/${queryArg.eventId}` }),
        providesTags: ['Schedule']
      }),
      updateScheduledChargerSession: build.mutation<
        UpdateScheduledChargerSessionResponse,
        UpdateScheduledChargerSessionArgs
      >({
        query: (queryArg) => ({
          url: `/api/chargers/${queryArg.chargerId}/events/${queryArg.eventId}`,
          method: 'PUT',
          body: queryArg.chargerEventUpdateModelDeprecated,
          params: { isRecurrence: queryArg.isRecurrence }
        }),
        invalidatesTags: ['Schedule']
      }),
      cancelScheduledChargerSession: build.mutation<
        CancelScheduledChargerSessionResponse,
        CancelScheduledChargerSessionArgs
      >({
        query: (queryArg) => ({
          url: `/api/chargers/${queryArg.chargerId}/events/${queryArg.eventId}`,
          method: 'DELETE',
          params: { isRecurrence: queryArg.isRecurrence }
        }),
        invalidatesTags: ['Schedule']
      }),
      getChargerEventById: build.query<GetChargerEventByIdResponse, GetChargerEventByIdArgs>({
        query: (queryArg) => ({ url: `/api/charger-events/${queryArg.eventId}` }),
        providesTags: ['Charger Events']
      }),
      updateChargerEvent: build.mutation<UpdateChargerEventResponse, UpdateChargerEventArgs>({
        query: (queryArg) => ({
          url: `/api/charger-events/${queryArg.eventId}`,
          method: 'PUT',
          body: queryArg.chargerEventUpdateModel,
          params: { includeRecurring: queryArg.includeRecurring }
        }),
        invalidatesTags: ['Charger Events']
      }),
      cancelChargerEvent: build.mutation<CancelChargerEventResponse, CancelChargerEventArgs>({
        query: (queryArg) => ({
          url: `/api/charger-events/${queryArg.eventId}`,
          method: 'DELETE',
          params: { includeRecurring: queryArg.includeRecurring }
        }),
        invalidatesTags: ['Charger Events']
      }),
      moveChargerEvent: build.mutation<MoveChargerEventResponse, MoveChargerEventArgs>({
        query: (queryArg) => ({
          url: `/api/charger-events/${queryArg.eventId}/transaction-update`,
          method: 'PUT',
          body: queryArg.reassignEventConnectorModel
        }),
        invalidatesTags: ['Charger Events']
      }),
      getTelemetryExclusionPeriod: build.query<GetTelemetryExclusionPeriodResponse, GetTelemetryExclusionPeriodArgs>({
        query: (queryArg) => ({
          url: `/api/telemetry-exclusion-periods`,
          params: {
            page: queryArg.page,
            count: queryArg.count,
            sort: queryArg.sort,
            vehicleIds: queryArg.vehicleIds,
            startsAfter: queryArg.startsAfter,
            startsBefore: queryArg.startsBefore,
            endsAfter: queryArg.endsAfter,
            endsBefore: queryArg.endsBefore
          }
        }),
        providesTags: ['Telemetry Exclusion Periods']
      }),
      createTelemetryExclusionPeriod: build.mutation<
        CreateTelemetryExclusionPeriodResponse,
        CreateTelemetryExclusionPeriodArgs
      >({
        query: (queryArg) => ({
          url: `/api/telemetry-exclusion-periods`,
          method: 'POST',
          body: queryArg.createTelemetryExclusionRequestModel
        }),
        invalidatesTags: ['Telemetry Exclusion Periods']
      }),
      getSiteEnergyConfigurations: build.query<GetSiteEnergyConfigurationsResponse, GetSiteEnergyConfigurationsArgs>({
        query: (queryArg) => ({
          url: `/api/sites/${queryArg.siteId}/energy-configurations`,
          params: {
            latest: queryArg.latest,
            from: queryArg['from'],
            to: queryArg.to,
            count: queryArg.count,
            afterId: queryArg.afterId
          }
        }),
        providesTags: ['Site Feature Configurations']
      }),
      createSiteEnergyConfiguration: build.mutation<
        CreateSiteEnergyConfigurationResponse,
        CreateSiteEnergyConfigurationArgs
      >({
        query: (queryArg) => ({
          url: `/api/sites/${queryArg.siteId}/energy-configurations`,
          method: 'POST',
          body: queryArg.createSiteEnergyConfigurationParams
        }),
        invalidatesTags: ['Site Feature Configurations']
      }),
      createRecurrenceSchedule: build.mutation<CreateRecurrenceScheduleResponse, CreateRecurrenceScheduleArgs>({
        query: (queryArg) => ({
          url: `/api/scheduled-trip-recurrences`,
          method: 'POST',
          body: queryArg.scheduledTripRecurrenceCreateModel
        }),
        invalidatesTags: ['Scheduled Vehicle Trips']
      }),
      setRecurrenceScheduleVehicles: build.mutation<
        SetRecurrenceScheduleVehiclesResponse,
        SetRecurrenceScheduleVehiclesArgs
      >({
        query: (queryArg) => ({
          url: `/api/scheduled-trip-recurrences/${queryArg.scheduleId}/set-vehicles`,
          method: 'POST',
          body: queryArg.scheduledTripRecurrenceSetVehiclesModel
        }),
        invalidatesTags: ['Scheduled Vehicle Trips']
      }),
      getOptimizations: build.query<GetOptimizationsResponse, GetOptimizationsArgs>({
        query: (queryArg) => ({
          url: `/api/optimizations`,
          params: {
            fleetId: queryArg.fleetId,
            depotId: queryArg.depotId,
            from: queryArg['from'],
            to: queryArg.to,
            feasible: queryArg.feasible,
            parentOptimizationId: queryArg.parentOptimizationId,
            page: queryArg.page,
            count: queryArg.count
          }
        }),
        providesTags: ['Optimization']
      }),
      createOptimization: build.mutation<CreateOptimizationResponse, CreateOptimizationArgs>({
        query: (queryArg) => ({ url: `/api/optimizations`, method: 'POST', body: queryArg.optimizationModel }),
        invalidatesTags: ['Optimization']
      }),
      getLoadBalancerEvents: build.query<GetLoadBalancerEventsResponse, GetLoadBalancerEventsArgs>({
        query: (queryArg) => ({
          url: `/api/energy/events/load-balancer`,
          params: { page: queryArg.page, size: queryArg.size, sort: queryArg.sort }
        }),
        providesTags: ['Load Balancer Events']
      }),
      createLoadBalancerEvent: build.mutation<CreateLoadBalancerEventResponse, CreateLoadBalancerEventArgs>({
        query: (queryArg) => ({
          url: `/api/energy/events/load-balancer`,
          method: 'POST',
          params: {
            origin: queryArg.origin,
            eventType: queryArg.eventType,
            chargerId: queryArg.chargerId,
            fleetId: queryArg.fleetId,
            depotId: queryArg.depotId,
            originTime: queryArg.originTime,
            executionTime: queryArg.executionTime
          }
        }),
        invalidatesTags: ['Load Balancer Events']
      }),
      getEnergyEfficiencyPredictions: build.query<
        GetEnergyEfficiencyPredictionsResponse,
        GetEnergyEfficiencyPredictionsArgs
      >({
        query: (queryArg) => ({
          url: `/api/energy-efficiency-predictions`,
          params: {
            fleetId: queryArg.fleetId,
            depotId: queryArg.depotId,
            vehicleId: queryArg.vehicleId,
            modelName: queryArg.modelName,
            validFrom: queryArg.validFrom,
            validTo: queryArg.validTo,
            windowFrom: queryArg.windowFrom,
            windowTo: queryArg.windowTo,
            mostRecent: queryArg.mostRecent,
            confidenceLower: queryArg.confidenceLower,
            confidenceUpper: queryArg.confidenceUpper,
            page: queryArg.page,
            count: queryArg.count
          }
        }),
        providesTags: ['Optimization']
      }),
      createEnergyEfficiencyPrediction: build.mutation<
        CreateEnergyEfficiencyPredictionResponse,
        CreateEnergyEfficiencyPredictionArgs
      >({
        query: (queryArg) => ({
          url: `/api/energy-efficiency-predictions`,
          method: 'POST',
          body: queryArg.createEnergyEfficiencyPredictionRequestParams
        }),
        invalidatesTags: ['Optimization']
      }),
      getTransactionProfileMappings1: build.query<
        GetTransactionProfileMappings1Response,
        GetTransactionProfileMappings1Args
      >({
        query: (queryArg) => ({
          url: `/api/ems-profiles`,
          params: {
            transactionId: queryArg.transactionId,
            chargerId: queryArg.chargerId,
            from: queryArg['from'],
            to: queryArg.to,
            isMostRecent: queryArg.isMostRecent,
            page: queryArg.page,
            count: queryArg.count
          }
        }),
        providesTags: ['Transaction Profile Mapping']
      }),
      getTransactionProfileMappings: build.mutation<
        GetTransactionProfileMappingsResponse,
        GetTransactionProfileMappingsArgs
      >({
        query: (queryArg) => ({
          url: `/api/ems-profiles`,
          method: 'POST',
          body: queryArg.transactionProfileMappingModel
        }),
        invalidatesTags: ['Transaction Profile Mapping']
      }),
      getProfileHistories: build.query<GetProfileHistoriesResponse, GetProfileHistoriesArgs>({
        query: (queryArg) => ({
          url: `/api/chargers/${queryArg.chargerId}/profile-history`,
          params: {
            latest: queryArg.latest,
            from: queryArg['from'],
            to: queryArg.to,
            count: queryArg.count,
            afterId: queryArg.afterId
          }
        }),
        providesTags: ['Profile History Records']
      }),
      createProfileHistory: build.mutation<CreateProfileHistoryResponse, CreateProfileHistoryArgs>({
        query: (queryArg) => ({
          url: `/api/chargers/${queryArg.chargerId}/profile-history`,
          method: 'POST',
          body: queryArg.createProfileApplicationRecordParams
        }),
        invalidatesTags: ['Profile History Records']
      }),
      getScheduledChargerSessions: build.query<GetScheduledChargerSessionsResponse, GetScheduledChargerSessionsArgs>({
        query: (queryArg) => ({
          url: `/api/chargers/${queryArg.chargerId}/events`,
          params: {
            from: queryArg['from'],
            to: queryArg.to,
            type: queryArg['type'],
            page: queryArg.page,
            count: queryArg.count
          }
        }),
        providesTags: ['Schedule']
      }),
      createScheduledChargerSession: build.mutation<
        CreateScheduledChargerSessionResponse,
        CreateScheduledChargerSessionArgs
      >({
        query: (queryArg) => ({
          url: `/api/chargers/${queryArg.chargerId}/events`,
          method: 'POST',
          body: queryArg.scheduleRequestModel
        }),
        invalidatesTags: ['Schedule']
      }),
      getScheduledChargerSessionDetails: build.query<
        GetScheduledChargerSessionDetailsResponse,
        GetScheduledChargerSessionDetailsArgs
      >({
        query: (queryArg) => ({ url: `/api/chargers/${queryArg.chargerId}/events/${queryArg.eventId}/details` }),
        providesTags: ['Schedule']
      }),
      saveScheduledChargerSessionDetails: build.mutation<
        SaveScheduledChargerSessionDetailsResponse,
        SaveScheduledChargerSessionDetailsArgs
      >({
        query: (queryArg) => ({
          url: `/api/chargers/${queryArg.chargerId}/events/${queryArg.eventId}/details`,
          method: 'POST',
          body: queryArg.chargerEventSessionModel
        }),
        invalidatesTags: ['Schedule']
      }),
      createChargerEventPrediction: build.mutation<
        CreateChargerEventPredictionResponse,
        CreateChargerEventPredictionArgs
      >({
        query: (queryArg) => ({
          url: `/api/chargers/${queryArg.chargerId}/event-predictions`,
          method: 'POST',
          body: queryArg.chargerEventPredictionRequestModel
        }),
        invalidatesTags: ['Schedule']
      }),
      getChargerEvents: build.query<GetChargerEventsResponse, GetChargerEventsArgs>({
        query: (queryArg) => ({
          url: `/api/charger-events`,
          params: {
            siteId: queryArg.siteId,
            chargerId: queryArg.chargerId,
            connectorId: queryArg.connectorId,
            vehicleId: queryArg.vehicleId,
            eventType: queryArg.eventType,
            targetType: queryArg.targetType,
            startsBefore: queryArg.startsBefore,
            startsAfter: queryArg.startsAfter,
            endsBefore: queryArg.endsBefore,
            endsAfter: queryArg.endsAfter,
            page: queryArg.page,
            count: queryArg.count,
            order: queryArg.order
          }
        }),
        providesTags: ['Charger Events']
      }),
      createChargerEvent: build.mutation<CreateChargerEventResponse, CreateChargerEventArgs>({
        query: (queryArg) => ({ url: `/api/charger-events`, method: 'POST', body: queryArg.chargerEventCreateModel }),
        invalidatesTags: ['Charger Events']
      }),
      getChargerEventDetailsById: build.query<GetChargerEventDetailsByIdResponse, GetChargerEventDetailsByIdArgs>({
        query: (queryArg) => ({ url: `/api/charger-events/${queryArg.eventId}/sessions` }),
        providesTags: ['Charger Events']
      }),
      addSessionToChargerEvent: build.mutation<AddSessionToChargerEventResponse, AddSessionToChargerEventArgs>({
        query: (queryArg) => ({
          url: `/api/charger-events/${queryArg.eventId}/sessions`,
          method: 'POST',
          body: queryArg.chargerEventNewSessionModel
        }),
        invalidatesTags: ['Charger Events']
      }),
      getLinkTelemetry: build.query<GetLinkTelemetryResponse, GetLinkTelemetryArgs>({
        query: (queryArg) => ({
          url: `/api/sites/${queryArg.siteId}/link-telemetry`,
          params: { from: queryArg['from'], to: queryArg.to }
        }),
        providesTags: ['Synop Link']
      }),
      getSiteEnergyConfiguration: build.query<GetSiteEnergyConfigurationResponse, GetSiteEnergyConfigurationArgs>({
        query: (queryArg) => ({ url: `/api/sites/${queryArg.siteId}/energy-configurations/${queryArg.id}` }),
        providesTags: ['Site Feature Configurations']
      }),
      getBulkSiteEnergyConfigurations: build.query<
        GetBulkSiteEnergyConfigurationsResponse,
        GetBulkSiteEnergyConfigurationsArgs
      >({
        query: (queryArg) => ({
          url: `/api/sites/energy-configurations`,
          params: { organizationId: queryArg.organizationId }
        }),
        providesTags: ['Site Feature Configurations']
      }),
      getSiteGridProfile: build.query<GetSiteGridProfileResponse, GetSiteGridProfileArgs>({
        query: (queryArg) => ({ url: `/api/site-grid-profiles/${queryArg.id}` }),
        providesTags: ['Site Grid Profiles']
      }),
      getOptimization: build.query<GetOptimizationResponse, GetOptimizationArgs>({
        query: (queryArg) => ({ url: `/api/optimizations/${queryArg.id}` }),
        providesTags: ['Optimization']
      }),
      getGridEventIntegrations: build.query<GetGridEventIntegrationsResponse, GetGridEventIntegrationsArgs>({
        query: () => ({ url: `/api/grid-event-integrations` }),
        providesTags: ['Grid Event Integrations']
      }),
      getGridEventIntegration: build.query<GetGridEventIntegrationResponse, GetGridEventIntegrationArgs>({
        query: (queryArg) => ({ url: `/api/grid-event-integrations/${queryArg.gridEventIntegrationId}` }),
        providesTags: ['Grid Event Integrations']
      }),
      getGridEventIntegrationAccount: build.query<
        GetGridEventIntegrationAccountResponse,
        GetGridEventIntegrationAccountArgs
      >({
        query: (queryArg) => ({
          url: `/api/grid-event-integration-accounts/${queryArg.gridEventIntegrationAccountId}`
        }),
        providesTags: ['Grid Event Integration Accounts']
      }),
      getGridEventIntegrationEventByAccount: build.query<
        GetGridEventIntegrationEventByAccountResponse,
        GetGridEventIntegrationEventByAccountArgs
      >({
        query: (queryArg) => ({
          url: `/api/grid-event-integration-accounts/${queryArg.gridEventIntegrationAccountId}/events`
        }),
        providesTags: ['Grid Event Integration Accounts']
      }),
      getGridEventIntegrationEvent: build.query<GetGridEventIntegrationEventResponse, GetGridEventIntegrationEventArgs>(
        {
          query: (queryArg) => ({
            url: `/api/grid-event-integration-accounts/${queryArg.gridEventIntegrationAccountId}/events/${queryArg.externalId}`
          }),
          providesTags: ['Grid Event Integration Accounts']
        }
      ),
      getLoadBalancerEvent: build.query<GetLoadBalancerEventResponse, GetLoadBalancerEventArgs>({
        query: (queryArg) => ({ url: `/api/energy/events/load-balancer/${queryArg.id}` }),
        providesTags: ['Load Balancer Events']
      }),
      getEnergyEfficiencyPrediction: build.query<
        GetEnergyEfficiencyPredictionResponse,
        GetEnergyEfficiencyPredictionArgs
      >({
        query: (queryArg) => ({ url: `/api/energy-efficiency-predictions/${queryArg.id}` }),
        providesTags: ['Optimization']
      }),
      getSmartPrices: build.query<GetSmartPricesResponse, GetSmartPricesArgs>({
        query: (queryArg) => ({
          url: `/api/depots/${queryArg.depotId}/smart-prices`,
          params: { from: queryArg['from'], to: queryArg.to }
        }),
        providesTags: ['Price Controller']
      }),
      getScheduledDepotPower: build.query<GetScheduledDepotPowerResponse, GetScheduledDepotPowerArgs>({
        query: (queryArg) => ({
          url: `/api/depots/${queryArg.depotId}/power-forecast`,
          params: { from: queryArg['from'], to: queryArg.to }
        }),
        providesTags: ['Grid']
      }),
      getDepotEventById: build.query<GetDepotEventByIdResponse, GetDepotEventByIdArgs>({
        query: (queryArg) => ({ url: `/api/depots/${queryArg.depotId}/events/${queryArg.eventId}` }),
        providesTags: ['Grid']
      }),
      cancelDepotEvent: build.mutation<CancelDepotEventResponse, CancelDepotEventArgs>({
        query: (queryArg) => ({
          url: `/api/depots/${queryArg.depotId}/events/${queryArg.eventId}`,
          method: 'DELETE',
          params: { isRecurrence: queryArg.isRecurrence }
        }),
        invalidatesTags: ['Grid']
      }),
      getDepotEventDetails: build.query<GetDepotEventDetailsResponse, GetDepotEventDetailsArgs>({
        query: (queryArg) => ({ url: `/api/depots/${queryArg.depotId}/events/${queryArg.eventId}/details` }),
        providesTags: ['Grid']
      }),
      getScheduledChargerSessionsByDepot: build.query<
        GetScheduledChargerSessionsByDepotResponse,
        GetScheduledChargerSessionsByDepotArgs
      >({
        query: (queryArg) => ({
          url: `/api/depots/${queryArg.depotId}/chargers/events`,
          params: {
            assetId: queryArg.assetId,
            from: queryArg['from'],
            to: queryArg.to,
            page: queryArg.page,
            count: queryArg.count
          }
        }),
        providesTags: ['Schedule']
      }),
      getPredictedChargerEvents: build.query<GetPredictedChargerEventsResponse, GetPredictedChargerEventsArgs>({
        query: (queryArg) => ({
          url: `/api/depots/${queryArg.depotId}/chargers/event-predictions`,
          params: {
            chargerId: queryArg.chargerId,
            dispenserId: queryArg.dispenserId,
            assetId: queryArg.assetId,
            from: queryArg['from'],
            to: queryArg.to,
            validFrom: queryArg.validFrom,
            validTo: queryArg.validTo,
            mostRecent: queryArg.mostRecent,
            modelName: queryArg.modelName,
            chargerEventId: queryArg.chargerEventId,
            confidenceLower: queryArg.confidenceLower,
            confidenceUpper: queryArg.confidenceUpper,
            page: queryArg.page,
            count: queryArg.count
          }
        }),
        providesTags: ['Schedule']
      }),
      getDepotEvents: build.query<GetDepotEventsResponse, GetDepotEventsArgs>({
        query: (queryArg) => ({ url: `/api/depots/events`, params: { from: queryArg['from'], to: queryArg.to } }),
        providesTags: ['Grid']
      }),
      getScheduledChargerSessionTelemetry: build.query<
        GetScheduledChargerSessionTelemetryResponse,
        GetScheduledChargerSessionTelemetryArgs
      >({
        query: (queryArg) => ({ url: `/api/chargers/${queryArg.chargerId}/events/${queryArg.eventId}/telemetry` }),
        providesTags: ['Schedule']
      }),
      getProfileHistory: build.query<GetProfileHistoryResponse, GetProfileHistoryArgs>({
        query: (queryArg) => ({ url: `/api/chargers/profile-history/${queryArg.id}` }),
        providesTags: ['Profile History Records']
      }),
      deleteTelemetryExclusionPeriod: build.mutation<
        DeleteTelemetryExclusionPeriodResponse,
        DeleteTelemetryExclusionPeriodArgs
      >({
        query: (queryArg) => ({ url: `/api/telemetry-exclusion-periods/${queryArg.id}`, method: 'DELETE' }),
        invalidatesTags: ['Telemetry Exclusion Periods']
      }),
      getApiVehiclesByIdTimeToSoc: build.query<GetApiVehiclesByIdTimeToSocResponse, GetApiVehiclesByIdTimeToSocArgs>({
        query: (queryArg) => ({
          url: `/api/vehicles/${queryArg.id}/time-to-soc`,
          params: { targetSoc: queryArg.targetSoc, powerOffered: queryArg.powerOffered }
        }),
        providesTags: ['Vehicles']
      }),
      getApiTransactionsTimeToSoc: build.query<GetApiTransactionsTimeToSocResponse, GetApiTransactionsTimeToSocArgs>({
        query: (queryArg) => ({
          url: `/api/transactions/time-to-soc`,
          params: { ocppTag: queryArg.ocppTag, targetSoc: queryArg.targetSoc, powerOffered: queryArg.powerOffered }
        }),
        providesTags: ['Charging Transactions']
      }),
      acknowledgeSentNotification: build.mutation<AcknowledgeSentNotificationResponse, AcknowledgeSentNotificationArgs>(
        {
          query: (queryArg) => ({
            url: `/api/notifications/acknowledge`,
            method: 'PUT',
            body: queryArg.acknowledgeNotificationsModel
          }),
          invalidatesTags: ['Notifications']
        }
      ),
      getAlertsByOrganizationId: build.query<GetAlertsByOrganizationIdResponse, GetAlertsByOrganizationIdArgs>({
        query: (queryArg) => ({ url: `/api/alerts`, params: { organizationId: queryArg.organizationId } }),
        providesTags: ['Alerts']
      }),
      updateAlertByOrganizations: build.mutation<UpdateAlertByOrganizationsResponse, UpdateAlertByOrganizationsArgs>({
        query: (queryArg) => ({ url: `/api/alerts`, method: 'PUT', body: queryArg.alertConfigurationModel }),
        invalidatesTags: ['Alerts']
      }),
      createAlertByOrganizations: build.mutation<CreateAlertByOrganizationsResponse, CreateAlertByOrganizationsArgs>({
        query: (queryArg) => ({ url: `/api/alerts`, method: 'POST', body: queryArg.createAlertConfigurationModel }),
        invalidatesTags: ['Alerts']
      }),
      createAlertNotification: build.mutation<CreateAlertNotificationResponse, CreateAlertNotificationArgs>({
        query: (queryArg) => ({
          url: `/api/notifications`,
          method: 'POST',
          body: queryArg.createAlertNotificationModel
        }),
        invalidatesTags: ['Notifications']
      }),
      deactivateNotifications: build.mutation<DeactivateNotificationsResponse, DeactivateNotificationsArgs>({
        query: (queryArg) => ({
          url: `/api/notifications/deactivate`,
          method: 'PATCH',
          body: queryArg.deactivateAlertNotificationsModel
        }),
        invalidatesTags: ['Notifications']
      }),
      getNotifications: build.query<GetNotificationsResponse, GetNotificationsArgs>({
        query: (queryArg) => ({
          url: `/api/v1/notifications`,
          params: {
            page: queryArg.page,
            count: queryArg.count,
            sort: queryArg.sort,
            isActive: queryArg.isActive,
            isResolved: queryArg.isResolved,
            isAcknowledged: queryArg.isAcknowledged,
            priority: queryArg.priority,
            searchString: queryArg.searchString
          }
        }),
        providesTags: ['Notifications']
      }),
      getNotificationById: build.query<GetNotificationByIdResponse, GetNotificationByIdArgs>({
        query: (queryArg) => ({ url: `/api/notifications/${queryArg.id}` }),
        providesTags: ['Notifications']
      }),
      getAlertByAlertId: build.query<GetAlertByAlertIdResponse, GetAlertByAlertIdArgs>({
        query: (queryArg) => ({ url: `/api/alerts/${queryArg.id}` }),
        providesTags: ['Alerts']
      }),
      getProperties: build.query<GetPropertiesResponse, GetPropertiesArgs>({
        query: () => ({ url: `/api/alerts/properties` }),
        providesTags: ['Alerts']
      }),
      getWorkplaceUser: build.query<GetWorkplaceUserResponse, GetWorkplaceUserArgs>({
        query: (queryArg) => ({ url: `/api/workplace-users/${queryArg.workplaceUserId}` }),
        providesTags: ['Workplace Charging']
      }),
      updateWorkplaceUser: build.mutation<UpdateWorkplaceUserResponse, UpdateWorkplaceUserArgs>({
        query: (queryArg) => ({
          url: `/api/workplace-users/${queryArg.workplaceUserId}`,
          method: 'PUT',
          body: queryArg.workplaceChargingUserModel
        }),
        invalidatesTags: ['Workplace Charging']
      }),
      deleteWorkplaceUser: build.mutation<DeleteWorkplaceUserResponse, DeleteWorkplaceUserArgs>({
        query: (queryArg) => ({ url: `/api/workplace-users/${queryArg.workplaceUserId}`, method: 'DELETE' }),
        invalidatesTags: ['Workplace Charging']
      }),
      getWorkplaceChargingRateById: build.query<GetWorkplaceChargingRateByIdResponse, GetWorkplaceChargingRateByIdArgs>(
        {
          query: (queryArg) => ({
            url: `/api/workplace-charging-rates/${queryArg.rateMappingId}`,
            params: { organizationId: queryArg.organizationId }
          }),
          providesTags: ['Workplace Charging']
        }
      ),
      updateWorkplaceChargingRates: build.mutation<
        UpdateWorkplaceChargingRatesResponse,
        UpdateWorkplaceChargingRatesArgs
      >({
        query: (queryArg) => ({
          url: `/api/workplace-charging-rates/${queryArg.rateMappingId}`,
          method: 'PUT',
          body: queryArg.updateWorkplaceChargingRate
        }),
        invalidatesTags: ['Workplace Charging']
      }),
      updatePushNotification: build.mutation<UpdatePushNotificationResponse, UpdatePushNotificationArgs>({
        query: (queryArg) => ({
          url: `/api/users/${queryArg.id}/push-token`,
          method: 'PUT',
          body: queryArg.createUserPushParams
        }),
        invalidatesTags: ['User']
      }),
      updateUserTags: build.mutation<UpdateUserTagsResponse, UpdateUserTagsArgs>({
        query: (queryArg) => ({ url: `/api/users/${queryArg.id}/auth-tags`, method: 'PUT', body: queryArg.body }),
        invalidatesTags: ['User']
      }),
      getSubscriptionById: build.query<GetSubscriptionByIdResponse, GetSubscriptionByIdArgs>({
        query: (queryArg) => ({ url: `/api/subscriptions/${queryArg.subscriptionId}` }),
        providesTags: ['Subscriptions']
      }),
      updateSubscription: build.mutation<UpdateSubscriptionResponse, UpdateSubscriptionArgs>({
        query: (queryArg) => ({
          url: `/api/subscriptions/${queryArg.subscriptionId}`,
          method: 'PUT',
          body: queryArg.updateSubscriptionModel
        }),
        invalidatesTags: ['Subscriptions']
      }),
      getFleetBillingProductById: build.query<GetFleetBillingProductByIdResponse, GetFleetBillingProductByIdArgs>({
        query: (queryArg) => ({ url: `/api/products/${queryArg.productId}` }),
        providesTags: ['Products']
      }),
      updateFleetBillingProduct: build.mutation<UpdateFleetBillingProductResponse, UpdateFleetBillingProductArgs>({
        query: (queryArg) => ({
          url: `/api/products/${queryArg.productId}`,
          method: 'PUT',
          body: queryArg.updateProductModel
        }),
        invalidatesTags: ['Products']
      }),
      getSessions: build.query<GetSessionsResponse, GetSessionsArgs>({
        query: (queryArg) => ({
          url: `/api/ocpi/cpo/2.2.1/sessions`,
          params: {
            date_from: queryArg.dateFrom,
            date_to: queryArg.dateTo,
            offset: queryArg.offset,
            limit: queryArg.limit
          }
        }),
        providesTags: ['OCPI']
      }),
      putChargingPreferences: build.mutation<PutChargingPreferencesResponse, PutChargingPreferencesArgs>({
        query: (queryArg) => ({
          url: `/api/ocpi/cpo/2.2.1/sessions`,
          method: 'PUT',
          body: queryArg.chargingPreferences,
          params: { session_id: queryArg.sessionId }
        }),
        invalidatesTags: ['OCPI']
      }),
      getSession: build.query<GetSessionResponse, GetSessionArgs>({
        query: (queryArg) => ({ url: `/api/ocpi/cpo/2.2.1/sessions/${queryArg.sessionId}` }),
        providesTags: ['OCPI']
      }),
      updateSession: build.mutation<UpdateSessionResponse, UpdateSessionArgs>({
        query: (queryArg) => ({
          url: `/api/ocpi/cpo/2.2.1/sessions/${queryArg.sessionId}`,
          method: 'PUT',
          body: queryArg.updateSessionRequest
        }),
        invalidatesTags: ['OCPI']
      }),
      getLocation: build.query<GetLocationResponse, GetLocationArgs>({
        query: (queryArg) => ({ url: `/api/ocpi/cpo/2.2.1/locations/${queryArg.locationId}` }),
        providesTags: ['OCPI']
      }),
      updateLocation: build.mutation<UpdateLocationResponse, UpdateLocationArgs>({
        query: (queryArg) => ({
          url: `/api/ocpi/cpo/2.2.1/locations/${queryArg.locationId}`,
          method: 'PUT',
          body: queryArg.location
        }),
        invalidatesTags: ['OCPI']
      }),
      getInvoiceById: build.query<GetInvoiceByIdResponse, GetInvoiceByIdArgs>({
        query: (queryArg) => ({ url: `/api/invoices/${queryArg.invoiceId}` }),
        providesTags: ['Invoices']
      }),
      updateInvoice: build.mutation<UpdateInvoiceResponse, UpdateInvoiceArgs>({
        query: (queryArg) => ({
          url: `/api/invoices/${queryArg.invoiceId}`,
          method: 'PUT',
          body: queryArg.updateInvoiceModel
        }),
        invalidatesTags: ['Invoices']
      }),
      deleteInvoice: build.mutation<DeleteInvoiceResponse, DeleteInvoiceArgs>({
        query: (queryArg) => ({ url: `/api/invoices/${queryArg.invoiceId}`, method: 'DELETE' }),
        invalidatesTags: ['Invoices']
      }),
      getFleetBillingInvoiceById: build.query<GetFleetBillingInvoiceByIdResponse, GetFleetBillingInvoiceByIdArgs>({
        query: (queryArg) => ({ url: `/api/fleet-billing-invoices/${queryArg.invoiceId}` }),
        providesTags: ['Fleet Billing']
      }),
      updateFleetBillingInvoices: build.mutation<UpdateFleetBillingInvoicesResponse, UpdateFleetBillingInvoicesArgs>({
        query: (queryArg) => ({
          url: `/api/fleet-billing-invoices/${queryArg.invoiceId}`,
          method: 'PUT',
          body: queryArg.updateInvoiceModel
        }),
        invalidatesTags: ['Fleet Billing']
      }),
      getChargingRateById: build.query<GetChargingRateByIdResponse, GetChargingRateByIdArgs>({
        query: (queryArg) => ({
          url: `/api/charging-rates/${queryArg.rateMappingId}`,
          params: { organizationId: queryArg.organizationId }
        }),
        providesTags: ['Billing']
      }),
      updateChargingRates: build.mutation<UpdateChargingRatesResponse, UpdateChargingRatesArgs>({
        query: (queryArg) => ({
          url: `/api/charging-rates/${queryArg.rateMappingId}`,
          method: 'PUT',
          body: queryArg.updateChargingRate
        }),
        invalidatesTags: ['Billing']
      }),
      getWorkplaceUsers: build.query<GetWorkplaceUsersResponse, GetWorkplaceUsersArgs>({
        query: (queryArg) => ({ url: `/api/workplace-users`, params: { organizationId: queryArg.organizationId } }),
        providesTags: ['Workplace Charging']
      }),
      saveWorkplaceUser: build.mutation<SaveWorkplaceUserResponse, SaveWorkplaceUserArgs>({
        query: (queryArg) => ({
          url: `/api/workplace-users`,
          method: 'POST',
          body: queryArg.createWorkplaceChargingUser
        }),
        invalidatesTags: ['Workplace Charging']
      }),
      getWorkplaceChargingRates: build.query<GetWorkplaceChargingRatesResponse, GetWorkplaceChargingRatesArgs>({
        query: (queryArg) => ({
          url: `/api/workplace-charging-rates`,
          params: { organizationId: queryArg.organizationId }
        }),
        providesTags: ['Workplace Charging']
      }),
      createWorkplaceChargingRates: build.mutation<
        CreateWorkplaceChargingRatesResponse,
        CreateWorkplaceChargingRatesArgs
      >({
        query: (queryArg) => ({
          url: `/api/workplace-charging-rates`,
          method: 'POST',
          body: queryArg.createWorkplaceChargingRate
        }),
        invalidatesTags: ['Workplace Charging']
      }),
      overrideWorkplaceChargingRate: build.mutation<
        OverrideWorkplaceChargingRateResponse,
        OverrideWorkplaceChargingRateArgs
      >({
        query: (queryArg) => ({
          url: `/api/workplace-charging-rates/${queryArg.rateMappingId}/override`,
          method: 'POST',
          body: queryArg.overrideRateStructureModel
        }),
        invalidatesTags: ['Workplace Charging']
      }),
      getWorkplaceChargingConfiguration: build.query<
        GetWorkplaceChargingConfigurationResponse,
        GetWorkplaceChargingConfigurationArgs
      >({
        query: (queryArg) => ({
          url: `/api/workplace-charging-configuration`,
          params: { organizationId: queryArg.organizationId }
        }),
        providesTags: ['Workplace Charging']
      }),
      saveWorkplaceChargingConfiguration: build.mutation<
        SaveWorkplaceChargingConfigurationResponse,
        SaveWorkplaceChargingConfigurationArgs
      >({
        query: (queryArg) => ({
          url: `/api/workplace-charging-configuration`,
          method: 'POST',
          body: queryArg.workplaceConfiguration
        }),
        invalidatesTags: ['Workplace Charging']
      }),
      updateWorkplaceChargingRecordStatuses: build.mutation<
        UpdateWorkplaceChargingRecordStatusesResponse,
        UpdateWorkplaceChargingRecordStatusesArgs
      >({
        query: (queryArg) => ({
          url: `/api/update-workplace-charging-records-status`,
          method: 'POST',
          body: queryArg.updateWorkplaceChargingRecordStatus,
          params: { organizationId: queryArg.organizationId }
        }),
        invalidatesTags: ['Workplace Charging']
      }),
      getSubscriptions: build.query<GetSubscriptionsResponse, GetSubscriptionsArgs>({
        query: (queryArg) => ({
          url: `/api/subscriptions`,
          params: {
            page: queryArg.page,
            count: queryArg.count,
            status: queryArg.status,
            customerOrganizationId: queryArg.customerOrganizationId,
            organizationId: queryArg.organizationId
          }
        }),
        providesTags: ['Subscriptions']
      }),
      createSubscription: build.mutation<CreateSubscriptionResponse, CreateSubscriptionArgs>({
        query: (queryArg) => ({ url: `/api/subscriptions`, method: 'POST', body: queryArg.createSubscriptionModel }),
        invalidatesTags: ['Subscriptions']
      }),
      resumeSubscription: build.mutation<ResumeSubscriptionResponse, ResumeSubscriptionArgs>({
        query: (queryArg) => ({
          url: `/api/subscriptions/${queryArg.subscriptionId}/resume`,
          method: 'POST',
          body: queryArg.subscriptionOperationRequest
        }),
        invalidatesTags: ['Subscriptions']
      }),
      pauseSubscription: build.mutation<PauseSubscriptionResponse, PauseSubscriptionArgs>({
        query: (queryArg) => ({
          url: `/api/subscriptions/${queryArg.subscriptionId}/pause`,
          method: 'POST',
          body: queryArg.subscriptionOperationRequest
        }),
        invalidatesTags: ['Subscriptions']
      }),
      cancelSubscription: build.mutation<CancelSubscriptionResponse, CancelSubscriptionArgs>({
        query: (queryArg) => ({
          url: `/api/subscriptions/${queryArg.subscriptionId}/cancel`,
          method: 'POST',
          body: queryArg.subscriptionOperationRequest
        }),
        invalidatesTags: ['Subscriptions']
      }),
      processStripeEvent: build.mutation<ProcessStripeEventResponse, ProcessStripeEventArgs>({
        query: () => ({ url: `/api/stripe/event`, method: 'POST' }),
        invalidatesTags: ['Stripe Events']
      }),
      getReimbursementUsers: build.query<GetReimbursementUsersResponse, GetReimbursementUsersArgs>({
        query: (queryArg) => ({ url: `/api/reimbursement-users`, params: { organizationId: queryArg.organizationId } }),
        providesTags: ['Reimbursements']
      }),
      saveReimbursementUser: build.mutation<SaveReimbursementUserResponse, SaveReimbursementUserArgs>({
        query: (queryArg) => ({
          url: `/api/reimbursement-users`,
          method: 'POST',
          body: queryArg.reimbursementUserModel
        }),
        invalidatesTags: ['Reimbursements']
      }),
      getReimbursementConfiguration: build.query<
        GetReimbursementConfigurationResponse,
        GetReimbursementConfigurationArgs
      >({
        query: (queryArg) => ({
          url: `/api/reimbursement-configuration`,
          params: { organizationId: queryArg.organizationId }
        }),
        providesTags: ['Reimbursements']
      }),
      saveReimbursementConfiguration: build.mutation<
        SaveReimbursementConfigurationResponse,
        SaveReimbursementConfigurationArgs
      >({
        query: (queryArg) => ({
          url: `/api/reimbursement-configuration`,
          method: 'POST',
          body: queryArg.reimbursementConfiguration
        }),
        invalidatesTags: ['Reimbursements']
      }),
      getFleetBillingProducts: build.query<GetFleetBillingProductsResponse, GetFleetBillingProductsArgs>({
        query: (queryArg) => ({ url: `/api/products`, params: { organizationId: queryArg.organizationId } }),
        providesTags: ['Products']
      }),
      createFleetBillingProduct: build.mutation<CreateFleetBillingProductResponse, CreateFleetBillingProductArgs>({
        query: (queryArg) => ({ url: `/api/products`, method: 'POST', body: queryArg.createProductModel }),
        invalidatesTags: ['Products']
      }),
      onboardOrganizationToStripe1: build.mutation<
        OnboardOrganizationToStripe1Response,
        OnboardOrganizationToStripe1Args
      >({
        query: (queryArg) => ({
          url: `/api/organizations/${queryArg.id}/onboard`,
          method: 'POST',
          body: queryArg.urlRedirectModel
        }),
        invalidatesTags: ['Organizations']
      }),
      stopSession: build.mutation<StopSessionResponse, StopSessionArgs>({
        query: (queryArg) => ({
          url: `/api/ocpi/cpo/2.2.1/commands/stop_session`,
          method: 'POST',
          body: queryArg.stopTransactionRequest
        }),
        invalidatesTags: ['OCPI']
      }),
      startSession: build.mutation<StartSessionResponse, StartSessionArgs>({
        query: (queryArg) => ({
          url: `/api/ocpi/cpo/2.2.1/commands/start_session`,
          method: 'POST',
          body: queryArg.startSessionRequest
        }),
        invalidatesTags: ['OCPI']
      }),
      getInvoices: build.query<GetInvoicesResponse, GetInvoicesArgs>({
        query: (queryArg) => ({
          url: `/api/invoices`,
          params: {
            page: queryArg.page,
            count: queryArg.count,
            status: queryArg.status,
            customerOrganizationId: queryArg.customerOrganizationId,
            organizationId: queryArg.organizationId
          }
        }),
        providesTags: ['Invoices']
      }),
      createInvoice: build.mutation<CreateInvoiceResponse, CreateInvoiceArgs>({
        query: (queryArg) => ({ url: `/api/invoices`, method: 'POST', body: queryArg.createInvoiceModel }),
        invalidatesTags: ['Invoices']
      }),
      voidInvoice: build.mutation<VoidInvoiceResponse, VoidInvoiceArgs>({
        query: (queryArg) => ({
          url: `/api/invoices/${queryArg.invoiceId}/void`,
          method: 'POST',
          body: queryArg.invoiceOperationRequest
        }),
        invalidatesTags: ['Invoices']
      }),
      sendInvoice: build.mutation<SendInvoiceResponse, SendInvoiceArgs>({
        query: (queryArg) => ({
          url: `/api/invoices/${queryArg.invoiceId}/send`,
          method: 'POST',
          body: queryArg.invoiceOperationRequest
        }),
        invalidatesTags: ['Invoices']
      }),
      finalizeInvoice: build.mutation<FinalizeInvoiceResponse, FinalizeInvoiceArgs>({
        query: (queryArg) => ({
          url: `/api/invoices/${queryArg.invoiceId}/finalize`,
          method: 'POST',
          body: queryArg.invoiceOperationRequest
        }),
        invalidatesTags: ['Invoices']
      }),
      getFleetChargingConfigurations: build.query<
        GetFleetChargingConfigurationsResponse,
        GetFleetChargingConfigurationsArgs
      >({
        query: (queryArg) => ({
          url: `/api/fleet-charging-configurations`,
          params: { organizationId: queryArg.organizationId }
        }),
        providesTags: ['Fleet Billing']
      }),
      saveFleetChargingConfiguration: build.mutation<
        SaveFleetChargingConfigurationResponse,
        SaveFleetChargingConfigurationArgs
      >({
        query: (queryArg) => ({
          url: `/api/fleet-charging-configurations`,
          method: 'POST',
          body: queryArg.fleetChargingConfiguration
        }),
        invalidatesTags: ['Fleet Billing']
      }),
      getFleetBillingInvoices: build.query<GetFleetBillingInvoicesResponse, GetFleetBillingInvoicesArgs>({
        query: (queryArg) => ({
          url: `/api/fleet-billing-invoices`,
          params: {
            page: queryArg.page,
            count: queryArg.count,
            status: queryArg.status,
            customerOrganizationId: queryArg.customerOrganizationId,
            organizationId: queryArg.organizationId
          }
        }),
        providesTags: ['Fleet Billing']
      }),
      createFleetBillingInvoice: build.mutation<CreateFleetBillingInvoiceResponse, CreateFleetBillingInvoiceArgs>({
        query: (queryArg) => ({
          url: `/api/fleet-billing-invoices`,
          method: 'POST',
          body: queryArg.createInvoiceModel
        }),
        invalidatesTags: ['Fleet Billing']
      }),
      voidFleetBillingInvoice: build.mutation<VoidFleetBillingInvoiceResponse, VoidFleetBillingInvoiceArgs>({
        query: (queryArg) => ({
          url: `/api/fleet-billing-invoices/${queryArg.invoiceId}/void`,
          method: 'POST',
          body: queryArg.invoiceOperationRequest
        }),
        invalidatesTags: ['Fleet Billing']
      }),
      sendFleetBillingInvoice: build.mutation<SendFleetBillingInvoiceResponse, SendFleetBillingInvoiceArgs>({
        query: (queryArg) => ({
          url: `/api/fleet-billing-invoices/${queryArg.invoiceId}/send`,
          method: 'POST',
          body: queryArg.invoiceOperationRequest
        }),
        invalidatesTags: ['Fleet Billing']
      }),
      finalizeFleetBillingInvoice: build.mutation<FinalizeFleetBillingInvoiceResponse, FinalizeFleetBillingInvoiceArgs>(
        {
          query: (queryArg) => ({
            url: `/api/fleet-billing-invoices/${queryArg.invoiceId}/finalize`,
            method: 'POST',
            body: queryArg.invoiceOperationRequest
          }),
          invalidatesTags: ['Fleet Billing']
        }
      ),
      getChargingRates: build.query<GetChargingRatesResponse, GetChargingRatesArgs>({
        query: (queryArg) => ({
          url: `/api/charging-rates`,
          params: { organizationId: queryArg.organizationId, productType: queryArg.productType }
        }),
        providesTags: ['Billing']
      }),
      createChargingRates: build.mutation<CreateChargingRatesResponse, CreateChargingRatesArgs>({
        query: (queryArg) => ({ url: `/api/charging-rates`, method: 'POST', body: queryArg.createChargingRate }),
        invalidatesTags: ['Billing']
      }),
      overrideChargingRate: build.mutation<OverrideChargingRateResponse, OverrideChargingRateArgs>({
        query: (queryArg) => ({
          url: `/api/charging-rates/${queryArg.rateMappingId}/override`,
          method: 'POST',
          body: queryArg.overrideRateStructureModel
        }),
        invalidatesTags: ['Billing']
      }),
      bulkUpdateReimbursementUsers: build.mutation<
        BulkUpdateReimbursementUsersResponse,
        BulkUpdateReimbursementUsersArgs
      >({
        query: (queryArg) => ({
          url: `/api/bulk-reimbursement-users`,
          method: 'POST',
          body: queryArg.bulkReimbursementConfiguration
        }),
        invalidatesTags: ['Reimbursements']
      }),
      authorizePublicSession: build.mutation<AuthorizePublicSessionResponse, AuthorizePublicSessionArgs>({
        query: (queryArg) => ({
          url: `/api/authorize-public-session`,
          method: 'POST',
          body: queryArg.authorizePublicSessionRequest
        }),
        invalidatesTags: ['Billing']
      }),
      auditBilling: build.mutation<AuditBillingResponse, AuditBillingArgs>({
        query: (queryArg) => ({ url: `/api/audit-billing`, method: 'POST', body: queryArg.auditRequestModel }),
        invalidatesTags: ['Billing']
      }),
      askQuestion: build.mutation<AskQuestionResponse, AskQuestionArgs>({
        query: (queryArg) => ({
          url: `/api/ask`,
          method: 'POST',
          body: queryArg.chatCompletionRequest,
          params: { question: queryArg.question }
        }),
        invalidatesTags: ['Fleet Assistant']
      }),
      getWorkplaceChargingUsage: build.query<GetWorkplaceChargingUsageResponse, GetWorkplaceChargingUsageArgs>({
        query: (queryArg) => ({
          url: `/api/workplace-charging-usage`,
          params: { billingMonth: queryArg.billingMonth, organizationId: queryArg.organizationId }
        }),
        providesTags: ['Workplace Charging']
      }),
      getWorkplaceSummary: build.query<GetWorkplaceSummaryResponse, GetWorkplaceSummaryArgs>({
        query: (queryArg) => ({
          url: `/api/workplace-charging-summary`,
          params: { billingMonth: queryArg.billingMonth, organizationId: queryArg.organizationId }
        }),
        providesTags: ['Workplace Charging']
      }),
      getWorkplaceChargingRecords: build.query<GetWorkplaceChargingRecordsResponse, GetWorkplaceChargingRecordsArgs>({
        query: (queryArg) => ({
          url: `/api/workplace-charging-records`,
          params: { from: queryArg['from'], to: queryArg.to, organizationId: queryArg.organizationId }
        }),
        providesTags: ['Workplace Charging']
      }),
      getStripeIntentDetail1: build.query<GetStripeIntentDetail1Response, GetStripeIntentDetail1Args>({
        query: (queryArg) => ({ url: `/api/users/${queryArg.id}/stripe-secret` }),
        providesTags: ['User']
      }),
      getPaymentMethod: build.query<GetPaymentMethodResponse, GetPaymentMethodArgs>({
        query: (queryArg) => ({ url: `/api/users/${queryArg.id}/payment-method` }),
        providesTags: ['User']
      }),
      getPortalLogin: build.query<GetPortalLoginResponse, GetPortalLoginArgs>({
        query: (queryArg) => ({ url: `/api/users/${queryArg.id}/manage-billing` }),
        providesTags: ['User']
      }),
      getUserInvoices: build.query<GetUserInvoicesResponse, GetUserInvoicesArgs>({
        query: (queryArg) => ({ url: `/api/users/${queryArg.id}/invoices` }),
        providesTags: ['User']
      }),
      getCheckoutSession: build.query<GetCheckoutSessionResponse, GetCheckoutSessionArgs>({
        query: (queryArg) => ({ url: `/api/users/${queryArg.id}/checkout-session` }),
        providesTags: ['User']
      }),
      getReimbursements: build.query<GetReimbursementsResponse, GetReimbursementsArgs>({
        query: (queryArg) => ({
          url: `/api/reimbursements`,
          params: { from: queryArg['from'], to: queryArg.to, organizationId: queryArg.organizationId }
        }),
        providesTags: ['Reimbursements']
      }),
      getReimbursementUser: build.query<GetReimbursementUserResponse, GetReimbursementUserArgs>({
        query: (queryArg) => ({ url: `/api/reimbursement-users/${queryArg.reimbursementUserId}` }),
        providesTags: ['Reimbursements']
      }),
      deleteReimbursementUser: build.mutation<DeleteReimbursementUserResponse, DeleteReimbursementUserArgs>({
        query: (queryArg) => ({ url: `/api/reimbursement-users/${queryArg.reimbursementUserId}`, method: 'DELETE' }),
        invalidatesTags: ['Reimbursements']
      }),
      getReimbursementUsageSummary: build.query<GetReimbursementUsageSummaryResponse, GetReimbursementUsageSummaryArgs>(
        {
          query: (queryArg) => ({
            url: `/api/reimbursement-usage`,
            params: { billingMonth: queryArg.billingMonth, organizationId: queryArg.organizationId }
          }),
          providesTags: ['Reimbursements']
        }
      ),
      getReimbursementSummary: build.query<GetReimbursementSummaryResponse, GetReimbursementSummaryArgs>({
        query: (queryArg) => ({
          url: `/api/reimbursement-summary`,
          params: { billingMonth: queryArg.billingMonth, organizationId: queryArg.organizationId }
        }),
        providesTags: ['Reimbursements']
      }),
      getQuotas: build.query<GetQuotasResponse, GetQuotasArgs>({
        query: (queryArg) => ({
          url: `/api/quotas`,
          params: { organizationId: queryArg.organizationId, month: queryArg.month }
        }),
        providesTags: ['Quotas']
      }),
      getReimbursements1: build.query<GetReimbursements1Response, GetReimbursements1Args>({
        query: (queryArg) => ({
          url: `/api/organizations/${queryArg.id}/reimbursements`,
          params: { from: queryArg['from'], to: queryArg.to }
        }),
        providesTags: ['Organizations']
      }),
      getPortal: build.query<GetPortalResponse, GetPortalArgs>({
        query: (queryArg) => ({ url: `/api/organizations/${queryArg.id}/manage-billing` }),
        providesTags: ['Organizations']
      }),
      getLoginSession: build.query<GetLoginSessionResponse, GetLoginSessionArgs>({
        query: (queryArg) => ({ url: `/api/organizations/${queryArg.id}/manage-account` }),
        providesTags: ['Organizations']
      }),
      getOrganizationBalance: build.query<GetOrganizationBalanceResponse, GetOrganizationBalanceArgs>({
        query: (queryArg) => ({ url: `/api/organizations/${queryArg.id}/balance` }),
        providesTags: ['Organizations']
      }),
      getMeterValues: build.query<GetMeterValuesResponse, GetMeterValuesArgs>({
        query: (queryArg) => ({ url: `/api/ocpi/cpo/2.2.1/sessions/${queryArg.sessionId}/meter_values` }),
        providesTags: ['OCPI']
      }),
      getLocations: build.query<GetLocationsResponse, GetLocationsArgs>({
        query: (queryArg) => ({
          url: `/api/ocpi/cpo/2.2.1/locations`,
          params: {
            date_from: queryArg.dateFrom,
            date_to: queryArg.dateTo,
            offset: queryArg.offset,
            limit: queryArg.limit
          }
        }),
        providesTags: ['OCPI']
      }),
      getEvse: build.query<GetEvseResponse, GetEvseArgs>({
        query: (queryArg) => ({ url: `/api/ocpi/cpo/2.2.1/locations/${queryArg.locationId}/${queryArg.evseUid}` }),
        providesTags: ['OCPI']
      }),
      getConnector: build.query<GetConnectorResponse, GetConnectorArgs>({
        query: (queryArg) => ({
          url: `/api/ocpi/cpo/2.2.1/locations/${queryArg.locationId}/${queryArg.evseUid}/${queryArg.connectorId}`
        }),
        providesTags: ['OCPI']
      }),
      getCdr: build.query<GetCdrResponse, GetCdrArgs>({
        query: (queryArg) => ({
          url: `/api/ocpi/cpo/2.2.1/cdr`,
          params: {
            authTag: queryArg.authTag,
            date_from: queryArg.dateFrom,
            date_to: queryArg.dateTo,
            offset: queryArg.offset,
            limit: queryArg.limit
          }
        }),
        providesTags: ['OCPI']
      }),
      getCdrById: build.query<GetCdrByIdResponse, GetCdrByIdArgs>({
        query: (queryArg) => ({ url: `/api/ocpi/cpo/2.2.1/cdr/${queryArg.sessionId}` }),
        providesTags: ['OCPI']
      }),
      getLineItems: build.query<GetLineItemsResponse, GetLineItemsArgs>({
        query: (queryArg) => ({
          url: `/api/line-items`,
          params: {
            productType: queryArg.productType,
            chargerId: queryArg.chargerId,
            vehicleId: queryArg.vehicleId,
            authTag: queryArg.authTag,
            fleetId: queryArg.fleetId,
            siteId: queryArg.siteId,
            payeeId: queryArg.payeeId,
            payorId: queryArg.payorId,
            payorOrgId: queryArg.payorOrgId,
            from: queryArg['from'],
            to: queryArg.to,
            page: queryArg.page,
            size: queryArg.size
          }
        }),
        providesTags: ['Billing']
      }),
      getFleetBillingSummary: build.query<GetFleetBillingSummaryResponse, GetFleetBillingSummaryArgs>({
        query: (queryArg) => ({
          url: `/api/fleet-billing-summary`,
          params: { organizationId: queryArg.organizationId }
        }),
        providesTags: ['Fleet Billing']
      }),
      getFleetBillingPayments: build.query<GetFleetBillingPaymentsResponse, GetFleetBillingPaymentsArgs>({
        query: (queryArg) => ({
          url: `/api/fleet-billing-payments`,
          params: { page: queryArg.page, count: queryArg.count, organizationId: queryArg.organizationId }
        }),
        providesTags: ['Fleet Billing']
      }),
      getCostExplorer: build.query<GetCostExplorerResponse, GetCostExplorerArgs>({
        query: (queryArg) => ({
          url: `/api/cost-explorer`,
          params: {
            groupBy: queryArg.groupBy,
            productType: queryArg.productType,
            granularity: queryArg.granularity,
            from: queryArg['from'],
            to: queryArg.to,
            organizationId: queryArg.organizationId
          }
        }),
        providesTags: ['Cost Explorer']
      }),
      getCostDashboardSummary: build.query<GetCostDashboardSummaryResponse, GetCostDashboardSummaryArgs>({
        query: (queryArg) => ({
          url: `/api/cost-dashboard-summary`,
          params: { from: queryArg['from'], to: queryArg.to, organizationId: queryArg.organizationId }
        }),
        providesTags: ['Cost Explorer']
      }),
      getCostDashboardGrouped: build.query<GetCostDashboardGroupedResponse, GetCostDashboardGroupedArgs>({
        query: (queryArg) => ({
          url: `/api/cost-dashboard-grouped`,
          params: {
            groupBy: queryArg.groupBy,
            from: queryArg['from'],
            to: queryArg.to,
            organizationId: queryArg.organizationId
          }
        }),
        providesTags: ['Cost Explorer']
      }),
      listSimulators: build.query<ListSimulatorsResponse, ListSimulatorsArgs>({
        query: () => ({ url: `/api/charger-simulator` }),
        providesTags: ['Simulator manager API']
      }),
      createSimulator: build.mutation<CreateSimulatorResponse, CreateSimulatorArgs>({
        query: (queryArg) => ({ url: `/api/charger-simulator`, method: 'POST', body: queryArg.simulatorCreationModel }),
        invalidatesTags: ['Simulator manager API']
      }),
      getContainer: build.query<GetContainerResponse, GetContainerArgs>({
        query: (queryArg) => ({ url: `/api/charger-simulator/${queryArg.id}` }),
        providesTags: ['Simulator manager API']
      }),
      deleteContainer: build.mutation<DeleteContainerResponse, DeleteContainerArgs>({
        query: (queryArg) => ({ url: `/api/charger-simulator/${queryArg.id}`, method: 'DELETE' }),
        invalidatesTags: ['Simulator manager API']
      }),
      ping: build.query<PingResponse, PingArgs>({
        query: () => ({ url: `/api/charger-simulator/ping` }),
        providesTags: ['Simulator manager API']
      }),
      simulatorProxyRequest: build.query<SimulatorProxyRequestResponse, SimulatorProxyRequestArgs>({
        query: (queryArg) => ({
          url: `/api/charger-simulator/${queryArg.id}/proxy`,
          params: { body: queryArg.body, path: queryArg.path }
        }),
        providesTags: ['Simulator manager proxy API']
      }),
      simulatorProxyRequest3: build.mutation<SimulatorProxyRequest3Response, SimulatorProxyRequest3Args>({
        query: (queryArg) => ({
          url: `/api/charger-simulator/${queryArg.id}/proxy`,
          method: 'PUT',
          body: queryArg.body,
          params: { path: queryArg.path }
        }),
        invalidatesTags: ['Simulator manager proxy API']
      }),
      simulatorProxyRequest2: build.mutation<SimulatorProxyRequest2Response, SimulatorProxyRequest2Args>({
        query: (queryArg) => ({
          url: `/api/charger-simulator/${queryArg.id}/proxy`,
          method: 'POST',
          body: queryArg.body,
          params: { path: queryArg.path }
        }),
        invalidatesTags: ['Simulator manager proxy API']
      }),
      simulatorProxyRequest5: build.mutation<SimulatorProxyRequest5Response, SimulatorProxyRequest5Args>({
        query: (queryArg) => ({
          url: `/api/charger-simulator/${queryArg.id}/proxy`,
          method: 'DELETE',
          body: queryArg.body,
          params: { path: queryArg.path }
        }),
        invalidatesTags: ['Simulator manager proxy API']
      }),
      simulatorProxyRequest6: build.mutation<SimulatorProxyRequest6Response, SimulatorProxyRequest6Args>({
        query: (queryArg) => ({
          url: `/api/charger-simulator/${queryArg.id}/proxy`,
          method: 'OPTIONS',
          body: queryArg.body,
          params: { path: queryArg.path }
        }),
        invalidatesTags: ['Simulator manager proxy API']
      }),
      simulatorProxyRequest1: build.mutation<SimulatorProxyRequest1Response, SimulatorProxyRequest1Args>({
        query: (queryArg) => ({
          url: `/api/charger-simulator/${queryArg.id}/proxy`,
          method: 'HEAD',
          body: queryArg.body,
          params: { path: queryArg.path }
        }),
        invalidatesTags: ['Simulator manager proxy API']
      }),
      simulatorProxyRequest4: build.mutation<SimulatorProxyRequest4Response, SimulatorProxyRequest4Args>({
        query: (queryArg) => ({
          url: `/api/charger-simulator/${queryArg.id}/proxy`,
          method: 'PATCH',
          body: queryArg.body,
          params: { path: queryArg.path }
        }),
        invalidatesTags: ['Simulator manager proxy API']
      })
    }),
    overrideExisting: false
  })
export { injectedRtkApi as enhancedApi }
export type GetVehiclesResponse = /** status 200 OK */ VehicleModel[]
export type GetVehiclesArgs = {
  ocppTags?: string[]
}
export type UpdateVehicleResponse = /** status 200 OK */ VehicleModel
export type UpdateVehicleArgs = {
  editVehicleModel: EditVehicleModel
}
export type CreateVehicleWithMetadataResponse = /** status 200 OK */ VehicleModel
export type CreateVehicleWithMetadataArgs = {
  createVehicleModel: CreateVehicleModel
}
export type UpdateUserResponse = /** status 200 OK */ UserModel
export type UpdateUserArgs = {
  userModel: UserModel
}
export type CreateUserResponse = /** status 200 OK */ UserModel
export type CreateUserArgs = {
  isFederated?: boolean
  createUserModel: CreateUserModel
}
export type SignOutUserResponse = /** status 200 OK */ boolean
export type SignOutUserArgs = {
  id: string
}
export type ResendResponse = /** status 200 OK */ boolean
export type ResendArgs = {
  id: string
}
export type ResetPasswordResponse = /** status 200 OK */ boolean
export type ResetPasswordArgs = {
  id: string
}
export type EnableUserResponse = /** status 200 OK */ boolean
export type EnableUserArgs = {
  id: string
}
export type DisableUserResponse = /** status 200 OK */ boolean
export type DisableUserArgs = {
  id: string
}
export type UpdateScheduledRouteResponse = /** status 200 OK */ ScheduledRoute
export type UpdateScheduledRouteArgs = {
  scheduledRouteId: string
  scheduledRouteUpdateModel: ScheduledRouteUpdateModel
}
export type CancelScheduledRouteResponse = /** status 200 OK */ boolean
export type CancelScheduledRouteArgs = {
  scheduledRouteId: string
}
export type GetRoutesForFleetResponse = /** status 200 OK */ Route[]
export type GetRoutesForFleetArgs = {
  fleetId?: string
}
export type UpdateRouteResponse = /** status 200 OK */ Route
export type UpdateRouteArgs = {
  route: Route
}
export type CreateRouteResponse = /** status 200 OK */ Route
export type CreateRouteArgs = {
  createRouteRequest: CreateRouteRequest
}
export type UpdateOrganizationResponse = /** status 200 OK */ OrganizationModel
export type UpdateOrganizationArgs = {
  organizationModel: OrganizationModel
}
export type UpdateFleetTermsResponse = /** status 200 OK */ OrganizationTerms
export type UpdateFleetTermsArgs = {
  organizationTerms: OrganizationTerms
}
export type SaveFleetTermsResponse = /** status 200 OK */ OrganizationTerms
export type SaveFleetTermsArgs = {
  organizationTerms: OrganizationTerms
}
export type GetLocationsOfInterestForOrganizationResponse = /** status 200 OK */ LocationOfInterestResponseModel[]
export type GetLocationsOfInterestForOrganizationArgs = {
  organizationId: string
}
export type UpdateLocationOfInterestResponse = unknown
export type UpdateLocationOfInterestArgs = {
  locationOfInterestUpdateModel: LocationOfInterestUpdateModel
}
export type CreateLocationOfInterestResponse = /** status 200 OK */ LocationOfInterestResponseModel
export type CreateLocationOfInterestArgs = {
  locationOfInterestCreateModel: LocationOfInterestCreateModel
}
export type EnableOrganizationIntegrationResponse = /** status 200 OK */ boolean
export type EnableOrganizationIntegrationArgs = {
  id: string
}
export type DisableIntegrationResponse = /** status 200 OK */ boolean
export type DisableIntegrationArgs = {
  id: string
}
export type UpdateFleetResponse = /** status 200 OK */ FleetModel
export type UpdateFleetArgs = {
  fleetModel: FleetModel
}
export type CreateFleet1Response = /** status 200 OK */ FleetModel
export type CreateFleet1Args = {
  fleetCreateModel: FleetCreateModel
}
export type AssignVehicleToFleetResponse = /** status 200 OK */ VehicleModel
export type AssignVehicleToFleetArgs = {
  fleetId: string
  vehicleId: string
}
export type UpdateAlarmResponse = /** status 200 OK */ AlarmModel
export type UpdateAlarmArgs = {
  id: string
  alarmModel: AlarmModel
}
export type EnableAlarmsForOrganizationResponse = unknown
export type EnableAlarmsForOrganizationArgs = {
  id: string
  defaultAlarmId: string
}
export type DisableAlarmsForOrganizationResponse = unknown
export type DisableAlarmsForOrganizationArgs = {
  id: string
  defaultAlarmId: string
}
export type AddVehicleEventResponse = /** status 200 OK */ VehicleEvent
export type AddVehicleEventArgs = {
  vehicleEvent: VehicleEvent
}
export type InitiateMfaSetupResponse = /** status 200 OK */ MfaModel
export type InitiateMfaSetupArgs = void
export type VerifySoftwareTokenResponse = unknown
export type VerifySoftwareTokenArgs = {
  userCode: string
}
export type CreateTripNoteResponse = /** status 200 OK */ TripNote
export type CreateTripNoteArgs = {
  tripNote: TripNote
}
export type GetScheduledRoutesResponse = /** status 200 OK */ PagedResponseV1ScheduledRouteModel
export type GetScheduledRoutesArgs = {
  vehicleId?: string
  siteId?: string
  ocppTag?: string
  scheduledRouteType?: 'USER_CREATED' | 'PREDICTED' | 'GEOTAB' | 'ZONAR' | 'SAMSARA' | 'OTHER'
  departFrom?: string
  departTo?: string
  arriveFrom?: string
  arriveTo?: string
  page?: number
  size?: number
}
export type CreateScheduledRouteResponse = /** status 200 OK */ ScheduleRouteResponse
export type CreateScheduledRouteArgs = {
  scheduleRouteRequest: ScheduleRouteRequest
}
export type AssignVehicleResponse = /** status 200 OK */ VehicleModel
export type AssignVehicleArgs = {
  id: string
  vehicleId: string
}
export type OnboardOrganizationToStripeResponse = /** status 200 OK */ StringResponseModel
export type OnboardOrganizationToStripeArgs = {
  id: string
  urlRedirectModel: UrlRedirectModel
}
export type SaveOrganizationLogoResponse = /** status 200 OK */ Organization
export type SaveOrganizationLogoArgs = {
  id: string
  body: {
    logo: Blob
  }
}
export type DeleteOrganizationLogoResponse = /** status 200 OK */ Organization
export type DeleteOrganizationLogoArgs = {
  id: string
}
export type GetApiKeysByOrganizationIdResponse = /** status 200 OK */ OrganizationApiKeyModel[]
export type GetApiKeysByOrganizationIdArgs = {
  id: string
}
export type ProvisionApiKeyByOrganizationIdResponse = /** status 200 OK */ OrganizationApiKeyModel
export type ProvisionApiKeyByOrganizationIdArgs = {
  id: string
}
export type GetIntegrationsByOrganizationIdResponse = /** status 200 OK */ OrganizationIntegration[]
export type GetIntegrationsByOrganizationIdArgs = {
  id: string
}
export type SaveOrganizationIntegrationResponse = /** status 200 OK */ OrganizationIntegration
export type SaveOrganizationIntegrationArgs = {
  id: string
  organizationIntegration: OrganizationIntegration
}
export type SaveOrganizationIconResponse = /** status 200 OK */ Organization
export type SaveOrganizationIconArgs = {
  id: string
  body: {
    icon: Blob
  }
}
export type DeleteOrganizationIconResponse = /** status 200 OK */ Organization
export type DeleteOrganizationIconArgs = {
  id: string
}
export type GetOrganizationCustomersResponse = /** status 200 OK */ OrganizationModel[]
export type GetOrganizationCustomersArgs = {
  id: string
  skipEnhanceData?: boolean
}
export type CreateFleetResponse = /** status 200 OK */ Organization
export type CreateFleetArgs = {
  id: string
  organization: Organization
}
export type PublishToSnsResponse = unknown
export type PublishToSnsArgs = {
  subEntity: 'TELEMETRY' | 'BASE' | 'INVALID'
  event: 'NEW' | 'UPDATED' | 'DELETED' | 'TRIP_SUMMARY' | 'COMPLETED_ROUTE_SUMMARY' | 'CHARGING_SUMMARY' | 'INVALID'
  body: object
}
export type AssignVehicleToBillingOrganizationResponse = /** status 200 OK */ VehicleModel
export type AssignVehicleToBillingOrganizationArgs = {
  id: string
  vehicleId: string
}
export type SaveNotificationMappingForAlarmsResponse = unknown
export type SaveNotificationMappingForAlarmsArgs = {
  body: AlarmNotificationMapping[]
}
export type DeleteNotificationMappingForAlarmsResponse = unknown
export type DeleteNotificationMappingForAlarmsArgs = {
  alarmNotificationMapping: AlarmNotificationMapping
}
export type GetVehicleResponse = /** status 200 OK */ VehicleModel
export type GetVehicleArgs = {
  id: string
}
export type GetLatestVehicleWeatherForecastResponse = /** status 200 OK */ VehicleWeatherForecast
export type GetLatestVehicleWeatherForecastArgs = {
  id: string
}
export type GetTripsResponse = /** status 200 OK */ Trip[]
export type GetTripsArgs = {
  id: string
  from?: string
  to?: string
  source?: string
}
export type GetTripResponse = /** status 200 OK */ Trip
export type GetTripArgs = {
  id: string
  tripId: string
}
export type GetVehicleTelematicsResponse = /** status 200 OK */ AssetTelematics[]
export type GetVehicleTelematicsArgs = {
  id: string
  from?: string
  to?: string
}
export type GetDailySummaryForVehicleResponse = /** status 200 OK */ DailySummaryMetrics
export type GetDailySummaryForVehicleArgs = {
  id: string
  date: string
  isCurrentDate: boolean
}
export type GetServiceableRangeForVehicleResponse = /** status 200 OK */ AzureServiceableRangeResponse
export type GetServiceableRangeForVehicleArgs = {
  id: string
}
export type GetMetricsForVehicleResponse = /** status 200 OK */ VehicleMetricsModel
export type GetMetricsForVehicleArgs = {
  id: string
  date: string
}
export type GetVehicleFaultsResponse = /** status 200 OK */ PagedResponseV1VehicleFaultModel
export type GetVehicleFaultsArgs = {
  id: string
  page: number
  count: number
  sort?: string
  searchString?: string
  from?: string
  to?: string
}
export type GetAssetEventsResponse = /** status 200 OK */ VehicleEvent[]
export type GetAssetEventsArgs = {
  id: string
  type?: string
  from?: string
  to?: string
}
export type GetVehicleStatusBreakdownForVehiclesResponse = /** status 200 OK */ TimeUtilization
export type GetVehicleStatusBreakdownForVehiclesArgs = {
  from: string
  to?: string
  organizationId?: string
  fleetId?: string
}
export type GetTimespanDataForVehiclesResponse = /** status 200 OK */ VehicleMetrics[]
export type GetTimespanDataForVehiclesArgs = {
  from: string
  to?: string
  organizationId?: string
  fleetId?: string
}
export type GetVehiclesBySiteResponse = /** status 200 OK */ VehicleModel[]
export type GetVehiclesBySiteArgs = {
  id: string
}
export type GetVehicleReportResponse = /** status 200 OK */ ReportGenerationStatus
export type GetVehicleReportArgs = {
  from: string
  to?: string
  organizationId?: string
  fleetId?: string
  siteId?: string[]
}
export type GetMetricsForVehiclesResponse = /** status 200 OK */ VehicleMetricsModel
export type GetMetricsForVehiclesArgs = {
  from: string
  to?: string
  organizationId?: string
  fleetId?: string
  siteId?: string
}
export type GetLatestVehicleStatusesResponse = /** status 200 OK */ LatestVehicleStatusSummaryModel
export type GetLatestVehicleStatusesArgs = {
  siteId: string
}
export type GetEnergyBreakdownDataForVehiclesResponse = /** status 200 OK */ EnergyUseBreakdown[]
export type GetEnergyBreakdownDataForVehiclesArgs = {
  from: string
  to?: string
  organizationId?: string
  fleetId?: string
}
export type GetMetricsForVehicles1Response = /** status 200 OK */ VehicleMetricsModel
export type GetMetricsForVehicles1Args = {
  from: string
  to?: string
  organizationId?: string
  fleetId?: string
}
export type GetVehicleFaults1Response = /** status 200 OK */ PagedResponseV2VehicleFaultModel
export type GetVehicleFaults1Args = {
  id: string
  page: number
  count: number
  sort?: string
  searchString?: string
  from?: string
  to?: string
}
export type GetUserResponse = /** status 200 OK */ UserModel
export type GetUserArgs = {
  id: string
  skipEnhanceUserData?: boolean
}
export type GetUserTagMappingsResponse = /** status 200 OK */ {
  [key: string]: string
}
export type GetUserTagMappingsArgs = {
  ocppTags?: string[]
}
export type GetDropdownOptionsResponse = /** status 200 OK */ OptionValue[]
export type GetDropdownOptionsArgs = void
export type GetTripNotesResponse = /** status 200 OK */ TripNote[]
export type GetTripNotesArgs = {
  id: string
}
export type GetTripReportResponse = /** status 200 OK */ ReportGenerationStatus
export type GetTripReportArgs = {
  from: string
  to?: string
  organizationId?: string
  fleetId?: string
  siteId?: string[]
}
export type GetVehicleUtilizationResponse = /** status 200 OK */ VehicleMetricsModel
export type GetVehicleUtilizationArgs = {
  from: string
  to?: string
  organizationId?: string
  fleetId?: string
  siteId?: string
}
export type GetVehicleBreakdownResponse = /** status 200 OK */ OrganizationSummaryModel
export type GetVehicleBreakdownArgs = {
  from: string
  to?: string
  organizationId?: string
  fleetId?: string
  siteId?: string
}
export type GetRouteByIdResponse = /** status 200 OK */ Route
export type GetRouteByIdArgs = {
  id: string
}
export type DeleteRouteResponse = /** status 200 OK */
  | '100 CONTINUE'
  | '101 SWITCHING_PROTOCOLS'
  | '102 PROCESSING'
  | '103 EARLY_HINTS'
  | '103 CHECKPOINT'
  | '200 OK'
  | '201 CREATED'
  | '202 ACCEPTED'
  | '203 NON_AUTHORITATIVE_INFORMATION'
  | '204 NO_CONTENT'
  | '205 RESET_CONTENT'
  | '206 PARTIAL_CONTENT'
  | '207 MULTI_STATUS'
  | '208 ALREADY_REPORTED'
  | '226 IM_USED'
  | '300 MULTIPLE_CHOICES'
  | '301 MOVED_PERMANENTLY'
  | '302 FOUND'
  | '302 MOVED_TEMPORARILY'
  | '303 SEE_OTHER'
  | '304 NOT_MODIFIED'
  | '305 USE_PROXY'
  | '307 TEMPORARY_REDIRECT'
  | '308 PERMANENT_REDIRECT'
  | '400 BAD_REQUEST'
  | '401 UNAUTHORIZED'
  | '402 PAYMENT_REQUIRED'
  | '403 FORBIDDEN'
  | '404 NOT_FOUND'
  | '405 METHOD_NOT_ALLOWED'
  | '406 NOT_ACCEPTABLE'
  | '407 PROXY_AUTHENTICATION_REQUIRED'
  | '408 REQUEST_TIMEOUT'
  | '409 CONFLICT'
  | '410 GONE'
  | '411 LENGTH_REQUIRED'
  | '412 PRECONDITION_FAILED'
  | '413 PAYLOAD_TOO_LARGE'
  | '413 REQUEST_ENTITY_TOO_LARGE'
  | '414 URI_TOO_LONG'
  | '414 REQUEST_URI_TOO_LONG'
  | '415 UNSUPPORTED_MEDIA_TYPE'
  | '416 REQUESTED_RANGE_NOT_SATISFIABLE'
  | '417 EXPECTATION_FAILED'
  | '418 I_AM_A_TEAPOT'
  | '419 INSUFFICIENT_SPACE_ON_RESOURCE'
  | '420 METHOD_FAILURE'
  | '421 DESTINATION_LOCKED'
  | '422 UNPROCESSABLE_ENTITY'
  | '423 LOCKED'
  | '424 FAILED_DEPENDENCY'
  | '425 TOO_EARLY'
  | '426 UPGRADE_REQUIRED'
  | '428 PRECONDITION_REQUIRED'
  | '429 TOO_MANY_REQUESTS'
  | '431 REQUEST_HEADER_FIELDS_TOO_LARGE'
  | '451 UNAVAILABLE_FOR_LEGAL_REASONS'
  | '500 INTERNAL_SERVER_ERROR'
  | '501 NOT_IMPLEMENTED'
  | '502 BAD_GATEWAY'
  | '503 SERVICE_UNAVAILABLE'
  | '504 GATEWAY_TIMEOUT'
  | '505 HTTP_VERSION_NOT_SUPPORTED'
  | '506 VARIANT_ALSO_NEGOTIATES'
  | '507 INSUFFICIENT_STORAGE'
  | '508 LOOP_DETECTED'
  | '509 BANDWIDTH_LIMIT_EXCEEDED'
  | '510 NOT_EXTENDED'
  | '511 NETWORK_AUTHENTICATION_REQUIRED'
export type DeleteRouteArgs = {
  id: string
}
export type GetRouteScheduleResponse = /** status 200 OK */ VehicleScheduleModel[]
export type GetRouteScheduleArgs = {
  id: string
  from?: string
  to?: string
}
export type GetStatusOfGeneratedReportResponse = /** status 200 OK */ ReportGenerationStatus
export type GetStatusOfGeneratedReportArgs = {
  id: string
}
export type GetOrganizationResponse = /** status 200 OK */ OrganizationModel
export type GetOrganizationArgs = {
  id: string
}
export type GetVehiclesForOrganizationResponse = /** status 200 OK */ VehicleModel[]
export type GetVehiclesForOrganizationArgs = {
  id: string
}
export type GetOrganizationUsersResponse = /** status 200 OK */ UserModel[]
export type GetOrganizationUsersArgs = {
  id: string
  skipEnhanceData?: boolean
}
export type GetTrips1Response = /** status 200 OK */ Trip[]
export type GetTrips1Args = {
  id: string
  from?: string
  to?: string
  vehicleIds?: string[]
  source?: string
}
export type GetOrganizationTermsResponse = /** status 200 OK */ OrganizationTerms[]
export type GetOrganizationTermsArgs = {
  id: string
}
export type LoginToStripeResponse = /** status 200 OK */ StringResponseModel
export type LoginToStripeArgs = {
  id: string
}
export type GetStripeIntentDetailResponse = /** status 200 OK */ StripeIntentModel
export type GetStripeIntentDetailArgs = {
  id: string
}
export type GetRoutesForOrganizationResponse = /** status 200 OK */ Route[]
export type GetRoutesForOrganizationArgs = {
  id: string
}
export type GetIntegrationsByOrganizationIdAndIntegrationTypeResponse = /** status 200 OK */ OrganizationIntegration[]
export type GetIntegrationsByOrganizationIdAndIntegrationTypeArgs = {
  id: string
  integrationId: string
}
export type GetAllOcppTagsResponse = /** status 200 OK */ OcppTag[]
export type GetAllOcppTagsArgs = {
  id: string
}
export type GetFleetsResponse = /** status 200 OK */ Fleet[]
export type GetFleetsArgs = {
  id: string
}
export type GetAllOrganizationCustomersResponse = /** status 200 OK */ string[]
export type GetAllOrganizationCustomersArgs = {
  id: string
  depth?: number
}
export type GetOrganizationConfigResponse = /** status 200 OK */ OrganizationConfig[]
export type GetOrganizationConfigArgs = {
  id: string
}
export type GetOrganizationApiKeyByApiKeyResponse = /** status 200 OK */ OrganizationApiKeyModel
export type GetOrganizationApiKeyByApiKeyArgs = {
  id: string
}
export type GetCurrentOrganizationResponse = /** status 200 OK */ ExternalOrganizationModel
export type GetCurrentOrganizationArgs = void
export type GetLocationOfInterestResponse = /** status 200 OK */ LocationOfInterestResponseModel
export type GetLocationOfInterestArgs = {
  id: string
}
export type DeleteLocationOfInterestResponse = unknown
export type DeleteLocationOfInterestArgs = {
  id: string
}
export type GetIntegrationsResponse = /** status 200 OK */ Integration[]
export type GetIntegrationsArgs = void
export type GetFleetResponse = /** status 200 OK */ FleetModel
export type GetFleetArgs = {
  fleetId: string
}
export type GetCustomersResponse = /** status 200 OK */ PagedResponseV2Customer
export type GetCustomersArgs = {
  organizationId: string
  page?: number
  count?: number
  depth?: number
}
export type GetChartValuesResponse = /** status 200 OK */ ChartDataResponseModel
export type GetChartValuesArgs = {
  id: string
  /** Defaults to last 14 days if from are not supplied. */
  from?: string
  /** Defaults to current time if to is not supplied. */
  to?: string
  attributes: string[]
}
export type GetAlarmsForFleetResponse = /** status 200 OK */ AlarmModel[]
export type GetAlarmsForFleetArgs = {
  organizationId?: string
}
export type GetNotificationMappingForAlarmsResponse = /** status 200 OK */ AlarmNotificationMapping[]
export type GetNotificationMappingForAlarmsArgs = {
  id: string
}
export type GetTriggeredAlarmsResponse = /** status 200 OK */ TriggeredAlarm[]
export type GetTriggeredAlarmsArgs = {
  from?: string
  to?: string
}
export type GetServiceEventResponse = /** status 200 OK */ ServiceEventModel
export type GetServiceEventArgs = {
  /** The ID of the service event to retrieve. */
  eventId: string
}
export type UpdateServiceEventResponse = /** status 200 OK */ ServiceEventModel
export type UpdateServiceEventArgs = {
  /** The ID of the service event to update. */
  eventId: string
  updateServiceEventModel: UpdateServiceEventModel
}
export type DeleteServiceEventResponse = /** status 200 OK */ ServiceEventModel
export type DeleteServiceEventArgs = {
  /** The ID of the service event to delete. */
  eventId: string
}
export type GetChargeProfilesResponse = /** status 200 OK */ PagedResponseV0ChargeProfileModel
export type GetChargeProfilesArgs = {
  /** An identifier of a fleet by which to filter charging profiles. */
  fleetId?: string
  /** An identifier of a site (depot) by which to filter charging profiles. */
  depotId?: string
  /** An optional time-bound to filter charging profiles that start after the provided time.
    
     The filter is performed on the field effectiveFrom in the chargingProfile model, and is different from validFrom and validTo. */
  effectiveAfter?: string
  /** The amount of levels of child organizations to search and include charging profiles for. */
  orgDepth?: number
  /** An index used to retrieve the next page of charging profiles. */
  page?: number
  /** The number of charging profiles to be returned in a page. */
  count?: number
}
export type UpdateChargeProfileResponse = /** status 200 OK */ ChargeProfileModel
export type UpdateChargeProfileArgs = {
  force?: boolean
  fleetId?: string
  chargeProfileUpdateModel: ChargeProfileUpdateModel
}
export type SaveChargeProfileResponse = /** status 200 OK */ ChargeProfileModel
export type SaveChargeProfileArgs = {
  fleetId?: string
  chargeProfileCreateModel: ChargeProfileCreateModel
}
export type ClearChargingProfileResponse = /** status 200 OK */ BooleanModel
export type ClearChargingProfileArgs = {
  fleetId?: string
  chargeProfileActionRequest: ChargeProfileActionRequest
}
export type GetDepotsResponse = /** status 200 OK */ PagedResponseV0DepotModel
export type GetDepotsArgs = {
  /** [DEPRECATED] An identifier of an organization (formerly 'fleet') by which to filter sites. NOTE: this param is deprecated. Please use `organizationId` instead */
  fleetId?: string
  /** An identifier of an organization by which to filter sites. */
  organizationId?: string
  /** Filter for sites that have been created since the provided UTC timestamp. */
  createdSince?: string
  /** Filter for sites that have been updated since the provided UTC timestamp. */
  updatedSince?: string
  /** An index used to retrieve the next page of sites. */
  page?: number
  /** The number of chargers to be returned in a page. */
  count?: number
}
export type UpdateDepotResponse = /** status 200 OK */ DepotModel
export type UpdateDepotArgs = {
  depotMetaDataUpdateModel: DepotMetaDataUpdateModel
}
export type CreateDepotResponse = /** status 200 OK */ DepotModel
export type CreateDepotArgs = {
  depotCreationModel: DepotCreationModel
}
export type GetDepotEnergyLimitsResponse = /** status 200 OK */ DepotEnergyLimitsModel
export type GetDepotEnergyLimitsArgs = {
  depotId: string
}
export type UpdateDepotEnergyLimitsResponse = /** status 200 OK */ DepotEnergyLimitsModel
export type UpdateDepotEnergyLimitsArgs = {
  depotId: string
  depotEnergyLimitsUpdateModel: DepotEnergyLimitsUpdateModel
}
export type GetChargersResponse = /** status 200 OK */ PagedResponseV0ChargerModel
export type GetChargersArgs = {
  /** [DEPRECATED] An identifier of an organization (formerly 'fleet') by which to filter chargers. NOTE: this param is deprecated. Please use `organizationId` instead */
  fleetId?: string
  /** Identifier(s) of an organization (or organizations) by which to filter chargers. If passing multiple organization IDs, separate with commas. */
  organizationId?: string[]
  /** [DEPRECATED] An identifier of a site (formerly 'depot') by which to filter chargers. NOTE: this param is deprecated. Please use `siteIds` instead */
  depotId?: string
  /** Filter chargers by a list of provided site ids, if provided, the depotId parameter will be ignored */
  siteIds?: string[]
  /** Filter chargers with a power rating greater than or equal to the value provided */
  minimumPower?: number
  /** Filter for chargers that are available from the provided UTC timestamp. */
  availableFrom?: string
  /** Filter for chargers that are available to the provided UTC timestamp. */
  availableTo?: string
  /** Filter for chargers that have been created since the provided UTC timestamp. */
  createdSince?: string
  /** Filter for chargers that have been updated since the provided UTC timestamp. */
  updatedSince?: string
  /** Filter chargers by their charger status. Status values can be separated by a comma. */
  chargerStatus?: ('Operative' | 'PartiallyFaulted' | 'Faulted' | 'Unavailable' | 'Unknown' | 'Offline')[]
  /** Filter chargers by their operational status. */
  operationalStatus?: ('Operative' | 'Inoperative' | 'Unknown')[]
  /** Filter chargers by their connectivity status. */
  connectivityStatus?: 'Online' | 'Offline'
  /** Filter chargers on whether or not there are any connectors currently in the `Preparing` state. */
  hasConnectorsPreparing?: boolean
  /** Filter chargers on whether or not there are any connectors currently in the `Charging` state. */
  hasConnectorsCharging?: boolean
  /** Filter chargers on whether or not there are any connectors currently in the `Faulted` state. */
  hasConnectorsFaulted?: boolean
  /** Filter chargers on whether or not there are any connectors currently in the `Unavailable` state. */
  hasConnectorsUnavailable?: boolean
  /** Filter chargers on whether or not there are any connectors currently in the `Available` state. */
  hasConnectorsAvailable?: boolean
  /** Filter chargers on whether or not there are any connectors currently in the `Finishing` state. */
  hasConnectorsFinishing?: boolean
  /** Filter chargers on whether or not there are any connectors currently in the `SuspendedEV` state. */
  hasConnectorsSuspendedEv?: boolean
  /** Filter chargers on whether or not there are any connectors currently in the `SuspendedEVSE` state. */
  hasConnectorsSuspendedEvse?: boolean
  /** Filter for chargers that have communicated with the OCPP server since the provided timestamp. */
  heardSince?: string
  /** Filter for chargers that have *not* communicated with the OCPP server since the provided timestamp. */
  notHeardSince?: string
  /** Search (case insensitive) for chargers by their chargerID, siteName, or chargerName. */
  searchString?: string
  /** List of commissioning states to filter chargers by. */
  commissioningStates?: ('PRECOMMISSIONED' | 'COMMISSIONED' | 'DECOMMISSIONED')[]
  /** Depth of 'child organizations' to include in the search for chargers. Pass 0 to exclude all child organizations, and only include chargers of the specific organization being queried with organizationId. */
  orgDepth?: number
  /** An index used to retrieve the next page of chargers. */
  page?: number
  /** The number of chargers to be returned in a page. */
  count?: number
  /** A value to sort your collection of chargers by */
  sortBy?:
    | 'depotId'
    | 'chargerName'
    | 'created'
    | 'updated'
    | 'lastHeartbeatTimestamp'
    | 'externalId'
    | 'diagnosticsTimestamp'
  /** The order in which to sort your collection of chargers by. */
  order?: 'ASC' | 'DESC'
}
export type UpdateChargerResponse = /** status 200 OK */ ChargerDetailModel
export type UpdateChargerArgs = {
  chargerUpdateModel: ChargerUpdateModel
}
export type CreateChargerResponse = /** status 200 OK */ ChargerDetailModel
export type CreateChargerArgs = {
  chargerCreationModel: ChargerCreationModel
}
export type GetServiceEventsResponse = /** status 200 OK */ PagedResponseV0ServiceEventModel
export type GetServiceEventsArgs = {
  /** The list of charger IDs to retrieve service events for. */
  chargerIds: string[]
  /** An optional lower time-bound to filter on the serviceEvents effectiveAsOf date. */
  from?: string
  /** An optional lower time-bound to filter on the serviceEvents effectiveAsOf date. */
  to?: string
  /** An index used to retrieve the next page of charger service events. */
  page: number
  /** The number of charger service events to be returned in a page. */
  count: number
  /** A value to sort your collection of charger service events by */
  sortBy?: 'effectiveAsOf'
  /** The order in which to sort your collection of charger service events by. */
  order?: 'ASC' | 'DESC'
}
export type CreateServiceEventResponse = /** status 200 OK */ ServiceEventModel
export type CreateServiceEventArgs = {
  createServiceEventModel: CreateServiceEventModel
}
export type BulkCommissionDecommissionChargersResponse = /** status 200 OK */ BulkCommissionActionResponseModel
export type BulkCommissionDecommissionChargersArgs = {
  bulkCommissionActionRequestModel: BulkCommissionActionRequestModel
}
export type SetChargeProfileResponse = /** status 200 OK */ BooleanModel
export type SetChargeProfileArgs = {
  fleetId?: string
  chargeProfileActionRequest: ChargeProfileActionRequest
}
export type CmsOcppTagsGetTagsResponse = /** status 200 OK */ PagedResponseV0TagResponseModel
export type CmsOcppTagsGetTagsArgs = {
  /** An identifier of a fleet by which to filter OCPP tags by, use this to filter down to one organization. */
  organizationId?: string
  /** The amount of levels of child organizations to search and include when searching for OCPP tags. */
  orgDepth?: number
  /** An index used to retrieve the next page of OCPP tags. */
  page?: number
  /** The number OCPP tags to be returned in a page. */
  count?: number
}
export type CmsOcppTagsAddTagsResponse = /** status 201 Created */ TagResponseModel[]
export type CmsOcppTagsAddTagsArgs = {
  addTagsInputModel: AddTagsInputModel
}
export type CmsOcppConfigurationChangeConfigurationResponse = /** status 200 OK */ ChangeConfigurationApiResponseModel
export type CmsOcppConfigurationChangeConfigurationArgs = {
  /** The unique identifier of the charger to update the configuration for */
  chargerId: string
  changeConfigurationKeyRequestModel: ChangeConfigurationKeyRequestModel
}
export type CmsOcppConfigurationRefreshConfigurationsResponse = /** status 200 OK */ RefreshConfigurationStatusModel
export type CmsOcppConfigurationRefreshConfigurationsArgs = {
  refreshChargerConfigurationParams: RefreshChargerConfigurationParams
}
export type CmsChargersGetChargingGroupsResponse = /** status 200 OK */ PagedResponseV0ChargingGroupResponseModel
export type CmsChargersGetChargingGroupsArgs = {
  /** The identifier of an organization by which to filter charging groups. Note: If organizationId is passed and 'organizationIds' is empty, the organizationId will be used along with orgDepth to retrieve charging groups from your organization and all child organizations. */
  organizationId?: string
  /** The identifiers of a set of organizations by which to filter charging groups. Note: If organizationIds is empty and 'organizationId' is empty, the requester's organizationId and an orgDepth of 0 will be used. */
  organizationIds?: string[]
  /** Depth of 'child organizations' to include in the search for charging groups. Pass 0 to exclude all child organizations, and only include charging groups of the specific organization being queried with organizationId. */
  orgDepth?: number
  /** A list of unique siteIds to filter your results by. */
  siteIds?: string[]
  /** An index used to retrieve the next page of charging groups. */
  page?: number
  /** The number of charging groups to be returned in a page. */
  count?: number
  /** The field by which to sort the charging groups. */
  sortBy?: 'name'
  /** The order in which to sort the charging groups. */
  order?: 'ASC' | 'DESC'
}
export type CmsChargersCreateChargingGroupResponse = /** status 200 OK */ ChargingGroupResponseModel
export type CmsChargersCreateChargingGroupArgs = {
  chargingGroupCreateModel: ChargingGroupCreateModel
}
export type StopChargingTransactionResponse = /** status 200 OK */ BooleanModel
export type StopChargingTransactionArgs = {
  /** The identifier for the charger you wish to reset */
  chargerId: string
  chargingSessionId?: string
  chargingTransactionId?: string
}
export type StartChargingTransactionResponse = /** status 200 OK */ TransactionStartResponseModel
export type StartChargingTransactionArgs = {
  /** The identifier for the charger you wish to reset */
  chargerId: string
  chargingTransactionRequest: ChargingTransactionRequest
}
export type ResetChargerResponse = /** status 200 OK */ BooleanModel
export type ResetChargerArgs = {
  /** The identifier for the charger you wish to reset */
  chargerId: string
  /** Type of reset to do. A hard reset may lead to more downtime as a full power cycle is performed whereas a soft resetwill perform a software reset. */
  type?: string
}
export type CmsOcppDestinationGetMultipleOcppDestinationsResponse =
  /** status 200 OK */ PagedResponseV0OcppDestinationResponseModel
export type CmsOcppDestinationGetMultipleOcppDestinationsArgs = {
  /** A list of unique destinationIds to filter your results by. */
  ids?: string[]
  /** A list of unique IoT destinationIds to filter your results by. */
  destinationIds?: string[]
  /** An index used to retrieve the next page of OCPP destinations. */
  page?: number
  /** The number OCPP destinations to be returned in a page. */
  count?: number
}
export type CmsOcppDestinationCreateOcppDestinationResponse = /** status 200 OK */ OcppDestinationResponseModel
export type CmsOcppDestinationCreateOcppDestinationArgs = {
  createOcppDestinationModel: CreateOcppDestinationModel
}
export type CmsChargersGetChargingGroupResponse = /** status 200 OK */ ChargingGroupResponseModel
export type CmsChargersGetChargingGroupArgs = {
  id: string
}
export type CmsChargersDeleteChargingGroupResponse = /** status 200 OK */ ChargingGroupResponseModel
export type CmsChargersDeleteChargingGroupArgs = {
  id: string
}
export type CmsChargersUpdateChargingGroupResponse = /** status 200 OK */ ChargingGroupResponseModel
export type CmsChargersUpdateChargingGroupArgs = {
  id: string
  chargingGroupUpdateModel: ChargingGroupUpdateModel
}
export type CmsOcppDestinationGetOcppDestinationResponse = /** status 200 OK */ OcppDestinationResponseModel
export type CmsOcppDestinationGetOcppDestinationArgs = {
  /** OCPP destination ID */
  id: string
}
export type CmsOcppDestinationDeleteOcppDestinationResponse = /** status 200 OK */ OcppDestinationResponseModel
export type CmsOcppDestinationDeleteOcppDestinationArgs = {
  /** The unique identifier of an OCPP Destination. */
  id: string
}
export type CmsOcppDestinationUpdateOcppDestinationResponse = /** status 200 OK */ OcppDestinationResponseModel
export type CmsOcppDestinationUpdateOcppDestinationArgs = {
  /** The unique identifier of an OCPP Destination. */
  id: string
  updateOcppDestinationModel: UpdateOcppDestinationModel
}
export type GetChargingTransactionsResponse = /** status 200 Success */ PagedResponseV0TransactionSummaryModel
export type GetChargingTransactionsArgs = {
  /** The identifier of a charger by which to filter charging transactions.
    
    NOTE: If a chargerId is passed, the depotId and organizationId params will be ignored. */
  chargerId?: string
  /** An identifier of a site (depot) by which to filter charging transactions. */
  depotId?: string
  /** An identifier of a organization by which to filter charging transactions. */
  organizationId?: string
  /** An identifier of an OCPP ID tag by which to filter charging transactions. */
  tagId?: string
  /** An optional lower time-bound to filter on the transaction's start_timestamp. */
  startTimeFrom?: string
  /** An optional upper time-bound to filter on the transaction's start_timestamp. */
  startTimeTo?: string
  /** An optional lower time-bound to filter on the transaction's stop_timestamp. */
  stopTimeFrom?: string
  /** An optional upper time-bound to filter on the transaction's stop_timestamp. */
  stopTimeTo?: string
  /** A list of transaction statuses to be filtered on. Can be "in progress" or "ended". Filtering for in progress transactions will include those which do not have a `stopTimestamp`. Filtering for ended transactions will include those which do have a `stopTimestamp`. */
  status?: ('inProgress' | 'ended')[]
  /** An optional upper time-bound to filter on the last time the transaction was updated in Synop.
    
    For example, a charger can go offline and backfill transactions at a later date, leading to a more recent updated_timestamp than stop_timestamp */
  updatedSince?: string
  /** An index used to retrieve the next page of charging transactions. */
  page?: number
  /** The number of charging transactions to be returned in a page. */
  count?: number
  /** An order direction of the charging transactions to be returned (uses start_timestamp) */
  order?: 'ASC' | 'DESC'
}
export type GetTransactionByIdResponse = /** status 200 Success */ TransactionSummaryModel
export type GetTransactionByIdArgs = {
  /** Identifier of the charging session. Cannot be empty. */
  id: string
}
export type GetTransactionMeterValuesResponse = /** status 200 Success */ TransactionMeterValuesModel[]
export type GetTransactionMeterValuesArgs = {
  /** Identifier of the transaction. Cannot be empty. */
  id: string
}
export type CmsChargingTransactionsGetMeterValueIntervalDataResponse =
  /** status 200 OK */ JsonApiObjectMeterValueCollectionIntervalModelMeterValueCollectionMetaAttributeModel
export type CmsChargingTransactionsGetMeterValueIntervalDataArgs = {
  /** Identifier of the transaction. Cannot be empty. */
  id: string
  /** A optional lower time-bound to filter on transaction-based meter value interval data from and including the provided UTC timestamp.
    If not provided, it will default to the first interval period that contains the start timestamp of the transaction.
    For example, with a 15 minute interval period, if the transaction start timestamp is 2020-01-01T12:07:00Z, the `from` timestamp will be rounded down and defaulted to 2020-01-01T12:00:00Z */
  from?: string
  /** An optional upper time-bound to filter on transaction-based meter value interval data ending at provided UTC timestamp.
     If not provided, it will default to the current timestamp of the request if a transaction is in progress, or to the stop timestamp of a transaction if it has completed.
    The `to` timestamp will be rounded up to the closest supported interval period to be inclusive of the interval in your request.
    For example, with a 15 minute interval period, if the `to` timestamp is 2020-01-01T12:07:00Z, the `to` timestamp will be rounded up to and defaulted to 2020-01-01T12:15:00Z */
  to?: string
  /** A time interval to group meter value data by. */
  intervalPeriod?: '15m' | '5m'
  /** A list of measure names to include in the response. If not provided, all default measures will be included  */
  measureNames?: ('maxPowerImport' | 'meanPowerImport' | 'totalEnergyImport')[]
  /** A boolean to truncate the first and last intervals of the response, respectively, to the transaction's start and stop timestamps.
     If not provided, the response will include the full interval period from the 'from' timestamp to the last interval period of the 'to' timestamp.
    For example, if truncateToTxnBounds is true, and the transaction start timestamp is 2020-01-01T12:07:00Z and the stop timestamp is 2020-01-01T12:22:00Z,
     the response will include first interval period will be [2020-01-01T12:07:00Z, 2020-01-01T12:15:00Z] and the last interval will be [2020-01-01T12:15:00Z, 2020-01-01T12:22:00Z].
    If truncateToTxnBounds if false (the default behavior), the first interval period will be [2020-01-01T12:00:00Z, 2020-01-01T12:15:00Z] and the last interval will be [2020-01-01T12:15:00Z, 2020-01-01T12:30:00Z]. */
  truncateToTxnBounds?: boolean
}
export type GetPowerUtilizationResponse = /** status 200 OK */ PowerUtilizationModel
export type GetPowerUtilizationArgs = {
  /** Lower time-bound for the time-series data (inclusive). */
  from?: string
  /** Upper time-bound for the time-series data (non-inclusive). */
  to?: string
  /** A time interval to group meter value data by (optional; default='1m') */
  intervalPeriod?: '1m' | '5m' | '15m'
  /** The ID of the organization over which to query utilization data. If not specified, the organization of the authenticated user will be used. */
  fleetId?: string
  /** Depth of 'child organizations' to include in the search for chargers. Pass 0 to exclude all child organizations, and only include chargers of the specific organization being queried with organizationId. */
  orgDepth?: number
}
export type GetChargingSummaryResponse = /** status 200 OK */ ChargingSummaryModel
export type GetChargingSummaryArgs = {
  fleetId?: string
  depotIds?: string[]
  from: string
  to: string
  orgDepth?: number
}
export type GetServiceEventAuditResponse = /** status 200 OK */ PagedResponseV0ServiceEventAuditModel
export type GetServiceEventAuditArgs = {
  /** The ID of the service event to retrieve audit history for. */
  eventId: string
  /** An optional lower time-bound to filter on the serviceEventAudits timestamp. */
  from?: string
  /** An optional lower time-bound to filter on the serviceEventAudits timestamp. */
  to?: string
  /** An index used to retrieve the next page of charger service event audits. */
  page: number
  /** The number of charger service event audits to be returned in a page. */
  count: number
  /** A value to sort your collection of charger service event audits by */
  sortBy?: 'auditTime'
  /** The order in which to sort your collection of charger service event audits by. */
  order?: 'ASC' | 'DESC'
}
export type GetTransactionsReportResponse = /** status 200 OK */ ReportGenerationStatusModel
export type GetTransactionsReportArgs = {
  from: string
  to?: string
  organizationId?: string
  siteId?: string[]
  chargerId?: string
  commissioningStates?: ('PRECOMMISSION' | 'COMMISSION' | 'DECOMMISSION')[]
  orgDepth?: number
}
export type CmsReportsGetStatusOfTransactionReportResponse = /** status 200 OK */ ReportGenerationStatusModel
export type CmsReportsGetStatusOfTransactionReportArgs = {
  id: string
}
export type GetMeterValueIntervalsReportResponse = /** status 200 OK */ ReportGenerationStatusModel
export type GetMeterValueIntervalsReportArgs = {
  /** The site ID for which the Meter Value Interval Report is to be generated. */
  siteId: string
  /** List of commissioning states to filter the Meter Value Interval Report. */
  commissioningStates?: ('PRECOMMISSIONED' | 'COMMISSIONED' | 'DECOMMISSIONED')[]
  /** The interval period for the Meter Value Interval Report. */
  intervalPeriod?: '15m' | '1h'
  /** The start time for the Meter Value Interval Report. */
  from: string
  /** The end time for the Meter Value Interval Report. If not provided, it defaults to 3 months from 'from' time or the current time if 3 months from 'from' time is in the future. */
  to?: string
}
export type GetMeterValueIntervalsReportStatusResponse = /** status 200 OK */ ReportGenerationStatusModel
export type GetMeterValueIntervalsReportStatusArgs = {
  id: string
}
export type GetChargeProfileResponse = /** status 200 OK */ ChargeProfileModel
export type GetChargeProfileArgs = {
  /** Profile identifier. Cannot be empty. */
  profileId: string
  fleetId?: string
}
export type CmsOcppConfigurationGetLatestConfigurationsForChargerResponse =
  /** status 200 OK */ OcppConfigurationResponseModel
export type CmsOcppConfigurationGetLatestConfigurationsForChargerArgs = {
  /** The unique identifier of the charger to pull OCPP configurations for */
  chargerId: string
}
export type CmsOcppConfigurationGetRefreshStatusForChargerResponse =
  /** status 200 OK */ RefreshConfigurationStatusModel
export type CmsOcppConfigurationGetRefreshStatusForChargerArgs = {
  /** The unique identifier of the charger to pull OCPP configurations for */
  chargerId: string
}
export type CmsOcppConfigurationGetRefreshStatusResponse = /** status 200 OK */ RefreshConfigurationStatusModel
export type CmsOcppConfigurationGetRefreshStatusArgs = {
  /** The unique identifier of the refresh configuration status request */
  id: string
}
export type CmsOcppConfigurationGetLatestConfigurationsResponse = /** status 200 OK */ OcppConfigurationResponseModel
export type CmsOcppConfigurationGetLatestConfigurationsArgs = {
  /** NOTE: This parameter will only retrieve configurations for the first charger in a list.
     A list of charger IDs to pull OCPP configurations for. */
  chargerIds: string[]
  /** NOTE:This paramater is not in use and will be expanded on in further release.
     A list of site ids to pull OCPP configurations for, for all chargers at the provided sites. */
  siteIds?: string[]
  /** NOTE:This paramater is not in use and will be expanded on in further release.
     The organization id to pull OCPP configurations for, for all chargers at all sites in the provided organization. */
  orgId?: string
  /** NOTE:This paramater is not in use and will be expanded on in further release.
    The depth of the organization to pull OCPP configurations for. */
  orgDepth?: number
}
export type GetLiveUtilizationResponse = /** status 200 OK */ {
  [key: string]: LiveDepotUtilizationModel
}
export type GetLiveUtilizationArgs = {
  /** Fleet identifier. Cannot be empty. */
  fleetId: string
  /** Depth of 'child fleets' to include in the search for depots. Pass 0 to exclude all child fleets, and only include the specific fleet being queried with fleetId. */
  orgDepth?: number
}
export type GetDepotResponse = /** status 200 OK */ DepotModel
export type GetDepotArgs = {
  depotId: string
  include?: 'importKwhLast24h'[]
}
export type DeleteDepotResponse = /** status 200 OK */ BooleanModel
export type DeleteDepotArgs = {
  depotId: string
}
export type GetDepotUtilizationResponse = /** status 200 OK */ PowerUtilizationModel
export type GetDepotUtilizationArgs = {
  depotId: string
  /** Lower time-bound for the time-series data (inclusive). */
  from?: string
  /** Upper time-bound for the time-series data (non-inclusive). */
  to?: string
  /** A time interval to group meter value data by (optional; default='1m') */
  intervalPeriod?: '1m' | '5m' | '15m'
  /** A list of measure names to include in the response if left null the default is activePowerImport,activePowerExport,powerOffered */
  measureNames?: (
    | 'activePowerImport'
    | 'activePowerExport'
    | 'powerOffered'
    | 'totalEnergyImport'
    | 'totalEnergyExport'
  )[]
}
export type GetDepotUptimeResponse = /** status 200 OK */ UptimeResponse
export type GetDepotUptimeArgs = {
  /** The identifier for the depot you wish to query */
  depotId: string
  from: string
  to: string
}
export type GetDepotStatusResponse = /** status 200 OK */ LiveDepotUtilizationModel
export type GetDepotStatusArgs = {
  depotId: string
}
export type GetChargerResponse = /** status 200 OK */ ChargerDetailModel
export type GetChargerArgs = {
  /** Identifier of the charger. Cannot be empty. */
  chargerId: string
}
export type DeleteChargerResponse = /** status 200 OK */ BooleanModel
export type DeleteChargerArgs = {
  chargerId: string
}
export type GetChargerUtilizationTimelineResponse = /** status 200 OK */ PowerUtilizationModel
export type GetChargerUtilizationTimelineArgs = {
  chargerId: string
  /** Lower time-bound for the time-series data (inclusive). */
  from?: string
  /** Upper time-bound for the time-series data (non-inclusive). */
  to?: string
  /** A time interval to group meter value data by (optional; default='1m') */
  intervalPeriod?: '1m' | '5m' | '15m'
  /** A list of measure names to include in the response if left null the default is activePowerImport,activePowerExport,powerOffered */
  measureNames?: (
    | 'activePowerImport'
    | 'activePowerExport'
    | 'powerOffered'
    | 'totalEnergyImport'
    | 'totalEnergyExport'
  )[]
}
export type GetChargerUptimeResponse = /** status 200 OK */ UptimeResponse
export type GetChargerUptimeArgs = {
  /** The identifier for the charger you wish to query */
  chargerId: string
  from: string
  to: string
}
export type GetMappedChargerProfilesResponse = /** status 200 OK */ ChargeProfileModel[]
export type GetMappedChargerProfilesArgs = {
  chargerId: string
  connectorId?: number
}
export type GetChargerLogsReportResponse = /** status 200 OK */ ReportGenerationStatusModel
export type GetChargerLogsReportArgs = {
  chargerId: string
  /** Date and time from which the logs are to be fetched. This field is required. The 'from' time must fall within the 52-week retention period for the OCPP logs */
  from: string
  /** Date and time up to which the logs are to be fetched. This field is optional. If left unspecified, it defaults to 1 day following the 'from' date. The 'to' date and time must be within 24 hours of the 'from' date and time. */
  to?: string
  ocppMessageTypes?:
    | 'Authorize'
    | 'BootNotification'
    | 'DataTransfer'
    | 'DiagnosticsStatusNotification'
    | 'FirmwareStatusNotification'
    | 'Heartbeat'
    | 'MeterValues'
    | 'StartTransaction'
    | 'StatusNotification'
    | 'StopTransaction'
    | 'CancelReservation'
    | 'ChangeAvailability'
    | 'ChangeConfiguration'
    | 'ClearCache'
    | 'ClearChargingProfile'
    | 'GetCompositeSchedule'
    | 'GetConfiguration'
    | 'GetDiagnostics'
    | 'GetLocalListVersion'
    | 'RemoteStartTransaction'
    | 'RemoteStopTransaction'
    | 'ReserveNow'
    | 'Reset'
    | 'SendLocalList'
    | 'SetChargingProfile'
    | 'TriggerMessage'
    | 'UnlockConnector'
    | 'UpdateFirmware'
}
export type GetChargerFaultsResponse = /** status 200 OK */ ChargerFaultsModel
export type GetChargerFaultsArgs = {
  chargerId: string
  from?: string
  to?: string
  page?: number
  count?: number
}
export type GetChargerConnectorsResponse = /** status 200 OK */ ConnectorModel[]
export type GetChargerConnectorsArgs = {
  chargerId: string
}
export type CmsChargersGetConfigurationKeysResponse = /** status 200 OK */ ConfigurationKeyResponseModel
export type CmsChargersGetConfigurationKeysArgs = {
  /** The identifier for the charger you wish to reset */
  chargerId: string
}
export type GetCapabilitySetResponse = /** status 200 Success */ ChargerCapabilitySetResponseModel
export type GetCapabilitySetArgs = {
  /** The identifier for the charger. */
  chargerId: string
}
export type CmsUtilizationGetUtilizationResponse = /** status 200 OK */ UtilizationResponseModel
export type CmsUtilizationGetUtilizationArgs = {
  /** The identifier of an organization by which to filter data. */
  organizationId?: string
  /** Identifiers of sites belonging to particular organizations to filter utilization data by. */
  siteIds?: string[]
  /** The amount of levels of child organizations to search and include data for. */
  orgDepth?: number
}
export type GetChargersTableDataResponse = /** status 200 OK */ ChargersTimespanTableModel
export type GetChargersTableDataArgs = {
  /** The identifier of an organization by which to filter data by. */
  organizationId?: string
  /** The amount of levels of child organizations to search and include data for. */
  orgDepth?: number
  /** Identifiers of sites belonging to particular organizations to filter dashboard table data by. */
  siteIds?: string[]
  /** A lower time-bound to filter on dashboard summary data starting from and including the provided UTC timestamp. */
  fromTs: string
  /** An optional upper time-bound to filter on dashboard summary data ending at including the provided UTC timestamp. If not provided, defaults to the current timestamp of the request. */
  toTs?: string
  /** An index used to retrieve the next page of charger summaries. */
  page?: number
  /** The number of chargers summaries to be returned in a page. */
  count?: number
  /** An order direction of the charger summary data to be returned, uses either the default of chargerName, or the parameter provided in sortBy) */
  order?: 'ASC' | 'DESC'
  /** A parameter to sort charger table data by. */
  sortBy?: 'chargerName' | 'chargerId'
  /** Search for chargers by their name or ID (case insensitive). */
  searchString?: string
}
export type GetSummaryDataResponse = /** status 200 OK */ ChargersTimespanSummaryModel
export type GetSummaryDataArgs = {
  /** The identifier of an organization by which to filter data by. */
  organizationId?: string
  /** The amount of levels of child organizations to search and include data for. */
  orgDepth?: number
  /** Identifiers of sites belonging to particular organizations to filter dashboard table data by. */
  siteIds?: string[]
  /** A lower time-bound to filter on dashboard summary data starting from and including the provided UTC timestamp. */
  fromTs: string
  /** An optional upper time-bound to filter on dashboard summary data ending at including the provided UTC timestamp. If not provided, defaults to the current timestamp of the request. */
  toTs?: string
}
export type CmsTimespanGetHistoricalStatusesResponse = /** status 200 OK */ HistoricalStatusSummaryModel
export type CmsTimespanGetHistoricalStatusesArgs = {
  /** The identifier of an organization by which to filter historical statuses. */
  organizationId?: string
  /** A list of site identifiers by which to filter historical statuses. */
  siteIds?: string[]
  /** A list of charger identifiers by which to filter historical statuses.
    
    NOTE: If any charger IDs are passed, the siteIds and organizationId params will be ignored. */
  chargerIds?: string[]
  /** The number of levels of child organizations to search and include historical charger and connector status summary summary data for. */
  orgDepth?: number
  /** An inclusive lower time-bound to filter on status data. */
  fromTs: string
  /** An optional non-inclusive upper time-bound to filter on status data. If not provided, defaults to the current timestamp of the request, rounded down to the nearest 15 minutes. */
  toTs?: string
}
export type CmsChargersGetChargerStatusResponse = /** status 200 OK */ PagedResponseV0ChargerStatusApiResponseModel
export type CmsChargersGetChargerStatusArgs = {
  /** The identifiers of a set of organizations by which to filter charger statuses. Note: If organizationIds is empty and 'organizationId' is empty, the requester's organizationId and an orgDepth of 0 will be used. */
  organizationIds?: string[]
  /** The identifiers of a set of chargers by which to filter charger statuses. Note: If any siteIds are passed, the organizationId and organizationId params will be ignored, and filtering on statuses will be performed by site. */
  siteIds?: string[]
  /** An identifier of an organization by which to filter charger statuses. NOTE: If organizationId is passed and 'organizationIds' is empty, the organizationId will be used along with orgDepth to retrieve statuses from your organization and all child organizations. */
  organizationId?: string
  /** Depth of 'child organizations' to include in the search for charger's connector statuses. Pass 0 to exclude all child organizations, and only include chargers of the specific organization being queried with organizationId. */
  orgDepth?: number
  /** The identifiers of a set of chargers by which to filter charger statuses. Note: If any charger IDs are passed, filtering will be performed on collections by chargerID only. */
  chargerIds?: string[]
  /** The identifiers of a set of connectors by which to filter charger statuses. Note: Connector 0 refers to the charger as a whole. */
  connectorIds?: number[]
  /** A list of statuses types to include in the response. */
  statuses?: (
    | 'Available'
    | 'Preparing'
    | 'Charging'
    | 'Finishing'
    | 'SuspendedEVSE'
    | 'SuspendedEV'
    | 'Reserved'
    | 'PartiallyFaulted'
    | 'Faulted'
    | 'Unavailable'
    | 'Unknown'
    | 'Offline'
  )[]
  /** A lower time-bound to filter on the status timestamp. */
  from: string
  /** An optional upper time-bound to filter on the status timestamp. Note: If not provided, the current time will be used. */
  to?: string
  /** An index used to retrieve the next page of charger statuses. */
  page?: number
  /** The number charger statuses to be returned in a page. */
  count?: number
  /** A value to sort your collection of statuses by. */
  sortBy?: 'timestamp'
  /** The order in which to sort your collection of statuses by. */
  order?: 'ASC' | 'DESC'
}
export type CmsOcppLogsGetStatusOfOcppLogsRequestResponse = /** status 200 OK */ ReportGenerationStatusModel
export type CmsOcppLogsGetStatusOfOcppLogsRequestArgs = {
  id: string
}
export type CmsMeterDataIntervalsResponse =
  /** status 200 OK */ JsonApiObjectMeterValueIntervalDataModelMeterValueIntervalMetaAttributeModel
export type CmsMeterDataIntervalsArgs = {
  /** A lower time-bound to filter interval data from (inclusive). Note that this actual time bound queried will be the interval period (associated with `intervalPeriod`) that contains this `from` field.
    For example, with a 15 minute `intervalPeriod`, if the `from` value passed is 2020-01-01T12:07:00Z, the `from` timestamp will be rounded down and queried as 2020-01-01T12:00:00Z.
     */
  from: string
  /** An upper time-bound to filter interval data from (exclusive). Note that this actual time bound queried will be the interval period (associated with `intervalPeriod`) that contains this `to` field.
    For example, with a 15 minute `intervalPeriod`, if the `to` value passed is 2020-01-01T12:07:00Z, the `to` timestamp will be rounded up and queried as 2020-01-01T12:15:00Z.
     */
  to: string
  /** A list of site identifiers by which to filter data. */
  siteIds?: string[]
  /** An identifier of an organization by which to filter data. */
  organizationId?: string
  /** The number of levels of child organizations to search and include data for. */
  orgDepth?: number
}
export type GetLatestStatusSummaryResponse = /** status 200 OK */ LatestStatusSummaryModel
export type GetLatestStatusSummaryArgs = {
  /** A list of organization identifiers by which to filter charger and connector statuses. If not provided, statuses for all organizations will be returned. */
  organizationId?: string[]
  /** An identifier of a site to filter charger and connector statuses by, use this to filter down to particular sites belonging to particular organizations. */
  siteIds?: string[]
  /** The amount of levels of child organizations to search and include when searching for latest charger and connector status statuses. */
  orgDepth?: number
  /** Whether to include charger-level breakdowns of connector statuses in the response (in the `connectorsByCharger` field). */
  includeConnectorsByCharger?: boolean
  /** Whether to include site-level breakdowns of connector statuses in the response (in the `connectorsBySite` field). */
  includeConnectorsBySite?: boolean
  /** Whether to include site-level breakdowns of charger statuses in the response (in the `chargersBySite` field). */
  includeChargersBySite?: boolean
}
export type CmsChargerFaultsGetFaultsResponse = /** status 200 Success */ PagedResponseV0ConnectorFaultModel
export type CmsChargerFaultsGetFaultsArgs = {
  /** The identifiers of a set of chargers by which to filter charger faults.
    
    NOTE: If any charger IDs are passed, the siteIds and organizationId params will be ignored. */
  chargerIds?: string[]
  /** A list of site identifiers by which to filter charger faults. */
  siteIds?: string[]
  /** A list of organization identifiers by which to filter charger faults. */
  organizationId?: string[]
  /** An optional lower time-bound to filter on the fault's timestamp. */
  occurredAfter?: string
  /** An optional upper time-bound to filter on the fault's timestamp. */
  occurredBefore?: string
  /** An optional string to filter by fuzzy-matching on charger names. */
  searchString?: string
  /** An index used to retrieve the next page of charger faults. */
  page: number
  /** The number of charger faults to be returned in a page. */
  count: number
  /** A value to sort charger faults by. */
  sortBy?: 'chargerName' | 'currentStatus' | 'errorCode' | 'siteName' | 'timestamp' | 'vendor'
  /** A sort direction for the results. */
  order?: 'ASC' | 'DESC'
}
export type GetManufacturersResponse = /** status 200 OK */ PagedResponseV0ChargerManufacturerModel
export type GetManufacturersArgs = {
  page?: number
  count?: number
}
export type GetManufacturerMakeModelsResponse = /** status 200 OK */ PagedResponseV0ChargerMakeModelResponseModel
export type GetManufacturerMakeModelsArgs = {
  /** The identifier for a charger make model, generated by Synop. */
  manufacturerId: string
  page?: number
  count?: number
}
export type GetSiteGridProfilesResponse = /** status 200 OK */ SiteGridProfileModel
export type GetSiteGridProfilesArgs = {
  gridEventIntegrationAccountId?: string
}
export type UpdateSiteGridProfileResponse = /** status 200 Updated */ SiteGridProfileModel
export type UpdateSiteGridProfileArgs = {
  updateSiteGridProfileRequestParams: UpdateSiteGridProfileRequestParams
}
export type CreateSiteGridProfileResponse = /** status 201 Created */ SiteGridProfileModel
export type CreateSiteGridProfileArgs = {
  createSiteGridProfileRequestParams: CreateSiteGridProfileRequestParams
}
export type GetScheduledTripsResponse = /** status 200 Success */ PagedResponseV2ScheduledVehicleTripModel
export type GetScheduledTripsArgs = {
  /** Filter for scheduled vehicle trips that are associated with a given site. */
  siteId?: string
  /** Filter for scheduled vehicle trips that are associated with a given vehicle. */
  vehicleId?: string
  /** Filter for scheduled vehicle trips that start before the given time. */
  startsBefore?: string
  /** Filter for scheduled vehicle trips that start after the given time. */
  startsAfter?: string
  /** Filter for scheduled vehicle trips that end before the given time. */
  endsBefore?: string
  /** Filter for scheduled vehicle trips that end after the given time. */
  endsAfter?: string
  /** The page number of scheduled vehicle trips to be returned. */
  page: number
  /** The number of scheduled vehicle trips to be returned per page. */
  count?: number
  /** A sort direction for the results. */
  order?: 'ASC' | 'DESC'
}
export type UpdateTripResponse = /** status 200 Success */ ScheduledVehicleTripModel[]
export type UpdateTripArgs = {
  body: ScheduledVehicleTripUpdateModel[]
}
export type CreateTripResponse = /** status 200 Success */ ScheduledVehicleTripModel
export type CreateTripArgs = {
  scheduledVehicleTripCreateModel: ScheduledVehicleTripCreateModel
}
export type DeleteTripResponse = /** status 200 Success */ boolean
export type DeleteTripArgs = {
  scheduledVehicleTripDeleteModel: ScheduledVehicleTripDeleteModel
}
export type GetRecurrenceScheduleResponse = /** status 200 Success */ ScheduledTripRecurrenceModel
export type GetRecurrenceScheduleArgs = {
  scheduleId: string
}
export type UpdateRecurrenceScheduleResponse = /** status 200 Success */ ScheduledTripRecurrenceModel
export type UpdateRecurrenceScheduleArgs = {
  scheduleId: string
  /** Set of vehicle IDs to associated with the trip schedule. */
  vehicleIds: string[]
  /** The index of the occurrence to update. */
  occurrenceIndex: number
  scheduledTripRecurrenceUpdateModel: ScheduledTripRecurrenceUpdateModel
}
export type DeleteRecurrenceScheduleResponse = /** status 200 Success */ boolean
export type DeleteRecurrenceScheduleArgs = {
  scheduleId: string
  /** Set of vehicle IDs to associated with the trip schedule. */
  vehicleIds: string[]
  /** The index of the occurrence to update. */
  occurrenceIndex: number
}
export type UpdateTripOccurrencesResponse = /** status 200 Success */ ScheduledVehicleTripModel[]
export type UpdateTripOccurrencesArgs = {
  scheduleId: string
  /** Set of vehicle IDs to associated with the trip schedule. */
  vehicleIds: string[]
  /** The index of the occurrence to update. */
  occurrenceIndex: number
  scheduledTripRecurrenceUpdateOccurrencesModel: ScheduledTripRecurrenceUpdateOccurrencesModel
}
export type GetGridEventIntegrationAccountsResponse = /** status 200 OK */ GridEventIntegrationAccountModel
export type GetGridEventIntegrationAccountsArgs = {
  organizationId?: string
  gridEventIntegrationId?: string
}
export type UpdateGridEventIntegrationAccountResponse = /** status 201 Updated */ GridEventIntegrationAccountModel
export type UpdateGridEventIntegrationAccountArgs = {
  updateGridEventIntegrationsAccountRequestParams: UpdateGridEventIntegrationsAccountRequestParams
}
export type CreateGridEventIntegrationAccountResponse = /** status 201 Created */ GridEventIntegrationAccountModel
export type CreateGridEventIntegrationAccountArgs = {
  createGridEventIntegrationsAccountRequestParams: CreateGridEventIntegrationsAccountRequestParams
}
export type GetDepotEventsByDepotIdResponse = /** status 200 Success */ DepotEventModel[]
export type GetDepotEventsByDepotIdArgs = {
  depotId: string
  from?: string
  to?: string
}
export type UpdateDepotEventResponse = unknown
export type UpdateDepotEventArgs = {
  depotId: string
  isRecurrence?: boolean
  depotEventModel: DepotEventModel
}
export type SaveDepotEventResponse = /** status 200 Success */ DepotEventModel
export type SaveDepotEventArgs = {
  depotId: string
  depotEventModel: DepotEventModel
}
export type GetScheduledChargerSessionResponse = /** status 200 Success */ ChargerEventModel
export type GetScheduledChargerSessionArgs = {
  chargerId: string
  eventId: string
}
export type UpdateScheduledChargerSessionResponse = /** status 200 Success */ ChargerEventModel
export type UpdateScheduledChargerSessionArgs = {
  chargerId: string
  eventId: string
  isRecurrence?: boolean
  chargerEventUpdateModelDeprecated: ChargerEventUpdateModelDeprecated
}
export type CancelScheduledChargerSessionResponse = /** status 200 Success */ ChargerEventModel
export type CancelScheduledChargerSessionArgs = {
  chargerId: string
  eventId: string
  isRecurrence?: boolean
}
export type GetChargerEventByIdResponse = /** status 200 OK */ ChargerEventModel
export type GetChargerEventByIdArgs = {
  eventId: string
}
export type UpdateChargerEventResponse = /** status 200 OK */ ChargerEventModel
export type UpdateChargerEventArgs = {
  eventId: string
  includeRecurring?: boolean
  chargerEventUpdateModel: ChargerEventUpdateModel
}
export type CancelChargerEventResponse = /** status 200 OK */ ChargerEventModel
export type CancelChargerEventArgs = {
  eventId: string
  includeRecurring?: boolean
}
export type MoveChargerEventResponse = /** status 200 OK */ ChargerEventModel[]
export type MoveChargerEventArgs = {
  eventId: string
  reassignEventConnectorModel: ReassignEventConnectorModel
}
export type GetTelemetryExclusionPeriodResponse = /** status 200 OK */ PagedResponseV2GetTelemetryExclusionModel
export type GetTelemetryExclusionPeriodArgs = {
  page: number
  count: number
  sort?: string
  vehicleIds: string[]
  startsAfter?: string
  startsBefore?: string
  endsAfter?: string
  endsBefore?: string
}
export type CreateTelemetryExclusionPeriodResponse = unknown
export type CreateTelemetryExclusionPeriodArgs = {
  createTelemetryExclusionRequestModel: CreateTelemetryExclusionRequestModel
}
export type GetSiteEnergyConfigurationsResponse = /** status 200 OK */ SiteEnergyConfiguration[]
export type GetSiteEnergyConfigurationsArgs = {
  siteId: string
  latest?: boolean
  from?: string
  to?: string
  count?: number
  afterId?: string
}
export type CreateSiteEnergyConfigurationResponse = /** status 201 Created */ SiteEnergyConfiguration
export type CreateSiteEnergyConfigurationArgs = {
  siteId: string
  createSiteEnergyConfigurationParams: CreateSiteEnergyConfigurationParams
}
export type CreateRecurrenceScheduleResponse = /** status 200 Success */ ScheduledTripRecurrenceModel
export type CreateRecurrenceScheduleArgs = {
  scheduledTripRecurrenceCreateModel: ScheduledTripRecurrenceCreateModel
}
export type SetRecurrenceScheduleVehiclesResponse = /** status 200 Success */ ScheduledTripRecurrenceModel
export type SetRecurrenceScheduleVehiclesArgs = {
  scheduleId: string
  scheduledTripRecurrenceSetVehiclesModel: ScheduledTripRecurrenceSetVehiclesModel
}
export type GetOptimizationsResponse = /** status 200 Success */ OptimizationModel[]
export type GetOptimizationsArgs = {
  fleetId: string
  depotId?: string
  from?: string
  to?: string
  feasible?: boolean
  parentOptimizationId?: string
  page?: number
  count?: number
}
export type CreateOptimizationResponse = /** status 200 Success */ OptimizationModel
export type CreateOptimizationArgs = {
  optimizationModel: OptimizationModel
}
export type GetLoadBalancerEventsResponse = /** status 200 Success */ PagedResponseLoadBalancerEventModel
export type GetLoadBalancerEventsArgs = {
  /** Zero-based page index (0..N) */
  page?: number
  /** The size of the page to be returned */
  size?: number
  /** Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported. */
  sort?: string[]
}
export type CreateLoadBalancerEventResponse = /** status 200 Success */ LoadBalancerEventModel
export type CreateLoadBalancerEventArgs = {
  origin?: string
  eventType?:
    | 'DEPOT_EVENT'
    | 'CHARGER_EVENT'
    | 'SCHEDULED_EVENT_START'
    | 'HEARTBEAT'
    | 'AFTER_START'
    | 'AFTER_STOP'
    | 'EMS_ON'
    | 'EMS_OFF'
    | 'SITE_LIMIT'
    | 'FIXED_LOAD_MANAGEMENT_TURNED_OFF'
  chargerId?: string
  fleetId?: string
  depotId?: string
  originTime?: string
  executionTime?: string
}
export type GetEnergyEfficiencyPredictionsResponse = /** status 200 Success */ EnergyEfficiencyPredictionModel[]
export type GetEnergyEfficiencyPredictionsArgs = {
  fleetId: string
  depotId?: string
  vehicleId?: string
  modelName?: string
  validFrom?: string
  validTo?: string
  windowFrom?: string
  windowTo?: string
  /** Get most recent prediction for each charger event. Not compatible with other time filtering parameters. */
  mostRecent?: boolean
  confidenceLower?: number
  confidenceUpper?: number
  page?: number
  count?: number
}
export type CreateEnergyEfficiencyPredictionResponse = /** status 200 Success */ BooleanModel
export type CreateEnergyEfficiencyPredictionArgs = {
  createEnergyEfficiencyPredictionRequestParams: CreateEnergyEfficiencyPredictionRequestParams
}
export type GetTransactionProfileMappings1Response = /** status 200 Success */ TransactionProfileMappingModel[]
export type GetTransactionProfileMappings1Args = {
  transactionId?: string
  chargerId?: string
  from?: string
  to?: string
  /** Get most recent profile mapping generated by the EMS */
  isMostRecent?: boolean
  page?: number
  count?: number
}
export type GetTransactionProfileMappingsResponse = /** status 200 Success */ TransactionProfileMappingModel
export type GetTransactionProfileMappingsArgs = {
  transactionProfileMappingModel: TransactionProfileMappingModel
}
export type GetProfileHistoriesResponse = /** status 200 OK */ ProfileApplicationRecord[]
export type GetProfileHistoriesArgs = {
  chargerId: string
  latest?: boolean
  from?: string
  to?: string
  count?: number
  afterId?: string
}
export type CreateProfileHistoryResponse = /** status 201 Created */ ProfileApplicationRecord
export type CreateProfileHistoryArgs = {
  chargerId: string
  createProfileApplicationRecordParams: CreateProfileApplicationRecordParams
}
export type GetScheduledChargerSessionsResponse = /** status 200 Success */ ChargerEventModel[]
export type GetScheduledChargerSessionsArgs = {
  chargerId: string
  from?: string
  to?: string
  type?: string
  page?: number
  count?: number
}
export type CreateScheduledChargerSessionResponse = /** status 200 Success */ ChargerEventModel
export type CreateScheduledChargerSessionArgs = {
  chargerId: string
  scheduleRequestModel: ScheduleRequestModel
}
export type GetScheduledChargerSessionDetailsResponse = /** status 200 OK */ ChargerEventSessionDetailsModel
export type GetScheduledChargerSessionDetailsArgs = {
  chargerId: string
  eventId: string
}
export type SaveScheduledChargerSessionDetailsResponse = /** status 200 Success */ ChargerEventSessionModel
export type SaveScheduledChargerSessionDetailsArgs = {
  chargerId: string
  eventId: string
  chargerEventSessionModel: ChargerEventSessionModel
}
export type CreateChargerEventPredictionResponse = /** status 200 Success */ BooleanModel
export type CreateChargerEventPredictionArgs = {
  chargerId: string
  chargerEventPredictionRequestModel: ChargerEventPredictionRequestModel
}
export type GetChargerEventsResponse = /** status 200 OK */ PagedResponseChargerEventModel
export type GetChargerEventsArgs = {
  /** Filter for charger events associated with the given site ID. */
  siteId?: string
  /** Filter for charger events associated with the given charger ID. */
  chargerId?: string
  /** Filter for charger events associated with the given connector ID. */
  connectorId?: string
  /** Filter for charger events associated with the given vehicle ID. */
  vehicleId?: string
  /** Filter for charger events based on the event type. Must be one of 'TIMED_CHARGE', 'AD_HOC_CHARGE' or 'PREDICTED'. */
  eventType?: string
  /** Filter based on events with a target state of charge or power level. */
  targetType?: 'VEHICLE' | 'CHARGER'
  /** Filter for charger events that start before the given time. */
  startsBefore?: string
  /** Filter for charger events that start after the given time. */
  startsAfter?: string
  /** Filter for charger events that end before the given time. */
  endsBefore?: string
  /** Filter for charger events that end after the given time. */
  endsAfter?: string
  /** The page number of charger events to be returned. */
  page: number
  /** The number of charger events to be returned in a page. */
  count?: number
  /** A sort direction for the results. */
  order?: 'ASC' | 'DESC'
}
export type CreateChargerEventResponse = /** status 200 OK */ ChargerEventModel
export type CreateChargerEventArgs = {
  chargerEventCreateModel: ChargerEventCreateModel
}
export type GetChargerEventDetailsByIdResponse = /** status 200 OK */ ChargerEventSessionDetailsModel
export type GetChargerEventDetailsByIdArgs = {
  eventId: string
}
export type AddSessionToChargerEventResponse = /** status 200 OK */ BooleanModel
export type AddSessionToChargerEventArgs = {
  eventId: string
  chargerEventNewSessionModel: ChargerEventNewSessionModel
}
export type GetLinkTelemetryResponse = /** status 200 OK */ LinkTelemetryResponse
export type GetLinkTelemetryArgs = {
  siteId: string
  /** Defaults to last 15 min if from are not supplied. */
  from?: string
  /** Defaults to current time if to is not supplied. */
  to?: string
}
export type GetSiteEnergyConfigurationResponse = /** status 200 OK */ SiteEnergyConfiguration
export type GetSiteEnergyConfigurationArgs = {
  siteId: string
  id: string
}
export type GetBulkSiteEnergyConfigurationsResponse = /** status 200 OK */ SiteEnergyConfiguration[]
export type GetBulkSiteEnergyConfigurationsArgs = {
  organizationId: string
}
export type GetSiteGridProfileResponse = /** status 200 OK */ SiteGridProfileModel
export type GetSiteGridProfileArgs = {
  id: string
}
export type GetOptimizationResponse = /** status 200 Success */ OptimizationModel
export type GetOptimizationArgs = {
  id: string
}
export type GetGridEventIntegrationsResponse = /** status 200 OK */ GridEventIntegrationModel[]
export type GetGridEventIntegrationsArgs = void
export type GetGridEventIntegrationResponse = /** status 200 OK */ GridEventIntegrationModel[]
export type GetGridEventIntegrationArgs = {
  gridEventIntegrationId: string
}
export type GetGridEventIntegrationAccountResponse = /** status 200 OK */ GridEventIntegrationAccountModel[]
export type GetGridEventIntegrationAccountArgs = {
  gridEventIntegrationAccountId: string
}
export type GetGridEventIntegrationEventByAccountResponse = /** status 200 OK */ DepotEventModel[]
export type GetGridEventIntegrationEventByAccountArgs = {
  gridEventIntegrationAccountId: string
}
export type GetGridEventIntegrationEventResponse = /** status 200 OK */ DepotEventModel[]
export type GetGridEventIntegrationEventArgs = {
  gridEventIntegrationAccountId: string
  externalId: string
}
export type GetLoadBalancerEventResponse = /** status 200 Success */ LoadBalancerEventModel
export type GetLoadBalancerEventArgs = {
  id: string
}
export type GetEnergyEfficiencyPredictionResponse = /** status 200 Success */ ChargerEventModel
export type GetEnergyEfficiencyPredictionArgs = {
  id: string
}
export type GetSmartPricesResponse = /** status 200 Success */ Price
export type GetSmartPricesArgs = {
  depotId: string
  from?: string
  to?: string
}
export type GetScheduledDepotPowerResponse = /** status 200 Success */ PowerTimeSeriesModel
export type GetScheduledDepotPowerArgs = {
  depotId: string
  from: string
  to: string
}
export type GetDepotEventByIdResponse = /** status 200 Success */ DepotEventModel
export type GetDepotEventByIdArgs = {
  depotId: string
  eventId: string
}
export type CancelDepotEventResponse = unknown
export type CancelDepotEventArgs = {
  depotId: string
  eventId: string
  isRecurrence?: boolean
}
export type GetDepotEventDetailsResponse = /** status 200 Success */ DepotEventDetailsModel
export type GetDepotEventDetailsArgs = {
  depotId: string
  eventId: string
}
export type GetScheduledChargerSessionsByDepotResponse = /** status 200 Success */ ChargerEventModel[]
export type GetScheduledChargerSessionsByDepotArgs = {
  depotId: string
  assetId?: string
  from?: string
  to?: string
  page?: number
  count?: number
}
export type GetPredictedChargerEventsResponse = /** status 200 Success */ ChargerEventPredictionModel[]
export type GetPredictedChargerEventsArgs = {
  depotId: string
  chargerId?: string
  dispenserId?: string
  assetId?: string
  from?: string
  to?: string
  validFrom?: string
  validTo?: string
  /** Return only most recent predictions. Not compatible with other time filtering parameters. */
  mostRecent: boolean
  modelName: string
  chargerEventId: string
  confidenceLower?: number
  confidenceUpper?: number
  page?: number
  count?: number
}
export type GetDepotEventsResponse = /** status 200 Success */ DepotEventModel[]
export type GetDepotEventsArgs = {
  from?: string
  to?: string
}
export type GetScheduledChargerSessionTelemetryResponse = /** status 200 Success */ ChargerEventSessionTelemetryModel
export type GetScheduledChargerSessionTelemetryArgs = {
  chargerId: string
  eventId: string
}
export type GetProfileHistoryResponse = /** status 200 OK */ ProfileApplicationRecord
export type GetProfileHistoryArgs = {
  id: string
}
export type DeleteTelemetryExclusionPeriodResponse = unknown
export type DeleteTelemetryExclusionPeriodArgs = {
  id: string
}
export type GetApiVehiclesByIdTimeToSocResponse = /** status 200 Success */ TimeSourceAttributeValue
export type GetApiVehiclesByIdTimeToSocArgs = {
  /** vehicle id */
  id: string
  /** target soc in percentage */
  targetSoc: number
  /** power offered from charger in Watts */
  powerOffered: number
}
export type GetApiTransactionsTimeToSocResponse = /** status 200 Success */ TimeSourceAttributeValue
export type GetApiTransactionsTimeToSocArgs = {
  /** ocpp tag */
  ocppTag: string
  /** target soc in percentage */
  targetSoc: number
  /** power offered from charger in Watts */
  powerOffered: number
}
export type AcknowledgeSentNotificationResponse = unknown
export type AcknowledgeSentNotificationArgs = {
  acknowledgeNotificationsModel: AcknowledgeNotificationsModel
}
export type GetAlertsByOrganizationIdResponse = /** status 200 OK */ AlertConfigurationModel[]
export type GetAlertsByOrganizationIdArgs = {
  organizationId: string
}
export type UpdateAlertByOrganizationsResponse = unknown
export type UpdateAlertByOrganizationsArgs = {
  alertConfigurationModel: AlertConfigurationModel
}
export type CreateAlertByOrganizationsResponse = unknown
export type CreateAlertByOrganizationsArgs = {
  createAlertConfigurationModel: CreateAlertConfigurationModel
}
export type CreateAlertNotificationResponse = /** status 200 OK */ NotificationModelV1
export type CreateAlertNotificationArgs = {
  createAlertNotificationModel: CreateAlertNotificationModel
}
export type DeactivateNotificationsResponse = unknown
export type DeactivateNotificationsArgs = {
  deactivateAlertNotificationsModel: DeactivateAlertNotificationsModel
}
export type GetNotificationsResponse = /** status 200 OK */ PagedResponseV1NotificationModelV1
export type GetNotificationsArgs = {
  page: number
  count: number
  sort?: string
  isActive: boolean
  isResolved: boolean
  isAcknowledged?: boolean
  priority?: 'Low' | 'Medium' | 'High' | 'Critical'
  searchString?: string
}
export type GetNotificationByIdResponse = /** status 200 OK */ NotificationModel
export type GetNotificationByIdArgs = {
  id: string
}
export type GetAlertByAlertIdResponse = /** status 200 OK */ AlertConfigurationModel
export type GetAlertByAlertIdArgs = {
  id: string
}
export type GetPropertiesResponse = /** status 200 OK */ PropertyModel[]
export type GetPropertiesArgs = void
export type GetWorkplaceUserResponse = /** status 200 Workplace user retrieved. */ WorkplaceChargingUserModel
export type GetWorkplaceUserArgs = {
  workplaceUserId: string
}
export type UpdateWorkplaceUserResponse = /** status 200 Workplace Users updated. */ WorkplaceChargingUserModel
export type UpdateWorkplaceUserArgs = {
  workplaceUserId: string
  workplaceChargingUserModel: WorkplaceChargingUserModel
}
export type DeleteWorkplaceUserResponse = /** status 200 Workplace user deleted. */ boolean
export type DeleteWorkplaceUserArgs = {
  workplaceUserId: string
}
export type GetWorkplaceChargingRateByIdResponse =
  /** status 200 Workplace charging rates retrieved. */ WorkplaceChargingRateModel
export type GetWorkplaceChargingRateByIdArgs = {
  organizationId?: string
  rateMappingId: string
}
export type UpdateWorkplaceChargingRatesResponse =
  /** status 200 Workplace charging rates updated. */ WorkplaceChargingRateModel
export type UpdateWorkplaceChargingRatesArgs = {
  rateMappingId: string
  updateWorkplaceChargingRate: UpdateWorkplaceChargingRate
}
export type UpdatePushNotificationResponse = /** status 200 OK */ UserPushNotificationMap
export type UpdatePushNotificationArgs = {
  id: string
  createUserPushParams: CreateUserPushParams
}
export type UpdateUserTagsResponse = /** status 200 OK */ FleetManagementUserTagModel[]
export type UpdateUserTagsArgs = {
  id: string
  body: FleetManagementUserTagModel[]
}
export type GetSubscriptionByIdResponse = /** status 200 OK */ SubscriptionModel
export type GetSubscriptionByIdArgs = {
  subscriptionId: string
}
export type UpdateSubscriptionResponse = /** status 200 OK */ UpdateSubscriptionModel
export type UpdateSubscriptionArgs = {
  subscriptionId: string
  updateSubscriptionModel: UpdateSubscriptionModel
}
export type GetFleetBillingProductByIdResponse = /** status 200 OK */ ProductModel
export type GetFleetBillingProductByIdArgs = {
  productId: string
}
export type UpdateFleetBillingProductResponse = /** status 200 OK */ UpdateProductModel
export type UpdateFleetBillingProductArgs = {
  productId: string
  updateProductModel: UpdateProductModel
}
export type GetSessionsResponse = /** status 200 OK */ ResponseFormatListSessionDto
export type GetSessionsArgs = {
  dateFrom: string
  dateTo?: string
  offset?: number
  limit?: number
}
export type PutChargingPreferencesResponse = /** status 200 OK */ ResponseFormatChargingPreferences
export type PutChargingPreferencesArgs = {
  sessionId: string
  chargingPreferences: ChargingPreferences
}
export type GetSessionResponse = /** status 200 OK */ BillableTransactionEntity
export type GetSessionArgs = {
  sessionId: string
}
export type UpdateSessionResponse = /** status 200 OK */ BillableTransactionEntity
export type UpdateSessionArgs = {
  sessionId: string
  updateSessionRequest: UpdateSessionRequest
}
export type GetLocationResponse = /** status 200 OK */ ResponseFormatLocation
export type GetLocationArgs = {
  locationId: string
}
export type UpdateLocationResponse = /** status 200 OK */ ResponseFormatLocationData
export type UpdateLocationArgs = {
  locationId: string
  location: Location
}
export type GetInvoiceByIdResponse = /** status 200 OK */ InvoiceModel
export type GetInvoiceByIdArgs = {
  invoiceId: string
}
export type UpdateInvoiceResponse = /** status 200 OK */ UpdateInvoiceModel
export type UpdateInvoiceArgs = {
  invoiceId: string
  updateInvoiceModel: UpdateInvoiceModel
}
export type DeleteInvoiceResponse = /** status 200 OK */ boolean
export type DeleteInvoiceArgs = {
  invoiceId: string
}
export type GetFleetBillingInvoiceByIdResponse = /** status 200 OK */ InvoiceModel
export type GetFleetBillingInvoiceByIdArgs = {
  invoiceId: string
}
export type UpdateFleetBillingInvoicesResponse = /** status 200 OK */ UpdateInvoiceModel
export type UpdateFleetBillingInvoicesArgs = {
  invoiceId: string
  updateInvoiceModel: UpdateInvoiceModel
}
export type GetChargingRateByIdResponse = /** status 200 Charging rates retrieved. */ ChargingRateModel
export type GetChargingRateByIdArgs = {
  organizationId?: string
  rateMappingId: string
}
export type UpdateChargingRatesResponse = /** status 200 Charging rates updated. */ ChargingRateModel
export type UpdateChargingRatesArgs = {
  rateMappingId: string
  updateChargingRate: UpdateChargingRate
}
export type GetWorkplaceUsersResponse = /** status 200 Workplace users retrieved. */ WorkplaceChargingUserModel[]
export type GetWorkplaceUsersArgs = {
  organizationId?: string
}
export type SaveWorkplaceUserResponse = /** status 200 Workplace Users configured. */ WorkplaceChargingUserModel
export type SaveWorkplaceUserArgs = {
  createWorkplaceChargingUser: CreateWorkplaceChargingUser
}
export type GetWorkplaceChargingRatesResponse =
  /** status 200 Workplace charging rates retrieved. */ WorkplaceChargingRateModelRateStructureModelListRatePeriod[]
export type GetWorkplaceChargingRatesArgs = {
  organizationId?: string
}
export type CreateWorkplaceChargingRatesResponse =
  /** status 200 Workplace charging rates created. */ WorkplaceChargingRateModel
export type CreateWorkplaceChargingRatesArgs = {
  createWorkplaceChargingRate: CreateWorkplaceChargingRate
}
export type OverrideWorkplaceChargingRateResponse =
  /** status 200 Workplace charging rates retrieved. */ OverrideRateStructureModel
export type OverrideWorkplaceChargingRateArgs = {
  rateMappingId: string
  overrideRateStructureModel: OverrideRateStructureModel
}
export type GetWorkplaceChargingConfigurationResponse =
  /** status 200 Workplace Charging retrieved. */ WorkplaceConfiguration
export type GetWorkplaceChargingConfigurationArgs = {
  organizationId?: string
}
export type SaveWorkplaceChargingConfigurationResponse =
  /** status 200 Workplace Charging configured. */ WorkplaceConfiguration
export type SaveWorkplaceChargingConfigurationArgs = {
  workplaceConfiguration: WorkplaceConfiguration
}
export type UpdateWorkplaceChargingRecordStatusesResponse = /** status 200 OK */ UpdateWorkplaceChargingRecordStatus
export type UpdateWorkplaceChargingRecordStatusesArgs = {
  organizationId?: string
  updateWorkplaceChargingRecordStatus: UpdateWorkplaceChargingRecordStatus
}
export type GetSubscriptionsResponse = /** status 200 OK */ PagedResponseSubscriptionModel
export type GetSubscriptionsArgs = {
  page?: number
  count?: number
  status?: 'INCOMPLETE' | 'INCOMPLETE_EXPIRED' | 'TRIALING' | 'ACTIVE' | 'PAST_DUE' | 'CANCELLED' | 'UNPAID' | 'PAUSED'
  customerOrganizationId?: string
  organizationId?: string
}
export type CreateSubscriptionResponse = /** status 200 OK */ CreateSubscriptionModel
export type CreateSubscriptionArgs = {
  createSubscriptionModel: CreateSubscriptionModel
}
export type ResumeSubscriptionResponse = /** status 200 OK */ boolean
export type ResumeSubscriptionArgs = {
  subscriptionId: string
  subscriptionOperationRequest: SubscriptionOperationRequest
}
export type PauseSubscriptionResponse = /** status 200 OK */ boolean
export type PauseSubscriptionArgs = {
  subscriptionId: string
  subscriptionOperationRequest: SubscriptionOperationRequest
}
export type CancelSubscriptionResponse = /** status 200 OK */ boolean
export type CancelSubscriptionArgs = {
  subscriptionId: string
  subscriptionOperationRequest: SubscriptionOperationRequest
}
export type ProcessStripeEventResponse = unknown
export type ProcessStripeEventArgs = void
export type GetReimbursementUsersResponse = /** status 200 Reimbursement users retrieved. */ ReimbursementUserModel[]
export type GetReimbursementUsersArgs = {
  organizationId?: string
}
export type SaveReimbursementUserResponse = /** status 200 Reimbursement configured. */ ReimbursementUser
export type SaveReimbursementUserArgs = {
  reimbursementUserModel: ReimbursementUserModel
}
export type GetReimbursementConfigurationResponse =
  /** status 200 Reimbursement configured. */ ReimbursementConfiguration
export type GetReimbursementConfigurationArgs = {
  organizationId?: string
}
export type SaveReimbursementConfigurationResponse =
  /** status 200 Reimbursement configured. */ ReimbursementConfiguration
export type SaveReimbursementConfigurationArgs = {
  reimbursementConfiguration: ReimbursementConfiguration
}
export type GetFleetBillingProductsResponse = /** status 200 OK */ PagedResponseProductModel
export type GetFleetBillingProductsArgs = {
  organizationId?: string
}
export type CreateFleetBillingProductResponse = /** status 200 OK */ CreateProductModel
export type CreateFleetBillingProductArgs = {
  createProductModel: CreateProductModel
}
export type OnboardOrganizationToStripe1Response = /** status 200 OK */ StripeRedirectModel
export type OnboardOrganizationToStripe1Args = {
  id: string
  urlRedirectModel: UrlRedirectModel
}
export type StopSessionResponse = /** status 200 OK */ StopTransactionResponse
export type StopSessionArgs = {
  stopTransactionRequest: StopTransactionRequest
}
export type StartSessionResponse = /** status 200 OK */ StartTransactionResponse
export type StartSessionArgs = {
  startSessionRequest: StartSessionRequest
}
export type GetInvoicesResponse = /** status 200 OK */ PagedResponseInvoiceModel
export type GetInvoicesArgs = {
  page?: number
  count?: number
  status?:
    | 'DRAFT'
    | 'OPEN'
    | 'UNCOLLECTIBLE'
    | 'VOID'
    | 'READY_FOR_REVIEW'
    | 'APPROVED'
    | 'PAID'
    | 'PAST_DUE'
    | 'ESTIMATE'
  customerOrganizationId?: string
  organizationId?: string
}
export type CreateInvoiceResponse = /** status 200 OK */ CreateInvoiceModel
export type CreateInvoiceArgs = {
  createInvoiceModel: CreateInvoiceModel
}
export type VoidInvoiceResponse = /** status 200 OK */ boolean
export type VoidInvoiceArgs = {
  invoiceId: string
  invoiceOperationRequest: InvoiceOperationRequest
}
export type SendInvoiceResponse = /** status 200 OK */ boolean
export type SendInvoiceArgs = {
  invoiceId: string
  invoiceOperationRequest: InvoiceOperationRequest
}
export type FinalizeInvoiceResponse = /** status 200 OK */ boolean
export type FinalizeInvoiceArgs = {
  invoiceId: string
  invoiceOperationRequest: InvoiceOperationRequest
}
export type GetFleetChargingConfigurationsResponse =
  /** status 200 Fleet charging configurations retrieved. */ FleetChargingConfiguration[]
export type GetFleetChargingConfigurationsArgs = {
  organizationId?: string
}
export type SaveFleetChargingConfigurationResponse =
  /** status 200 Fleet charging configurations saved. */ FleetChargingConfiguration
export type SaveFleetChargingConfigurationArgs = {
  fleetChargingConfiguration: FleetChargingConfiguration
}
export type GetFleetBillingInvoicesResponse = /** status 200 OK */ PagedResponseInvoiceModel
export type GetFleetBillingInvoicesArgs = {
  page?: number
  count?: number
  status?:
    | 'DRAFT'
    | 'OPEN'
    | 'UNCOLLECTIBLE'
    | 'VOID'
    | 'READY_FOR_REVIEW'
    | 'APPROVED'
    | 'PAID'
    | 'PAST_DUE'
    | 'ESTIMATE'
  customerOrganizationId?: string
  organizationId?: string
}
export type CreateFleetBillingInvoiceResponse = /** status 200 OK */ CreateInvoiceModel
export type CreateFleetBillingInvoiceArgs = {
  createInvoiceModel: CreateInvoiceModel
}
export type VoidFleetBillingInvoiceResponse = /** status 200 OK */ boolean
export type VoidFleetBillingInvoiceArgs = {
  invoiceId: string
  invoiceOperationRequest: InvoiceOperationRequest
}
export type SendFleetBillingInvoiceResponse = /** status 200 OK */ boolean
export type SendFleetBillingInvoiceArgs = {
  invoiceId: string
  invoiceOperationRequest: InvoiceOperationRequest
}
export type FinalizeFleetBillingInvoiceResponse = /** status 200 OK */ boolean
export type FinalizeFleetBillingInvoiceArgs = {
  invoiceId: string
  invoiceOperationRequest: InvoiceOperationRequest
}
export type GetChargingRatesResponse =
  /** status 200 Charging rates retrieved. */ ChargingRateModelRateStructureModelListRatePeriod[]
export type GetChargingRatesArgs = {
  organizationId?: string
  productType?:
    | 'WORKPLACE_CHARGING'
    | 'FLEET_CHARGING'
    | 'PUBLIC_CHARGING'
    | 'HOME_CHARGING'
    | 'VEHICLE_SUBSCRIPTION'
    | 'CHARGER_SUBSCRIPTION'
    | 'OTHER'
}
export type CreateChargingRatesResponse = /** status 200 Charging rates created. */ ChargingRateModel
export type CreateChargingRatesArgs = {
  createChargingRate: CreateChargingRate
}
export type OverrideChargingRateResponse = /** status 200 Charging rates retrieved. */ OverrideRateStructureModel
export type OverrideChargingRateArgs = {
  rateMappingId: string
  overrideRateStructureModel: OverrideRateStructureModel
}
export type BulkUpdateReimbursementUsersResponse =
  /** status 200 User rate configurations updated. */ BulkReimbursementConfiguration
export type BulkUpdateReimbursementUsersArgs = {
  bulkReimbursementConfiguration: BulkReimbursementConfiguration
}
export type AuthorizePublicSessionResponse = /** status 200 OK */ StripeIntentModel1
export type AuthorizePublicSessionArgs = {
  authorizePublicSessionRequest: AuthorizePublicSessionRequest
}
export type AuditBillingResponse =
  /** status 200 Audit for requested period and product completed. */ AuditResponseModel
export type AuditBillingArgs = {
  auditRequestModel: AuditRequestModel
}
export type AskQuestionResponse = /** status 200 OK */ ChatCompletionResponse
export type AskQuestionArgs = {
  question: string
  chatCompletionRequest: ChatCompletionRequest
}
export type GetWorkplaceChargingUsageResponse =
  /** status 200 Workplace charging usage retrieved. */ ChargeRecordModelWorkplaceChargingUserOrganization[]
export type GetWorkplaceChargingUsageArgs = {
  billingMonth?: string
  organizationId?: string
}
export type GetWorkplaceSummaryResponse =
  /** status 200 Workplace Charging summary retrieved. */ WorkplaceChargingSummaryModel
export type GetWorkplaceSummaryArgs = {
  billingMonth?: string
  organizationId?: string
}
export type GetWorkplaceChargingRecordsResponse = /** status 200 OK */ WorkplaceChargingModel[]
export type GetWorkplaceChargingRecordsArgs = {
  from: string
  to: string
  organizationId?: string
}
export type GetStripeIntentDetail1Response = /** status 200 OK */ StripeIntentModel1
export type GetStripeIntentDetail1Args = {
  id: string
}
export type GetPaymentMethodResponse = /** status 200 OK */ BillingDetailsModel
export type GetPaymentMethodArgs = {
  id: string
}
export type GetPortalLoginResponse = /** status 200 OK */ StripeRedirectModel
export type GetPortalLoginArgs = {
  id: string
}
export type GetUserInvoicesResponse = /** status 200 OK */ StripeRedirectModel
export type GetUserInvoicesArgs = {
  id: string
}
export type GetCheckoutSessionResponse = /** status 200 OK */ StripeRedirectModel
export type GetCheckoutSessionArgs = {
  id: string
}
export type GetReimbursementsResponse = /** status 200 OK */ ReimbursementsModelObject[]
export type GetReimbursementsArgs = {
  from: string
  to: string
  organizationId?: string
}
export type GetReimbursementUserResponse = /** status 200 Reimbursement configured. */ ReimbursementUserModel
export type GetReimbursementUserArgs = {
  reimbursementUserId: string
}
export type DeleteReimbursementUserResponse = /** status 200 Reimbursement user deleted. */ boolean
export type DeleteReimbursementUserArgs = {
  reimbursementUserId: string
}
export type GetReimbursementUsageSummaryResponse =
  /** status 200 Reimbursement usage retrieved. */ ChargeRecordModelOrganizationReimbursementUser[]
export type GetReimbursementUsageSummaryArgs = {
  billingMonth?: string
  organizationId?: string
}
export type GetReimbursementSummaryResponse =
  /** status 200 Reimbursement summary retrieved. */ ReimbursementSummaryModel
export type GetReimbursementSummaryArgs = {
  billingMonth?: string
  organizationId?: string
}
export type GetQuotasResponse = /** status 200 OK */ ApiQuota[]
export type GetQuotasArgs = {
  organizationId?: string
  month?: string
}
export type GetReimbursements1Response = /** status 200 OK */ ReimbursementsModelObject[]
export type GetReimbursements1Args = {
  id: string
  from: string
  to: string
}
export type GetPortalResponse = /** status 200 OK */ StripeRedirectModel
export type GetPortalArgs = {
  id: string
}
export type GetLoginSessionResponse = /** status 200 OK */ LoginLink
export type GetLoginSessionArgs = {
  id: string
}
export type GetOrganizationBalanceResponse = /** status 200 OK */ AccountBalance
export type GetOrganizationBalanceArgs = {
  id: string
}
export type GetMeterValuesResponse = /** status 200 OK */ MeterValueModel[]
export type GetMeterValuesArgs = {
  sessionId: string
}
export type GetLocationsResponse = /** status 200 OK */ ResponseFormatListLocation
export type GetLocationsArgs = {
  dateFrom?: string
  dateTo?: string
  offset?: number
  limit?: number
}
export type GetEvseResponse = /** status 200 OK */ ResponseFormatLocationData
export type GetEvseArgs = {
  locationId: string
  evseUid: string
}
export type GetConnectorResponse = /** status 200 OK */ ResponseFormatLocationData
export type GetConnectorArgs = {
  locationId: string
  evseUid: string
  connectorId: string
}
export type GetCdrResponse = /** status 200 OK */ ResponseFormatListCdrDto
export type GetCdrArgs = {
  authTag: string
  dateFrom?: string
  dateTo?: string
  offset: number
  limit: number
}
export type GetCdrByIdResponse = /** status 200 OK */ ResponseFormatCdrDto
export type GetCdrByIdArgs = {
  sessionId: string
}
export type GetLineItemsResponse = /** status 200 Successfully retrieved line items */ PagedResponseSynopLineItem
export type GetLineItemsArgs = {
  /** Type of the product */
  productType?: string
  /** ID of the charger */
  chargerId?: string
  /** ID of the vehicle */
  vehicleId?: string
  /** Authorization tag */
  authTag?: string
  /** ID of the fleet */
  fleetId?: string
  /** ID of the site */
  siteId?: string
  /** ID of the payee */
  payeeId?: string
  /** ID of the payor */
  payorId?: string
  /** ID of the org the payor belongs to */
  payorOrgId?: string
  /** Start date and time for filtering (ISO 8601 format) */
  from?: string
  /** End date and time for filtering (ISO 8601 format) */
  to?: string
  /** Page number for pagination (default is 0) */
  page?: number
  /** Page size for pagination (default is 10) */
  size?: number
}
export type GetFleetBillingSummaryResponse = /** status 200 OK */ FleetBillingSummaryModel
export type GetFleetBillingSummaryArgs = {
  organizationId?: string
}
export type GetFleetBillingPaymentsResponse = /** status 200 OK */ PagedResponsePaymentModel
export type GetFleetBillingPaymentsArgs = {
  page?: number
  count?: number
  organizationId?: string
}
export type GetCostExplorerResponse = /** status 200 OK */ CostExplorerResponse
export type GetCostExplorerArgs = {
  groupBy?: string
  productType?:
    | 'WORKPLACE_CHARGING'
    | 'FLEET_CHARGING'
    | 'PUBLIC_CHARGING'
    | 'HOME_CHARGING'
    | 'VEHICLE_SUBSCRIPTION'
    | 'CHARGER_SUBSCRIPTION'
    | 'OTHER'
  granularity?: 'DAY' | 'MONTH'
  from: string
  to: string
  organizationId?: string
}
export type GetCostDashboardSummaryResponse = /** status 200 OK */ CostDashboardSummary
export type GetCostDashboardSummaryArgs = {
  from: string
  to: string
  organizationId?: string
}
export type GetCostDashboardGroupedResponse = /** status 200 OK */ CostDashboardGroupResponse
export type GetCostDashboardGroupedArgs = {
  groupBy?: 'SITE' | 'CHARGER' | 'CONNECTOR' | 'VEHICLE' | 'AUTH_TAG'
  from: string
  to: string
  organizationId?: string
}
export type ListSimulatorsResponse = /** status 200 OK */ SimulatorDetailModel[]
export type ListSimulatorsArgs = void
export type CreateSimulatorResponse = /** status 200 OK */ SimulatorDetailModel
export type CreateSimulatorArgs = {
  simulatorCreationModel: SimulatorCreationModel
}
export type GetContainerResponse = /** status 200 OK */ SimulatorDetailModel
export type GetContainerArgs = {
  id: string
}
export type DeleteContainerResponse = unknown
export type DeleteContainerArgs = {
  id: string
}
export type PingResponse = /** status 200 OK */ PingPong
export type PingArgs = void
export type SimulatorProxyRequestResponse = /** status 200 OK */ string
export type SimulatorProxyRequestArgs = {
  body: string
  path: string
  id: string
}
export type SimulatorProxyRequest3Response = /** status 200 OK */ string
export type SimulatorProxyRequest3Args = {
  path: string
  id: string
  body: string
}
export type SimulatorProxyRequest2Response = /** status 200 OK */ string
export type SimulatorProxyRequest2Args = {
  path: string
  id: string
  body: string
}
export type SimulatorProxyRequest5Response = /** status 200 OK */ string
export type SimulatorProxyRequest5Args = {
  path: string
  id: string
  body: string
}
export type SimulatorProxyRequest6Response = /** status 200 OK */ string
export type SimulatorProxyRequest6Args = {
  path: string
  id: string
  body: string
}
export type SimulatorProxyRequest1Response = /** status 200 OK */ string
export type SimulatorProxyRequest1Args = {
  path: string
  id: string
  body: string
}
export type SimulatorProxyRequest4Response = /** status 200 OK */ string
export type SimulatorProxyRequest4Args = {
  path: string
  id: string
  body: string
}
export type SourceAttributeValueBigDecimal = {
  units?: string
  lastUpdated?: number
  source?: string
  value?: number
}
export type LocationOfInterestModel = {
  id?: string
  type?: string
}
export type SourceAttributeValueString = {
  units?: string
  lastUpdated?: number
  source?: string
  value?: string
}
export type CurrentLocation = {
  locationOfInterestModels?: LocationOfInterestModel[]
  bearing?: SourceAttributeValueBigDecimal
  cardinalDirection?: SourceAttributeValueString
  street?: SourceAttributeValueString
}
export type Subscription = {
  isSubscriptionActive?: boolean
  subscriptionType?: string
  subscriptionTerm?: string
  startDate?: string
  endDate?: string
  autoRenewal?: boolean
}
export type Fault = {
  code?: string
  name?: string
  description?: string
  latitude?: number
  longitude?: number
  sourceAddress?: string
  parameterIdentifier?: string
  failureModeIndicator?: string
  occurrenceCount?: number
  lastUpdated?: number
  provider?: string
  faultType?: number
  mil?: string[]
}
export type VehicleModel = {
  vin: string
  vehicleNm: string
  organizationId: string
  make: string
  model: string
  modelYear: number
  macAddress?: string
  homeSiteId?: string
  batterySize: number
  efficiency?: number
  estimatedRange: number
  weight: SourceAttributeValueBigDecimal
  externalId?: string
  id: string
  vehicleType?: string
  vehicleClass?: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8
  active?: boolean
  logicalDeleteIn?: boolean
  providerId?: string
  vehicleToGridCapable?: boolean
  lifetimeEfficiency?: number
  fleetId?: string
  fleetNm?: string
  organizationNm?: string
  billingOrganizationId?: string
  currentLocation?: CurrentLocation
  vehicleStatus?: SourceAttributeValueString
  latitude?: SourceAttributeValueBigDecimal
  longitude?: SourceAttributeValueBigDecimal
  remainingChargeTime?: SourceAttributeValueBigDecimal
  remainingRange?: SourceAttributeValueBigDecimal
  stateOfCharge?: SourceAttributeValueBigDecimal
  odometer?: SourceAttributeValueBigDecimal
  speed?: SourceAttributeValueBigDecimal
  batteryPotential?: SourceAttributeValueBigDecimal
  distanceUntilHome?: SourceAttributeValueBigDecimal
  timeUntilHome?: SourceAttributeValueBigDecimal
  engineOnLifetime?: SourceAttributeValueBigDecimal
  drivingTimeLifetime?: SourceAttributeValueBigDecimal
  eptoUseLifetime?: SourceAttributeValueBigDecimal
  batteryDischargeLifetime?: SourceAttributeValueBigDecimal
  batteryRegenLifetime?: SourceAttributeValueBigDecimal
  tracInverterMotorLifetime?: SourceAttributeValueBigDecimal
  tracInverterRegenLifetime?: SourceAttributeValueBigDecimal
  batteryManagementLifetime?: SourceAttributeValueBigDecimal
  dcdcLifetime?: SourceAttributeValueBigDecimal
  hvacLifetime?: SourceAttributeValueBigDecimal
  chargerPowerSupplied?: SourceAttributeValueBigDecimal
  subscriptions?: Subscription[]
  faults?: Fault[]
  telematicsTimestamp?: string
  telematicsTimestampEpochMillisecond?: number
  ambientAirTemperature?: SourceAttributeValueBigDecimal
  inDepot?: boolean
}
export type EditVehicleModel = {
  vin: string
  vehicleNm: string
  organizationId: string
  make: string
  model: string
  modelYear: number
  macAddress?: string
  homeSiteId?: string
  batterySize: number
  efficiency?: number
  estimatedRange: number
  weight: SourceAttributeValueBigDecimal
  externalId?: string
  id: string
}
export type CreateVehicleModel = {
  vin: string
  vehicleNm: string
  organizationId: string
  make: string
  model: string
  modelYear: number
  macAddress?: string
  homeSiteId?: string
  batterySize: number
  efficiency?: number
  estimatedRange: number
  weight: SourceAttributeValueBigDecimal
  externalId?: string
}
export type MfaOptionType = object
export type UserTag = {
  created?: string
  updated?: string
  userId?: string
  ocppTagValue?: string
  ocppTagUseCase: 'OPERATIONS' | 'WORKPLACE_CHARGING' | 'FLEET_CHARGING' | 'REIMBURSEMENTS' | 'OTHER'
  ocppTagType: 'MOBILE' | 'VID' | 'RFID' | 'QR_CODE' | 'NFC' | 'OTHER' | 'OPERATIONS'
}
export type UserModel = {
  id?: string
  name?: string
  email?: string
  phoneNumber?: string
  organizationId?: string
  status?: string
  mfaEnabled?: boolean
  isActive?: boolean
  preferredDistanceUnits?: string
  preferredTemperatureUnits?: string
  preferredEfficiencyUnits?: string
  preferredTimeFormat?: string
  preferredDateFormat?: string
  preferredTimeZone?: string
  receiveEmailNotifications?: boolean
  receiveSMSNotifications?: boolean
  roles?: string[]
  sites?: string[]
  mfaOptionTypes?: MfaOptionType[]
  termsAccepted?: boolean
  termsAcceptedDt?: string
  rfidTags?: UserTag[]
  mfaSetupStatus?: 'COMPLETED' | 'INCOMPLETE' | 'NOT_APPLICABLE'
  customerId?: string
  setupIntentId?: string
  externalId?: string
}
export type CreateUserTagModel = {
  ocppTagValue?: string
  ocppTagUseCase?: 'OPERATIONS' | 'WORKPLACE_CHARGING' | 'FLEET_CHARGING' | 'REIMBURSEMENTS' | 'OTHER'
  ocppTagType?: 'MOBILE' | 'VID' | 'RFID' | 'QR_CODE' | 'NFC' | 'OTHER' | 'OPERATIONS'
}
export type CreateUserModel = {
  name?: string
  email?: string
  phoneNumber?: string
  organizationId?: string
  status?: string
  mfaEnabled?: boolean
  isActive?: boolean
  preferredDistanceUnits?: string
  preferredTemperatureUnits?: string
  preferredEfficiencyUnits?: string
  preferredTimeFormat?: string
  preferredDateFormat?: string
  preferredTimeZone?: string
  receiveEmailNotifications?: boolean
  receiveSMSNotifications?: boolean
  roles?: string[]
  sites?: string[]
  mfaOptionTypes?: MfaOptionType[]
  termsAccepted?: boolean
  termsAcceptedDt?: string
  rfidTags?: CreateUserTagModel[]
  mfaSetupStatus?: 'COMPLETED' | 'INCOMPLETE' | 'NOT_APPLICABLE'
  externalId?: string
}
export type EventRecurrence = {
  created?: string
  updated?: string
  eventRecurrenceId?: string
  startDate?: string
  recurrenceRule?: string
  validUntilDate?: string
  recurrenceName?: string
  recurrenceType?: 'DAILY' | 'WEEKLY' | 'WEEKDAYS' | 'CUSTOM'
}
export type RouteComponent = {
  created?: string
  updated?: string
  id?: string
  routeId?: string
  routeComponentNm?: string
  routeComponentType?: 'ORIGIN' | 'POINT' | 'DESTINATION'
  locationType?: 'LOCATION_OF_INTEREST' | 'SITE' | 'POINT' | 'MAINTENANCE' | 'OTHER'
  locationId?: string
  precedence?: number
  latitude?: number
  longitude?: number
  address?: string
  componentDesc?: string
  distance?: number
  distanceUnits?: string
  duration?: number
  durationUnits?: string
  bearingBefore?: number
  bearingAfter?: number
}
export type Route = {
  id?: string
  routeNm?: string
  routeType?: 'OPEN' | 'POINT_TO_POINT' | 'FROM_TELEMETRY'
  distance?: number
  distanceUnits?: string
  duration?: number
  durationUnits?: string
  weight?: number
  weightUnits?: string
  organizationId?: string
  fleetId?: string
  components?: RouteComponent[]
}
export type ScheduledRoute = {
  created?: string
  updated?: string
  scheduledRouteId?: string
  vehicleId?: string
  scheduledStart?: string
  actualStart?: string
  scheduledEnd?: string
  actualEnd?: string
  status?: 'SCHEDULED' | 'IN_PROGRESS' | 'COMPLETED' | 'CANCELLED'
  type?: 'USER_CREATED' | 'PREDICTED' | 'GEOTAB' | 'ZONAR' | 'SAMSARA' | 'OTHER'
  routeId?: string
  driverId?: string
  weight?: number
  weightUnits?: string
  eventRecurrence?: EventRecurrence
  route?: Route
}
export type ScheduledRouteUpdateModel = {
  vehicleId: string
  scheduledRouteId: string
  status?: 'SCHEDULED' | 'IN_PROGRESS' | 'COMPLETED' | 'CANCELLED'
  scheduledRouteType?: 'USER_CREATED' | 'PREDICTED' | 'GEOTAB' | 'ZONAR' | 'SAMSARA' | 'OTHER'
  actualStart?: string
  actualEnd?: string
  scheduledStart?: string
  scheduledEnd?: string
  updateRecurrence?: boolean
  driverId?: string
  weight?: number
  weightUnits?: string
}
export type CreateRouteRequest = {
  routeNm: string
  routeType?: 'OPEN' | 'POINT_TO_POINT' | 'FROM_TELEMETRY'
  distance?: number
  distanceUnits?: string
  duration?: number
  durationUnits?: string
  weight?: number
  weightUnits?: string
  organizationId: string
  fromTelemetryTripId?: string
  components?: RouteComponent[]
}
export type OrganizationModel = {
  id: string
  organizationNm?: string
  organizationType?: string
  logoUrl?: string
  iconUrl?: string
  styleOverrides?: string
  hasOpsCenter?: boolean
  hasReporting?: boolean
  hasBilling?: boolean
  hasAlarms?: boolean
  connectedAccountId?: string
  connectedBillingEnabled?: boolean
  customerId?: string
  setupIntentId?: string
  hasCompletedOnboarding?: boolean
  hasMarketplace?: boolean
  hasEnergyManagement?: boolean
  hasChargeManagement?: boolean
  hasFleetManagement?: boolean
  hasReimbursements?: boolean
  hasCostExplorer?: boolean
  hasFleetBilling?: boolean
  hasPublicCharging?: boolean
  hasV2G?: boolean
  hasSynopLink?: boolean
  hasSynopAutopilot?: boolean
  fleetBillingStatus?: 'NEVER_ENABLED' | 'ENABLED' | 'DISABLED'
  publicChargingStatus?: 'NEVER_ENABLED' | 'ENABLED' | 'DISABLED'
  reimbursementsStatus?: 'NEVER_ENABLED' | 'ENABLED' | 'DISABLED'
  hasWorkplaceCharging?: boolean
  workplaceChargingStatus?: 'NEVER_ENABLED' | 'ENABLED' | 'DISABLED'
  connectedAccountStatus?:
    | 'RESTRICTED'
    | 'RESTRICTED_SOON'
    | 'PENDING'
    | 'ENABLED'
    | 'COMPLETE'
    | 'REJECTED'
    | 'UNKNOWN'
  loginUrl?: string
  active?: boolean
  fleetsCount?: number
  chargersCount?: number
  vehiclesCount?: number
  usersCount?: number
  appClientId?: string
  hasSSO?: boolean
  hasCustomLogo: boolean
  hasCustomIcon: boolean
  mfaStatus?: 'OFF' | 'ON_REQUIRED' | 'ON_NOT_REQUIRED'
  iosAppStoreUrl?: string
  androidPlayStoreUrl?: string
  hasInsights?: boolean
}
export type OrganizationTerms = {
  created?: string
  updated?: string
  id?: string
  organizationId?: string
  documentTitle?: string
  effectiveDt?: string
  terms?: string
}
export type LocationOfInterestResponseModel = {
  loiNm: string
  loiType: 'Maintenance' | 'Port' | 'Residence' | 'Other'
  address: string
  latitude: number
  longitude: number
  organizationId: string
  geofence: string
  id: string
}
export type LocationOfInterestUpdateModel = {
  id: string
  loiNm: string
  loiType: 'Maintenance' | 'Port' | 'Residence' | 'Other'
  address: string
  latitude: number
  longitude: number
  organizationId: string
  geofence: string
}
export type LocationOfInterestCreateModel = {
  loiNm: string
  loiType: 'Maintenance' | 'Port' | 'Residence' | 'Other'
  address: string
  latitude: number
  longitude: number
  organizationId: string
  geofence: string
}
export type FleetModel = {
  fleetNm?: string
  fleetType?: 'DEFAULT' | 'CUSTOM'
  organizationId: string
  active?: boolean
  id: string
}
export type FleetCreateModel = {
  fleetNm?: string
  fleetType?: 'DEFAULT' | 'CUSTOM'
  organizationId: string
  active?: boolean
}
export type Recipient = {
  deliveryType?: string
  deliveryValue?: string
  userId?: string
  alarmId?: string
}
export type Alarm = {
  created?: string
  updated?: string
  id?: string
  defaultAlarmId?: string
  alarmDesc?: string
  alarmMessage?: string
  alarmNm?: string
  cron?: string
  definition?: string
  entityType?: string
  entityValue?: string
  eventBridgeArn?: string
  organizationId?: string
  nextAlarmDelaySeconds?: number
  nextAlarmId?: string
  active?: boolean
}
export type AlarmModel = {
  id: string
  alarmNm?: string
  alarmDesc?: string
  notificationText?: string
  isEnabled?: boolean
  recipients?: Recipient[]
  alarms?: Alarm[]
}
export type VehicleEvent = {
  created?: string
  updated?: string
  id?: string
  vehicleId?: string
  eventType?: string
  scheduledStart?: string
  scheduledEnd?: string
  status?: string
  eventTargetNm?: string
  routeId?: string
  actorId?: string
}
export type MfaModel = {
  secretCode: string
}
export type TripNote = {
  created?: string
  updated?: string
  id?: string
  tripId?: string
  fromUserId?: string
  content?: string
  senderName?: string
}
export type PaginationMetadata = {
  first: boolean
  last: boolean
  pageSize: number
  page: number
  totalPages: number
  totalItems: number
}
export type Meta = {
  pagination: PaginationMetadata
}
export type ScheduledRouteModel = {
  vehicleId?: string
  scheduledRouteId?: string
  routeId?: string
  status?: 'SCHEDULED' | 'IN_PROGRESS' | 'COMPLETED' | 'CANCELLED'
  scheduledStart?: string
  scheduledEnd?: string
  actualStart?: string
  actualEnd?: string
  distance?: number
  distanceUnit?: string
  duration?: number
  durationUnit?: string
  weight?: number
  weightUnit?: string
  driverId?: string
  vehicle?: VehicleModel
  route?: Route
}
export type PagedResponseV1ScheduledRouteModel = {
  meta: Meta
  payload: ScheduledRouteModel[]
}
export type ScheduleRouteResponse = {
  succeeded?: string[]
  failed?: string[]
}
export type EventRecurrenceModel = {
  eventRecurrenceId?: string
  recurrenceRule?: string
  startDate?: string
  validUntilDate?: string
  recurrenceName?: string
  recurrenceType?: 'DAILY' | 'WEEKLY' | 'WEEKDAYS' | 'CUSTOM'
}
export type ScheduleRouteRequest = {
  routeId: string
  scheduledRouteType?: 'USER_CREATED' | 'PREDICTED' | 'GEOTAB' | 'ZONAR' | 'SAMSARA' | 'OTHER'
  scheduledStart: string
  scheduledEnd?: string
  eventRecurrence?: EventRecurrenceModel
  vehicleIds?: string[]
  siteId?: string
  fleetId?: string
  organizationId?: string
  weight?: number
  weightUnits?: number
}
export type StringResponseModel = {
  value?: string
}
export type UrlRedirectModel = {
  baseUrl?: string
  path?: string
  parameters?: string
}
export type Organization = {
  created?: string
  updated?: string
  id?: string
  organizationNm: string
  organizationType?: string
  logoUrl?: string
  styleOverrides?: string
  hasOpsCenter?: boolean
  hasReporting?: boolean
  hasBilling?: boolean
  hasAlarms?: boolean
  mfaStatus?: string
  customerId?: string
  setupIntentId?: string
  hasCompeletedOnboarding?: boolean
  hasMarketplace?: boolean
  hasEnergyManagement?: boolean
  hasChargeManagement?: boolean
  hasFleetManagement?: boolean
  hasReimbursements?: boolean
  reimbursementsStatus?: 'NEVER_ENABLED' | 'ENABLED' | 'DISABLED'
  hasWorkplaceCharging?: boolean
  workplaceChargingStatus?: 'NEVER_ENABLED' | 'ENABLED' | 'DISABLED'
  connectedAccountStatus?:
    | 'RESTRICTED'
    | 'RESTRICTED_SOON'
    | 'PENDING'
    | 'ENABLED'
    | 'COMPLETE'
    | 'REJECTED'
    | 'UNKNOWN'
  loginUrl?: string
  active?: boolean
  iconUrl?: string
  hasCostExplorer?: boolean
  hasFleetBilling?: boolean
  hasPublicCharging?: boolean
  fleetBillingStatus?: 'NEVER_ENABLED' | 'ENABLED' | 'DISABLED'
  publicChargingStatus?: 'NEVER_ENABLED' | 'ENABLED' | 'DISABLED'
  hasV2G?: boolean
  hasSynopLink?: boolean
  hasSynopAutopilot?: boolean
  androidPlayStoreUrl?: string
  hasSSO?: boolean
  appClientId?: string
  hasInsights?: boolean
  iosappStoreUrl?: string
}
export type OrganizationApiKeyModel = {
  organizationId: string
  apiKey: string
  awsKeyId: string
  active?: boolean
  usagePlanId?: string
  created?: string
  updated?: string
}
export type OrganizationIntegration = {
  created?: string
  updated?: string
  id?: string
  organizationId?: string
  integrationId?: string
  externalId?: string
  autoOnboard?: boolean
  ingestVehicles?: boolean
  ingestTelemetry?: boolean
  active?: boolean
  metadata?: string
}
export type AlarmNotificationMapping = {
  created?: string
  updated?: string
  id?: string
  alarmId?: string
  notificationType?: string
  notificationValue?: string
  userId?: string
  active?: boolean
}
export type WeatherForecast = {
  ambientAirTemperature?: SourceAttributeValueBigDecimal
  humidity?: SourceAttributeValueBigDecimal
  pressure?: SourceAttributeValueBigDecimal
  windSpeed?: SourceAttributeValueBigDecimal
  windDirectionDegrees?: SourceAttributeValueBigDecimal
  windGust?: SourceAttributeValueBigDecimal
  firstWeatherConditionId?: SourceAttributeValueBigDecimal
  firstWeatherParameter?: SourceAttributeValueString
  secondWeatherConditionId?: SourceAttributeValueBigDecimal
  secondWeatherParameter?: SourceAttributeValueString
  thirdWeatherConditionId?: SourceAttributeValueBigDecimal
  thirdWeatherParameter?: SourceAttributeValueString
  cloudiness?: SourceAttributeValueBigDecimal
  visibility?: SourceAttributeValueBigDecimal
  partOfDay?: SourceAttributeValueString
  precipitationProbability?: SourceAttributeValueBigDecimal
}
export type VehicleWeatherForecast = {
  current?: WeatherForecast
  oneHourLead?: WeatherForecast
  twoHourLead?: WeatherForecast
  threeHourLead?: WeatherForecast
  fourHourLead?: WeatherForecast
  fiveHourLead?: WeatherForecast
}
export type VehicleFault = {
  created?: string
  updated?: string
  id?: string
  vehicleId?: string
  timestamp?: string
  sourceAddress?: number
  sourceName?: string
  sourceLabel?: string
  spn?: number
  spnName?: string
  spnDescription?: string
  fmi?: number
  fmiText?: string
  occurrenceCount?: number
  milLampOn?: boolean
  amberLampOn?: boolean
  redLampOn?: boolean
  protectLampOn?: boolean
  dtcId?: string
  dtcShortCode?: string
  dtcDescription?: string
  latitude?: number
  longitude?: number
  provider?: string
}
export type Trip = {
  created?: string
  updated?: string
  id?: string
  tripStatus?: string
  vehicleId?: string
  fleetId?: string
  averageSpeed?: number
  speedUnit?: string
  distance?: number
  distanceUnit?: string
  emissionsSave?: number
  emissionsUnit?: string
  routeId?: string
  routeStart?: string
  routeEnd?: string
  efficiency?: number
  efficiencyUnit?: string
  startSoc?: number
  endSoc?: number
  startLatitude?: number
  endLatitude?: number
  startLongitude?: number
  endLongitude?: number
  driverId?: string
  originNm?: string
  destinationNm?: string
  source?: string
  energyUsed?: number
  faults?: VehicleFault[]
}
export type AssetTelematics = {
  assetId?: string
  stateOfCharge?: number
  telematicsTimestamp?: string
  latitude?: number
  longitude?: number
  speed?: number
  speedUnit?: string
  odometer?: number
  odometerUnit?: string
  ambientAirTemperatureDegc?: number
  hvacLifetimeWh?: number
  batteryRegenLifetimeWh?: number
  batteryDischargeLifetimeWh?: number
  tracInverterRegenLifetimeWh?: number
  tracInverterMotorLifetimeWh?: number
  batteryManagementLifetimeWh?: number
  dcdcLifetimeWh?: number
  batteryPotential?: number
  keySwitchState?: string
  gearSelect?: string
  evseState?: string
  evsePowerKw?: number
  chargeTimeEstimateMin?: number
  gpsHdop?: number
  rangeRemainingEstimateKm?: number
  keyOnTimeHr?: number
  drivingTimeHr?: number
  vehicleStatus?: 'In Motion' | 'Off' | 'Charging' | 'Plugged In' | 'Idle' | 'Unknown'
  date?: string
  milesDriven?: number
  totalEnergyConsumedKwh?: number
  hvacEnergyKwh?: number
  motorEnergyConsumedKwh?: number
  regenEnergyKwh?: number
  dcdcEnergyKwh?: number
  batteryManagementEnergyKwh?: number
  airAmbientAvgTempC?: number
  chargeEnergyKwh?: number
}
export type DailySummaryMetrics = {
  created?: string
  updated?: string
  vehicleId?: string
  date?: string
  distanceDriven?: number
  totalEnergyConsumed?: number
  hvacEnergy?: number
  motorEnergyConsumed?: number
  regenEnergy?: number
  dcdcEnergy?: number
  batteryManagementEnergy?: number
  airAmbientAverageTemperature?: number
  chargeEnergy?: number
  engineOnTime?: number
  drivingTime?: number
  elevationGain?: number
}
export type LatLngPair = {
  latitude?: number
  longitude?: number
}
export type Boundary = {
  latitude?: number
  longitude?: number
}
export type ReachableRange = {
  center?: LatLngPair
  boundary?: Boundary[]
}
export type AzureServiceableRangeResponse = {
  formatVersion?: string
  reachableRange?: ReachableRange
}
export type TimeUtilization = {
  totalDrivingTime?: SourceAttributeValueBigDecimal
  totalIdleTime?: SourceAttributeValueBigDecimal
  totalChargingTime?: SourceAttributeValueBigDecimal
  totalPluggedInTime?: SourceAttributeValueBigDecimal
}
export type EnergyUtilization = {
  totalMotorEnergyConsumed?: SourceAttributeValueBigDecimal
  totalRegenEnergyConsumed?: SourceAttributeValueBigDecimal
  totalDcdcEnergyConsumed?: SourceAttributeValueBigDecimal
  totalHvacEnergyConsumed?: SourceAttributeValueBigDecimal
  totalBatteryManagementEnergyConsumed?: SourceAttributeValueBigDecimal
}
export type VehicleMetricsModel = {
  vehicleId?: string
  distanceDriven?: SourceAttributeValueBigDecimal
  energyUsed?: SourceAttributeValueBigDecimal
  efficiency?: SourceAttributeValueBigDecimal
  carbonEmissionsSaved?: SourceAttributeValueBigDecimal
  averageTemperature?: SourceAttributeValueBigDecimal
  timeUtilization?: TimeUtilization
  energyUtilization?: EnergyUtilization
  fuelSaved?: SourceAttributeValueBigDecimal
  numVehicles?: number
  numDrivingDays?: number
  numTrips?: number
}
export type VehicleFaultModel = {
  id: string
  timestamp: string
  sourceAddress?: number
  sourceName?: string
  sourceLabel?: string
  spn?: number
  spnName?: string
  spnDescription?: string
  fmi?: number
  fmiText?: string
  occurrenceCount?: number
  milLampOn?: boolean
  amberLampOn?: boolean
  redLampOn?: boolean
  protectLampOn?: boolean
  dtcId?: string
  dtcShortCode?: string
  dtcDescription?: string
  latitude?: number
  longitude?: number
  provider: string
}
export type PagedResponseV1VehicleFaultModel = {
  meta: Meta
  payload: VehicleFaultModel[]
}
export type VehicleMetrics = {
  id?: string
  name?: string
  totalTrips?: number
  numDrivingDays?: number
  fuelSaved?: SourceAttributeValueBigDecimal
  energyUsed?: SourceAttributeValueBigDecimal
  efficiency?: SourceAttributeValueBigDecimal
  distanceDriven?: SourceAttributeValueBigDecimal
  carbonEmissionsSaved?: SourceAttributeValueBigDecimal
}
export type ReportGenerationStatus = {
  created?: string
  updated?: string
  id?: string
  status?: string
  reportUrl?: string
  requestedBy?: string
}
export type LatestVehicleStatusSummaryModel = {
  total: number
  siteId?: string
  latestVehicleStatuses: {
    [key: string]: number
  }
}
export type EnergyUseBreakdown = {
  vehicleIds?: string[]
  telematicsProvider?: string
  energyUseBreakdowns?: {
    [key: string]: SourceAttributeValueBigDecimal
  }
}
export type PagedResponseV2VehicleFaultModel = {
  meta: Meta
  data: VehicleFaultModel[]
}
export type OptionValue = {
  created?: string
  updated?: string
  optionType?: string
  value?: string
}
export type VehicleSummaryModel = {
  vehicleId?: string
  vehicleNm?: string
  year?: number
  make?: string
  model?: string
  vin?: string
  batterySize?: number
  distanceDriven?: number
  distanceUnit?: string
  kwhUsed?: number
  efficiency?: number
  efficiencyUnit?: string
  aggregateCarbonSave?: number
  numTrips?: number
  mpge?: number
  gallons?: number
  chargeTimeHrs?: number
  idleTimeHrs?: number
  driveTimeHrs?: number
}
export type FiveNumberSummaryModel = {
  upperQuartile?: number
  lowerQuartile?: number
  max?: number
  min?: number
  median?: number
  mean?: number
  summaryOf?: string
  unit?: string
}
export type OrganizationSummaryModel = {
  from?: string
  to?: string
  vehicleSummaries?: VehicleSummaryModel[]
  distanceDrivenSummary?: FiveNumberSummaryModel
  energyConsumedSummary?: FiveNumberSummaryModel
  efficiencySummary?: FiveNumberSummaryModel
}
export type VehicleScheduleModel = {
  depotId?: string
  assetNm?: string
  model?: string
  eventId?: string
  vehicleId?: string
  chargerId?: string
  dispenserId?: string
  status?: string
  scheduledStart?: string
  scheduledEnd?: string
  actualStart?: string
  actualEnd?: string
  eventType?: string
  isBlocking?: boolean
  powerOffered?: number
  targetSoc?: number
  eventTargetNm?: string
  distance?: number
  distanceUnit?: string
  driverId?: string
  driverNm?: string
}
export type StripeIntentModel = {
  organizationId?: string
  organizationNm?: string
  customerId?: string
  intentId?: string
  clientSecret?: string
}
export type OcppTag = {
  entityId?: string
  entityName?: string
  entityType?: 'VEHICLE' | 'USER'
  externalId?: string
  tagId?: string
  ocppTagType?: 'MOBILE' | 'VID' | 'RFID' | 'QR_CODE' | 'NFC' | 'OTHER' | 'OPERATIONS'
}
export type Fleet = {
  created?: string
  updated?: string
  id?: string
  fleetNm?: string
  fleetType?: string
  active?: boolean
  organizationId?: string
}
export type OrganizationConfig = {
  created?: string
  updated?: string
  organizationId?: string
  configKey?: 'FROM_EMAIL' | 'SNS_PLATFORM_ARN'
  configValue?: string
}
export type ExternalOrganizationModel = {
  id?: string
  organizationNm?: string
}
export type Integration = {
  created?: string
  updated?: string
  id?: string
  integrationNm?: string
  integrationOrganizationId?: string
  description?: string
  requiredFields?: string
  defaultValues?: string
  logoUrl?: string
}
export type Customer = {
  id?: string
  organizationNm?: string
  parentOrganizationId?: string
}
export type PagedResponseV2Customer = {
  meta: Meta
  data: Customer[]
}
export type ChartDataResponseModel = {
  units?: object[]
  data?: object[][]
}
export type TriggeredAlarm = {
  created?: string
  updated?: string
  alarmId?: string
  triggeredDateTime?: string
  alarmNm?: string
  entityType?: string
  entityValue?: string
  entityName?: string
  currentEntityAttributes?: string
}
export type ServiceEventModel = {
  eventId: string
  chargerId: string
  effectiveAsOf: string
  eventType: 'COMMISSIONING' | 'OUTAGE'
  commissioningState?: 'PRECOMMISSIONED' | 'COMMISSIONED' | 'DECOMMISSIONED'
  note?: string
  updatedDate?: string
  createdDate: string
  userId?: string
  organizationId?: string
}
export type ExternalServiceErrorMetaAttribute = {
  serviceCode?: 'FLEET_MANAGEMENT'
  path?: string
  statusCode?: number
  body?: string
}
export type JsonApiErrorObjectExternalServiceErrorMetaAttribute = {
  id: string
  title: string
  status: '200' | '400' | '401' | '500'
  source?: string
  detail: string
  code?: string
  meta?: ExternalServiceErrorMetaAttribute
}
export type JsonApiErrorWrapperExternalServiceErrorMetaAttribute = {
  errors?: JsonApiErrorObjectExternalServiceErrorMetaAttribute[]
}
export type UpdateServiceEventModel = {
  effectiveAsOf: string
  note?: string
}
export type ChargeSchedulePeriodModel = {
  startPeriodInSeconds: number
  powerLimit: number
  numberPhases: number
  limitDischarge?: number
  desiredMode?: 'discharging' | 'charging'
}
export type ChargeProfileModel = {
  chargerId?: string
  connectorId?: number
  profileId?: string
  stackLevel?: number
  chargeProfilePurpose?: 'ChargePointMaxProfile' | 'TxDefaultProfile' | 'TxProfile'
  chargeProfileKind?: 'Absolute' | 'Recurring' | 'Relative'
  recurrencyKind?: 'Daily' | 'Weekly'
  validFrom?: string
  validTo?: string
  durationInSeconds?: number
  effectiveFrom?: string
  chargingRateUnit?: 'W' | 'A'
  minChargingRate?: number
  description?: string
  depotId?: string
  fleetId?: string
  currentlyActive?: boolean
  schedulePeriods?: ChargeSchedulePeriodModel[]
}
export type PagedResponseV0ChargeProfileModel = {
  first?: boolean
  last?: boolean
  pageSize?: number
  page?: number
  totalPages?: number
  totalItems?: number
  items?: ChargeProfileModel[]
}
export type V2GCompatibleSchedulePeriod = {
  startPeriodInSeconds: number
  limitDischarge?: number
  desiredMode?: 'discharging' | 'charging'
  powerLimit?: number
  numberPhases?: number
}
export type ChargeProfileUpdateModel = {
  depotId?: string
  description?: string
  stackLevel?: number
  chargingProfilePurpose:
    | 'ChargePointMaxProfile'
    | 'TxDefaultProfile'
    | 'TxProfile'
    | 'CHARGE_POINT_MAX_PROFILE'
    | 'TX_DEFAULT_PROFILE'
    | 'TX_PROFILE'
  chargingProfileKind: 'Absolute' | 'Recurring' | 'Relative'
  recurrencyKind?: 'Daily' | 'Weekly'
  validFrom?: string
  validTo?: string
  durationInSeconds: number
  startSchedule: string
  chargingRateUnit: 'W' | 'A'
  minChargingRate?: number
  schedulePeriodMap: {
    [key: string]: V2GCompatibleSchedulePeriod
  }
  chargerId?: string
  connectorId?: number
  profileId: string
}
export type ChargeProfileCreateModel = {
  depotId?: string
  description?: string
  stackLevel?: number
  chargingProfilePurpose:
    | 'ChargePointMaxProfile'
    | 'TxDefaultProfile'
    | 'TxProfile'
    | 'CHARGE_POINT_MAX_PROFILE'
    | 'TX_DEFAULT_PROFILE'
    | 'TX_PROFILE'
  chargingProfileKind: 'Absolute' | 'Recurring' | 'Relative'
  recurrencyKind?: 'Daily' | 'Weekly'
  validFrom?: string
  validTo?: string
  durationInSeconds: number
  startSchedule: string
  chargingRateUnit: 'W' | 'A'
  minChargingRate?: number
  schedulePeriodMap: {
    [key: string]: V2GCompatibleSchedulePeriod
  }
}
export type BooleanModel = {
  result?: boolean
}
export type ChargeProfileActionRequest = {
  chargerId: string
  connectorId: number
  profileId: string
}
export type DepotModel = {
  depotId: string
  fleetId?: string
  depotNm?: string
  externalId?: string
  powerCeiling?: number
  powerCeilingUnit?: string
  currentCeiling?: number
  currentCeilingUnit?: string
  voltageCeiling?: number
  voltageCeilingUnits?: string
  latitude?: number
  longitude?: number
  address?: string
  v2gEnrolled?: boolean
  geofence?: string
  numChargers?: number
  numVehicles?: number
  activeChargers?: number
  connectedChargers?: number
  rateCardId?: string
  rateCardTierType?: string
  created?: string
  updated?: string
  isLoadBalanced?: boolean
  chargersPrecommissioned?: number
  chargersCommissioned?: number
  chargersDecommissioned?: number
  chargersOutOfService?: number
  timezone?: string
  importKwhLast24h?: number
}
export type PagedResponseV0DepotModel = {
  first?: boolean
  last?: boolean
  pageSize?: number
  page?: number
  totalPages?: number
  totalItems?: number
  items?: DepotModel[]
}
export type GeometryBigDecimal = {
  type: string
  coordinates: number[]
}
export type DepotMetaDataUpdateModel = {
  depotId: string
  fleetId?: string
  depotNm?: string
  geofence?: string
  location?: GeometryBigDecimal
  address?: string
  isLoadBalanced?: boolean
  siteLimit?: number
  rateCardId?: string
  rateCardTierType?: string
  externalId?: string
}
export type DepotCreationModel = {
  fleetId: string
  depotNm: string
  externalId?: string
  powerCeiling?: number
  currentCeiling?: number
  voltageCeiling?: number
  location?: GeometryBigDecimal
  v2gEnrolled?: boolean
  address?: string
  geofence?: string
  rateCardId?: string
  rateCardTierType?: string
  isLoadBalanced?: boolean
}
export type DepotEnergyLimitsModel = {
  depotId?: string
  fleetId?: string
  externalId?: string
  powerCeiling?: number
  voltageCeiling?: number
  currentCeiling?: number
  isLoadBalanced?: boolean
}
export type DepotEnergyLimitsUpdateModel = {
  powerCeiling?: number
  currentCeiling?: number
  voltageCeiling?: number
  isLoadBalanced?: boolean
}
export type ConnectorStatusModel = {
  chargerId?: string
  chargerNm?: string
  connectorId?: number
  status?:
    | 'Available'
    | 'Preparing'
    | 'Charging'
    | 'Finishing'
    | 'SuspendedEVSE'
    | 'SuspendedEV'
    | 'Reserved'
    | 'Faulted'
    | 'Unavailable'
    | 'Unknown'
  currentCombinedStatus?:
    | 'Available'
    | 'Preparing'
    | 'Charging'
    | 'Finishing'
    | 'SuspendedEVSE'
    | 'SuspendedEV'
    | 'Reserved'
    | 'Faulted'
    | 'Unavailable'
    | 'Unknown'
    | 'Offline'
  legacyStatus?:
    | 'Available'
    | 'Preparing'
    | 'Charging'
    | 'Finishing'
    | 'SuspendedEVSE'
    | 'SuspendedEV'
    | 'Reserved'
    | 'Faulted'
    | 'Unavailable'
  operationalStatus?: 'Operative' | 'Inoperative' | 'Unknown'
  occupiedStatus?: 'Occupied' | 'Unoccupied' | 'Unknown'
  simplifiedStatus?: 'Unoccupied' | 'PreparingOrCharging' | 'FinishedCharging' | 'Faulted' | 'Unavailable' | 'Unknown'
  errorCode?: string
  errorInfo?: string
  vendorId?: string
  vendorErrorCode?: string
  statusTimestamp?: string
}
export type ConnectorUtilizationModel = {
  lastKnownPowerImportKw?: number
  livePowerImportKw?: number
  lastKnownPowerExportKw?: number
  livePowerExportKw?: number
}
export type LastMeterValuesModel = {
  currentImportAmps?: number
  currentImportAmpsTimestamp?: string
  voltage?: number
  voltageTimestamp?: string
  currentOfferedAmps?: number
  currentOfferedAmpsTimestamp?: string
  energyActiveImportRegisterKwh?: number
  energyActiveImportRegisterKwhTimestamp?: string
  powerActiveImportKw?: number
  powerActiveImportKwTimestamp?: string
  powerOfferedKw?: number
  powerOfferedKwTimestamp?: string
  powerActiveExportKw?: number
  powerActiveExportKwTimestamp?: string
  powerFactor?: number
  powerFactorTimestamp?: string
  temperature?: number
  temperatureTimestamp?: string
  soc?: number
  socTimestamp?: string
  currentExportAmps?: number
  currentExportAmpsTimestamp?: string
  energyActiveExportRegisterKwh?: number
  energyActiveExportRegisterKwhTimestamp?: string
}
export type TransactionSummaryModel = {
  id: string
  transactionPk: number
  connectorId: number
  connectorName?: string
  chargerPk: number
  chargerId: string
  chargerName: string
  depotId: string
  organizationId: string
  ocppTag: string
  stopReason?: string
  stopEventActor?: string
  startTimestamp: string
  stopTimestamp?: string
  updatedTimestamp: string
  totalEnergyImported?: number
  totalEnergyExported?: number
  meanPowerImport?: number
  maxPowerImport?: number
  latestPowerImport?: number
  meanPowerExport?: number
  maxPowerExport?: number
  latestPowerExport?: number
  meanPowerOffered?: number
  maxPowerOffered?: number
  latestPowerOffered?: number
  meanAmperageImport?: number
  maxAmperageImport?: number
  latestAmperageImport?: number
  meanAmperageOffered?: number
  maxAmperageOffered?: number
  latestAmperageOffered?: number
  minVoltage?: number
  meanVoltage?: number
  maxVoltage?: number
  latestVoltage?: number
  minFrequency?: number
  meanFrequency?: number
  maxFrequency?: number
  latestFrequency?: number
  startingTemperature?: number
  minTemperature?: number
  meanTemperature?: number
  maxTemperature?: number
  latestTemperature?: number
  startingSoc?: number
  latestSoc?: number
  timezone?: string
  commissioningState?: 'PRECOMMISSIONED' | 'COMMISSIONED' | 'DECOMMISSIONED'
}
export type ChargerStatusModel = {
  status?: 'Operative' | 'Faulted' | 'Unavailable' | 'Unknown' | 'PartiallyFaulted'
  currentCombinedStatus?: 'Operative' | 'Faulted' | 'Unavailable' | 'Unknown' | 'Offline' | 'PartiallyFaulted'
  statusTimestamp?: string
  operationalStatus?: 'Operative' | 'Inoperative' | 'Unknown'
  connectivityStatus?: 'Online' | 'Offline'
}
export type ConnectorSimplifiedStatusCountsModel = {
  numConnectorsFaulted?: number
  numConnectorsUnavailable?: number
  numConnectorsPreparingOrCharging?: number
  numConnectorsFinishedCharging?: number
  numConnectorsUnoccupied?: number
}
export type ChargingSessionTelemetryModel = {
  deprecationMessage?: string
  chargerId?: string
  connectorId?: number
  chargingSessionId?: string
  measuredAt?: string
  currentImport?: number
  currentImportUnit?: string
  currentOffered?: number
  currentOfferedUnit?: string
  energyActiveImportRegister?: number
  energyActiveImportRegisterUnit?: string
  powerActiveImport?: number
  powerActiveImportUnit?: string
  livePowerImportUnit?: string
  powerOffered?: number
  powerOfferedUnit?: string
  voltage?: number
  voltageUnit?: string
  temperature?: number
  temperatureUnit?: string
  soc?: number
  socUnit?: string
  currentExport?: number
  currentExportUnit?: string
  powerActiveExport?: number
  powerActiveExportUnit?: string
  energyActiveExportRegister?: number
  energyActiveExportRegisterUnit?: string
  energyActiveImportInterval?: number
  energyActiveImportIntervalUnit?: string
  powerFactor?: number
  powerFactorUnit?: string
  context?: string
  livePowerImport?: number
  livePowerUnit?: string
}
export type ChargingSessionModel = {
  deprecationNotice?: string
  transactionId?: number
  sessionId?: string
  connectorId?: number
  chargerId?: string
  ocppTag?: string
  vehicleId?: string
  vin?: string
  userId?: string
  startTimestamp?: string
  stopTimestamp?: string
  stopReason?: string
  depotId?: string
  fleetId?: string
  chargerName?: string
  depotName?: string
  kwhConsumed?: number
  kwhConsumedUnit?: string
  powerImported?: number
  powerImportUnit?: string
  powerOffered?: number
  powerOfferedUnit?: string
  startSocValue?: number
  endSocValue?: number
}
export type ConnectorModel = {
  connectorId?: number
  chargerId?: string
  connectorType?: 'J1772' | 'CHAdeMO' | 'CCS1' | 'CCS2' | 'NACS' | 'Other'
  connectorName?: string
  maxPower?: number
  voltage?: number
  currentType?: string
  powerFactor?: number
}
export type ChargerModel = {
  chargerId: string
  chargerName?: string
  endpointAddress?: string
  ocppProtocol?: string
  registrationStatus?: string
  chargePointYear?: number
  chargePointVendor?: string
  chargePointModel?: string
  chargePointSerialNumber?: string
  chargeBoxSerialNumber?: string
  firmwareVersion?: string
  firmwareUpdateStatus?: string
  firmwareUpdateTimestamp?: string
  iccid?: string
  imsi?: string
  location?: GeometryBigDecimal
  meterType?: string
  meterSerialNumber?: string
  diagnosticsStatus?: string
  diagnosticsTimestamp?: string
  lastHeartbeatTimestamp?: string
  lastHeardTimestamp?: string
  description?: string
  note?: string
  autoRegisterTags?: boolean
  groupId?: string
  maxPower: number
  maxCurrent?: number
  voltage?: number
  currentType?: 'AC' | 'DC'
  depotId?: string
  depotName?: string
  fleetId?: string
  externalId?: string
  latitude?: number
  longitude?: number
  created?: string
  updated?: string
  connectorIds: number[]
  activeConnectorIds: number[]
  connectorStatus: {
    [key: string]: ConnectorStatusModel
  }
  connectorUtilization: {
    [key: string]: ConnectorUtilizationModel
  }
  lastMeterValues: {
    [key: string]: LastMeterValuesModel
  }
  lastTransactions: {
    [key: string]: TransactionSummaryModel
  }
  latestChargerStatus: ChargerStatusModel
  connectorSimplifiedStatusCounts: ConnectorSimplifiedStatusCountsModel
  lastTelemetry?: {
    [key: string]: ChargingSessionTelemetryModel
  }
  lastSession?: {
    [key: string]: ChargingSessionModel
  }
  v2gEnabled?: boolean
  connectors: {
    [key: string]: ConnectorModel
  }
  livePowerImport?: number
  livePowerImportKw?: number
  lastKnownPowerImportKw: number
  livePowerImportUnit?: string
  livePowerExport?: number
  livePowerExportKw?: number
  lastKnownPowerExportKw: number
  livePowerExportUnit?: string
  powerUnit?: string
  commissioningDate?: string
  decommissioningDate?: string
  networkConnectionMethod?: 'SIM' | 'WiFi' | 'LAN'
  networkConnectionDetails?: string
  utilityLocationId?: string
  isAdaAccessible?: boolean
  evseInstallationType?: 'Pedestal' | 'WallMounted' | 'Other'
  preferredProfileNumPhases: number
  isSimulator: boolean
  commissioningState?: 'PRECOMMISSIONED' | 'COMMISSIONED' | 'DECOMMISSIONED'
}
export type PagedResponseV0ChargerModel = {
  first?: boolean
  last?: boolean
  pageSize?: number
  page?: number
  totalPages?: number
  totalItems?: number
  items?: ChargerModel[]
}
export type ChargerMakeModelKnownOperatingParameters = {
  knownPowerRatingsKw?: number[]
  knownOperatingCurrentsAmps?: number[]
  knownOperatingVoltages?: number[]
}
export type ChargerMakeModelResponseModel = {
  id?: string
  vendorName?: string
  chargerModel?: string
  modelHelperText?: string
  currentType?: string
  chargerMakeModelKnownOperatingParameters?: ChargerMakeModelKnownOperatingParameters
}
export type ChargerManufacturerModel = {
  id?: string
  name?: string
}
export type ChargerCapabilitiesOcppIdTagTypes = {
  values?: string[]
}
export type ChargerCapabilitySetResponseModel = {
  id?: string
  version?: string
  ocppIdTagTypes?: ChargerCapabilitiesOcppIdTagTypes
  ocppProtocol?: string
  chargingProfilesAnySupport?: boolean
  chargingProfilesSupportsDefaultTxProfile?: boolean
  chargingProfilesSupportsChargepointMax?: boolean
  chargingProfilesSupportsTxProfile?: boolean
  chargingProfilesStackLevelsSupported?: boolean
  chargingProfilesMaxStackLevels?: number
  chargingProfilesMaxSimultaneouslySupported?: number
  chargingProfilesMaxSchedulePeriods?: number
  chargingProfilesZeroPowerSupported?: boolean
  chargingProfilesAcceptsAmps?: boolean
  chargingProfilesAcceptsWatts?: boolean
  chargingProfilesSupportsDischarge?: boolean
  chargingProfilesMaxDischargePower?: number
  knownQuirks?: {
    [key: string]: object
  }
  remoteStartSupport?: boolean
  remoteStopSupport?: boolean
  sendsTransactionStart?: boolean
  sendsTransactionStop?: boolean
  sendsPlugInEvent?: boolean
  sendsPlugOutEvent?: boolean
  connectorStatusNotifSupport?: boolean
  meterValuesAnySupport?: boolean
  meterValuesContextStart?: boolean
  meterValuesContextPeriodic?: boolean
  meterValuesContextStop?: boolean
  meterValuesSupportedMeasurands?: {
    [key: string]: string[]
  }
  hardRebootSupported?: boolean
  softRebootSupported?: boolean
  configKeysGetAllSupported?: boolean
  configKeysGetSpecificSupported?: boolean
  configKeysSetSupported?: boolean
}
export type ChargerInsightsModel = {
  chargerHealth: 'Unhealthy' | 'Marginal' | 'Unknown' | 'Healthy'
}
export type ChargerDetailModel = {
  chargerId: string
  chargerName?: string
  endpointAddress?: string
  ocppProtocol?: string
  registrationStatus?: string
  chargePointYear?: number
  chargePointVendor?: string
  chargePointModel?: string
  chargePointSerialNumber?: string
  chargeBoxSerialNumber?: string
  firmwareVersion?: string
  firmwareUpdateStatus?: string
  firmwareUpdateTimestamp?: string
  iccid?: string
  imsi?: string
  location?: GeometryBigDecimal
  meterType?: string
  meterSerialNumber?: string
  diagnosticsStatus?: string
  diagnosticsTimestamp?: string
  lastHeartbeatTimestamp?: string
  lastHeardTimestamp?: string
  description?: string
  note?: string
  autoRegisterTags?: boolean
  groupId?: string
  maxPower: number
  maxCurrent?: number
  voltage?: number
  currentType?: 'AC' | 'DC'
  depotId?: string
  depotName?: string
  fleetId?: string
  externalId?: string
  latitude?: number
  longitude?: number
  created?: string
  updated?: string
  connectorIds: number[]
  activeConnectorIds: number[]
  connectorStatus: {
    [key: string]: ConnectorStatusModel
  }
  connectorUtilization: {
    [key: string]: ConnectorUtilizationModel
  }
  lastMeterValues: {
    [key: string]: LastMeterValuesModel
  }
  lastTransactions: {
    [key: string]: TransactionSummaryModel
  }
  latestChargerStatus: ChargerStatusModel
  connectorSimplifiedStatusCounts: ConnectorSimplifiedStatusCountsModel
  lastTelemetry?: {
    [key: string]: ChargingSessionTelemetryModel
  }
  lastSession?: {
    [key: string]: ChargingSessionModel
  }
  v2gEnabled?: boolean
  connectors: {
    [key: string]: ConnectorModel
  }
  livePowerImport?: number
  livePowerImportKw?: number
  lastKnownPowerImportKw: number
  livePowerImportUnit?: string
  livePowerExport?: number
  livePowerExportKw?: number
  lastKnownPowerExportKw: number
  livePowerExportUnit?: string
  powerUnit?: string
  commissioningDate?: string
  decommissioningDate?: string
  networkConnectionMethod?: 'SIM' | 'WiFi' | 'LAN'
  networkConnectionDetails?: string
  utilityLocationId?: string
  isAdaAccessible?: boolean
  evseInstallationType?: 'Pedestal' | 'WallMounted' | 'Other'
  preferredProfileNumPhases: number
  isSimulator: boolean
  commissioningState?: 'PRECOMMISSIONED' | 'COMMISSIONED' | 'DECOMMISSIONED'
  chargerMakeModel?: ChargerMakeModelResponseModel
  chargerManufacturerModel?: ChargerManufacturerModel
  chargerCapabilitySet?: ChargerCapabilitySetResponseModel
  insights: ChargerInsightsModel
  importKwhLast24h: number
}
export type ConnectorUpdateModel = {
  connectorType?: 'J1772' | 'CHAdeMO' | 'CCS1' | 'CCS2' | 'NACS' | 'Other'
  connectorName?: string
}
export type ChargerUpdateModel = {
  chargerId: string
  depotId?: string
  externalId?: string
  chargerName?: string
  autoRegisterTags?: boolean
  description?: string
  latitude?: number
  longitude?: number
  maxPower?: number
  maxCurrent?: number
  voltage?: number
  currentType?: string
  year?: number
  v2gEnabled?: boolean
  makeModelId?: string
  commissioningDate?: string
  networkConnectionDetails?: string
  utilityLocationId?: string
  isAdaAccessible?: 'Yes' | 'No' | 'unset'
  evseInstallationType?: 'Pedestal' | 'WallMounted' | 'Other' | 'unset'
  networkConnectionMethod?: 'SIM' | 'WiFi' | 'LAN' | 'unset'
  connectors?: {
    [key: string]: ConnectorUpdateModel
  }
  preferredProfileNumPhases?: number
}
export type ChargerCreationModel = {
  chargerId: string
  depotId: string
  externalId?: string
  chargerName?: string
  autoRegisterTags?: boolean
  description?: string
  latitude: number
  longitude: number
  maxPower?: number
  maxCurrent?: number
  voltage?: number
  currentType: string
  year?: number
  v2gEnabled?: boolean
  makeModelId?: string
  commissioningDate?: string
  networkConnectionMethod?: 'SIM' | 'WiFi' | 'LAN'
  networkConnectionDetails?: string
  utilityLocationId?: string
  isAdaAccessible?: boolean
  evseInstallationType?: 'Pedestal' | 'WallMounted' | 'Other'
  preferredProfileNumPhases?: number
  isSimulator?: boolean
}
export type PagedResponseV0ServiceEventModel = {
  first?: boolean
  last?: boolean
  pageSize?: number
  page?: number
  totalPages?: number
  totalItems?: number
  items?: ServiceEventModel[]
}
export type CreateServiceEventModel = {
  chargerId: string
  effectiveAsOf: string
  eventType: 'COMMISSIONING' | 'OUTAGE'
  commissioningState?: 'COMMISSIONED' | 'DECOMMISSIONED'
  note?: string
}
export type BulkCommissionActionResponseModel = {
  succeeded: string[]
  failed: string[]
  commissioningState: 'COMMISSIONED' | 'DECOMMISSIONED'
}
export type BulkCommissionActionRequestModel = {
  chargerIds: string[]
  effectiveAsOf: string
  note?: string
  commissioningState: 'COMMISSIONED' | 'DECOMMISSIONED'
}
export type TagResponseModel = {
  idTag: string
  organizationId: string
}
export type PagedResponseV0TagResponseModel = {
  first?: boolean
  last?: boolean
  pageSize?: number
  page?: number
  totalPages?: number
  totalItems?: number
  items?: TagResponseModel[]
}
export type AddTagsInputModel = {
  idTags: TagResponseModel[]
}
export type RefreshConfigurationStatusModel = {
  id: string
  chargerId: string
  status: 'PROCESSING' | 'COMPLETED' | 'ERROR'
  errorReason?: string
  requestedAt: string
}
export type ChangeConfigurationApiResponseModel = {
  key?: string
  value: string
  chargerResponseStatus:
    | 'Accepted'
    | 'Rejected'
    | 'NotSupported'
    | 'RebootRequired'
    | 'Unknown'
    | 'UnknownMessageId'
    | 'UnknownVendorId'
    | 'Idle'
    | 'Uploaded'
    | 'UploadFailed'
    | 'Uploading'
    | 'Downloaded'
    | 'DownloadFailed'
    | 'Downloading'
    | 'InstallationFailed'
    | 'Installing'
    | 'Installed'
    | 'Pending'
    | 'Occupied'
    | 'Faulted'
    | 'Unavailable'
    | 'NotImplemented'
    | 'Expired'
    | 'Invalid'
    | 'ConcurrentTx'
    | 'Blocked'
    | 'Scheduled'
    | 'Unlocked'
    | 'UnlockFailed'
    | 'NoResponse'
    | 'NotConnected'
    | 'OK'
  responseMessage: string
  refreshConfigurationStatus?: RefreshConfigurationStatusModel
}
export type ChangeConfigurationKeyRequestModel = {
  key: string
  value: string
}
export type RefreshChargerConfigurationParams = {
  chargerId: string
}
export type ChargingGroupResponseModel = {
  id: string
  name?: string
  siteId: string
  destinationId: string
  isDefaultGroup?: boolean
}
export type PagedResponseV0ChargingGroupResponseModel = {
  first?: boolean
  last?: boolean
  pageSize?: number
  page?: number
  totalPages?: number
  totalItems?: number
  items?: ChargingGroupResponseModel[]
}
export type ChargingGroupCreateModel = {
  name: string
  siteId: string
  destinationId?: string
}
export type TransactionStartResponseModel = {
  id?: string
  transactionPk?: number
}
export type ChargingTransactionRequest = {
  connectorId?: number
  tagId: string
}
export type OcppDestinationResponseModel = {
  id: string
  name?: string
  destinationId: string
}
export type PagedResponseV0OcppDestinationResponseModel = {
  first?: boolean
  last?: boolean
  pageSize?: number
  page?: number
  totalPages?: number
  totalItems?: number
  items?: OcppDestinationResponseModel[]
}
export type CreateOcppDestinationModel = {
  name?: string
  destinationId: string
}
export type ChargingGroupUpdateModel = {
  name?: string
  destinationId?: string
}
export type UpdateOcppDestinationModel = {
  name?: string
  destinationId?: string
}
export type PagedResponseV0TransactionSummaryModel = {
  first?: boolean
  last?: boolean
  pageSize?: number
  page?: number
  totalPages?: number
  totalItems?: number
  items?: TransactionSummaryModel[]
}
export type TransactionMeterValuesModel = {
  id?: string
  chargerId?: string
  connectorId?: number
  transactionPk?: number
  measuredAt?: string
  currentImport?: number
  currentImportUnit?: string
  currentOffered?: number
  currentOfferedUnit?: string
  energyActiveImportRegister?: number
  energyActiveImportRegisterUnit?: string
  powerActiveImport?: number
  powerActiveImportUnit?: string
  livePowerImportUnit?: string
  powerOffered?: number
  powerOfferedUnit?: string
  voltage?: number
  voltageUnit?: string
  temperature?: number
  temperatureUnit?: string
  soc?: number
  socUnit?: string
  currentExport?: number
  currentExportUnit?: string
  powerActiveExport?: number
  powerActiveExportUnit?: string
  energyActiveExportRegister?: number
  energyActiveExportRegisterUnit?: string
  energyActiveImportInterval?: number
  energyActiveImportIntervalUnit?: string
  powerFactor?: number
  powerFactorUnit?: string
  context?: string
  livePowerImport?: number
  livePowerUnit?: string
}
export type MeterValueCollectionIntervalModel = {
  intervals?: string[][]
  meanPowerImport?: number[]
  maxPowerImport?: number[]
  totalEnergyImport?: number[]
}
export type MeterValueCollectionMetaAttributeModel = {
  intervalPeriod: '15m'
  transactionId?: string
}
export type JsonApiObjectMeterValueCollectionIntervalModelMeterValueCollectionMetaAttributeModel = {
  data?: MeterValueCollectionIntervalModel
  meta?: MeterValueCollectionMetaAttributeModel
}
export type PowerUtilizationModel = {
  activePowerImport?: number[][]
  activePowerExport?: number[][]
  powerOffered?: number[][]
  totalEnergyImport?: number[][]
  totalEnergyExport?: number[][]
}
export type ChargingSummaryModel = {
  from?: string
  to?: string
  numChargingSessions?: number
  numVehiclesCharged?: number
  avgSessionChargedKwh?: number
  totalSessionChargedKwh?: number
  avgSessionLengthMin?: number
  totalSessionLengthMin?: number
}
export type ServiceEventAuditModel = {
  timestamp: string
  eventId: string
  chargerId: string
  effectiveAsOf: string
  eventType: 'COMMISSIONING' | 'OUTAGE'
  commissioningState?: 'PRECOMMISSIONED' | 'COMMISSIONED' | 'DECOMMISSIONED'
  note?: string
  userId?: string
  organizationId?: string
  operation: 'CREATE' | 'UPDATE' | 'DELETE'
}
export type PagedResponseV0ServiceEventAuditModel = {
  first?: boolean
  last?: boolean
  pageSize?: number
  page?: number
  totalPages?: number
  totalItems?: number
  items?: ServiceEventAuditModel[]
}
export type ReportGenerationStatusModel = {
  id?: string
  status?: string
  reportUrl?: string
  requestedBy?: string
  created?: string
  updated?: string
}
export type OcppConfigurationParameterModel = {
  key: string
  value: string
  readOnly: boolean
}
export type OcppConfigurationResponseModel = {
  chargerId: string
  configurationKeys: OcppConfigurationParameterModel[]
  customKeys: OcppConfigurationParameterModel[]
  unknownKeys: string
  asOf: string
}
export type ActiveChargerModel = {
  chargeBoxId?: string
  sessionId?: number
  connectorId?: number
  depotId?: string
  fleetId?: string
  firstEnergyImportValue?: number
  lastEnergyImportValue?: number
  latestPowerImport?: number
  latestCurrentImport?: number
  latestVoltage?: number
  isEnergyMeasurandMissing?: boolean
  isPowerEstimated?: boolean
  isVoltageMeasurandMissing?: boolean
  isCurrentMeasurandMissing?: boolean
  powerOffered?: number
  currentOffered?: number
  kwhConsumed?: number
  currentType?: string
  maxChargingSchedulePeriods?: number
  maxChargingProfileStackLevels?: number
  maxChargingProfilesInstalled?: number
}
export type LiveDepotUtilizationModel = {
  externalId?: string
  depotId?: string
  kwhConsumed?: number
  kwhConsumedUnit?: string
  totalVoltage?: number
  voltageUnit?: string
  totalCurrent?: number
  currentUnit?: string
  totalPower?: number
  powerUnit?: string
  powerCalculationEstimated?: boolean
  isPartialEnergyTotal?: boolean
  isPartialCurrentTotal?: boolean
  isPartialVoltageTotal?: boolean
  chargersPowerEstimatedOn?: string[]
  chargersMissingPowerMeasurand?: string[]
  chargersMissingEnergyMeasurand?: string[]
  chargersMissingCurrentMeasurand?: string[]
  chargersMissingVoltageMeasurand?: string[]
  activeChargers?: ActiveChargerModel[]
}
export type UptimeResponse = {
  from?: string
  to?: string
  statusAllocation?: {
    [key: string]: number
  }
  uptimePc?: number
}
export type ChargerFaultsModel = {
  connectorFaults?: {
    [key: string]: ConnectorStatusModel[]
  }
}
export type ConfigurationKey = {
  value?: string
  readOnly?: boolean
}
export type ConfigurationKeyResponseModel = {
  ocppStandardConfigurationKeys?: {
    [key: string]: ConfigurationKey
  }
  customConfigurationKeys?: {
    [key: string]: ConfigurationKey
  }
  unknownKeys?: string
}
export type CollectionUtilizationResponseModel = {
  lastKnownPowerImportKw: number
  livePowerImportKw: number
  livePowerExportKw: number
  lastKnownPowerExportKw: number
  chargersNotRecentlyHeardFrom: string[]
}
export type ChargerUtilizationResponseModel = {
  lastKnownPowerImportKw: number
  livePowerImportKw: number
  livePowerExportKw: number
  lastKnownPowerExportKw: number
}
export type UtilizationResponseModel = {
  total?: CollectionUtilizationResponseModel
  sites?: {
    [key: string]: CollectionUtilizationResponseModel
  }
  chargers?: {
    [key: string]: ChargerUtilizationResponseModel
  }
}
export type ChargersTimespanTableElementModel = {
  chargerPk: number
  chargerId: string
  chargerName?: string
  siteId?: string
  totalTransactions?: number
  totalUniqueTags?: number
  totalEnergyDispensedWh?: number
  totalChargeTime?: number
  averageTransactionLength?: number
  totalFaults?: number
  totalFaultedStatuses?: number
  totalWarnings?: number
  uptimeSeconds?: number
  uptimePercentage?: number
  numConnectors: number
}
export type ChargersTimespanTableModel = {
  fromTs?: string
  toTs?: string
  chargerSummary?: ChargersTimespanTableElementModel[]
}
export type ChargersTimespanSummaryModel = {
  fromTs?: string
  toTs?: string
  totalChargers?: number
  totalTransactions?: number
  totalUniqueTags?: number
  totalEnergyDispensedWh?: number
  totalChargeTime?: number
  averageTransactionLength?: number
  totalFaults?: number
  totalFaultedStatuses?: number
  totalWarnings?: number
  chargerUptimeSeconds?: number
  chargerUptimePercentage?: number
}
export type ChargerStatusHistoryModel = {
  numChargers: number
  statuses: {
    [key: string]: number
  }
}
export type ConnectorStatusHistoryModel = {
  numConnectors: number
  statuses: {
    [key: string]: number
  }
  uptimeMs?: number
  uptimePercentage?: number
}
export type HistoricalStatusSummaryModel = {
  fromTs?: string
  toTs?: string
  chargerStatusHistory?: ChargerStatusHistoryModel
  connectorStatusHistory?: ConnectorStatusHistoryModel
}
export type ChargerStatusApiResponseModel = {
  chargerId: string
  chargerName?: string
  connectorId?: number
  connectorName: string
  siteId: string
  organizationId: string
  status:
    | 'Available'
    | 'Preparing'
    | 'Charging'
    | 'Finishing'
    | 'SuspendedEVSE'
    | 'SuspendedEV'
    | 'Reserved'
    | 'PartiallyFaulted'
    | 'Faulted'
    | 'Unavailable'
    | 'Unknown'
    | 'Offline'
  operationalStatus?: 'Operative' | 'Inoperative' | 'Unknown' | 'Offline'
  occupiedStatus: 'Occupied' | 'Unoccupied' | 'Unknown' | 'Offline'
  errorCode:
    | 'ConnectorLockFailure'
    | 'EVCommunicationError'
    | 'GroundFailure'
    | 'HighTemperature'
    | 'InternalError'
    | 'LocalListConflict'
    | 'NoError'
    | 'OtherError'
    | 'OverCurrentFailure'
    | 'OverVoltage'
    | 'PowerMeterFailure'
    | 'PowerSwitchFailure'
    | 'ReaderFailure'
    | 'ResetFailure'
    | 'UnderVoltage'
    | 'WeakSignal'
  errorInfo?: string
  vendorId?: string
  vendorErrorCode?: string
  timestamp: string
}
export type PagedResponseV0ChargerStatusApiResponseModel = {
  first?: boolean
  last?: boolean
  pageSize?: number
  page?: number
  totalPages?: number
  totalItems?: number
  items?: ChargerStatusApiResponseModel[]
}
export type MeterValueIntervalDataSeriesModel = {
  groupKey?: {
    [key: string]: string
  }
  values?: {
    [key: string]: number[]
  }
}
export type MeterValueIntervalDataModel = {
  intervals?: string[][]
  series?: MeterValueIntervalDataSeriesModel[]
}
export type MeterValueIntervalMetaAttributeModel = {
  intervalPeriod: 'none'
  from: string
  to: string
}
export type JsonApiObjectMeterValueIntervalDataModelMeterValueIntervalMetaAttributeModel = {
  data?: MeterValueIntervalDataModel
  meta?: MeterValueIntervalMetaAttributeModel
}
export type LatestConnectorStatusSummaryModel = {
  total: number
  currentStatuses: {
    [key: string]: number
  }
  lastKnownStatuses: {
    [key: string]: number
  }
  operationalStatuses: {
    [key: string]: number
  }
  simplifiedStatuses: {
    [key: string]: number
  }
  occupiedStatuses: {
    [key: string]: number
  }
}
export type LatestChargerStatusSummaryModel = {
  total: number
  currentStatuses: {
    [key: string]: number
  }
  lastKnownStatuses: {
    [key: string]: number
  }
  operationalStatuses: {
    [key: string]: number
  }
  connectivityStatuses: {
    [key: string]: number
  }
}
export type LatestStatusSummaryModel = {
  connectors: LatestConnectorStatusSummaryModel
  chargers: LatestChargerStatusSummaryModel
  connectorsByCharger?: {
    [key: string]: LatestConnectorStatusSummaryModel
  }
  connectorsBySite?: {
    [key: string]: LatestConnectorStatusSummaryModel
  }
  chargersBySite?: {
    [key: string]: LatestChargerStatusSummaryModel
  }
}
export type ConnectorFaultModel = {
  siteId: string
  chargerId: string
  chargerName?: string
  connectorId: number
  connectorName?: string
  connectorStatusId: number
  currentChargerStatus?: 'Operative' | 'Faulted' | 'Unavailable' | 'Unknown' | 'PartiallyFaulted'
  currentConnectorStatus?:
    | 'Available'
    | 'Preparing'
    | 'Charging'
    | 'Finishing'
    | 'SuspendedEVSE'
    | 'SuspendedEV'
    | 'Reserved'
    | 'Faulted'
    | 'Unavailable'
    | 'Unknown'
    | 'Offline'
  faultStatus?:
    | 'Available'
    | 'Preparing'
    | 'Charging'
    | 'Finishing'
    | 'SuspendedEVSE'
    | 'SuspendedEV'
    | 'Reserved'
    | 'Faulted'
    | 'Unavailable'
    | 'Unknown'
    | 'Offline'
  errorCode: string
  errorInfo?: string
  vendor?: string
  vendorErrorCode?: string
  timestamp: string
}
export type PagedResponseV0ConnectorFaultModel = {
  first?: boolean
  last?: boolean
  pageSize?: number
  page?: number
  totalPages?: number
  totalItems?: number
  items?: ConnectorFaultModel[]
}
export type PagedResponseV0ChargerManufacturerModel = {
  first?: boolean
  last?: boolean
  pageSize?: number
  page?: number
  totalPages?: number
  totalItems?: number
  items?: ChargerManufacturerModel[]
}
export type PagedResponseV0ChargerMakeModelResponseModel = {
  first?: boolean
  last?: boolean
  pageSize?: number
  page?: number
  totalPages?: number
  totalItems?: number
  items?: ChargerMakeModelResponseModel[]
}
export type SiteGridProfileModel = {
  id: string
  siteId: string
  gridEventIntegrationAccountId: string
  externalId?: string
  defaultEventLength?: number
  defaultCurtailedPowerLevel?: number
  autoOptIn: boolean
}
export type UpdateSiteGridProfileRequestParams = {
  id: string
  siteId: string
  gridEventIntegrationAccountId: string
  externalId?: string
  defaultEventLength?: number
  defaultCurtailedPowerLevel?: number
  autoOptIn: boolean
}
export type CreateSiteGridProfileRequestParams = {
  siteId: string
  gridEventIntegrationAccountId: string
  externalId?: string
  defaultEventLength?: number
  defaultCurtailedPowerLevel?: number
  autoOptIn: boolean
}
export type ScheduledVehicleTripModel = {
  id: string
  vehicleId: string
  departureTime: string
  arrivalTime?: string
  distance?: number
  distanceUnits?: string
  recurrenceId?: string
  occurrenceIndex?: number
}
export type PagedResponseV2ScheduledVehicleTripModel = {
  meta: Meta
  data: ScheduledVehicleTripModel[]
}
export type TripConflictsModel = object
export type ScheduledVehicleTripUpdateModel = {
  id: string
  departureTime: string
  arrivalTime?: string
  distance?: number
  distanceUnits?: string
}
export type ScheduledVehicleTripCreateModel = {
  vehicleId: string
  departureTime: string
  arrivalTime?: string
  distance?: number
  distanceUnits?: string
}
export type ScheduledVehicleTripDeleteModel = {
  tripIds: string[]
}
export type ScheduledTripRecurrenceModel = {
  id: string
  siteId: string
  vehicleIds: string[]
  startDate: string
  endDate: string
  startTime: string
  endTime?: string
  timezone: string
  distance?: number
  distanceUnits?: string
  scheduleName?: string
  recurrenceRule: string
}
export type ScheduledTripRecurrenceUpdateModel = {
  startDate: string
  endDate: string
  startTime: string
  endTime?: string
  timezone: string
  distance?: number
  distanceUnits?: string
  scheduleName?: string
  recurrenceRule: string
}
export type ScheduledTripRecurrenceUpdateOccurrencesModel = {
  departureTime: string
  arrivalTime?: string
  distance?: number
  distanceUnits?: string
}
export type GridEventIntegrationAccountModel = {
  id: string
  organizationId?: string
  gridEventIntegrationId?: string
  authType?: 'PASSWORD' | 'API_KEY' | 'OAUTH'
}
export type UpdateGridEventIntegrationsAccountRequestParams = {
  id: string
  organizationId: string
  gridEventIntegrationId: string
  authType: 'PASSWORD' | 'API_KEY' | 'OAUTH'
}
export type CreateGridEventIntegrationsAccountRequestParams = {
  organizationId: string
  gridEventIntegrationId: string
  authType: 'PASSWORD' | 'API_KEY' | 'OAUTH'
  username?: string
  password?: string
  apiKey?: string
}
export type DepotEventIntervalModel = {
  intervalId?: string
  controlSignalValue?: string
  startDateTime: string
  endDateTime: string
  curtailedSiteLimit?: number
}
export type DepotEventScheduleModel = {
  depotEventScheduleId?: string
  intervals: DepotEventIntervalModel[]
}
export type EventRecurrenceModel1 = {
  eventRecurrenceId?: string
  recurrenceRule?: string
  startDate?: string
  validUntilDate?: string
  recurrenceName?: string
  recurrenceType?: 'DAILY' | 'WEEKLY' | 'WEEKDAYS' | 'CUSTOM'
}
export type DepotEventModel = {
  eventId?: string
  providerId?: string
  externalId?: string
  depotGridProfileId?: string
  depotId: string
  eventStartDate: string
  eventEndDate: string
  eventType: string
  eventDetails?: string
  schedule: DepotEventScheduleModel
  eventRecurrence?: EventRecurrenceModel1
  isCommitted?: boolean
  isConfirmed?: boolean
}
export type ChargerEventModel = {
  depotId: string
  eventId: string
  assetNm?: string
  assetId?: string
  chargerId?: string
  dispenserId?: string
  fleetId?: string
  status?: string
  scheduledStart?: string
  scheduledEnd?: string
  actualStart?: string
  actualEnd?: string
  eventType?: string
  powerOffered?: number
  targetSoc?: number
  eventTargetNm?: string
  chargerTagId?: string
  executionArn?: string
  profileId?: string
  startBehavior?: 'NeverSendRemoteStart' | 'AlwaysSendRemoteStart' | 'SynopManaged'
  stopBehavior?: 'SendStopAtEndOfEvent' | 'NeverSendStop'
  eventRecurrence?: EventRecurrenceModel1
}
export type ChargerEventUpdateModelDeprecated = {
  eventId: string
  status?: string
  eventType?: string
  targetSoc?: number
  profileId?: string
  powerOffered?: number
  chargerId?: string
  dispenserId?: string
  actualStart?: string
  actualEnd?: string
  scheduledStart?: string
  scheduledEnd?: string
  startBehavior?: 'NeverSendRemoteStart' | 'AlwaysSendRemoteStart' | 'SynopManaged'
  stopBehavior?: 'SendStopAtEndOfEvent' | 'NeverSendStop'
}
export type ChargerEventUpdateModel = {
  status?: string
  eventType?: string
  powerOffered?: number
  targetSoc?: number
  profileId?: string
  chargerId?: string
  connectorId?: string
  actualStart?: string
  actualEnd?: string
  scheduledStart?: string
  scheduledEnd?: string
  startBehavior?: 'NeverSendRemoteStart' | 'AlwaysSendRemoteStart' | 'SynopManaged'
  stopBehavior?: 'SendStopAtEndOfEvent' | 'NeverSendStop'
}
export type ReassignEventConnectorModel = {
  chargerId?: string
  dispenserId?: string
}
export type GetTelemetryExclusionModel = {
  vehicleId: string
  startTimestamp: string
  endTimestamp: string
  id: string
}
export type PagedResponseV2GetTelemetryExclusionModel = {
  meta: Meta
  data: GetTelemetryExclusionModel[]
}
export type BaseTelemetryExclusionModel = {
  vehicleId: string
  startTimestamp: string
  endTimestamp: string
}
export type CreateTelemetryExclusionRequestModel = {
  createTelemetryExclusionModelList: BaseTelemetryExclusionModel[]
}
export type ChargeManagementConfiguration = {
  status?: 'ACTIVE' | 'INACTIVE'
  loadManagement?: 'OFF' | 'FIXED' | 'DYNAMIC'
  offlineControls?: 'OFF' | 'FIXED_POWER_DISTRIBUTION' | 'DYNAMIC_POWER_DISTRIBUTION'
  siteLimit?: number
}
export type EnergyManagementConfiguration = {
  status?: 'ACTIVE' | 'INACTIVE'
  vehicleToGridStatus?: 'ACTIVE' | 'INACTIVE'
  linkStatus?: 'ACTIVE' | 'INACTIVE'
  autopilotStatus?: 'ACTIVE' | 'INACTIVE'
}
export type SiteEnergyConfiguration = {
  id?: string
  siteId?: string
  chargeManagementConfiguration?: ChargeManagementConfiguration
  energyManagementConfiguration?: EnergyManagementConfiguration
  validFrom?: string
  validTo?: string
}
export type ChargeManagementConfigurationRequestParams = {
  status: 'ACTIVE' | 'INACTIVE'
  loadManagement: 'OFF' | 'FIXED' | 'DYNAMIC'
  offlineControls: 'OFF' | 'FIXED_POWER_DISTRIBUTION' | 'DYNAMIC_POWER_DISTRIBUTION'
  siteLimit?: number
}
export type EnergyManagementConfigurationRequestParams = {
  status: 'ACTIVE' | 'INACTIVE'
  vehicleToGridStatus: 'ACTIVE' | 'INACTIVE'
  linkStatus: 'ACTIVE' | 'INACTIVE'
  autopilotStatus: 'ACTIVE' | 'INACTIVE'
}
export type CreateSiteEnergyConfigurationParams = {
  chargeManagementConfiguration: ChargeManagementConfigurationRequestParams
  energyManagementConfiguration: EnergyManagementConfigurationRequestParams
}
export type ScheduledTripRecurrenceCreateModel = {
  siteId: string
  vehicleIds: string[]
  startDate: string
  endDate: string
  startTime: string
  endTime?: string
  timezone: string
  distance?: number
  distanceUnits?: string
  scheduleName?: string
  recurrenceRule: string
}
export type ScheduledTripRecurrenceSetVehiclesModel = {
  vehicleIds: string[]
  occurrenceIndex: number
}
export type VehicleOptimizationModel = {
  vehicleOptimizationId?: string
  optimizationId?: string
  chargerEventId?: string
  profileId?: string
  depotId: string
  fleetId: string
  startSoc?: number
  targetSoc?: number
  scheduledRouteId?: string
  batterySize?: number
  maxPower?: number
  from?: string
  to?: string
}
export type OptimizationModel = {
  optimizationId?: string
  depotId: string
  fleetId?: string
  timeStepMinutes?: number
  optimizationType?: string
  costCurve?: number[]
  siteLimitCurve?: number[]
  regularizationConstant?: number
  from?: string
  to?: string
  feasible?: boolean
  errorMessage?: string
  parentOptimizationId?: string
  vehicleOptimizations?: VehicleOptimizationModel[]
  nsteps?: number
}
export type LoadBalancerEventModel = {
  id?: string
  origin?: string
  eventType?:
    | 'DEPOT_EVENT'
    | 'CHARGER_EVENT'
    | 'SCHEDULED_EVENT_START'
    | 'HEARTBEAT'
    | 'AFTER_START'
    | 'AFTER_STOP'
    | 'EMS_ON'
    | 'EMS_OFF'
    | 'SITE_LIMIT'
    | 'FIXED_LOAD_MANAGEMENT_TURNED_OFF'
  chargerId?: string
  fleetId?: string
  depotId?: string
  originTime?: string
  executionTime?: string
}
export type PagedResponseLoadBalancerEventModel = {
  first?: boolean
  last?: boolean
  pageSize?: number
  page?: number
  totalPages?: number
  totalItems?: number
  items?: LoadBalancerEventModel[]
}
export type EnergyEfficiencyPredictionModel = {
  energyEfficiencyPredictionId: string
  fleetId: string
  depotId: string
  vehicleId: string
  efficiency?: number
  confidence?: number
  modelName?: string
  validFrom?: string
  validTo?: string
  windowFrom?: string
  windowTo?: string
}
export type CreateEnergyEfficiencyPredictionRequestParams = {
  fleetId?: string
  depotId?: string
  vehicleId?: string
  efficiency?: number
  confidence?: number
  modelName?: string
  validFrom?: string
  validTo?: string
  windowFrom?: string
  windowTo?: string
}
export type TransactionProfileMappingModel = {
  id?: string
  profileId?: string
  transactionId?: string
  chargerId?: string
  connectorId?: string
  depotId?: string
  siteDataSnapshotTimestamp?: string
  source?: string
}
export type ProfileApplicationRecord = {
  id?: string
  applicationTime?: string
  chargerId?: string
  dispenserId?: string
  profileId?: string
  applicationStatus?: 'ACCEPTED' | 'FAILED' | 'REJECTED'
  profileType?: 'TX_PROFILE' | 'TX_DEFAULT_PROFILE' | 'CHARGE_POINT_MAX_PROFILE'
  origin?: string
}
export type CreateProfileApplicationRecordParams = {
  dispenserId: string
  profileId: string
  applicationStatus: 'ACCEPTED' | 'FAILED' | 'REJECTED'
  profileType: 'TX_PROFILE' | 'TX_DEFAULT_PROFILE' | 'CHARGE_POINT_MAX_PROFILE'
  origin: string
}
export type ScheduleRequestModel = {
  assetId?: string
  chargerId: string
  dispenserId: string
  powerOffered?: number
  targetSoc?: number
  profileId?: string
  eventType: string
  eventTargetNm?: string
  chargerTagId?: string
  depotId: string
  sessionLengthMinutes?: number
  validUntilDate?: string
  scheduledStart: string
  scheduledEnd?: string
  startBehavior?: 'NeverSendRemoteStart' | 'AlwaysSendRemoteStart' | 'SynopManaged'
  stopBehavior?: 'SendStopAtEndOfEvent' | 'NeverSendStop'
  eventRecurrence?: EventRecurrenceModel1
}
export type ChargingSessionModel1 = {
  sessionId?: string
  connectorId?: number
  chargerId?: string
  ocppTag?: string
  vehicleId?: string
  vin?: string
  userId?: string
  startTimestamp?: string
  stopTimestamp?: string
  stopReason?: string
  depotId?: string
  fleetId?: string
  chargerName?: string
  depotName?: string
  kwhConsumed?: number
  kwhConsumedUnit?: string
  powerImported?: number
  powerImportUnit?: string
  powerOffered?: number
  powerOfferedUnit?: string
  startSocValue?: number
  endSocValue?: number
}
export type ChargerEventSessionDetailsModel = {
  eventId?: string
  sessions?: ChargingSessionModel1[]
}
export type ChargerEventSessionModel = {
  chargerEventSessionId?: string
  eventId?: string
  sessionId?: string
}
export type ChargerEventPredictionRequestModel = {
  assetId?: string
  chargerId: string
  dispenserId: string
  depotId: string
  fleetId: string
  scheduledStart?: string
  scheduledEnd?: string
  validTo: string
  modelName: string
  chargerEventId: string
  confidence?: number
}
export type PagedResponseChargerEventModel = {
  first?: boolean
  last?: boolean
  pageSize?: number
  page?: number
  totalPages?: number
  totalItems?: number
  items?: ChargerEventModel[]
}
export type ChargerEventCreateModel = {
  siteId: string
  chargerId?: string
  connectorId?: string
  vehicleId?: string
  eventType: string
  powerOffered?: number
  targetSoc?: number
  profileId?: string
  chargerTagId?: string
  scheduledStart: string
  scheduledEnd?: string
  startBehavior?: 'NeverSendRemoteStart' | 'AlwaysSendRemoteStart' | 'SynopManaged'
  stopBehavior?: 'SendStopAtEndOfEvent' | 'NeverSendStop'
  eventRecurrence?: EventRecurrenceModel1
}
export type ChargerEventNewSessionModel = {
  sessionId: string
}
export type LinkTelemetryTuple = {
  timestamp?: number
  value?: number
}
export type LinkTelemetrySeries = {
  units?: string
  seriesName?: string
  values?: LinkTelemetryTuple[]
}
export type LinkTelemetryResponse = {
  telemetrySeries?: LinkTelemetrySeries[]
}
export type GridEventIntegrationModel = {
  id: string
  name?: string
  pollingRate?: number
  endpoint?: string
}
export type PriceChange = {
  name?: string
  from?: string
  to?: string
  rateAmount?: number
  rateMeanDelta?: number
}
export type Price = {
  description?: string
  from?: string
  to?: string
  currency?: string
  rateMean?: number
  rateStandardDeviation?: number
  priceChanges?: PriceChange[]
}
export type PowerTimeSeriesPeriod = {
  startTimestamp: string
  startEpochSeconds: number
  powerLevel: number
}
export type PowerTimeSeriesModel = {
  startTimestamp: string
  endTimestamp: string
  powerLevels?: PowerTimeSeriesPeriod[]
}
export type DepotEventDetailsModel = {
  eventId?: string
  depotId?: string
  eventStartDate?: string
  eventEndDate?: string
  transactionIds?: string[]
  vehicles?: string[]
  chargers?: string[]
  peakPower?: number
  unmanagedPeakPower?: number
  energyImported?: number
  unmanagedEnergyImported?: number
  energyExported?: number
}
export type ChargerEventPredictionModel = {
  depotId: string
  eventId?: string
  assetId?: string
  chargerId?: string
  dispenserId?: string
  fleetId?: string
  validFrom?: string
  validTo?: string
  scheduledStart?: string
  scheduledEnd?: string
  confidence?: number
  modelName?: string
  chargerEventId?: string
}
export type ChargingSessionTelemetryModel1 = {
  chargerId?: string
  connectorId?: number
  chargingSessionId?: string
  measuredAt?: string
  currentImport?: number
  currentImportUnit?: string
  currentOffered?: number
  currentOfferedUnit?: string
  energyActiveImportRegister?: number
  energyActiveImportRegisterUnit?: string
  powerActiveImport?: number
  powerActiveImportUnit?: string
  livePowerImportUnit?: string
  powerOffered?: number
  powerOfferedUnit?: string
  voltage?: number
  voltageUnit?: string
  temperature?: number
  temperatureUnit?: string
  soc?: number
  socUnit?: string
  currentExport?: number
  currentExportUnit?: string
  powerActiveExport?: number
  powerActiveExportUnit?: string
  energyActiveExportRegister?: number
  energyActiveExportRegisterUnit?: string
  energyActiveImportInterval?: number
  energyActiveImportIntervalUnit?: string
  powerFactor?: number
  powerFactorUnit?: string
  context?: string
  livePowerImport?: number
  livePowerUnit?: string
}
export type ChargerEventSessionTelemetryModel = {
  chargerEventId?: string
  sessionTelemetry?: {
    [key: string]: ChargingSessionTelemetryModel1[]
  }
}
export type TimeSourceAttributeValue = {
  units: 'min'
  source: string
  value: number
  confidence?: number
  lastUpdated: number
}
export type JsonApiErrorModel = {
  title: string
  detail: string
  status: number
}
export type AcknowledgeNotificationsModel = {
  sentNotificationIds: string[]
}
export type AlertUiRule = object
export type AlertUiRuleSet = {
  condition?: string
  rules?: AlertUiRule[]
}
export type NotificationRecipientModel = {
  message: string
  recipients: string[]
}
export type AlertConfigurationModel = {
  createdOrganizationId?: string
  alertName: string
  assetType: 'Vehicle' | 'Charger' | 'Site'
  lookBackPeriod?: number
  priority: 'Critical' | 'High' | 'Medium' | 'Low'
  assets?: string[]
  sites?: string[]
  organizations?: string[]
  rules?: AlertUiRuleSet
  notification: NotificationRecipientModel
  id: string
  createdBy: string
  createdOn: string
  enabled: boolean
}
export type CreateAlertConfigurationModel = {
  createdOrganizationId?: string
  alertName: string
  assetType: 'Vehicle' | 'Charger' | 'Site'
  lookBackPeriod?: number
  priority: 'Critical' | 'High' | 'Medium' | 'Low'
  assets?: string[]
  sites?: string[]
  organizations?: string[]
  rules?: AlertUiRuleSet
  notification: NotificationRecipientModel
}
export type NotificationModelV1 = {
  id: string
  alertName: string
  priority: 'Critical' | 'High' | 'Medium' | 'Low'
  message: string
  assetType: 'Vehicle' | 'Charger'
  assetId: string
  assetName?: string
  organizationId?: string
  siteId?: string
  recipients: string[]
  acknowledged?: boolean
  acknowledgedAt?: string
  activeAt?: string
  inactiveAt?: string
}
export type SourceAttributeValue = {
  value?: string
  units?: string
  lastUpdated?: number
  source?: string
  type?: string
}
export type Event = {
  assetId?: string
  assetType?: 'Charger' | 'Site' | 'Vehicle'
  siteId?: string
  organizationId?: string
  timestamp?: number
  ingestionTimestamp?: number
  data?: {
    [key: string]: SourceAttributeValue
  }
}
export type CreateAlertNotificationModel = {
  alertId: string
  eventMessage: Event
}
export type DeactivateAlertNotificationsModel = {
  alertId: string
  assetType: 'Vehicle' | 'Charger' | 'Site'
  assetId: string
  inactiveAt: number
}
export type PagedResponseV1NotificationModelV1 = {
  meta: Meta
  payload: NotificationModelV1[]
}
export type NotificationModel = {
  id: string
  alertName: string
  priority: 'Critical' | 'High' | 'Medium' | 'Low'
  message: string
  assetType: 'Vehicle' | 'Charger'
  assetId: string
  assetName?: string
  organizationId?: string
  siteId?: string
  recipients: string[]
  acknowledged?: boolean
  acknowledgedDateTime?: string
  active?: string
  inactive?: string
}
export type AlertOptionModel = {
  id: string
  name: string
  value: string
}
export type PropertyModel = {
  id: string
  displayName: string
  propertyName: string
  propertyType: 'string' | 'number' | 'geospatial'
  assetType: 'Vehicle' | 'Charger' | 'Site'
  options: AlertOptionModel[]
  operators: AlertOptionModel[]
  chainableProperties: string[]
  units?: string
}
export type UserTagModel = {
  userId?: string
  userType?:
    | 'SYNOP_USER'
    | 'EXTERNAL_WORKPLACE_CHARGING_USER'
    | 'EXTERNAL_REIMBURSEMENT_CHARGING_USER'
    | 'EXTERNAL_OTHER_USER'
  ocppTagValue?: string
  ocppTagType?: 'RFID' | 'QR_CODE' | 'NFC' | 'OTHER' | 'MOBILE' | 'VID'
  useCase?: 'WORKPLACE_CHARGING' | 'FLEET_CHARGING' | 'REIMBURSEMENTS' | 'OTHER' | 'OPERATIONS'
  created?: string
  updated?: string
  modifiedBy?: string
  createdBy?: string
}
export type WorkplaceChargingUserModel = {
  firstNm?: string
  lastNm?: string
  email?: string
  externalId?: string
  userId?: string
  organizationId?: string
  status?: 'CURRENT' | 'PAST_DUE' | 'PENDING'
  onboarded?: string
  ocppTags?: UserTagModel[]
  id: string
}
export type RatePeriod = {
  rateId?: string
  ratePeriodId?: string
  from: string
  to: string
  price: number
  priceId?: string
  productId?: string
  productNm?: string
  priceDesc?: string
  dayOfWeek?: ('MONDAY' | 'TUESDAY' | 'WEDNESDAY' | 'THURSDAY' | 'FRIDAY' | 'SATURDAY' | 'SUNDAY')[]
}
export type OverrideRateStructureByOrganizationModelListRatePeriod = {
  rateStatus?: 'ACTIVE' | 'INACTIVE'
  rateNm: string
  rateType: 'FLAT' | 'VOLUME_BASED' | 'TIME_OF_USE'
  rateStructure: RatePeriod[]
  OrganizationRateOverride?: OverrideRateStructureByOrganizationModelListRatePeriod
  lastModifiedByUserName?: string
  lastModifiedByUserId?: string
  lastModified?: string
  idleFee?: number
  idleStepSize?: number
  flatFee?: number
  maxSoc?: number
  maxTime?: number
  rateMappingId: string
  rateId?: string
  productId?: string
  overrideTargetType:
    | 'ORGANIZATION'
    | 'USER'
    | 'FLEET'
    | 'SITE'
    | 'CHARGER'
    | 'VEHICLE'
    | 'HOME_CHARGING_USER'
    | 'NETWORK'
  overrideTargetId: string
  overridesMappingId: string
}
export type RateStructureModelListRatePeriod = {
  rateStatus?: 'ACTIVE' | 'INACTIVE'
  rateNm: string
  rateType: 'FLAT' | 'VOLUME_BASED' | 'TIME_OF_USE'
  rateStructure: RatePeriod[]
  OrganizationRateOverride?: OverrideRateStructureByOrganizationModelListRatePeriod
  lastModifiedByUserName?: string
  lastModifiedByUserId?: string
  lastModified?: string
  idleFee?: number
  idleStepSize?: number
  flatFee?: number
  maxSoc?: number
  maxTime?: number
  rateMappingId: string
  rateId?: string
  productId?: string
}
export type OverrideRateStructureModelListRatePeriod = {
  rateStatus?: 'ACTIVE' | 'INACTIVE'
  rateNm: string
  rateType: 'FLAT' | 'VOLUME_BASED' | 'TIME_OF_USE'
  rateStructure: RatePeriod[]
  OrganizationRateOverride?: OverrideRateStructureByOrganizationModelListRatePeriod
  lastModifiedByUserName?: string
  lastModifiedByUserId?: string
  lastModified?: string
  idleFee?: number
  idleStepSize?: number
  flatFee?: number
  maxSoc?: number
  maxTime?: number
  rateMappingId: string
  rateId?: string
  productId?: string
  overrideTargetType:
    | 'ORGANIZATION'
    | 'USER'
    | 'FLEET'
    | 'SITE'
    | 'CHARGER'
    | 'VEHICLE'
    | 'HOME_CHARGING_USER'
    | 'NETWORK'
  overrideTargetId: string
  overridesMappingId: string
}
export type WorkplaceChargingRateModel = {
  administeringOrganizationId?: string
  siteId: string
  defaultRateStructure: RateStructureModelListRatePeriod
  rateMappingId?: string
  overrides?: OverrideRateStructureModelListRatePeriod[]
}
export type UpdateWorkplaceChargingRate = {
  rateMappingId: string
  organizationId: string
  rateStatus?: 'ACTIVE' | 'INACTIVE'
  defaultRateStructure: RateStructureModelListRatePeriod
}
export type UserPushNotificationMap = {
  created?: string
  updated?: string
  createdBy?: string
  modifiedBy?: string
  logicalDeleteIn?: boolean
  userId?: string
  deviceId?: string
  snsArn?: string
}
export type CreateUserPushParams = {
  userId: string
  deviceId: string
  token: string
}
export type FleetManagementUserTagModel = {
  userId?: string
  ocppTagValue?: string
  ocppTagType?: 'RFID' | 'QR_CODE' | 'NFC' | 'OTHER' | 'MOBILE' | 'VID'
  ocppTagUseCase?: 'WORKPLACE_CHARGING' | 'FLEET_CHARGING' | 'REIMBURSEMENTS' | 'OTHER' | 'OPERATIONS'
  created?: string
  updated?: string
}
export type UpdateSubscriptionLineItemModel = {
  subscriptionItemId?: string
  subscriptionId?: string
  priceId?: string
  lineItemNm: string
  unitAmount: number
  quantity: number
  total?: number
}
export type Commitment = {
  commitmentType: 'COST' | 'USAGE'
  minimumSpend?: number
  committedQuantity?: number
  applicablePriceIds: string[]
  rateMultiplierAbove?: number
  rateMultiplierBelow?: number
}
export type DiscountTier = {
  minUsage: number
  maxUsage?: number
  discountAmount?: number
  discountPercentage?: number
}
export type Discount = {
  discountType: 'FLAT' | 'PERCENTAGE'
  flatAmount?: number
  percentage?: number
  applicablePriceIds?: string[]
  tiers?: DiscountTier[]
}
export type SubscriptionModel = {
  subscriptionId: string
  daysUntilDue?: number
  billedOrganizationNm?: string
  billedOrganizationId: string
  billingOrganizationNm?: string
  collectionMethod?: string
  pauseCollectionStatus?:
    | 'INCOMPLETE'
    | 'INCOMPLETE_EXPIRED'
    | 'TRIALING'
    | 'ACTIVE'
    | 'PAST_DUE'
    | 'CANCELLED'
    | 'UNPAID'
    | 'PAUSED'
  resumeCollectionAt?: string
  billingOrganizationId: string
  description?: string
  latestInvoice?: string
  status?: 'INCOMPLETE' | 'INCOMPLETE_EXPIRED' | 'TRIALING' | 'ACTIVE' | 'PAST_DUE' | 'CANCELLED' | 'UNPAID' | 'PAUSED'
  lineItems?: UpdateSubscriptionLineItemModel[]
  commitments?: Commitment[]
  discounts?: Discount[]
}
export type UpdateSubscriptionModel = {
  merchantOrganizationId: string
  subscriptionId: string
  customerOrganizationId: string
  collectionMethod?: 'CHARGE_AUTOMATICALLY' | 'SEND_INVOICE'
  daysUntilDue?: number
  description?: string
  statementDescriptor?: string
  dueDate?: string
  subscription?: string
  lineItems?: UpdateSubscriptionLineItemModel[]
  commitments?: Commitment[]
  discounts?: Discount[]
  allowCreditCard?: boolean
  allowAch?: boolean
}
export type RecurringModel = {
  aggregateUsage?: 'LAST_DURING_PERIOD' | 'LAST_EVER' | 'MAX' | 'SUM'
  interval?: 'DAY' | 'WEEK' | 'MONTH' | 'YEAR'
  intervalCount?: number
  meter?: string
  usageType?: 'LICENSED' | 'METERED'
}
export type TierModelObject = {
  upTo?: object
  flatAmountDecimal?: number
  unitAmountDecimal?: number
}
export type PriceModel = {
  priceId?: string
  productId?: string
  currency?: string
  recurring?: RecurringModel
  unitAmount?: number
  tiers?: TierModelObject[]
  tiersMode?: 'GRADUATED' | 'VOLUME' | 'TIME_OF_USE'
}
export type ProductModel = {
  productId?: string
  productNm?: string
  active?: boolean
  description?: string
  statementDescriptor?: string
  unitLabel?: string
  metadata?: {
    [key: string]: object
  }
  shippable?: boolean
  prices?: PriceModel[]
}
export type TierModel = {
  upTo?: object
  flatAmountDecimal?: number
  unitAmountDecimal?: number
}
export type UpdatePriceModel = {
  priceId?: string
  currency?: string
  recurring?: RecurringModel
  unitAmount?: number
  tiers?: TierModel[]
  tiersMode?: 'GRADUATED' | 'VOLUME' | 'TIME_OF_USE'
}
export type UpdateProductModel = {
  productId?: string
  productNm?: string
  description?: string
  statementDescriptor?: string
  unitLabel?: string
  taxCode?: string
  prices?: UpdatePriceModel[]
}
export type CdrToken = {
  country_code: string
  party_id: string
  uid: string
  type: 'VEHICLE' | 'AD_HOC_USER' | 'APP_USER' | 'OTHER' | 'RFID'
  contract_id: string
}
export type Price1 = {
  excl_vat: number
  incl_vat: number
}
export type CdrDimension = {
  type:
    | 'CURRENT'
    | 'ENERGY'
    | 'ENERGY_EXPORT'
    | 'ENERGY_IMPORT'
    | 'MAX_CURRENT'
    | 'MIN_CURRENT'
    | 'MAX_POWER'
    | 'MIN_POWER'
    | 'PARKING_TIME'
    | 'POWER'
    | 'RESERVATION_TIME'
    | 'STATE_OF_CHARGE'
    | 'TIME'
  volume: number
  price?: Price1
}
export type ChargingPeriod = {
  dimensions: CdrDimension[]
  start_date_time: string
  tariff_id?: string
}
export type SessionDto = {
  id: string
  country_code: string
  party_id: string
  last_updated: string
  start_date_time: string
  end_date_time?: string
  kwh: number
  cdr_token: CdrToken
  auth_method: 'AUTH_REQUEST' | 'COMMAND' | 'WHITELIST'
  authorization_reference?: string
  location_id: string
  evse_uid: string
  connector_id: string
  meter_id?: string
  currency: string
  charging_periods?: ChargingPeriod[]
  total_cost?: Price1
  status: 'ACTIVE' | 'COMPLETED' | 'INVALID' | 'PENDING' | 'RESERVATION'
}
export type ResponseFormatListSessionDto = {
  timestamp: string
  data?: SessionDto[]
  totalItems?: number
  status_code: number
  status_message?: string
}
export type ChargingPreferences = {
  profile_type: 'CHEAP' | 'FAST' | 'GREEN' | 'REGULAR'
  departure_time?: string
  energy_need?: number
  target_soc?: number
  discharge_allowed?: boolean
}
export type ResponseFormatChargingPreferences = {
  timestamp: string
  data?: ChargingPreferences
  totalItems?: number
  status_code: number
  status_message?: string
}
export type SourceAttributeValue1 = {
  source?: string
  attribute?: string
  value?: number
  unit?: string
  metadata?: {
    [key: string]: string
  }
  timestamp?: string
}
export type Rate = {
  created?: string
  updated?: string
  createdBy?: string
  modifiedBy?: string
  logicalDeleteIn?: boolean
  rateId?: string
  rateNm?: string
  rateType?: 'FLAT' | 'VOLUME_BASED' | 'TIME_OF_USE'
  measurand?: 'ENERGY_ADDED' | 'ENERGY_USED' | 'DISTANCE_DRIVEN' | 'TIME_USED' | 'COUNT'
  effectiveFrom?: string
  effectiveTo?: string
  productId?: string
  ratePeriods?: RatePeriod[]
  idleFee?: number
  idleStepSize?: number
  initiationFee?: number
  maxSoc?: number
  maxTime?: number
}
export type TransactionSummaryModel1 = {
  id?: string
  transactionPk?: number
  connectorId?: number
  chargerPk?: number
  chargerId?: string
  chargerName?: string
  depotId?: string
  organizationId?: string
  ocppTag?: string
  stopReason?: string
  stopEventActor?: string
  startTimestamp?: string
  stopTimestamp?: string
  updatedTimestamp?: string
  totalEnergyImported?: number
  totalEnergyExported?: number
  meanPowerImport?: number
  maxPowerImport?: number
  latestPowerImport?: number
  meanPowerExport?: number
  maxPowerExport?: number
  latestPowerExport?: number
  meanPowerOffered?: number
  maxPowerOffered?: number
  latestPowerOffered?: number
  meanAmperageImport?: number
  maxAmperageImport?: number
  latestAmperageImport?: number
  meanAmperageOffered?: number
  maxAmperageOffered?: number
  latestAmperageOffered?: number
  minVoltage?: number
  meanVoltage?: number
  maxVoltage?: number
  latestVoltage?: number
  minFrequency?: number
  meanFrequency?: number
  maxFrequency?: number
  latestFrequency?: number
  startingTemperature?: number
  minTemperature?: number
  meanTemperature?: number
  maxTemperature?: number
  latestTemperature?: number
  startingSoc?: number
  latestSoc?: number
  timezone?: string
  transactionCost?: number
}
export type ConnectorModel1 = {
  connectorId?: number
  chargerId?: string
  connectorType?:
    | 'CHAdeMO'
    | 'J1772'
    | 'CCS1'
    | 'CCS2'
    | 'NACS'
    | 'Other'
    | 'Unknown'
    | 'CHADEMO'
    | 'CHAOJI'
    | 'DOMESTIC_A'
    | 'DOMESTIC_B'
    | 'DOMESTIC_C'
    | 'DOMESTIC_D'
    | 'DOMESTIC_E'
    | 'DOMESTIC_F'
    | 'DOMESTIC_G'
    | 'DOMESTIC_H'
    | 'DOMESTIC_I'
    | 'DOMESTIC_J'
    | 'DOMESTIC_K'
    | 'DOMESTIC_L'
    | 'DOMESTIC_M'
    | 'DOMESTIC_N'
    | 'DOMESTIC_O'
    | 'GBT_AC'
    | 'GBT_DC'
    | 'IEC_60309_2_single_16'
    | 'IEC_60309_2_three_16'
    | 'IEC_60309_2_three_32'
    | 'IEC_60309_2_three_64'
    | 'IEC_62196_T1'
    | 'IEC_62196_T1_COMBO'
    | 'IEC_62196_T2'
    | 'IEC_62196_T2_COMBO'
    | 'IEC_62196_T3A'
    | 'IEC_62196_T3C'
    | 'NEMA_5_20'
    | 'NEMA_6_30'
    | 'NEMA_6_50'
    | 'NEMA_10_30'
    | 'NEMA_10_50'
    | 'NEMA_14_30'
    | 'NEMA_14_50'
    | 'PANTOGRAPH_BOTTOM_UP'
    | 'PANTOGRAPH_TOP_DOWN'
    | 'TESLA_R'
    | 'TESLA_S'
  connectorName?: string
  maxCurrent?: number
  maxPower?: number
  voltage?: number
  currentType?: string
  powerFactor?: number
}
export type ChargerModel1 = {
  chargerId?: string
  chargerName?: string
  depotId?: string
  depotName?: string
  latitude?: number
  longitude?: number
  fleetId?: string
  maxCurrent?: number
  maxPower?: number
  voltage?: number
  currentType?: string
  activeConnectorIds?: number[]
  connectors?: {
    [key: string]: ConnectorModel1
  }
  lastTransactions?: {
    [key: string]: TransactionSummaryModel1
  }
  lastHeartbeatTimestamp?: string
  commissioningState?: string
}
export type SiteModel = {
  depotId?: string
  fleetId?: string
  depotNm?: string
  numChargers?: number
  latitude?: number
  longitude?: number
  geofence?: string
  address?: string
  timezone?: string
}
export type TariffRestrictions = {
  reservation?: 'RESERVATION' | 'RESERVATION_EXPIRES'
  start_time?: string
  end_time?: string
  start_date?: string
  end_date?: string
  min_kwh?: number
  max_kwh?: number
  min_current?: number
  max_current?: number
  min_power?: number
  max_power?: number
  min_duration?: number
  max_duration?: number
  day_of_week?: ('MONDAY' | 'TUESDAY' | 'WEDNESDAY' | 'THURSDAY' | 'FRIDAY' | 'SATURDAY' | 'SUNDAY')[]
}
export type PriceComponent = {
  type: 'ENERGY' | 'FLAT' | 'PARKING_TIME' | 'TIME'
  price: number
  vat?: number
  step_size?: number
}
export type TariffElement = {
  restrictions?: TariffRestrictions
  price_components: PriceComponent[]
}
export type DisplayText = {
  language: string
  text: string
}
export type EnergySource = {
  source: 'NUCLEAR' | 'GENERAL_FOSSIL' | 'COAL' | 'GAS' | 'GENERAL_GREEN' | 'SOLAR' | 'WIND' | 'WATER'
  percentage: number
}
export type EnvironmentalImpact = {
  category: 'NUCLEAR_WASTE' | 'CARBON_DIOXIDE'
  amount: number
}
export type EnergyMix = {
  energy_sources?: EnergySource[]
  environ_impact?: EnvironmentalImpact[]
  supplier_name?: string
  energy_product_name?: string
  is_green_energy: boolean
}
export type TariffDto = {
  id: string
  currency: string
  type?: 'AD_HOC_PAYMENT' | 'PROFILE_CHEAP' | 'PROFILE_FAST' | 'REGULAR' | 'PROFILE_GREEN'
  elements: TariffElement[]
  country_code: string
  party_id: string
  last_updated: string
  tariff_alt_text?: DisplayText[]
  tariff_alt_url?: string
  min_price?: Price1
  max_price?: Price1
  start_date_time?: string
  end_date_time?: string
  energy_mix?: EnergyMix
}
export type Image = {
  url: string
  thumbnail?: string
  category: 'CHARGER' | 'ENTRANCE' | 'LOCATION' | 'NETWORK' | 'OPERATOR' | 'OTHER' | 'OWNER'
  type: string
  width?: string
  height?: string
}
export type BusinessDetails = {
  name: string
  website?: string
  logo?: Image
  phone_number?: string
}
export type GeoLocation = {
  latitude: string
  longitude: string
}
export type CdrLocation = {
  operator?: BusinessDetails
  id: string
  name?: string
  address: string
  city: string
  postal_code?: string
  state?: string
  country: string
  coordinates: GeoLocation
  evse_uid: string
  evse_id: string
  connector_id: string
  connector_standard?:
    | 'CHAdeMO'
    | 'J1772'
    | 'CCS1'
    | 'CCS2'
    | 'NACS'
    | 'Other'
    | 'Unknown'
    | 'CHADEMO'
    | 'CHAOJI'
    | 'DOMESTIC_A'
    | 'DOMESTIC_B'
    | 'DOMESTIC_C'
    | 'DOMESTIC_D'
    | 'DOMESTIC_E'
    | 'DOMESTIC_F'
    | 'DOMESTIC_G'
    | 'DOMESTIC_H'
    | 'DOMESTIC_I'
    | 'DOMESTIC_J'
    | 'DOMESTIC_K'
    | 'DOMESTIC_L'
    | 'DOMESTIC_M'
    | 'DOMESTIC_N'
    | 'DOMESTIC_O'
    | 'GBT_AC'
    | 'GBT_DC'
    | 'IEC_60309_2_single_16'
    | 'IEC_60309_2_three_16'
    | 'IEC_60309_2_three_32'
    | 'IEC_60309_2_three_64'
    | 'IEC_62196_T1'
    | 'IEC_62196_T1_COMBO'
    | 'IEC_62196_T2'
    | 'IEC_62196_T2_COMBO'
    | 'IEC_62196_T3A'
    | 'IEC_62196_T3C'
    | 'NEMA_5_20'
    | 'NEMA_6_30'
    | 'NEMA_6_50'
    | 'NEMA_10_30'
    | 'NEMA_10_50'
    | 'NEMA_14_30'
    | 'NEMA_14_50'
    | 'PANTOGRAPH_BOTTOM_UP'
    | 'PANTOGRAPH_TOP_DOWN'
    | 'TESLA_R'
    | 'TESLA_S'
  connector_format?: 'SOCKET' | 'CABLE'
  connector_power_type?: 'AC_1_PHASE' | 'AC_2_PHASE' | 'AC_2_PHASE_SPLIT' | 'AC_3_PHASE' | 'DC'
}
export type SignedValue = {
  nature: string
  plain_data: string
  signed_data: string
}
export type SignedData = {
  encoding_method: string
  encoding_method_version?: number
  public_key?: string
  signed_values: SignedValue[]
  url?: string
}
export type CdrDto = {
  id: string
  currency: string
  tariffs?: TariffDto[]
  remark?: string
  credit?: boolean
  country_code: string
  party_id: string
  last_updated: string
  start_date_time: string
  end_date_time: string
  session_id?: string
  cdr_token: CdrToken
  auth_method: 'AUTH_REQUEST' | 'COMMAND' | 'WHITELIST'
  authorization_reference?: string
  cdr_location: CdrLocation
  meter_id?: string
  charging_periods: ChargingPeriod[]
  signed_data?: SignedData
  total_cost: Price1
  total_fixed_cost?: Price1
  total_energy: number
  total_energy_cost?: Price1
  total_time: number
  total_time_cost?: Price1
  total_parking_time?: number
  total_parking_cost?: Price1
  total_reservation_cost?: Price1
  invoice_reference_id?: string
  invoice_url?: string
  credit_reference_id?: string
  home_charging_compensation?: boolean
}
export type LineItemRateDetail = {
  created?: string
  updated?: string
  createdBy?: string
  modifiedBy?: string
  logicalDeleteIn?: boolean
  lineItemRateDetailId?: string
  lineItemId?: string
  rateId?: string
  ratePeriodId?: string
  priceId?: string
  rateNm?: string
  rate?: number
  quantity?: number
  total?: number
  detailStart?: string
  detailEnd?: string
  lineItemType?: 'CHARGE' | 'CREDIT' | 'REFUND' | 'DISCOUNT' | 'REIMBURSEMENT' | 'COMMITMENT'
}
export type SynopLineItem = {
  created?: string
  updated?: string
  createdBy?: string
  modifiedBy?: string
  logicalDeleteIn?: boolean
  id?: string
  administeringOrgId?: string
  payorOrgId?: string
  lineItemType?: 'CHARGE' | 'CREDIT' | 'REFUND' | 'DISCOUNT' | 'REIMBURSEMENT' | 'COMMITMENT'
  payeeType?: 'ORGANIZATION' | 'USER'
  payeeId?: string
  payorType?: 'ORGANIZATION' | 'USER'
  payorId?: string
  productId?: string
  productNm?: string
  productType?:
    | 'WORKPLACE_CHARGING'
    | 'FLEET_CHARGING'
    | 'PUBLIC_CHARGING'
    | 'HOME_CHARGING'
    | 'VEHICLE_SUBSCRIPTION'
    | 'CHARGER_SUBSCRIPTION'
    | 'OTHER'
  priceId?: string
  priceDesc?: string
  priceType?: 'STRIPE' | 'SYNOP'
  measurand?: 'ENERGY_ADDED' | 'ENERGY_USED' | 'DISTANCE_DRIVEN' | 'TIME_USED' | 'COUNT'
  usageAmount?: number
  currency?: string
  usageUnits?: 'KWH' | 'MI' | 'KM' | 'MIN' | 'KW' | 'PC'
  usageStart?: string
  usageStartNote?: string
  usageEnd?: string
  usageEndNote?: string
  appliedRate?: number
  isBlended?: boolean
  rateDetail?: LineItemRateDetail[]
  chargeAmount?: number
  taxAmount?: number
  additionalFees?: number
  totalAmount?: number
  authTagType?: 'VEHICLE' | 'AD_HOC_USER' | 'APP_USER' | 'OTHER' | 'RFID'
  authTag?: string
  authTagExternalId?: string
  chargerId?: string
  connectorId?: string
  chargerNm?: string
  depotId?: string
  depotNm?: string
  vehicleId?: string
  vehicleNm?: string
  fleetId?: string
  usageEntityType?: 'SITE' | 'CHARGER' | 'CONNECTOR' | 'VEHICLE' | 'AUTH_TAG'
  usageEntityId?: string
  usageSourceId?: string
  billRunId?: string
  externalId?: string
  invoiceId?: string
  paymentId?: string
  chargeId?: string
  payoutId?: string
  receiptUrl?: string
}
export type User = {
  created?: string
  updated?: string
  createdBy?: string
  modifiedBy?: string
  logicalDeleteIn?: boolean
  id?: string
  name?: string
  email?: string
  phoneNumber?: string
  organizationId?: string
  rfidTag?: string
  termsAccepted?: boolean
  termsAcceptedDt?: string
  preferredTimeZone?: string
  preferredDistanceUnits?: string
  preferredTemperatureUnits?: string
  preferredEfficiencyUnits?: string
  preferredTimeFormat?: string
  preferredDateFormat?: string
  active?: boolean
  connectedAccountId?: string
  setupIntentId?: string
  customerId?: string
  externalId?: string
}
export type Vehicle = {
  id?: string
  vehicleNm?: string
  vin?: string
  make?: string
  model?: string
  modelYear?: number
  serialNumber?: string
  vehicleClass?: number
  vehicleType?: string
  batteryCapacity?: number
  estimatedRange?: number
  mpgEstimate?: number
  conventionalFuelType?: string
  purchaseDt?: string
  ocppTag?: string
  vehicleToGridCapable?: boolean
  efficiency?: number
  homeSiteId?: string
  homeSiteLatitude?: number
  homeSiteLongitude?: number
  providerId?: string
  active?: boolean
  externalId?: string
}
export type BillableTransactionEntity = {
  isMobileInitiated?: boolean
  userId?: string
  vehicleId?: string
  transactionId: string
  transactionPk?: number
  limitType?: 'TIME' | 'COST' | 'ENERGY' | 'STATE_OF_CHARGE'
  limitValue?: number
  siteId?: string
  chargerId?: string
  connectorId?: number
  authToken?: string
  tokenType?: 'VEHICLE' | 'AD_HOC_USER' | 'APP_USER' | 'OTHER' | 'RFID'
  lastEnergyImport?: SourceAttributeValue1
  lastEnergyExport?: SourceAttributeValue1
  lastCurrentImport?: SourceAttributeValue1
  lastCurrentExport?: SourceAttributeValue1
  lastPowerImport?: SourceAttributeValue1
  lastPowerExport?: SourceAttributeValue1
  lastVoltage?: SourceAttributeValue1
  lastSessionCost?: SourceAttributeValue1
  lastSoc?: SourceAttributeValue1
  chargeTimeRemaining?: SourceAttributeValue1
  isFinalized?: boolean
  isBillable?: boolean
  rate?: Rate
  applicableRate?: RatePeriod
  transactionSummary?: TransactionSummaryModel1
  charger?: ChargerModel1
  site?: SiteModel
  cdr?: CdrDto
  lineItem?: SynopLineItem
  requestingUser?: User
  requestingVehicle?: Vehicle
  paymentId?: string
  externalId?: string
  setupIntentId?: string
  paymentIntentId?: string
  additionalFees?: number
  receiptUrl?: string
  productType?:
    | 'WORKPLACE_CHARGING'
    | 'FLEET_CHARGING'
    | 'PUBLIC_CHARGING'
    | 'HOME_CHARGING'
    | 'VEHICLE_SUBSCRIPTION'
    | 'CHARGER_SUBSCRIPTION'
    | 'OTHER'
  payeeType?: 'ORGANIZATION' | 'USER'
  payeeId?: string
  payorType?: 'ORGANIZATION' | 'USER'
  payorId?: string
  payorOrgId?: string
  administeringOrgId?: string
  fleetId?: string
}
export type UpdateSessionRequest = {
  sessionId: string
  departureTime?: string
  limitType?: 'TIME' | 'COST' | 'ENERGY' | 'STATE_OF_CHARGE'
  limitValue?: number
}
export type StatusSchedule = {
  status:
    | 'Available'
    | 'Blocked'
    | 'Charging'
    | 'Inoperative'
    | 'OutOfOrder'
    | 'Planned'
    | 'Removed'
    | 'Reserved'
    | 'Unknown'
  period_begin: string
  period_end?: string
}
export type Connector = {
  tariff?: TariffDto
  currentPrice?: PriceComponent
  id: string
  connector_name?: string
  standard:
    | 'CHAdeMO'
    | 'J1772'
    | 'CCS1'
    | 'CCS2'
    | 'NACS'
    | 'Other'
    | 'Unknown'
    | 'CHADEMO'
    | 'CHAOJI'
    | 'DOMESTIC_A'
    | 'DOMESTIC_B'
    | 'DOMESTIC_C'
    | 'DOMESTIC_D'
    | 'DOMESTIC_E'
    | 'DOMESTIC_F'
    | 'DOMESTIC_G'
    | 'DOMESTIC_H'
    | 'DOMESTIC_I'
    | 'DOMESTIC_J'
    | 'DOMESTIC_K'
    | 'DOMESTIC_L'
    | 'DOMESTIC_M'
    | 'DOMESTIC_N'
    | 'DOMESTIC_O'
    | 'GBT_AC'
    | 'GBT_DC'
    | 'IEC_60309_2_single_16'
    | 'IEC_60309_2_three_16'
    | 'IEC_60309_2_three_32'
    | 'IEC_60309_2_three_64'
    | 'IEC_62196_T1'
    | 'IEC_62196_T1_COMBO'
    | 'IEC_62196_T2'
    | 'IEC_62196_T2_COMBO'
    | 'IEC_62196_T3A'
    | 'IEC_62196_T3C'
    | 'NEMA_5_20'
    | 'NEMA_6_30'
    | 'NEMA_6_50'
    | 'NEMA_10_30'
    | 'NEMA_10_50'
    | 'NEMA_14_30'
    | 'NEMA_14_50'
    | 'PANTOGRAPH_BOTTOM_UP'
    | 'PANTOGRAPH_TOP_DOWN'
    | 'TESLA_R'
    | 'TESLA_S'
  format: 'SOCKET' | 'CABLE'
  power_type: 'AC_1_PHASE' | 'AC_2_PHASE' | 'AC_2_PHASE_SPLIT' | 'AC_3_PHASE' | 'DC'
  max_voltage: number
  max_amperage: number
  max_electric_power?: number
  status:
    | 'Available'
    | 'Blocked'
    | 'Charging'
    | 'Inoperative'
    | 'OutOfOrder'
    | 'Planned'
    | 'Removed'
    | 'Reserved'
    | 'Unknown'
  tariff_ids?: string[]
  terms_and_conditions?: string
  last_updated: string
}
export type Evse = {
  tariff?: TariffDto
  currentPrice?: PriceComponent
  uid: string
  evse_id?: string
  status:
    | 'Available'
    | 'Blocked'
    | 'Charging'
    | 'Inoperative'
    | 'OutOfOrder'
    | 'Planned'
    | 'Removed'
    | 'Reserved'
    | 'Unknown'
  evse_name?: string
  status_schedule?: StatusSchedule[]
  capabilities?: (
    | 'CHARGING_PROFILE_CAPABLE'
    | 'CHARGING_PREFERENCES_CAPABLE'
    | 'CHIP_CARD_SUPPORT'
    | 'CONTACTLESS_CARD_SUPPORT'
    | 'CREDIT_CARD_PAYABLE'
    | 'DEBIT_CARD_PAYABLE'
    | 'PED_TERMINAL'
    | 'REMOTE_START_STOP_CAPABLE'
    | 'RESERVABLE'
    | 'RFID_READER'
    | 'START_SESSION_CONNECTOR_REQUIRED'
    | 'TOKEN_GROUP_CAPABLE'
    | 'UNLOCK_CAPABLE'
  )[]
  connectors: Connector[]
  floor_level?: string
  coordinates?: GeoLocation
  physical_reference?: string
  directions?: DisplayText[]
  parking_restrictions?: ('EV_ONLY' | 'PLUGGED' | 'DISABLED' | 'CUSTOMERS' | 'MOTORCYCLES')[]
  images?: Image[]
  last_updated: string
}
export type PublishTokenType = {
  uid?: string
  type?: 'VEHICLE' | 'AD_HOC_USER' | 'APP_USER' | 'OTHER' | 'RFID'
  visual_number?: string
  issuer?: string
  group_id?: string
}
export type AdditionalGeoLocation = {
  latitude: string
  longitude: string
  name?: DisplayText
}
export type RegularHours = {
  weekday: number
  period_begin: string
  period_end: string
}
export type ExceptionalPeriod = {
  period_begin: string
  period_end: string
}
export type Hours = {
  twentyfourseven: boolean
  regular_hours?: RegularHours[]
  exceptional_openings?: ExceptionalPeriod[]
  exceptional_closings?: ExceptionalPeriod[]
}
export type Location = {
  id: string
  publish: boolean
  name?: string
  address: string
  city: string
  state?: string
  country: string
  coordinates: GeoLocation
  evses?: Evse[]
  directions?: DisplayText[]
  operator?: BusinessDetails
  owner?: BusinessDetails
  facilities?: (
    | 'HOTEL'
    | 'RESTAURANT'
    | 'CAFE'
    | 'MALL'
    | 'SUPERMARKET'
    | 'SPORT'
    | 'RECREATION_AREA'
    | 'NATURE'
    | 'MUSEUM'
    | 'BIKE_SHARING'
    | 'BUS_STOP'
    | 'TAXI_STAND'
    | 'TRAM_STOP'
    | 'METRO_STATION'
    | 'TRAIN_STATION'
    | 'AIRPORT'
    | 'PARKING_LOT'
    | 'CARPOOL_PARKING'
    | 'FUEL_STATION'
    | 'WIFI'
    | 'RESTROOM'
  )[]
  images?: Image[]
  tariff?: TariffDto
  currentPrice?: PriceComponent
  totalConnectors?: number
  availableConnectors?: number
  organizationId?: string
  country_code: string
  party_id: string
  last_updated: string
  publish_allowed_to?: PublishTokenType[]
  postal_code?: string
  related_locations?: AdditionalGeoLocation[]
  parking_type?:
    | 'ALONG_MOTORWAY'
    | 'PARKING_GARAGE'
    | 'PARKING_LOT'
    | 'ON_DRIVEWAY'
    | 'ON_STREET'
    | 'UNDERGROUND_GARAGE'
  suboperator?: BusinessDetails
  time_zone: string
  opening_times?: Hours
  charging_when_closed?: boolean
  energy_mix?: EnergyMix
}
export type ResponseFormatLocation = {
  timestamp: string
  data?: Location
  totalItems?: number
  status_code: number
  status_message?: string
}
export type LocationData = object
export type ResponseFormatLocationData = {
  timestamp: string
  data?: LocationData
  totalItems?: number
  status_code: number
  status_message?: string
}
export type UpdateInvoiceLineItemModel = {
  belongsTo?: 'SUBSCRIPTION' | 'INVOICE'
  lineItemId?: string
  invoiceId?: string
  lineItemNm: string
  unitAmount: number
  quantity: number
  total?: number
}
export type InvoiceModel = {
  invoiceId: string
  invoiceUrl?: string
  invoicePdfUrl?: string
  billedOrganizationNm?: string
  billedOrganizationId: string
  billingOrganizationNm?: string
  billingOrganizationId: string
  description?: string
  statementDescriptor?: string
  subscriptionId?: string
  status?:
    | 'DRAFT'
    | 'OPEN'
    | 'UNCOLLECTIBLE'
    | 'VOID'
    | 'READY_FOR_REVIEW'
    | 'APPROVED'
    | 'PAID'
    | 'PAST_DUE'
    | 'ESTIMATE'
  currency?: string
  amountDue?: number
  amountPaid?: number
  asOf?: string
  dueDate?: string
  lineItems?: UpdateInvoiceLineItemModel[]
}
export type UpdateInvoiceModel = {
  merchantOrganizationId: string
  invoiceId: string
  customerOrganizationId: string
  collectionMethod?: 'CHARGE_AUTOMATICALLY' | 'SEND_INVOICE'
  description?: string
  statementDescriptor?: string
  dueDate?: string
  subscription?: string
  lineItems?: UpdateInvoiceLineItemModel[]
  allowCreditCard?: boolean
  allowAch?: boolean
}
export type CreateRateStructureModelListRatePeriod = {
  rateStatus?: 'ACTIVE' | 'INACTIVE'
  rateNm: string
  rateType: 'FLAT' | 'VOLUME_BASED' | 'TIME_OF_USE'
  rateStructure: RatePeriod[]
  OrganizationRateOverride?: OverrideRateStructureByOrganizationModelListRatePeriod
  lastModifiedByUserName?: string
  lastModifiedByUserId?: string
  lastModified?: string
  idleFee?: number
  idleStepSize?: number
  flatFee?: number
  maxSoc?: number
  maxTime?: number
}
export type ChargingRateModel = {
  administeringOrganizationId?: string
  billedOrganizationId?: string
  productType:
    | 'WORKPLACE_CHARGING'
    | 'FLEET_CHARGING'
    | 'PUBLIC_CHARGING'
    | 'HOME_CHARGING'
    | 'VEHICLE_SUBSCRIPTION'
    | 'CHARGER_SUBSCRIPTION'
    | 'OTHER'
  siteId: string
  defaultRateStructure: CreateRateStructureModelListRatePeriod
  rateMappingId: string
  overrides?: OverrideRateStructureModelListRatePeriod[]
}
export type UpdateChargingRate = {
  administeringOrganizationId?: string
  rateMappingId: string
  organizationId: string
  rateStatus?: 'ACTIVE' | 'INACTIVE'
  productType:
    | 'WORKPLACE_CHARGING'
    | 'FLEET_CHARGING'
    | 'PUBLIC_CHARGING'
    | 'HOME_CHARGING'
    | 'VEHICLE_SUBSCRIPTION'
    | 'CHARGER_SUBSCRIPTION'
    | 'OTHER'
  defaultRateStructure: RateStructureModelListRatePeriod
}
export type CreateWorkplaceChargingUser = {
  firstNm?: string
  lastNm?: string
  email?: string
  externalId?: string
  userId?: string
  organizationId?: string
  status?: 'CURRENT' | 'PAST_DUE' | 'PENDING'
  onboarded?: string
  ocppTags?: UserTagModel[]
}
export type WorkplaceChargingRateModelRateStructureModelListRatePeriod = {
  administeringOrganizationId?: string
  siteId: string
  defaultRateStructure: RateStructureModelListRatePeriod
  rateMappingId?: string
  overrides?: OverrideRateStructureModelListRatePeriod[]
}
export type CreateWorkplaceChargingRate = {
  administeringOrganizationId?: string
  siteId: string
  defaultRateStructure: CreateRateStructureModelListRatePeriod
}
export type OverrideRateStructureModel = {
  rateStatus?: 'ACTIVE' | 'INACTIVE'
  rateNm: string
  rateType: 'FLAT' | 'VOLUME_BASED' | 'TIME_OF_USE'
  rateStructure: RatePeriod[]
  OrganizationRateOverride?: OverrideRateStructureByOrganizationModelListRatePeriod
  lastModifiedByUserName?: string
  lastModifiedByUserId?: string
  lastModified?: string
  idleFee?: number
  idleStepSize?: number
  flatFee?: number
  maxSoc?: number
  maxTime?: number
  rateMappingId: string
  rateId?: string
  productId?: string
  overrideTargetType:
    | 'ORGANIZATION'
    | 'USER'
    | 'FLEET'
    | 'SITE'
    | 'CHARGER'
    | 'VEHICLE'
    | 'HOME_CHARGING_USER'
    | 'NETWORK'
  overrideTargetId: string
  overridesMappingId: string
}
export type WorkplaceConfiguration = {
  created?: string
  updated?: string
  createdBy?: string
  modifiedBy?: string
  logicalDeleteIn?: boolean
  organizationId?: string
  billingAnchor?: number
  billingCycle?: string
  administeredBy?: string
  transactionLevelSettlement?: boolean
  autoBill?: boolean
  prepayAmount?: number
  prepayThreshold?: number
  currentBalance?: number
  administeredByOrgName?: string
}
export type UpdateWorkplaceChargingRecordStatus = {
  status:
    | 'DRAFT'
    | 'OPEN'
    | 'UNCOLLECTIBLE'
    | 'VOID'
    | 'READY_FOR_REVIEW'
    | 'APPROVED'
    | 'PAID'
    | 'PAST_DUE'
    | 'ESTIMATE'
  periodStart: string
  workplaceChargingUserIds: string[]
}
export type PagedResponseSubscriptionModel = {
  first?: boolean
  last?: boolean
  pageSize?: number
  page?: number
  totalPages?: number
  totalItems?: number
  items?: SubscriptionModel[]
}
export type CreateSubscriptionLineItemModel = {
  productId?: string
  priceId?: string
  lineItemNm?: string
  unitAmount?: number
  quantity?: number
}
export type CreateSubscriptionModel = {
  merchantOrganizationId: string
  description?: string
  customerOrganizationId: string
  collectionMethod?: 'CHARGE_AUTOMATICALLY' | 'SEND_INVOICE'
  daysUntilDue?: number
  anchorDate?: string
  lineItems?: CreateSubscriptionLineItemModel[]
  commitments?: Commitment[]
  discounts?: Discount[]
  allowCreditCard?: boolean
  allowAch?: boolean
}
export type SubscriptionOperationRequest = {
  operation: 'PAUSE' | 'RESUME' | 'CANCEL'
  subscriptionId: string
}
export type ReimbursementUserModel = {
  id?: string
  firstNm?: string
  lastNm?: string
  email?: string
  externalId?: string
  zipCode?: string
  latitude?: number
  longitude?: number
  address?: string
  reimbursementRate?: number
  rateStructure?: CreateRateStructureModelListRatePeriod
  reimbursementMetric?: string
  reimbursementUnit?: string
  entityType: string
  entityId: string
  userId?: string
  organizationId: string
  status?: string
  onboarded?: string
}
export type ReimbursementUser = {
  created?: string
  updated?: string
  createdBy?: string
  modifiedBy?: string
  logicalDeleteIn?: boolean
  id?: string
  firstNm?: string
  lastNm?: string
  email?: string
  externalId?: string
  zipCode?: string
  address?: string
  latitude?: number
  longitude?: number
  reimbursementRate?: number
  reimbursementMetric?: string
  reimbursementUnit?: string
  entityId?: string
  entityType?: string
  userId?: string
  status?: string
  organizationId?: string
}
export type ReimbursementConfiguration = {
  created?: string
  updated?: string
  createdBy?: string
  modifiedBy?: string
  logicalDeleteIn?: boolean
  organizationId?: string
  billingAnchor?: number
  billingCycle?: string
  administeredBy?: string
  administeredByOrgName?: string
}
export type PagedResponseProductModel = {
  first?: boolean
  last?: boolean
  pageSize?: number
  page?: number
  totalPages?: number
  totalItems?: number
  items?: ProductModel[]
}
export type CreatePriceModelObject = {
  currency?: string
  recurring?: RecurringModel
  unitAmount?: number
  tiers?: TierModelObject[]
  tiersMode?: 'GRADUATED' | 'VOLUME' | 'TIME_OF_USE'
}
export type CreateProductModel = {
  productNm?: string
  description?: string
  statementDescriptor?: string
  unitLabel?: string
  taxCode?: string
  prices?: CreatePriceModelObject[]
}
export type StripeRedirectModel = {
  url?: string
  expires?: string
}
export type StopTransactionResponse = {
  id?: string
  transactionPk?: number
}
export type StopTransactionRequest = {
  transactionId: string
}
export type StartTransactionResponse = {
  id?: string
  transactionPk?: number
}
export type StartSessionRequest = {
  userId?: string
  chargerId: string
  connectorId: number
  authTag: string
  limitType?: 'TIME' | 'COST' | 'ENERGY' | 'STATE_OF_CHARGE'
  limitValue?: number
  setupIntentId?: string
}
export type PagedResponseInvoiceModel = {
  first?: boolean
  last?: boolean
  pageSize?: number
  page?: number
  totalPages?: number
  totalItems?: number
  items?: InvoiceModel[]
}
export type CreateInvoiceLineItemModel = {
  lineItemNm: string
  unitAmount: number
  quantity: number
}
export type CreateInvoiceModel = {
  merchantOrganizationId: string
  customerOrganizationId: string
  collectionMethod?: 'CHARGE_AUTOMATICALLY' | 'SEND_INVOICE'
  description?: string
  statementDescriptor?: string
  dueDate?: string
  subscription?: string
  lineItems?: CreateInvoiceLineItemModel[]
  allowCreditCard?: boolean
  allowAch?: boolean
}
export type InvoiceOperationRequest = {
  operation: 'VOID' | 'SEND' | 'FINALIZE'
  invoiceId: string
}
export type FleetChargingConfiguration = {
  created?: string
  updated?: string
  createdBy?: string
  modifiedBy?: string
  logicalDeleteIn?: boolean
  organizationId: string
  billingAnchor?: number
  transactionLevelSettlement?: boolean
  autoBill?: boolean
  prepayAmount?: number
  currentBalance?: number
  prepayThreshold?: number
  billingCycle?: string
  billingEmail?: string
  administeringOrganizationId: string
}
export type ChargingRateModelRateStructureModelListRatePeriod = {
  administeringOrganizationId?: string
  billedOrganizationId?: string
  productType:
    | 'WORKPLACE_CHARGING'
    | 'FLEET_CHARGING'
    | 'PUBLIC_CHARGING'
    | 'HOME_CHARGING'
    | 'VEHICLE_SUBSCRIPTION'
    | 'CHARGER_SUBSCRIPTION'
    | 'OTHER'
  siteId: string
  defaultRateStructure: CreateRateStructureModelListRatePeriod
  rateMappingId: string
  overrides?: OverrideRateStructureModelListRatePeriod[]
}
export type CreateChargingRate = {
  administeringOrganizationId?: string
  billedOrganizationId?: string
  productType:
    | 'WORKPLACE_CHARGING'
    | 'FLEET_CHARGING'
    | 'PUBLIC_CHARGING'
    | 'HOME_CHARGING'
    | 'VEHICLE_SUBSCRIPTION'
    | 'CHARGER_SUBSCRIPTION'
    | 'OTHER'
  siteId: string
  defaultRateStructure: CreateRateStructureModelListRatePeriod
}
export type BulkReimbursementConfiguration = {
  rate?: number
  reimbursementUserIds?: string[]
}
export type StripeResponse = object
export type JsonNull = {
  asInt?: number
  asDouble?: number
  asLong?: number
  asBoolean?: boolean
  asString?: string
  asJsonObject?: JsonObject
  asJsonPrimitive?: JsonPrimitive
  jsonArray?: boolean
  jsonObject?: boolean
  jsonPrimitive?: boolean
  jsonNull?: boolean
  asJsonArray?: JsonArray
  asJsonNull?: JsonNull
  asNumber?: number
  asFloat?: number
  asByte?: string
  asCharacter?: string
  asBigDecimal?: number
  asBigInteger?: number
  asShort?: number
}
export type JsonArray = {
  empty?: boolean
  asInt?: number
  asDouble?: number
  asLong?: number
  asBoolean?: boolean
  asString?: string
  asNumber?: number
  asFloat?: number
  asByte?: string
  asCharacter?: string
  asBigDecimal?: number
  asBigInteger?: number
  asShort?: number
  asJsonObject?: JsonObject
  asJsonPrimitive?: JsonPrimitive
  jsonArray?: boolean
  jsonObject?: boolean
  jsonPrimitive?: boolean
  jsonNull?: boolean
  asJsonArray?: JsonArray
  asJsonNull?: JsonNull
}
export type JsonPrimitive = {
  asInt?: number
  asDouble?: number
  asLong?: number
  asBoolean?: boolean
  boolean?: boolean
  number?: boolean
  string?: boolean
  asString?: string
  asNumber?: number
  asFloat?: number
  asByte?: string
  asCharacter?: string
  asBigDecimal?: number
  asBigInteger?: number
  asShort?: number
  asJsonObject?: JsonObject
  asJsonPrimitive?: JsonPrimitive
  jsonArray?: boolean
  jsonObject?: boolean
  jsonPrimitive?: boolean
  jsonNull?: boolean
  asJsonArray?: JsonArray
  asJsonNull?: JsonNull
}
export type JsonObject = {
  empty?: boolean
  asInt?: number
  asDouble?: number
  asLong?: number
  asBoolean?: boolean
  asString?: string
  asJsonObject?: JsonObject
  asJsonPrimitive?: JsonPrimitive
  jsonArray?: boolean
  jsonObject?: boolean
  jsonPrimitive?: boolean
  jsonNull?: boolean
  asJsonArray?: JsonArray
  asJsonNull?: JsonNull
  asNumber?: number
  asFloat?: number
  asByte?: string
  asCharacter?: string
  asBigDecimal?: number
  asBigInteger?: number
  asShort?: number
}
export type AutomaticPaymentMethods = {
  allowRedirects?: string
  enabled?: boolean
  lastResponse?: StripeResponse
  rawJsonObject?: JsonObject
}
export type Tip = {
  amount?: number
  lastResponse?: StripeResponse
  rawJsonObject?: JsonObject
}
export type AmountDetails = {
  tip?: Tip
  lastResponse?: StripeResponse
  rawJsonObject?: JsonObject
}
export type AlipayHandleRedirect = {
  nativeData?: string
  nativeUrl?: string
  returnUrl?: string
  url?: string
  lastResponse?: StripeResponse
  rawJsonObject?: JsonObject
}
export type BoletoDisplayDetails = {
  expiresAt?: number
  hostedVoucherUrl?: string
  number?: string
  pdf?: string
  lastResponse?: StripeResponse
  rawJsonObject?: JsonObject
}
export type CardAwaitNotification = {
  chargeAttemptAt?: number
  customerApprovalRequired?: boolean
  lastResponse?: StripeResponse
  rawJsonObject?: JsonObject
}
export type QrCode = {
  data?: string
  imageUrlPng?: string
  imageUrlSvg?: string
  lastResponse?: StripeResponse
  rawJsonObject?: JsonObject
}
export type CashappHandleRedirectOrDisplayQrCode = {
  hostedInstructionsUrl?: string
  mobileAuthUrl?: string
  qrCode?: QrCode
  lastResponse?: StripeResponse
  rawJsonObject?: JsonObject
}
export type Aba = {
  accountNumber?: string
  bankName?: string
  routingNumber?: string
  lastResponse?: StripeResponse
  rawJsonObject?: JsonObject
}
export type Iban = {
  accountHolderName?: string
  bic?: string
  country?: string
  iban?: string
  lastResponse?: StripeResponse
  rawJsonObject?: JsonObject
}
export type SortCode = {
  accountHolderName?: string
  accountNumber?: string
  sortCode?: string
  lastResponse?: StripeResponse
  rawJsonObject?: JsonObject
}
export type Spei = {
  bankCode?: string
  bankName?: string
  clabe?: string
  lastResponse?: StripeResponse
  rawJsonObject?: JsonObject
}
export type Swift = {
  accountNumber?: string
  bankName?: string
  swiftCode?: string
  lastResponse?: StripeResponse
  rawJsonObject?: JsonObject
}
export type Zengin = {
  accountHolderName?: string
  accountNumber?: string
  accountType?: string
  bankCode?: string
  bankName?: string
  branchCode?: string
  branchName?: string
  lastResponse?: StripeResponse
  rawJsonObject?: JsonObject
}
export type FinancialAddress = {
  aba?: Aba
  iban?: Iban
  sortCode?: SortCode
  spei?: Spei
  supportedNetworks?: string[]
  swift?: Swift
  type?: string
  zengin?: Zengin
  lastResponse?: StripeResponse
  rawJsonObject?: JsonObject
}
export type DisplayBankTransferInstructions = {
  amountRemaining?: number
  currency?: string
  financialAddresses?: FinancialAddress[]
  hostedInstructionsUrl?: string
  reference?: string
  type?: string
  lastResponse?: StripeResponse
  rawJsonObject?: JsonObject
}
export type Familymart = {
  confirmationNumber?: string
  paymentCode?: string
  lastResponse?: StripeResponse
  rawJsonObject?: JsonObject
}
export type Lawson = {
  confirmationNumber?: string
  paymentCode?: string
  lastResponse?: StripeResponse
  rawJsonObject?: JsonObject
}
export type Ministop = {
  confirmationNumber?: string
  paymentCode?: string
  lastResponse?: StripeResponse
  rawJsonObject?: JsonObject
}
export type Seicomart = {
  confirmationNumber?: string
  paymentCode?: string
  lastResponse?: StripeResponse
  rawJsonObject?: JsonObject
}
export type Stores = {
  familymart?: Familymart
  lawson?: Lawson
  ministop?: Ministop
  seicomart?: Seicomart
  lastResponse?: StripeResponse
  rawJsonObject?: JsonObject
}
export type KonbiniDisplayDetails = {
  expiresAt?: number
  hostedVoucherUrl?: string
  stores?: Stores
  lastResponse?: StripeResponse
  rawJsonObject?: JsonObject
}
export type MultibancoDisplayDetails = {
  entity?: string
  expiresAt?: number
  hostedVoucherUrl?: string
  reference?: string
  lastResponse?: StripeResponse
  rawJsonObject?: JsonObject
}
export type OxxoDisplayDetails = {
  expiresAfter?: number
  hostedVoucherUrl?: string
  number?: string
  lastResponse?: StripeResponse
  rawJsonObject?: JsonObject
}
export type PaynowDisplayQrCode = {
  data?: string
  hostedInstructionsUrl?: string
  imageUrlPng?: string
  imageUrlSvg?: string
  lastResponse?: StripeResponse
  rawJsonObject?: JsonObject
}
export type PixDisplayQrCode = {
  data?: string
  expiresAt?: number
  hostedInstructionsUrl?: string
  imageUrlPng?: string
  imageUrlSvg?: string
  lastResponse?: StripeResponse
  rawJsonObject?: JsonObject
}
export type PromptpayDisplayQrCode = {
  data?: string
  hostedInstructionsUrl?: string
  imageUrlPng?: string
  imageUrlSvg?: string
  lastResponse?: StripeResponse
  rawJsonObject?: JsonObject
}
export type RedirectToUrl = {
  returnUrl?: string
  url?: string
  lastResponse?: StripeResponse
  rawJsonObject?: JsonObject
}
export type SwishHandleRedirectOrDisplayQrCode = {
  hostedInstructionsUrl?: string
  mobileAuthUrl?: string
  qrCode?: QrCode
  lastResponse?: StripeResponse
  rawJsonObject?: JsonObject
}
export type VerifyWithMicrodeposits = {
  arrivalDate?: number
  hostedVerificationUrl?: string
  microdepositType?: string
  lastResponse?: StripeResponse
  rawJsonObject?: JsonObject
}
export type WechatPayDisplayQrCode = {
  data?: string
  hostedInstructionsUrl?: string
  imageDataUrl?: string
  imageUrlPng?: string
  imageUrlSvg?: string
  lastResponse?: StripeResponse
  rawJsonObject?: JsonObject
}
export type WechatPayRedirectToAndroidApp = {
  appId?: string
  nonceStr?: string
  partnerId?: string
  prepayId?: string
  sign?: string
  timestamp?: string
  package_?: string
  package?: string
  lastResponse?: StripeResponse
  rawJsonObject?: JsonObject
}
export type WechatPayRedirectToIosApp = {
  nativeUrl?: string
  lastResponse?: StripeResponse
  rawJsonObject?: JsonObject
}
export type NextAction = {
  alipayHandleRedirect?: AlipayHandleRedirect
  boletoDisplayDetails?: BoletoDisplayDetails
  cardAwaitNotification?: CardAwaitNotification
  cashappHandleRedirectOrDisplayQrCode?: CashappHandleRedirectOrDisplayQrCode
  displayBankTransferInstructions?: DisplayBankTransferInstructions
  konbiniDisplayDetails?: KonbiniDisplayDetails
  multibancoDisplayDetails?: MultibancoDisplayDetails
  oxxoDisplayDetails?: OxxoDisplayDetails
  paynowDisplayQrCode?: PaynowDisplayQrCode
  pixDisplayQrCode?: PixDisplayQrCode
  promptpayDisplayQrCode?: PromptpayDisplayQrCode
  redirectToUrl?: RedirectToUrl
  swishHandleRedirectOrDisplayQrCode?: SwishHandleRedirectOrDisplayQrCode
  type?: string
  useStripeSdk?: {
    [key: string]: object
  }
  verifyWithMicrodeposits?: VerifyWithMicrodeposits
  wechatPayDisplayQrCode?: WechatPayDisplayQrCode
  wechatPayRedirectToAndroidApp?: WechatPayRedirectToAndroidApp
  wechatPayRedirectToIosApp?: WechatPayRedirectToIosApp
  lastResponse?: StripeResponse
  rawJsonObject?: JsonObject
}
export type PaymentMethodConfigurationDetails = {
  id?: string
  parent?: string
  lastResponse?: StripeResponse
  rawJsonObject?: JsonObject
}
export type MandateOptions = {
  collectionMethod?: string
  lastResponse?: StripeResponse
  rawJsonObject?: JsonObject
}
export type AcssDebit = {
  mandateOptions?: MandateOptions
  setupFutureUsage?: string
  verificationMethod?: string
  lastResponse?: StripeResponse
  rawJsonObject?: JsonObject
}
export type Affirm = {
  captureMethod?: string
  preferredLocale?: string
  setupFutureUsage?: string
  lastResponse?: StripeResponse
  rawJsonObject?: JsonObject
}
export type AfterpayClearpay = {
  captureMethod?: string
  reference?: string
  setupFutureUsage?: string
  lastResponse?: StripeResponse
  rawJsonObject?: JsonObject
}
export type Alipay = {
  setupFutureUsage?: string
  lastResponse?: StripeResponse
  rawJsonObject?: JsonObject
}
export type AmazonPay = {
  captureMethod?: string
  setupFutureUsage?: string
  lastResponse?: StripeResponse
  rawJsonObject?: JsonObject
}
export type AuBecsDebit = {
  setupFutureUsage?: string
  lastResponse?: StripeResponse
  rawJsonObject?: JsonObject
}
export type BacsDebit = {
  setupFutureUsage?: string
  lastResponse?: StripeResponse
  rawJsonObject?: JsonObject
}
export type Address = {
  city?: string
  country?: string
  line1?: string
  line2?: string
  postalCode?: string
  state?: string
  lastResponse?: StripeResponse
  rawJsonObject?: JsonObject
}
export type BillingDetails = {
  address?: Address
  email?: string
  name?: string
  phone?: string
  lastResponse?: StripeResponse
  rawJsonObject?: JsonObject
}
export type Blik = {
  setupFutureUsage?: string
  lastResponse?: StripeResponse
  rawJsonObject?: JsonObject
}
export type Boleto = {
  lastResponse?: StripeResponse
  rawJsonObject?: JsonObject
}
export type Checks = {
  addressLine1Check?: string
  addressPostalCodeCheck?: string
  cvcCheck?: string
  lastResponse?: StripeResponse
  rawJsonObject?: JsonObject
}
export type Offline = {
  storedAt?: number
  lastResponse?: StripeResponse
  rawJsonObject?: JsonObject
}
export type CardPresent = {
  generatedCard?: string
  offline?: Offline
  generatedCardObject?: PaymentMethod
  lastResponse?: StripeResponse
  rawJsonObject?: JsonObject
}
export type Cashapp = {
  lastResponse?: StripeResponse
  rawJsonObject?: JsonObject
}
export type Online = {
  ipAddress?: string
  userAgent?: string
  lastResponse?: StripeResponse
  rawJsonObject?: JsonObject
}
export type CustomerAcceptance = {
  acceptedAt?: number
  offline?: Offline
  online?: Online
  type?: string
  lastResponse?: StripeResponse
  rawJsonObject?: JsonObject
}
export type MultiUse = {
  lastResponse?: StripeResponse
  rawJsonObject?: JsonObject
}
export type SingleUse = {
  amount?: number
  currency?: string
  lastResponse?: StripeResponse
  rawJsonObject?: JsonObject
}
export type StripeResponseGetter = object
export type Mandate = {
  customerAcceptance?: CustomerAcceptance
  id?: string
  livemode?: boolean
  multiUse?: MultiUse
  object?: string
  onBehalfOf?: string
  paymentMethod?: string
  paymentMethodDetails?: PaymentMethodDetails
  singleUse?: SingleUse
  status?: string
  type?: string
  paymentMethodObject?: PaymentMethod
  responseGetter?: StripeResponseGetter
  lastResponse?: StripeResponse
  rawJsonObject?: JsonObject
}
export type Ideal = {
  bank?: string
  bic?: string
  generatedSepaDebit?: string
  generatedSepaDebitMandate?: string
  ibanLast4?: string
  verifiedName?: string
  generatedSepaDebitObject?: PaymentMethod
  generatedSepaDebitMandateObject?: Mandate
  lastResponse?: StripeResponse
  rawJsonObject?: JsonObject
}
export type Klarna = {
  lastResponse?: StripeResponse
  rawJsonObject?: JsonObject
}
export type Link = {
  lastResponse?: StripeResponse
  rawJsonObject?: JsonObject
}
export type Paypal = {
  lastResponse?: StripeResponse
  rawJsonObject?: JsonObject
}
export type RevolutPay = {
  lastResponse?: StripeResponse
  rawJsonObject?: JsonObject
}
export type SepaDebit = {
  lastResponse?: StripeResponse
  rawJsonObject?: JsonObject
}
export type Sofort = {
  bankCode?: string
  bankName?: string
  bic?: string
  generatedSepaDebit?: string
  generatedSepaDebitMandate?: string
  ibanLast4?: string
  preferredLanguage?: string
  verifiedName?: string
  generatedSepaDebitObject?: PaymentMethod
  generatedSepaDebitMandateObject?: Mandate
  lastResponse?: StripeResponse
  rawJsonObject?: JsonObject
}
export type UsBankAccount = {
  lastResponse?: StripeResponse
  rawJsonObject?: JsonObject
}
export type PaymentMethodDetails = {
  acssDebit?: AcssDebit
  amazonPay?: AmazonPay
  auBecsDebit?: AuBecsDebit
  bacsDebit?: BacsDebit
  bancontact?: Bancontact
  boleto?: Boleto
  card?: Card
  cardPresent?: CardPresent
  cashapp?: Cashapp
  ideal?: Ideal
  klarna?: Klarna
  link?: Link
  paypal?: Paypal
  revolutPay?: RevolutPay
  sepaDebit?: SepaDebit
  sofort?: Sofort
  type?: string
  usBankAccount?: UsBankAccount
  lastResponse?: StripeResponse
  rawJsonObject?: JsonObject
}
export type Application = {
  deleted?: boolean
  id?: string
  name?: string
  object?: string
  lastResponse?: StripeResponse
  rawJsonObject?: JsonObject
}
export type BacsDebitPayments = {
  displayName?: string
  serviceUserNumber?: string
  lastResponse?: StripeResponse
  rawJsonObject?: JsonObject
}
export type FileLink = {
  created?: number
  expired?: boolean
  expiresAt?: number
  file?: string
  id?: string
  livemode?: boolean
  metadata?: {
    [key: string]: string
  }
  object?: string
  url?: string
  fileObject?: File
  responseGetter?: StripeResponseGetter
  lastResponse?: StripeResponse
  rawJsonObject?: JsonObject
}
export type RequestOptions = {
  apiKey?: string
  clientId?: string
  idempotencyKey?: string
  stripeAccount?: string
  baseUrl?: string
  stripeVersion?: string
  connectTimeout?: number
  readTimeout?: number
  maxNetworkRetries?: number
  connectionProxy?: object
  proxyCredential?: {
    userName?: string
    password?: string[]
  }
}
export type FileLinkCollection = {
  object?: string
  data?: FileLink[]
  hasMore?: boolean
  url?: string
  requestParams?: {
    [key: string]: object
  }
  requestOptions?: RequestOptions
  pageTypeToken?: {
    typeName?: string
  }
  responseGetter?: StripeResponseGetter
  lastResponse?: StripeResponse
  rawJsonObject?: JsonObject
}
export type File = {
  created?: number
  expiresAt?: number
  filename?: string
  id?: string
  links?: FileLinkCollection
  object?: string
  purpose?: string
  size?: number
  title?: string
  type?: string
  url?: string
  responseGetter?: StripeResponseGetter
  lastResponse?: StripeResponse
  rawJsonObject?: JsonObject
}
export type Branding = {
  icon?: string
  logo?: string
  primaryColor?: string
  secondaryColor?: string
  iconObject?: File
  logoObject?: File
  lastResponse?: StripeResponse
  rawJsonObject?: JsonObject
}
export type TosAcceptance = {
  date?: number
  ip?: string
  serviceAgreement?: string
  userAgent?: string
  lastResponse?: StripeResponse
  rawJsonObject?: JsonObject
}
export type CardIssuing = {
  tosAcceptance?: TosAcceptance
  lastResponse?: StripeResponse
  rawJsonObject?: JsonObject
}
export type DeclineOn = {
  avsFailure?: boolean
  cvcFailure?: boolean
  lastResponse?: StripeResponse
  rawJsonObject?: JsonObject
}
export type CardPayments = {
  declineOn?: DeclineOn
  statementDescriptorPrefix?: string
  statementDescriptorPrefixKana?: string
  statementDescriptorPrefixKanji?: string
  lastResponse?: StripeResponse
  rawJsonObject?: JsonObject
}
export type Dashboard = {
  displayName?: string
  timezone?: string
  lastResponse?: StripeResponse
  rawJsonObject?: JsonObject
}
export type AnnualRevenue = {
  amount?: number
  currency?: string
  fiscalYearEnd?: string
  lastResponse?: StripeResponse
  rawJsonObject?: JsonObject
}
export type MonthlyEstimatedRevenue = {
  amount?: number
  currency?: string
  lastResponse?: StripeResponse
  rawJsonObject?: JsonObject
}
export type BusinessProfile = {
  annualRevenue?: AnnualRevenue
  estimatedWorkerCount?: number
  mcc?: string
  monthlyEstimatedRevenue?: MonthlyEstimatedRevenue
  name?: string
  productDescription?: string
  supportAddress?: Address
  supportEmail?: string
  supportPhone?: string
  supportUrl?: string
  url?: string
  lastResponse?: StripeResponse
  rawJsonObject?: JsonObject
}
export type Capabilities = {
  acssDebitPayments?: string
  affirmPayments?: string
  afterpayClearpayPayments?: string
  amazonPayPayments?: string
  auBecsDebitPayments?: string
  bacsDebitPayments?: string
  bancontactPayments?: string
  bankTransferPayments?: string
  blikPayments?: string
  boletoPayments?: string
  cardIssuing?: string
  cardPayments?: string
  cartesBancairesPayments?: string
  cashappPayments?: string
  epsPayments?: string
  fpxPayments?: string
  gbBankTransferPayments?: string
  giropayPayments?: string
  grabpayPayments?: string
  idealPayments?: string
  indiaInternationalPayments?: string
  jcbPayments?: string
  jpBankTransferPayments?: string
  klarnaPayments?: string
  konbiniPayments?: string
  legacyPayments?: string
  linkPayments?: string
  mobilepayPayments?: string
  multibancoPayments?: string
  mxBankTransferPayments?: string
  oxxoPayments?: string
  p24Payments?: string
  paynowPayments?: string
  promptpayPayments?: string
  revolutPayPayments?: string
  sepaBankTransferPayments?: string
  sepaDebitPayments?: string
  sofortPayments?: string
  swishPayments?: string
  taxReportingUs1099K?: string
  taxReportingUs1099Misc?: string
  transfers?: string
  treasury?: string
  twintPayments?: string
  usBankAccountAchPayments?: string
  usBankTransferPayments?: string
  zipPayments?: string
  lastResponse?: StripeResponse
  rawJsonObject?: JsonObject
}
export type AddressKana = {
  city?: string
  country?: string
  line1?: string
  line2?: string
  postalCode?: string
  state?: string
  town?: string
  lastResponse?: StripeResponse
  rawJsonObject?: JsonObject
}
export type AddressKanji = {
  city?: string
  country?: string
  line1?: string
  line2?: string
  postalCode?: string
  state?: string
  town?: string
  lastResponse?: StripeResponse
  rawJsonObject?: JsonObject
}
export type OwnershipDeclaration = {
  date?: number
  ip?: string
  userAgent?: string
  lastResponse?: StripeResponse
  rawJsonObject?: JsonObject
}
export type Verification = {
  status?: string
  verifiedAddress?: string
  verifiedName?: string
  lastResponse?: StripeResponse
  rawJsonObject?: JsonObject
}
export type Company = {
  address?: Address
  addressKana?: AddressKana
  addressKanji?: AddressKanji
  directorsProvided?: boolean
  executivesProvided?: boolean
  exportLicenseId?: string
  exportPurposeCode?: string
  name?: string
  nameKana?: string
  nameKanji?: string
  ownersProvided?: boolean
  ownershipDeclaration?: OwnershipDeclaration
  phone?: string
  structure?: string
  taxIdProvided?: boolean
  taxIdRegistrar?: string
  vatIdProvided?: boolean
  verification?: Verification
  lastResponse?: StripeResponse
  rawJsonObject?: JsonObject
}
export type Fees = {
  payer?: string
  lastResponse?: StripeResponse
  rawJsonObject?: JsonObject
}
export type Losses = {
  payments?: string
  lastResponse?: StripeResponse
  rawJsonObject?: JsonObject
}
export type StripeDashboard = {
  type?: string
  lastResponse?: StripeResponse
  rawJsonObject?: JsonObject
}
export type Controller = {
  fees?: Fees
  isController?: boolean
  losses?: Losses
  requirementCollection?: string
  stripeDashboard?: StripeDashboard
  type?: string
  lastResponse?: StripeResponse
  rawJsonObject?: JsonObject
}
export type ExternalAccount = {
  lastResponse?: StripeResponse
  id?: string
}
export type ExternalAccountCollection = {
  object?: string
  data?: ExternalAccount[]
  hasMore?: boolean
  url?: string
  requestParams?: {
    [key: string]: object
  }
  requestOptions?: RequestOptions
  pageTypeToken?: {
    typeName?: string
  }
  responseGetter?: StripeResponseGetter
  lastResponse?: StripeResponse
  rawJsonObject?: JsonObject
}
export type Alternative = {
  alternativeFieldsDue?: string[]
  originalFieldsDue?: string[]
  lastResponse?: StripeResponse
  rawJsonObject?: JsonObject
}
export type Errors = {
  code?: string
  reason?: string
  requirement?: string
  lastResponse?: StripeResponse
  rawJsonObject?: JsonObject
}
export type FutureRequirements = {
  alternatives?: Alternative[]
  currentlyDue?: string[]
  errors?: Errors[]
  eventuallyDue?: string[]
  pastDue?: string[]
  pendingVerification?: string[]
  lastResponse?: StripeResponse
  rawJsonObject?: JsonObject
}
export type AdditionalTosAcceptances = {
  account?: Account
  lastResponse?: StripeResponse
  rawJsonObject?: JsonObject
}
export type Dob = {
  day?: number
  month?: number
  year?: number
  lastResponse?: StripeResponse
  rawJsonObject?: JsonObject
}
export type Relationship = {
  director?: boolean
  executive?: boolean
  legalGuardian?: boolean
  owner?: boolean
  percentOwnership?: number
  representative?: boolean
  title?: string
  lastResponse?: StripeResponse
  rawJsonObject?: JsonObject
}
export type Requirements = {
  alternatives?: Alternative[]
  currentDeadline?: number
  currentlyDue?: string[]
  disabledReason?: string
  errors?: Errors[]
  eventuallyDue?: string[]
  pastDue?: string[]
  pendingVerification?: string[]
  lastResponse?: StripeResponse
  rawJsonObject?: JsonObject
}
export type Person = {
  account?: string
  additionalTosAcceptances?: AdditionalTosAcceptances
  address?: Address
  addressKana?: AddressKana
  addressKanji?: AddressKanji
  created?: number
  deleted?: boolean
  dob?: Dob
  email?: string
  firstName?: string
  firstNameKana?: string
  firstNameKanji?: string
  fullNameAliases?: string[]
  futureRequirements?: FutureRequirements
  gender?: string
  id?: string
  idNumberProvided?: boolean
  idNumberSecondaryProvided?: boolean
  lastName?: string
  lastNameKana?: string
  lastNameKanji?: string
  maidenName?: string
  metadata?: {
    [key: string]: string
  }
  nationality?: string
  object?: string
  phone?: string
  politicalExposure?: string
  registeredAddress?: Address
  relationship?: Relationship
  requirements?: Requirements
  ssnLast4Provided?: boolean
  verification?: Verification
  responseGetter?: StripeResponseGetter
  lastResponse?: StripeResponse
  rawJsonObject?: JsonObject
}
export type Account = {
  businessProfile?: BusinessProfile
  businessType?: string
  capabilities?: Capabilities
  chargesEnabled?: boolean
  company?: Company
  controller?: Controller
  country?: string
  created?: number
  defaultCurrency?: string
  deleted?: boolean
  detailsSubmitted?: boolean
  email?: string
  externalAccounts?: ExternalAccountCollection
  futureRequirements?: FutureRequirements
  id?: string
  individual?: Person
  metadata?: {
    [key: string]: string
  }
  object?: string
  payoutsEnabled?: boolean
  requirements?: Requirements
  settings?: Settings
  tosAcceptance?: TosAcceptance
  type?: string
  responseGetter?: StripeResponseGetter
  lastResponse?: StripeResponse
  rawJsonObject?: JsonObject
}
export type Owner = {
  account?: string
  application?: string
  customer?: string
  type?: string
  accountObject?: Account
  applicationObject?: Application
  customerObject?: Customer1
  lastResponse?: StripeResponse
  rawJsonObject?: JsonObject
}
export type TaxId = {
  country?: string
  created?: number
  customer?: string
  deleted?: boolean
  id?: string
  livemode?: boolean
  object?: string
  owner?: Owner
  type?: string
  value?: string
  verification?: Verification
  responseGetter?: StripeResponseGetter
  customerObject?: Customer1
  lastResponse?: StripeResponse
  rawJsonObject?: JsonObject
}
export type Invoices = {
  defaultAccountTaxIds?: string[]
  defaultAccountTaxIdObjects?: TaxId[]
  lastResponse?: StripeResponse
  rawJsonObject?: JsonObject
}
export type Payments = {
  statementDescriptor?: string
  statementDescriptorKana?: string
  statementDescriptorKanji?: string
  statementDescriptorPrefixKana?: string
  statementDescriptorPrefixKanji?: string
  lastResponse?: StripeResponse
  rawJsonObject?: JsonObject
}
export type Schedule = {
  delayDays?: number
  interval?: string
  monthlyAnchor?: number
  weeklyAnchor?: string
  lastResponse?: StripeResponse
  rawJsonObject?: JsonObject
}
export type Payouts = {
  debitNegativeBalances?: boolean
  schedule?: Schedule
  statementDescriptor?: string
  lastResponse?: StripeResponse
  rawJsonObject?: JsonObject
}
export type SepaDebitPayments = {
  creditorId?: string
  lastResponse?: StripeResponse
  rawJsonObject?: JsonObject
}
export type Treasury = {
  tosAcceptance?: TosAcceptance
  lastResponse?: StripeResponse
  rawJsonObject?: JsonObject
}
export type Settings = {
  bacsDebitPayments?: BacsDebitPayments
  branding?: Branding
  cardIssuing?: CardIssuing
  cardPayments?: CardPayments
  dashboard?: Dashboard
  invoices?: Invoices
  payments?: Payments
  payouts?: Payouts
  sepaDebitPayments?: SepaDebitPayments
  treasury?: Treasury
  lastResponse?: StripeResponse
  rawJsonObject?: JsonObject
}
export type CashBalance = {
  available?: {
    [key: string]: number
  }
  customer?: string
  livemode?: boolean
  object?: string
  settings?: Settings
  responseGetter?: StripeResponseGetter
  lastResponse?: StripeResponse
  rawJsonObject?: JsonObject
}
export type Issuer = {
  account?: string
  type?: string
  accountObject?: Account
  lastResponse?: StripeResponse
  rawJsonObject?: JsonObject
}
export type InvoiceSettings = {
  accountTaxIds?: string[]
  daysUntilDue?: number
  issuer?: Issuer
  lastResponse?: StripeResponse
  rawJsonObject?: JsonObject
}
export type ShippingDetails = {
  address?: Address
  carrier?: string
  name?: string
  phone?: string
  trackingNumber?: string
  lastResponse?: StripeResponse
  rawJsonObject?: JsonObject
}
export type PaymentSource = {
  lastResponse?: StripeResponse
  id?: string
}
export type PaymentSourceCollection = {
  object?: string
  data?: PaymentSource[]
  hasMore?: boolean
  url?: string
  requestParams?: {
    [key: string]: object
  }
  requestOptions?: RequestOptions
  pageTypeToken?: {
    typeName?: string
  }
  responseGetter?: StripeResponseGetter
  lastResponse?: StripeResponse
  rawJsonObject?: JsonObject
}
export type Liability = {
  account?: string
  type?: string
  accountObject?: Account
  lastResponse?: StripeResponse
  rawJsonObject?: JsonObject
}
export type AutomaticTax = {
  enabled?: boolean
  liability?: Liability
  lastResponse?: StripeResponse
  rawJsonObject?: JsonObject
}
export type BillingCycleAnchorConfig = {
  dayOfMonth?: number
  hour?: number
  minute?: number
  month?: number
  second?: number
  lastResponse?: StripeResponse
  rawJsonObject?: JsonObject
}
export type BillingThresholds = {
  usageGte?: number
  lastResponse?: StripeResponse
  rawJsonObject?: JsonObject
}
export type CancellationDetails = {
  comment?: string
  feedback?: string
  reason?: string
  lastResponse?: StripeResponse
  rawJsonObject?: JsonObject
}
export type TaxRate = {
  active?: boolean
  country?: string
  created?: number
  description?: string
  displayName?: string
  effectivePercentage?: number
  id?: string
  inclusive?: boolean
  jurisdiction?: string
  jurisdictionLevel?: string
  livemode?: boolean
  metadata?: {
    [key: string]: string
  }
  object?: string
  percentage?: number
  state?: string
  taxType?: string
  responseGetter?: StripeResponseGetter
  lastResponse?: StripeResponse
  rawJsonObject?: JsonObject
}
export type Tier = {
  flatAmount?: number
  flatAmountDecimal?: number
  unitAmount?: number
  unitAmountDecimal?: number
  upTo?: number
  lastResponse?: StripeResponse
  rawJsonObject?: JsonObject
}
export type TransformUsage = {
  divideBy?: number
  round?: string
  lastResponse?: StripeResponse
  rawJsonObject?: JsonObject
}
export type MarketingFeature = {
  name?: string
  lastResponse?: StripeResponse
  rawJsonObject?: JsonObject
}
export type PackageDimensions = {
  height?: number
  length?: number
  weight?: number
  width?: number
  lastResponse?: StripeResponse
  rawJsonObject?: JsonObject
}
export type TaxCode = {
  description?: string
  id?: string
  name?: string
  object?: string
  responseGetter?: StripeResponseGetter
  lastResponse?: StripeResponse
  rawJsonObject?: JsonObject
}
export type Product = {
  active?: boolean
  created?: number
  defaultPrice?: string
  deleted?: boolean
  description?: string
  id?: string
  images?: string[]
  livemode?: boolean
  marketingFeatures?: MarketingFeature[]
  metadata?: {
    [key: string]: string
  }
  name?: string
  object?: string
  packageDimensions?: PackageDimensions
  shippable?: boolean
  statementDescriptor?: string
  taxCode?: string
  type?: string
  unitLabel?: string
  updated?: number
  url?: string
  defaultPriceObject?: Price1
  taxCodeObject?: TaxCode
  responseGetter?: StripeResponseGetter
  lastResponse?: StripeResponse
  rawJsonObject?: JsonObject
}
export type Plan = {
  active?: boolean
  aggregateUsage?: string
  amount?: number
  amountDecimal?: number
  billingScheme?: string
  created?: number
  currency?: string
  deleted?: boolean
  id?: string
  interval?: string
  intervalCount?: number
  livemode?: boolean
  metadata?: {
    [key: string]: string
  }
  meter?: string
  nickname?: string
  object?: string
  product?: string
  tiers?: Tier[]
  tiersMode?: string
  transformUsage?: TransformUsage
  trialPeriodDays?: number
  usageType?: string
  productObject?: Product
  responseGetter?: StripeResponseGetter
  lastResponse?: StripeResponse
  rawJsonObject?: JsonObject
}
export type SubscriptionItem = {
  billingThresholds?: BillingThresholds
  created?: number
  deleted?: boolean
  discounts?: string[]
  id?: string
  metadata?: {
    [key: string]: string
  }
  object?: string
  plan?: Plan
  price?: Price1
  quantity?: number
  subscription?: string
  taxRates?: TaxRate[]
  responseGetter?: StripeResponseGetter
  discountObjects?: Discount[]
  lastResponse?: StripeResponse
  rawJsonObject?: JsonObject
}
export type SubscriptionItemCollection = {
  object?: string
  data?: SubscriptionItem[]
  hasMore?: boolean
  url?: string
  requestParams?: {
    [key: string]: object
  }
  requestOptions?: RequestOptions
  pageTypeToken?: {
    typeName?: string
  }
  responseGetter?: StripeResponseGetter
  lastResponse?: StripeResponse
  rawJsonObject?: JsonObject
}
export type PauseCollection = {
  behavior?: string
  resumesAt?: number
  lastResponse?: StripeResponse
  rawJsonObject?: JsonObject
}
export type PaymentSettings = {
  defaultMandate?: string
  paymentMethodOptions?: PaymentMethodOptions
  paymentMethodTypes?: string[]
  lastResponse?: StripeResponse
  rawJsonObject?: JsonObject
}
export type PendingInvoiceItemInterval = {
  interval?: string
  intervalCount?: number
  lastResponse?: StripeResponse
  rawJsonObject?: JsonObject
}
export type PendingUpdate = {
  billingCycleAnchor?: number
  expiresAt?: number
  subscriptionItems?: SubscriptionItem[]
  trialEnd?: number
  trialFromPlan?: boolean
  lastResponse?: StripeResponse
  rawJsonObject?: JsonObject
}
export type TransferData = {
  amount?: number
  destination?: string
  destinationObject?: Account
  lastResponse?: StripeResponse
  rawJsonObject?: JsonObject
}
export type EndBehavior = {
  missingPaymentMethod?: string
  lastResponse?: StripeResponse
  rawJsonObject?: JsonObject
}
export type TrialSettings = {
  endBehavior?: EndBehavior
  lastResponse?: StripeResponse
  rawJsonObject?: JsonObject
}
export type CustomField = {
  name?: string
  value?: string
  lastResponse?: StripeResponse
  rawJsonObject?: JsonObject
}
export type CustomerTaxId = {
  type?: string
  value?: string
  lastResponse?: StripeResponse
  rawJsonObject?: JsonObject
}
export type FromInvoice = {
  action?: string
  invoice?: string
  invoiceObject?: Invoice
  lastResponse?: StripeResponse
  rawJsonObject?: JsonObject
}
export type DiscountAmount = {
  amount?: number
  discount?: string
  discountObject?: Discount
  lastResponse?: StripeResponse
  rawJsonObject?: JsonObject
}
export type Period = {
  end?: number
  start?: number
  lastResponse?: StripeResponse
  rawJsonObject?: JsonObject
}
export type CreditedItems = {
  invoice?: string
  invoiceLineItems?: string[]
  lastResponse?: StripeResponse
  rawJsonObject?: JsonObject
}
export type ProrationDetails = {
  creditedItems?: CreditedItems
  lastResponse?: StripeResponse
  rawJsonObject?: JsonObject
}
export type TaxAmount = {
  amount?: number
  inclusive?: boolean
  taxRate?: string
  taxabilityReason?: string
  taxableAmount?: number
  taxRateObject?: TaxRate
  lastResponse?: StripeResponse
  rawJsonObject?: JsonObject
}
export type TestClock = {
  created?: number
  deleted?: boolean
  deletesAfter?: number
  frozenTime?: number
  id?: string
  livemode?: boolean
  name?: string
  object?: string
  status?: string
  responseGetter?: StripeResponseGetter
  lastResponse?: StripeResponse
  rawJsonObject?: JsonObject
}
export type InvoiceItem = {
  amount?: number
  currency?: string
  customer?: string
  date?: number
  deleted?: boolean
  description?: string
  discountable?: boolean
  discounts?: string[]
  id?: string
  invoice?: string
  livemode?: boolean
  metadata?: {
    [key: string]: string
  }
  object?: string
  period?: Period
  plan?: Plan
  price?: Price1
  proration?: boolean
  quantity?: number
  subscription?: string
  subscriptionItem?: string
  taxRates?: TaxRate[]
  testClock?: string
  unitAmount?: number
  unitAmountDecimal?: number
  invoiceObject?: Invoice
  responseGetter?: StripeResponseGetter
  customerObject?: Customer1
  subscriptionObject?: Subscription1
  testClockObject?: TestClock
  discountObjects?: Discount[]
  lastResponse?: StripeResponse
  rawJsonObject?: JsonObject
}
export type InvoiceLineItem = {
  amount?: number
  amountExcludingTax?: number
  currency?: string
  description?: string
  discountAmounts?: DiscountAmount[]
  discountable?: boolean
  discounts?: string[]
  id?: string
  invoice?: string
  invoiceItem?: string
  livemode?: boolean
  metadata?: {
    [key: string]: string
  }
  object?: string
  period?: Period
  plan?: Plan
  price?: Price1
  proration?: boolean
  prorationDetails?: ProrationDetails
  quantity?: number
  subscription?: string
  subscriptionItem?: string
  taxAmounts?: TaxAmount[]
  taxRates?: TaxRate[]
  type?: string
  unitAmountExcludingTax?: number
  invoiceItemObject?: InvoiceItem
  subscriptionItemObject?: SubscriptionItem
  responseGetter?: StripeResponseGetter
  subscriptionObject?: Subscription1
  discountObjects?: Discount[]
  lastResponse?: StripeResponse
  rawJsonObject?: JsonObject
}
export type InvoiceLineItemCollection = {
  object?: string
  data?: InvoiceLineItem[]
  hasMore?: boolean
  url?: string
  requestParams?: {
    [key: string]: object
  }
  requestOptions?: RequestOptions
  pageTypeToken?: {
    typeName?: string
  }
  responseGetter?: StripeResponseGetter
  lastResponse?: StripeResponse
  rawJsonObject?: JsonObject
}
export type Pdf = {
  pageSize?: string
  lastResponse?: StripeResponse
  rawJsonObject?: JsonObject
}
export type Rendering = {
  amountTaxDisplay?: string
  pdf?: Pdf
  lastResponse?: StripeResponse
  rawJsonObject?: JsonObject
}
export type Tax = {
  automaticTax?: string
  ipAddress?: string
  location?: Location
  lastResponse?: StripeResponse
  rawJsonObject?: JsonObject
}
export type Maximum = {
  unit?: string
  value?: number
  lastResponse?: StripeResponse
  rawJsonObject?: JsonObject
}
export type Minimum = {
  unit?: string
  value?: number
  lastResponse?: StripeResponse
  rawJsonObject?: JsonObject
}
export type DeliveryEstimate = {
  maximum?: Maximum
  minimum?: Minimum
  lastResponse?: StripeResponse
  rawJsonObject?: JsonObject
}
export type CurrencyOption = {
  amount?: number
  taxBehavior?: string
  lastResponse?: StripeResponse
  rawJsonObject?: JsonObject
}
export type FixedAmount = {
  amount?: number
  currency?: string
  currencyOptions?: {
    [key: string]: CurrencyOption
  }
  lastResponse?: StripeResponse
  rawJsonObject?: JsonObject
}
export type ShippingRate = {
  active?: boolean
  created?: number
  deliveryEstimate?: DeliveryEstimate
  displayName?: string
  fixedAmount?: FixedAmount
  id?: string
  livemode?: boolean
  metadata?: {
    [key: string]: string
  }
  object?: string
  taxBehavior?: string
  taxCode?: string
  type?: string
  taxCodeObject?: TaxCode
  responseGetter?: StripeResponseGetter
  lastResponse?: StripeResponse
  rawJsonObject?: JsonObject
}
export type ShippingCost = {
  amountSubtotal?: number
  amountTax?: number
  amountTotal?: number
  shippingRate?: string
  taxes?: Tax[]
  shippingRateObject?: ShippingRate
  lastResponse?: StripeResponse
  rawJsonObject?: JsonObject
}
export type StatusTransitions = {
  acceptedAt?: number
  canceledAt?: number
  finalizedAt?: number
  lastResponse?: StripeResponse
  rawJsonObject?: JsonObject
}
export type SubscriptionDetails = {
  metadata?: {
    [key: string]: string
  }
  lastResponse?: StripeResponse
  rawJsonObject?: JsonObject
}
export type ItemReason = {
  lineItemIds?: string[]
  usageGte?: number
  lastResponse?: StripeResponse
  rawJsonObject?: JsonObject
}
export type ThresholdReason = {
  amountGte?: number
  itemReasons?: ItemReason[]
  lastResponse?: StripeResponse
  rawJsonObject?: JsonObject
}
export type TotalDiscountAmount = {
  amount?: number
  discount?: string
  discountObject?: Discount
  lastResponse?: StripeResponse
  rawJsonObject?: JsonObject
}
export type TotalTaxAmount = {
  amount?: number
  inclusive?: boolean
  taxRate?: string
  taxabilityReason?: string
  taxableAmount?: number
  taxRateObject?: TaxRate
  lastResponse?: StripeResponse
  rawJsonObject?: JsonObject
}
export type FraudDetails = {
  stripeReport?: string
  userReport?: string
  lastResponse?: StripeResponse
  rawJsonObject?: JsonObject
}
export type LineItem = {
  amountDiscount?: number
  amountSubtotal?: number
  amountTax?: number
  amountTotal?: number
  currency?: string
  description?: string
  discounts?: Discount[]
  id?: string
  object?: string
  price?: Price1
  quantity?: number
  taxes?: Tax[]
  lastResponse?: StripeResponse
  rawJsonObject?: JsonObject
}
export type Level3 = {
  customerReference?: string
  lineItems?: LineItem[]
  merchantReference?: string
  shippingAddressZip?: string
  shippingAmount?: number
  shippingFromZip?: string
  lastResponse?: StripeResponse
  rawJsonObject?: JsonObject
}
export type Rule = {
  action?: string
  id?: string
  predicate?: string
  lastResponse?: StripeResponse
  rawJsonObject?: JsonObject
}
export type Outcome = {
  networkStatus?: string
  reason?: string
  riskLevel?: string
  riskScore?: number
  rule?: string
  sellerMessage?: string
  type?: string
  ruleObject?: Rule
  lastResponse?: StripeResponse
  rawJsonObject?: JsonObject
}
export type RadarOptions = {
  session?: string
  lastResponse?: StripeResponse
  rawJsonObject?: JsonObject
}
export type AuBankTransfer = {
  lastResponse?: StripeResponse
  rawJsonObject?: JsonObject
}
export type BrBankTransfer = {
  reference?: string
  referenceStatus?: string
  lastResponse?: StripeResponse
  rawJsonObject?: JsonObject
}
export type CustomerCashBalance = {
  lastResponse?: StripeResponse
  rawJsonObject?: JsonObject
}
export type Eps = {
  setupFutureUsage?: string
  lastResponse?: StripeResponse
  rawJsonObject?: JsonObject
}
export type EuBankTransfer = {
  country?: string
  lastResponse?: StripeResponse
  rawJsonObject?: JsonObject
}
export type GbBankTransfer = {
  reference?: string
  referenceStatus?: string
  lastResponse?: StripeResponse
  rawJsonObject?: JsonObject
}
export type Giropay = {
  setupFutureUsage?: string
  lastResponse?: StripeResponse
  rawJsonObject?: JsonObject
}
export type Grabpay = {
  setupFutureUsage?: string
  lastResponse?: StripeResponse
  rawJsonObject?: JsonObject
}
export type JpBankTransfer = {
  reference?: string
  referenceStatus?: string
  lastResponse?: StripeResponse
  rawJsonObject?: JsonObject
}
export type Multibanco = {
  setupFutureUsage?: string
  lastResponse?: StripeResponse
  rawJsonObject?: JsonObject
}
export type MxBankTransfer = {
  reference?: string
  referenceStatus?: string
  lastResponse?: StripeResponse
  rawJsonObject?: JsonObject
}
export type P24 = {
  setupFutureUsage?: string
  lastResponse?: StripeResponse
  rawJsonObject?: JsonObject
}
export type Paynow = {
  setupFutureUsage?: string
  lastResponse?: StripeResponse
  rawJsonObject?: JsonObject
}
export type Pix = {
  expiresAfterSeconds?: number
  expiresAt?: number
  setupFutureUsage?: string
  lastResponse?: StripeResponse
  rawJsonObject?: JsonObject
}
export type Revolut = {
  lastResponse?: StripeResponse
  rawJsonObject?: JsonObject
}
export type Swish = {
  lastResponse?: StripeResponse
  rawJsonObject?: JsonObject
}
export type ThBankTransfer = {
  reference?: string
  referenceStatus?: string
  lastResponse?: StripeResponse
  rawJsonObject?: JsonObject
}
export type UsBankTransfer = {
  reference?: string
  referenceStatus?: string
  lastResponse?: StripeResponse
  rawJsonObject?: JsonObject
}
export type WechatPay = {
  lastResponse?: StripeResponse
  rawJsonObject?: JsonObject
}
export type Zip = {
  lastResponse?: StripeResponse
  rawJsonObject?: JsonObject
}
export type DestinationDetails = {
  affirm?: Affirm
  afterpayClearpay?: AfterpayClearpay
  alipay?: Alipay
  amazonPay?: AmazonPay
  auBankTransfer?: AuBankTransfer
  blik?: Blik
  brBankTransfer?: BrBankTransfer
  card?: Card
  cashapp?: Cashapp
  customerCashBalance?: CustomerCashBalance
  eps?: Eps
  euBankTransfer?: EuBankTransfer
  gbBankTransfer?: GbBankTransfer
  giropay?: Giropay
  grabpay?: Grabpay
  jpBankTransfer?: JpBankTransfer
  klarna?: Klarna
  multibanco?: Multibanco
  mxBankTransfer?: MxBankTransfer
  p24?: P24
  paynow?: Paynow
  paypal?: Paypal
  pix?: Pix
  revolut?: Revolut
  sofort?: Sofort
  swish?: Swish
  thBankTransfer?: ThBankTransfer
  type?: string
  usBankTransfer?: UsBankTransfer
  wechatPay?: WechatPay
  zip?: Zip
  lastResponse?: StripeResponse
  rawJsonObject?: JsonObject
}
export type FeeDetail = {
  amount?: number
  application?: string
  currency?: string
  description?: string
  type?: string
  lastResponse?: StripeResponse
  rawJsonObject?: JsonObject
}
export type BalanceTransactionSource = {
  lastResponse?: StripeResponse
  id?: string
}
export type BalanceTransaction = {
  amount?: number
  availableOn?: number
  created?: number
  currency?: string
  description?: string
  exchangeRate?: number
  fee?: number
  feeDetails?: FeeDetail[]
  id?: string
  net?: number
  object?: string
  reportingCategory?: string
  source?: string
  status?: string
  type?: string
  sourceObject?: BalanceTransactionSource
  responseGetter?: StripeResponseGetter
  lastResponse?: StripeResponse
  rawJsonObject?: JsonObject
}
export type TransferReversalCollection = {
  object?: string
  data?: TransferReversal[]
  hasMore?: boolean
  url?: string
  requestParams?: {
    [key: string]: object
  }
  requestOptions?: RequestOptions
  pageTypeToken?: {
    typeName?: string
  }
  responseGetter?: StripeResponseGetter
  lastResponse?: StripeResponse
  rawJsonObject?: JsonObject
}
export type Transfer = {
  amount?: number
  amountReversed?: number
  balanceTransaction?: string
  created?: number
  currency?: string
  description?: string
  destination?: string
  destinationPayment?: string
  id?: string
  livemode?: boolean
  metadata?: {
    [key: string]: string
  }
  object?: string
  reversals?: TransferReversalCollection
  reversed?: boolean
  sourceTransaction?: string
  sourceType?: string
  transferGroup?: string
  responseGetter?: StripeResponseGetter
  balanceTransactionObject?: BalanceTransaction
  destinationObject?: Account
  destinationPaymentObject?: Charge
  sourceTransactionObject?: Charge
  lastResponse?: StripeResponse
  rawJsonObject?: JsonObject
}
export type TransferReversal = {
  amount?: number
  balanceTransaction?: string
  created?: number
  currency?: string
  destinationPaymentRefund?: string
  id?: string
  metadata?: {
    [key: string]: string
  }
  object?: string
  sourceRefund?: string
  transfer?: string
  transferObject?: Transfer
  destinationPaymentRefundObject?: Refund
  sourceRefundObject?: Refund
  responseGetter?: StripeResponseGetter
  balanceTransactionObject?: BalanceTransaction
  lastResponse?: StripeResponse
  rawJsonObject?: JsonObject
}
export type TestHelpers = object
export type Refund = {
  amount?: number
  balanceTransaction?: string
  charge?: string
  created?: number
  currency?: string
  description?: string
  destinationDetails?: DestinationDetails
  failureBalanceTransaction?: string
  failureReason?: string
  id?: string
  instructionsEmail?: string
  metadata?: {
    [key: string]: string
  }
  nextAction?: NextAction
  object?: string
  paymentIntent?: string
  reason?: string
  receiptNumber?: string
  sourceTransferReversal?: string
  status?: string
  transferReversal?: string
  failureBalanceTransactionObject?: BalanceTransaction
  sourceTransferReversalObject?: TransferReversal
  transferReversalObject?: TransferReversal
  testHelpers?: TestHelpers
  responseGetter?: StripeResponseGetter
  chargeObject?: Charge
  paymentIntentObject?: PaymentIntent
  balanceTransactionObject?: BalanceTransaction
  lastResponse?: StripeResponse
  rawJsonObject?: JsonObject
}
export type RefundCollection = {
  object?: string
  data?: Refund[]
  hasMore?: boolean
  url?: string
  requestParams?: {
    [key: string]: object
  }
  requestOptions?: RequestOptions
  pageTypeToken?: {
    typeName?: string
  }
  responseGetter?: StripeResponseGetter
  lastResponse?: StripeResponse
  rawJsonObject?: JsonObject
}
export type IpAddressLocation = {
  city?: string
  country?: string
  latitude?: number
  longitude?: number
  region?: string
  lastResponse?: StripeResponse
  rawJsonObject?: JsonObject
}
export type Session = {
  browser?: string
  device?: string
  platform?: string
  version?: string
  lastResponse?: StripeResponse
  rawJsonObject?: JsonObject
}
export type Review = {
  billingZip?: string
  charge?: string
  closedReason?: string
  created?: number
  id?: string
  ipAddress?: string
  ipAddressLocation?: IpAddressLocation
  livemode?: boolean
  object?: string
  open?: boolean
  openedReason?: string
  paymentIntent?: string
  reason?: string
  session?: Session
  responseGetter?: StripeResponseGetter
  chargeObject?: Charge
  paymentIntentObject?: PaymentIntent
  lastResponse?: StripeResponse
  rawJsonObject?: JsonObject
}
export type FeeSource = {
  charge?: string
  payout?: string
  type?: string
  lastResponse?: StripeResponse
  rawJsonObject?: JsonObject
}
export type FeeRefund = {
  amount?: number
  balanceTransaction?: string
  created?: number
  currency?: string
  fee?: string
  id?: string
  metadata?: {
    [key: string]: string
  }
  object?: string
  feeObject?: ApplicationFee
  responseGetter?: StripeResponseGetter
  balanceTransactionObject?: BalanceTransaction
  lastResponse?: StripeResponse
  rawJsonObject?: JsonObject
}
export type FeeRefundCollection = {
  object?: string
  data?: FeeRefund[]
  hasMore?: boolean
  url?: string
  requestParams?: {
    [key: string]: object
  }
  requestOptions?: RequestOptions
  pageTypeToken?: {
    typeName?: string
  }
  responseGetter?: StripeResponseGetter
  lastResponse?: StripeResponse
  rawJsonObject?: JsonObject
}
export type ApplicationFee = {
  account?: string
  amount?: number
  amountRefunded?: number
  application?: string
  balanceTransaction?: string
  charge?: string
  created?: number
  currency?: string
  feeSource?: FeeSource
  id?: string
  livemode?: boolean
  object?: string
  originatingTransaction?: string
  refunded?: boolean
  refunds?: FeeRefundCollection
  accountObject?: Account
  originatingTransactionObject?: Charge
  responseGetter?: StripeResponseGetter
  applicationObject?: Application
  chargeObject?: Charge
  balanceTransactionObject?: BalanceTransaction
  lastResponse?: StripeResponse
  rawJsonObject?: JsonObject
}
export type Charge = {
  amount?: number
  amountCaptured?: number
  amountRefunded?: number
  application?: string
  applicationFee?: string
  applicationFeeAmount?: number
  authorizationCode?: string
  balanceTransaction?: string
  billingDetails?: BillingDetails
  calculatedStatementDescriptor?: string
  captured?: boolean
  created?: number
  currency?: string
  customer?: string
  description?: string
  disputed?: boolean
  failureBalanceTransaction?: string
  failureCode?: string
  failureMessage?: string
  fraudDetails?: FraudDetails
  id?: string
  invoice?: string
  level3?: Level3
  livemode?: boolean
  metadata?: {
    [key: string]: string
  }
  object?: string
  onBehalfOf?: string
  outcome?: Outcome
  paid?: boolean
  paymentIntent?: string
  paymentMethod?: string
  paymentMethodDetails?: PaymentMethodDetails
  radarOptions?: RadarOptions
  receiptEmail?: string
  receiptNumber?: string
  receiptUrl?: string
  refunded?: boolean
  refunds?: RefundCollection
  review?: string
  shipping?: ShippingDetails
  source?: PaymentSource
  sourceTransfer?: string
  statementDescriptor?: string
  statementDescriptorSuffix?: string
  status?: string
  transfer?: string
  transferData?: TransferData
  transferGroup?: string
  reviewObject?: Review
  applicationFeeObject?: ApplicationFee
  failureBalanceTransactionObject?: BalanceTransaction
  sourceTransferObject?: Transfer
  transferObject?: Transfer
  invoiceObject?: Invoice
  responseGetter?: StripeResponseGetter
  applicationObject?: Application
  customerObject?: Customer1
  onBehalfOfObject?: Account
  paymentIntentObject?: PaymentIntent
  balanceTransactionObject?: BalanceTransaction
  lastResponse?: StripeResponse
  rawJsonObject?: JsonObject
}
export type Breakdown = {
  discounts?: Discount[]
  taxes?: Tax[]
  lastResponse?: StripeResponse
  rawJsonObject?: JsonObject
}
export type TotalDetails = {
  amountDiscount?: number
  amountShipping?: number
  amountTax?: number
  breakdown?: Breakdown
  lastResponse?: StripeResponse
  rawJsonObject?: JsonObject
}
export type Recurring = {
  amountSubtotal?: number
  amountTotal?: number
  interval?: string
  intervalCount?: number
  totalDetails?: TotalDetails
  lastResponse?: StripeResponse
  rawJsonObject?: JsonObject
}
export type LineItemCollection = {
  object?: string
  data?: LineItem[]
  hasMore?: boolean
  url?: string
  requestParams?: {
    [key: string]: object
  }
  requestOptions?: RequestOptions
  pageTypeToken?: {
    typeName?: string
  }
  responseGetter?: StripeResponseGetter
  lastResponse?: StripeResponse
  rawJsonObject?: JsonObject
}
export type Upfront = {
  amountSubtotal?: number
  amountTotal?: number
  lineItems?: LineItemCollection
  totalDetails?: TotalDetails
  lastResponse?: StripeResponse
  rawJsonObject?: JsonObject
}
export type Computed = {
  recurring?: Recurring
  upfront?: Upfront
  lastResponse?: StripeResponse
  rawJsonObject?: JsonObject
}
export type FromQuote = {
  isRevision?: boolean
  quote?: string
  quoteObject?: Quote
  lastResponse?: StripeResponse
  rawJsonObject?: JsonObject
}
export type SubscriptionData = {
  description?: string
  effectiveDate?: number
  metadata?: {
    [key: string]: string
  }
  trialPeriodDays?: number
  lastResponse?: StripeResponse
  rawJsonObject?: JsonObject
}
export type CurrentPhase = {
  endDate?: number
  startDate?: number
  lastResponse?: StripeResponse
  rawJsonObject?: JsonObject
}
export type DefaultSettings = {
  applicationFeePercent?: number
  automaticTax?: AutomaticTax
  billingCycleAnchor?: string
  billingThresholds?: BillingThresholds
  collectionMethod?: string
  defaultPaymentMethod?: string
  description?: string
  invoiceSettings?: InvoiceSettings
  onBehalfOf?: string
  transferData?: TransferData
  defaultPaymentMethodObject?: PaymentMethod
  onBehalfOfObject?: Account
  lastResponse?: StripeResponse
  rawJsonObject?: JsonObject
}
export type AddInvoiceItem = {
  discounts?: Discount[]
  price?: string
  quantity?: number
  taxRates?: TaxRate[]
  priceObject?: Price1
  lastResponse?: StripeResponse
  rawJsonObject?: JsonObject
}
export type Item = {
  billingThresholds?: BillingThresholds
  discounts?: Discount[]
  metadata?: {
    [key: string]: string
  }
  plan?: string
  price?: string
  quantity?: number
  taxRates?: TaxRate[]
  priceObject?: Price1
  planObject?: Plan
  lastResponse?: StripeResponse
  rawJsonObject?: JsonObject
}
export type AppliesTo = {
  products?: string[]
  lastResponse?: StripeResponse
  rawJsonObject?: JsonObject
}
export type Coupon = {
  amountOff?: number
  appliesTo?: AppliesTo
  created?: number
  currency?: string
  currencyOptions?: {
    [key: string]: CurrencyOption
  }
  deleted?: boolean
  duration?: string
  durationInMonths?: number
  id?: string
  livemode?: boolean
  maxRedemptions?: number
  metadata?: {
    [key: string]: string
  }
  name?: string
  object?: string
  percentOff?: number
  redeemBy?: number
  timesRedeemed?: number
  valid?: boolean
  responseGetter?: StripeResponseGetter
  lastResponse?: StripeResponse
  rawJsonObject?: JsonObject
}
export type Phase = {
  addInvoiceItems?: AddInvoiceItem[]
  applicationFeePercent?: number
  automaticTax?: AutomaticTax
  billingCycleAnchor?: string
  billingThresholds?: BillingThresholds
  collectionMethod?: string
  coupon?: string
  currency?: string
  defaultPaymentMethod?: string
  defaultTaxRates?: TaxRate[]
  description?: string
  discounts?: Discount[]
  endDate?: number
  invoiceSettings?: InvoiceSettings
  items?: Item[]
  metadata?: {
    [key: string]: string
  }
  onBehalfOf?: string
  prorationBehavior?: string
  startDate?: number
  transferData?: TransferData
  trialEnd?: number
  couponObject?: Coupon
  defaultPaymentMethodObject?: PaymentMethod
  onBehalfOfObject?: Account
  lastResponse?: StripeResponse
  rawJsonObject?: JsonObject
}
export type SubscriptionSchedule = {
  application?: string
  canceledAt?: number
  completedAt?: number
  created?: number
  currentPhase?: CurrentPhase
  customer?: string
  defaultSettings?: DefaultSettings
  endBehavior?: string
  id?: string
  livemode?: boolean
  metadata?: {
    [key: string]: string
  }
  object?: string
  phases?: Phase[]
  releasedAt?: number
  releasedSubscription?: string
  status?: string
  subscription?: string
  testClock?: string
  responseGetter?: StripeResponseGetter
  applicationObject?: Application
  customerObject?: Customer1
  subscriptionObject?: Subscription1
  testClockObject?: TestClock
  lastResponse?: StripeResponse
  rawJsonObject?: JsonObject
}
export type Quote = {
  amountSubtotal?: number
  amountTotal?: number
  application?: string
  applicationFeeAmount?: number
  applicationFeePercent?: number
  automaticTax?: AutomaticTax
  collectionMethod?: string
  computed?: Computed
  created?: number
  currency?: string
  customer?: string
  defaultTaxRates?: string[]
  description?: string
  discounts?: string[]
  expiresAt?: number
  footer?: string
  fromQuote?: FromQuote
  header?: string
  id?: string
  invoice?: string
  invoiceSettings?: InvoiceSettings
  lineItems?: LineItemCollection
  livemode?: boolean
  metadata?: {
    [key: string]: string
  }
  number?: string
  object?: string
  onBehalfOf?: string
  status?: string
  statusTransitions?: StatusTransitions
  subscription?: string
  subscriptionData?: SubscriptionData
  subscriptionSchedule?: string
  testClock?: string
  totalDetails?: TotalDetails
  transferData?: TransferData
  subscriptionScheduleObject?: SubscriptionSchedule
  defaultTaxRateObjects?: TaxRate[]
  invoiceObject?: Invoice
  responseGetter?: StripeResponseGetter
  applicationObject?: Application
  customerObject?: Customer1
  onBehalfOfObject?: Account
  subscriptionObject?: Subscription1
  testClockObject?: TestClock
  discountObjects?: Discount[]
  lastResponse?: StripeResponse
  rawJsonObject?: JsonObject
}
export type Invoice = {
  accountCountry?: string
  accountName?: string
  accountTaxIds?: string[]
  amountDue?: number
  amountPaid?: number
  amountRemaining?: number
  amountShipping?: number
  application?: string
  applicationFeeAmount?: number
  attemptCount?: number
  attempted?: boolean
  autoAdvance?: boolean
  automaticTax?: AutomaticTax
  billingReason?: string
  charge?: string
  collectionMethod?: string
  created?: number
  currency?: string
  customFields?: CustomField[]
  customer?: string
  customerAddress?: Address
  customerEmail?: string
  customerName?: string
  customerPhone?: string
  customerShipping?: ShippingDetails
  customerTaxExempt?: string
  customerTaxIds?: CustomerTaxId[]
  defaultPaymentMethod?: string
  defaultSource?: string
  defaultTaxRates?: TaxRate[]
  deleted?: boolean
  description?: string
  discount?: Discount
  discounts?: string[]
  dueDate?: number
  effectiveAt?: number
  endingBalance?: number
  footer?: string
  fromInvoice?: FromInvoice
  hostedInvoiceUrl?: string
  id?: string
  invoicePdf?: string
  issuer?: Issuer
  lastFinalizationError?: StripeError
  latestRevision?: string
  lines?: InvoiceLineItemCollection
  livemode?: boolean
  metadata?: {
    [key: string]: string
  }
  nextPaymentAttempt?: number
  number?: string
  object?: string
  onBehalfOf?: string
  paid?: boolean
  paidOutOfBand?: boolean
  paymentIntent?: string
  paymentSettings?: PaymentSettings
  periodEnd?: number
  periodStart?: number
  postPaymentCreditNotesAmount?: number
  prePaymentCreditNotesAmount?: number
  quote?: string
  receiptNumber?: string
  rendering?: Rendering
  shippingCost?: ShippingCost
  shippingDetails?: ShippingDetails
  startingBalance?: number
  statementDescriptor?: string
  status?: string
  statusTransitions?: StatusTransitions
  subscription?: string
  subscriptionDetails?: SubscriptionDetails
  subscriptionProrationDate?: number
  subtotal?: number
  subtotalExcludingTax?: number
  tax?: number
  testClock?: string
  thresholdReason?: ThresholdReason
  total?: number
  totalDiscountAmounts?: TotalDiscountAmount[]
  totalExcludingTax?: number
  totalTaxAmounts?: TotalTaxAmount[]
  transferData?: TransferData
  webhooksDeliveredAt?: number
  responseGetter?: StripeResponseGetter
  applicationObject?: Application
  chargeObject?: Charge
  customerObject?: Customer1
  defaultPaymentMethodObject?: PaymentMethod
  defaultSourceObject?: PaymentSource
  latestRevisionObject?: Invoice
  onBehalfOfObject?: Account
  paymentIntentObject?: PaymentIntent
  quoteObject?: Quote
  subscriptionObject?: Subscription1
  testClockObject?: TestClock
  discountObjects?: Discount[]
  lastResponse?: StripeResponse
  rawJsonObject?: JsonObject
}
export type Subscription1 = {
  application?: string
  applicationFeePercent?: number
  automaticTax?: AutomaticTax
  billingCycleAnchor?: number
  billingCycleAnchorConfig?: BillingCycleAnchorConfig
  billingThresholds?: BillingThresholds
  cancelAt?: number
  cancelAtPeriodEnd?: boolean
  canceledAt?: number
  cancellationDetails?: CancellationDetails
  collectionMethod?: string
  created?: number
  currency?: string
  currentPeriodEnd?: number
  currentPeriodStart?: number
  customer?: string
  daysUntilDue?: number
  defaultPaymentMethod?: string
  defaultSource?: string
  defaultTaxRates?: TaxRate[]
  description?: string
  discount?: Discount
  discounts?: string[]
  endedAt?: number
  id?: string
  invoiceSettings?: InvoiceSettings
  items?: SubscriptionItemCollection
  latestInvoice?: string
  livemode?: boolean
  metadata?: {
    [key: string]: string
  }
  nextPendingInvoiceItemInvoice?: number
  object?: string
  onBehalfOf?: string
  pauseCollection?: PauseCollection
  paymentSettings?: PaymentSettings
  pendingInvoiceItemInterval?: PendingInvoiceItemInterval
  pendingSetupIntent?: string
  pendingUpdate?: PendingUpdate
  schedule?: string
  startDate?: number
  status?: string
  testClock?: string
  transferData?: TransferData
  trialEnd?: number
  trialSettings?: TrialSettings
  trialStart?: number
  latestInvoiceObject?: Invoice
  pendingSetupIntentObject?: SetupIntent
  scheduleObject?: SubscriptionSchedule
  responseGetter?: StripeResponseGetter
  applicationObject?: Application
  customerObject?: Customer1
  defaultPaymentMethodObject?: PaymentMethod
  defaultSourceObject?: PaymentSource
  onBehalfOfObject?: Account
  testClockObject?: TestClock
  discountObjects?: Discount[]
  lastResponse?: StripeResponse
  rawJsonObject?: JsonObject
}
export type SubscriptionCollection = {
  object?: string
  data?: Subscription1[]
  hasMore?: boolean
  url?: string
  requestParams?: {
    [key: string]: object
  }
  requestOptions?: RequestOptions
  pageTypeToken?: {
    typeName?: string
  }
  responseGetter?: StripeResponseGetter
  lastResponse?: StripeResponse
  rawJsonObject?: JsonObject
}
export type TaxIdCollection = {
  object?: string
  hasMore?: boolean
  url?: string
  requestParams?: {
    [key: string]: object
  }
  requestOptions?: RequestOptions
  pageTypeToken?: {
    typeName?: string
  }
  responseGetter?: StripeResponseGetter
  lastResponse?: StripeResponse
  rawJsonObject?: JsonObject
}
export type Customer1 = {
  address?: Address
  balance?: number
  cashBalance?: CashBalance
  created?: number
  currency?: string
  defaultSource?: string
  deleted?: boolean
  delinquent?: boolean
  description?: string
  discount?: Discount
  email?: string
  id?: string
  invoiceCreditBalance?: {
    [key: string]: number
  }
  invoicePrefix?: string
  invoiceSettings?: InvoiceSettings
  livemode?: boolean
  metadata?: {
    [key: string]: string
  }
  name?: string
  nextInvoiceSequence?: number
  object?: string
  phone?: string
  preferredLocales?: string[]
  shipping?: ShippingDetails
  sources?: PaymentSourceCollection
  subscriptions?: SubscriptionCollection
  tax?: Tax
  taxExempt?: string
  taxIds?: TaxIdCollection
  testClock?: string
  testHelpers?: TestHelpers
  responseGetter?: StripeResponseGetter
  defaultSourceObject?: PaymentSource
  testClockObject?: TestClock
  lastResponse?: StripeResponse
  rawJsonObject?: JsonObject
}
export type SetupAttempt = {
  application?: string
  attachToSelf?: boolean
  created?: number
  customer?: string
  flowDirections?: string[]
  id?: string
  livemode?: boolean
  object?: string
  onBehalfOf?: string
  paymentMethod?: string
  paymentMethodDetails?: PaymentMethodDetails
  setupError?: StripeError
  setupIntent?: string
  status?: string
  usage?: string
  setupIntentObject?: SetupIntent
  paymentMethodObject?: PaymentMethod
  responseGetter?: StripeResponseGetter
  applicationObject?: Application
  customerObject?: Customer1
  onBehalfOfObject?: Account
  lastResponse?: StripeResponse
  rawJsonObject?: JsonObject
}
export type GeneratedFrom = {
  charge?: string
  paymentMethodDetails?: PaymentMethodDetails
  setupAttempt?: string
  setupAttemptObject?: SetupAttempt
  lastResponse?: StripeResponse
  rawJsonObject?: JsonObject
}
export type Networks = {
  available?: string[]
  preferred?: string
  lastResponse?: StripeResponse
  rawJsonObject?: JsonObject
}
export type ThreeDSecureUsage = {
  supported?: boolean
  lastResponse?: StripeResponse
  rawJsonObject?: JsonObject
}
export type AmexExpressCheckout = {
  lastResponse?: StripeResponse
  rawJsonObject?: JsonObject
}
export type ApplePay = {
  lastResponse?: StripeResponse
  rawJsonObject?: JsonObject
}
export type GooglePay = {
  lastResponse?: StripeResponse
  rawJsonObject?: JsonObject
}
export type Masterpass = {
  billingAddress?: Address
  email?: string
  name?: string
  shippingAddress?: Address
  lastResponse?: StripeResponse
  rawJsonObject?: JsonObject
}
export type SamsungPay = {
  lastResponse?: StripeResponse
  rawJsonObject?: JsonObject
}
export type VisaCheckout = {
  billingAddress?: Address
  email?: string
  name?: string
  shippingAddress?: Address
  lastResponse?: StripeResponse
  rawJsonObject?: JsonObject
}
export type Wallet = {
  amexExpressCheckout?: AmexExpressCheckout
  applePay?: ApplePay
  dynamicLast4?: string
  googlePay?: GooglePay
  link?: Link
  masterpass?: Masterpass
  samsungPay?: SamsungPay
  type?: string
  visaCheckout?: VisaCheckout
  lastResponse?: StripeResponse
  rawJsonObject?: JsonObject
}
export type Card = {
  brand?: string
  checks?: Checks
  country?: string
  description?: string
  displayBrand?: string
  expMonth?: number
  expYear?: number
  fingerprint?: string
  funding?: string
  generatedFrom?: GeneratedFrom
  iin?: string
  issuer?: string
  last4?: string
  networks?: Networks
  threeDSecureUsage?: ThreeDSecureUsage
  wallet?: Wallet
  lastResponse?: StripeResponse
  rawJsonObject?: JsonObject
}
export type BankTransfer = {
  euBankTransfer?: EuBankTransfer
  requestedAddressTypes?: string[]
  type?: string
  lastResponse?: StripeResponse
  rawJsonObject?: JsonObject
}
export type CustomerBalance = {
  bankTransfer?: BankTransfer
  fundingType?: string
  setupFutureUsage?: string
  lastResponse?: StripeResponse
  rawJsonObject?: JsonObject
}
export type Fpx = {
  setupFutureUsage?: string
  lastResponse?: StripeResponse
  rawJsonObject?: JsonObject
}
export type InteracPresent = {
  lastResponse?: StripeResponse
  rawJsonObject?: JsonObject
}
export type Konbini = {
  confirmationNumber?: string
  expiresAfterDays?: number
  expiresAt?: number
  productDescription?: string
  setupFutureUsage?: string
  lastResponse?: StripeResponse
  rawJsonObject?: JsonObject
}
export type Mobilepay = {
  captureMethod?: string
  setupFutureUsage?: string
  lastResponse?: StripeResponse
  rawJsonObject?: JsonObject
}
export type Oxxo = {
  expiresAfterDays?: number
  setupFutureUsage?: string
  lastResponse?: StripeResponse
  rawJsonObject?: JsonObject
}
export type Promptpay = {
  setupFutureUsage?: string
  lastResponse?: StripeResponse
  rawJsonObject?: JsonObject
}
export type Twint = {
  lastResponse?: StripeResponse
  rawJsonObject?: JsonObject
}
export type PaymentMethod = {
  acssDebit?: AcssDebit
  affirm?: Affirm
  afterpayClearpay?: AfterpayClearpay
  alipay?: Alipay
  allowRedisplay?: string
  amazonPay?: AmazonPay
  auBecsDebit?: AuBecsDebit
  bacsDebit?: BacsDebit
  bancontact?: Bancontact
  billingDetails?: BillingDetails
  blik?: Blik
  boleto?: Boleto
  card?: Card
  cardPresent?: CardPresent
  cashapp?: Cashapp
  created?: number
  customer?: string
  customerBalance?: CustomerBalance
  eps?: Eps
  fpx?: Fpx
  giropay?: Giropay
  grabpay?: Grabpay
  id?: string
  ideal?: Ideal
  interacPresent?: InteracPresent
  klarna?: Klarna
  konbini?: Konbini
  link?: Link
  livemode?: boolean
  metadata?: {
    [key: string]: string
  }
  mobilepay?: Mobilepay
  multibanco?: Multibanco
  object?: string
  oxxo?: Oxxo
  p24?: P24
  paynow?: Paynow
  paypal?: Paypal
  pix?: Pix
  promptpay?: Promptpay
  radarOptions?: RadarOptions
  revolutPay?: RevolutPay
  sepaDebit?: SepaDebit
  sofort?: Sofort
  swish?: Swish
  twint?: Twint
  type?: string
  usBankAccount?: UsBankAccount
  wechatPay?: WechatPay
  zip?: Zip
  responseGetter?: StripeResponseGetter
  customerObject?: Customer1
  lastResponse?: StripeResponse
  rawJsonObject?: JsonObject
}
export type Bancontact = {
  bankCode?: string
  bankName?: string
  bic?: string
  generatedSepaDebit?: string
  generatedSepaDebitMandate?: string
  ibanLast4?: string
  preferredLanguage?: string
  verifiedName?: string
  generatedSepaDebitObject?: PaymentMethod
  generatedSepaDebitMandateObject?: Mandate
  lastResponse?: StripeResponse
  rawJsonObject?: JsonObject
}
export type PaymentMethodOptions = {
  acssDebit?: AcssDebit
  affirm?: Affirm
  afterpayClearpay?: AfterpayClearpay
  alipay?: Alipay
  amazonPay?: AmazonPay
  auBecsDebit?: AuBecsDebit
  bacsDebit?: BacsDebit
  bancontact?: Bancontact
  blik?: Blik
  boleto?: Boleto
  card?: Card
  cardPresent?: CardPresent
  cashapp?: Cashapp
  customerBalance?: CustomerBalance
  eps?: Eps
  fpx?: Fpx
  giropay?: Giropay
  grabpay?: Grabpay
  ideal?: Ideal
  interacPresent?: InteracPresent
  klarna?: Klarna
  konbini?: Konbini
  link?: Link
  mobilepay?: Mobilepay
  multibanco?: Multibanco
  oxxo?: Oxxo
  p24?: P24
  paynow?: Paynow
  paypal?: Paypal
  pix?: Pix
  promptpay?: Promptpay
  revolutPay?: RevolutPay
  sepaDebit?: SepaDebit
  sofort?: Sofort
  swish?: Swish
  twint?: Twint
  usBankAccount?: UsBankAccount
  wechatPay?: WechatPay
  zip?: Zip
  lastResponse?: StripeResponse
  rawJsonObject?: JsonObject
}
export type Processing = {
  card?: Card
  type?: string
  lastResponse?: StripeResponse
  rawJsonObject?: JsonObject
}
export type PaymentIntent = {
  amount?: number
  amountCapturable?: number
  amountDetails?: AmountDetails
  amountReceived?: number
  application?: string
  applicationFeeAmount?: number
  automaticPaymentMethods?: AutomaticPaymentMethods
  canceledAt?: number
  cancellationReason?: string
  captureMethod?: string
  clientSecret?: string
  confirmationMethod?: string
  created?: number
  currency?: string
  customer?: string
  description?: string
  id?: string
  invoice?: string
  lastPaymentError?: StripeError
  latestCharge?: string
  livemode?: boolean
  metadata?: {
    [key: string]: string
  }
  nextAction?: NextAction
  object?: string
  onBehalfOf?: string
  paymentMethod?: string
  paymentMethodConfigurationDetails?: PaymentMethodConfigurationDetails
  paymentMethodOptions?: PaymentMethodOptions
  paymentMethodTypes?: string[]
  processing?: Processing
  receiptEmail?: string
  review?: string
  setupFutureUsage?: string
  shipping?: ShippingDetails
  source?: string
  statementDescriptor?: string
  statementDescriptorSuffix?: string
  status?: string
  transferData?: TransferData
  transferGroup?: string
  latestChargeObject?: Charge
  reviewObject?: Review
  sourceObject?: PaymentSource
  paymentMethodObject?: PaymentMethod
  invoiceObject?: Invoice
  responseGetter?: StripeResponseGetter
  applicationObject?: Application
  customerObject?: Customer1
  onBehalfOfObject?: Account
  lastResponse?: StripeResponse
  rawJsonObject?: JsonObject
}
export type StripeError = {
  charge?: string
  code?: string
  declineCode?: string
  docUrl?: string
  message?: string
  param?: string
  paymentIntent?: PaymentIntent
  paymentMethod?: PaymentMethod
  paymentMethodType?: string
  requestLogUrl?: string
  setupIntent?: SetupIntent
  source?: PaymentSource
  type?: string
  lastResponse?: StripeResponse
  rawJsonObject?: JsonObject
}
export type SetupIntent = {
  application?: string
  attachToSelf?: boolean
  automaticPaymentMethods?: AutomaticPaymentMethods
  cancellationReason?: string
  clientSecret?: string
  created?: number
  customer?: string
  description?: string
  flowDirections?: string[]
  id?: string
  lastSetupError?: StripeError
  latestAttempt?: string
  livemode?: boolean
  mandate?: string
  metadata?: {
    [key: string]: string
  }
  nextAction?: NextAction
  object?: string
  onBehalfOf?: string
  paymentMethod?: string
  paymentMethodConfigurationDetails?: PaymentMethodConfigurationDetails
  paymentMethodOptions?: PaymentMethodOptions
  paymentMethodTypes?: string[]
  singleUseMandate?: string
  status?: string
  usage?: string
  latestAttemptObject?: SetupAttempt
  mandateObject?: Mandate
  paymentMethodObject?: PaymentMethod
  singleUseMandateObject?: Mandate
  responseGetter?: StripeResponseGetter
  applicationObject?: Application
  customerObject?: Customer1
  onBehalfOfObject?: Account
  lastResponse?: StripeResponse
  rawJsonObject?: JsonObject
}
export type StripeIntentModel1 = {
  id?: string
  type?: string
  intentId?: string
  intent?: SetupIntent
  clientSecret?: string
}
export type AuthorizePublicSessionRequest = {
  siteId?: string
  chargerId?: string
}
export type AuditDetail = {
  productType?:
    | 'WORKPLACE_CHARGING'
    | 'FLEET_CHARGING'
    | 'PUBLIC_CHARGING'
    | 'HOME_CHARGING'
    | 'VEHICLE_SUBSCRIPTION'
    | 'CHARGER_SUBSCRIPTION'
    | 'OTHER'
  entityId?: string
  entityType?: 'SITE' | 'CHARGER' | 'CONNECTOR' | 'VEHICLE' | 'AUTH_TAG'
  usageBefore?: number
  usageAfter?: number
  costBefore?: number
  costAfter?: number
}
export type Error = {
  userId?: string
  message?: string
}
export type AuditResponseModel = {
  updatedLineItemIds?: string[]
  createdLineItemIds?: string[]
  auditDetails?: AuditDetail[]
  errors?: Error[]
}
export type AuditRequestModel = {
  organizationId: string
  productType:
    | 'WORKPLACE_CHARGING'
    | 'FLEET_CHARGING'
    | 'PUBLIC_CHARGING'
    | 'HOME_CHARGING'
    | 'VEHICLE_SUBSCRIPTION'
    | 'CHARGER_SUBSCRIPTION'
    | 'OTHER'
  billingMonth?: string
  from?: string
  to?: string
}
export type Message = {
  role?: string
  content?: string
}
export type Choice = {
  index?: number
  message?: Message
  logprobs?: object
  finishReason?: string
}
export type Usage = {
  promptTokens?: number
  completionTokens?: number
  totalTokens?: number
}
export type ChatCompletionResponse = {
  id?: string
  object?: string
  created?: number
  model?: string
  choices?: Choice[]
  usage?: Usage
  systemFingerprint?: string
}
export type ChatCompletionRequest = {
  data?: string
}
export type WorkplaceChargingUser = {
  created?: string
  updated?: string
  createdBy?: string
  modifiedBy?: string
  logicalDeleteIn?: boolean
  workplaceChargingUserId?: string
  firstNm?: string
  lastNm?: string
  email?: string
  externalId?: string
  rfidTag?: string
  userId?: string
  status?: 'CURRENT' | 'PAST_DUE' | 'PENDING'
  customerId?: string
  paymentIntentId?: string
  organizationId?: string
}
export type Organization1 = {
  created?: string
  updated?: string
  createdBy?: string
  modifiedBy?: string
  logicalDeleteIn?: boolean
  id?: string
  organizationNm?: string
  organizationType?: string
  logoUrl?: string
  styleOverrides?: string
  hasOpsCenter?: boolean
  hasReporting?: boolean
  hasBilling?: boolean
  connectedAccountId?: string
  connectedBillingEnabled?: boolean
  customerId?: string
  setupIntentId?: string
  hasCompeletedOnboarding?: boolean
  hasMarketplace?: boolean
  hasEnergyManagement?: boolean
  hasChargeManagement?: boolean
  hasFleetManagement?: boolean
  hasReimbursements?: boolean
  reimbursementsStatus?: 'NEVER_ENABLED' | 'ENABLED' | 'DISABLED'
  hasWorkplaceCharging?: boolean
  workplaceChargingStatus?: 'NEVER_ENABLED' | 'ENABLED' | 'DISABLED'
  connectedAccountStatus?:
    | 'RESTRICTED'
    | 'RESTRICTED_SOON'
    | 'PENDING'
    | 'ENABLED'
    | 'COMPLETE'
    | 'REJECTED'
    | 'UNKNOWN'
  loginUrl?: string
  active?: boolean
  isCurrent?: boolean
}
export type ChargeRecordModelWorkplaceChargingUserOrganization = {
  chargeRecordId?: string
  usageRecordId?: string
  chargeType?: string
  usage?: number
  usageType?: string
  appliedRate?: number
  chargeCost?: number
  currency?: string
  organizationId?: string
  usageStart?: string
  usageEnd?: string
  assetType?: string
  assetId?: string
  usageSourceId?: string
  usageSource?: string
  measurand?: string
  payer?: WorkplaceChargingUser
  payee?: Organization1
}
export type WorkplaceChargingSummaryModel = {
  workplaceUsers?: number
  organizationId?: string
  currency?: string
  periodStart?: string
  periodEnd?: string
  currentPeriodWorkplaceChargingRevenue?: number
  workplaceChargingRevenueMoMChange?: number
  currentPeriodWorkplaceChargingEnergyUse?: number
  energyUseMoMChange?: number
  asOf?: string
  lastBillingPeriod?: WorkplaceChargingSummaryModel
  status?: 'RESTRICTED' | 'RESTRICTED_SOON' | 'PENDING' | 'ENABLED' | 'COMPLETE' | 'REJECTED' | 'UNKNOWN'
}
export type WorkplaceChargingModel = {
  workplaceUserId?: string
  organizationId?: string
  firstNm?: string
  lastNm?: string
  email?: string
  externalId?: string
  currency?: string
  periodStart?: string
  periodEnd?: string
  status?:
    | 'DRAFT'
    | 'OPEN'
    | 'UNCOLLECTIBLE'
    | 'VOID'
    | 'READY_FOR_REVIEW'
    | 'APPROVED'
    | 'PAID'
    | 'PAST_DUE'
    | 'ESTIMATE'
  statusAsOf?: string
  amount?: number
  usage?: number
  numberTransactions?: number
  asOf?: string
  approvedById?: string
  approvedByName?: string
  approvedOn?: string
  invoiceId?: string
  invoiceUrl?: string
}
export type BillingDetailsModel = {
  name?: string
  email?: string
  address?: Address
  paymentMethods?: PaymentMethod[]
  balance?: number
  currency?: string
}
export type ReimbursementsModelObject = {
  reimbursementId?: string
  reimbursementUserId?: string
  organizationId?: string
  firstNm?: string
  lastNm?: string
  email?: string
  externalId?: string
  currency?: string
  periodStart?: string
  periodEnd?: string
  settled?: string
  status?: string
  amount?: number
  usage?: number
  entityId?: string
  entityType?: string
  payee?: ReimbursementUser
  payer?: Organization1
  asOf?: string
}
export type ChargeRecordModelOrganizationReimbursementUser = {
  chargeRecordId?: string
  usageRecordId?: string
  chargeType?: string
  usage?: number
  usageType?: string
  appliedRate?: number
  chargeCost?: number
  currency?: string
  organizationId?: string
  usageStart?: string
  usageEnd?: string
  assetType?: string
  assetId?: string
  usageSourceId?: string
  usageSource?: string
  measurand?: string
  payer?: Organization1
  payee?: ReimbursementUser
}
export type ReimbursementSummaryModel = {
  reimbursementUsers?: number
  organizationId?: string
  reimbursementAmount?: number
  reimbursementAmountMoMChange?: number
  currency?: string
  energyUseKwh?: number
  energyUseMoMChange?: number
  periodStart?: string
  periodEnd?: string
  asOf?: string
  lastBillingPeriod?: ReimbursementSummaryModel
}
export type QuotaEntry = {
  date?: string
  usedQuota?: number
  remainingQuota?: number
}
export type ApiQuota = {
  organizationId?: string
  active?: boolean
  quotaType?: 'API'
  apiKeyNm?: string
  apiKeyLast4?: string
  quotaLimit?: number
  quotaRemaining?: number
  quotaPeriod?: 'DAY' | 'WEEK' | 'MONTH'
  burstLimit?: number
  rateLimit?: number
  quotaEntries?: QuotaEntry[]
}
export type LoginLink = {
  created?: number
  object?: string
  url?: string
  responseGetter?: StripeResponseGetter
  lastResponse?: StripeResponse
  rawJsonObject?: JsonObject
}
export type AccountBalance = {
  pending?: number
  available?: number
  issuingAvailable?: number
  connectReserved?: number
  lastPayout?: string
  nextPayout?: string
}
export type MeterValueModel = {
  id?: string
  chargerId?: string
  connectorId?: number
  transactionPk?: number
  measuredAt?: string
  energyActiveImportRegister?: number
  energyActiveImportRegisterUnit?: string
}
export type ResponseFormatListLocation = {
  timestamp: string
  data?: Location[]
  totalItems?: number
  status_code: number
  status_message?: string
}
export type ResponseFormatListCdrDto = {
  timestamp: string
  data?: CdrDto[]
  totalItems?: number
  status_code: number
  status_message?: string
}
export type ResponseFormatCdrDto = {
  timestamp: string
  data?: CdrDto
  totalItems?: number
  status_code: number
  status_message?: string
}
export type PagedResponseSynopLineItem = {
  first?: boolean
  last?: boolean
  pageSize?: number
  page?: number
  totalPages?: number
  totalItems?: number
  items?: SynopLineItem[]
}
export type SourceTypes = {
  bankAccount?: number
  card?: number
  fpx?: number
  lastResponse?: StripeResponse
  rawJsonObject?: JsonObject
}
export type Available = {
  amount?: number
  currency?: string
  sourceTypes?: SourceTypes
  lastResponse?: StripeResponse
  rawJsonObject?: JsonObject
}
export type ConnectReserved = {
  amount?: number
  currency?: string
  sourceTypes?: SourceTypes
  lastResponse?: StripeResponse
  rawJsonObject?: JsonObject
}
export type NetAvailable = {
  amount?: number
  destination?: string
  sourceTypes?: SourceTypes
  lastResponse?: StripeResponse
  rawJsonObject?: JsonObject
}
export type InstantAvailable = {
  amount?: number
  currency?: string
  netAvailable?: NetAvailable[]
  sourceTypes?: SourceTypes
  lastResponse?: StripeResponse
  rawJsonObject?: JsonObject
}
export type Issuing = {
  available?: Available[]
  lastResponse?: StripeResponse
  rawJsonObject?: JsonObject
}
export type Pending = {
  amount?: number
  currency?: string
  sourceTypes?: SourceTypes
  lastResponse?: StripeResponse
  rawJsonObject?: JsonObject
}
export type Balance = {
  available?: Available[]
  connectReserved?: ConnectReserved[]
  instantAvailable?: InstantAvailable[]
  issuing?: Issuing
  livemode?: boolean
  object?: string
  pending?: Pending[]
  responseGetter?: StripeResponseGetter
  lastResponse?: StripeResponse
  rawJsonObject?: JsonObject
}
export type PayoutModel = {
  id?: string
  amount?: number
  currency?: string
  status?: 'PAID' | 'PENDING' | 'IN_TRANSIT' | 'CANCELED' | 'FAILED'
  initiatedDate?: string
  arrivalDate?: string
  last4?: number
  destinationAccountNm?: string
}
export type FleetBillingSummaryModel = {
  account?: Account
  balance?: Balance
  availableBalance?: number
  pendingBalance?: number
  status?: 'RESTRICTED' | 'RESTRICTED_SOON' | 'PENDING' | 'ENABLED' | 'COMPLETE' | 'REJECTED' | 'UNKNOWN'
  payouts?: PayoutModel[]
}
export type PaymentModel = {
  id: string
  amount: number
  currency: string
  description?: string
  statementDescriptor?: string
  payeeType?: 'ORGANIZATION' | 'USER'
  payeeId?: string
  payeeNm?: string
  invoiceId?: string
  invoiceUrl?: string
  status?:
    | 'REQUIRES_PAYMENT_METHOD'
    | 'REQUIRES_CONFIRMATION'
    | 'REQUIRES_ACTION'
    | 'PROCESSING'
    | 'REQUIRES_CAPTURE'
    | 'CANCELED'
    | 'SUCCEEDED'
}
export type PagedResponsePaymentModel = {
  first?: boolean
  last?: boolean
  pageSize?: number
  page?: number
  totalPages?: number
  totalItems?: number
  items?: PaymentModel[]
}
export type DimensionValue = {
  dimension: string
  cost: number
  usage: number
}
export type CostExplorerInterval = {
  interval?: string
  dimensions?: DimensionValue[]
}
export type CostExplorerResponse = {
  intervals?: CostExplorerInterval[]
}
export type CostDashboardSummary = {
  numTransactions?: number
  numVehiclesAuthorized?: number
  numUsersAuthorized?: number
  authTokenTypeCounts?: {
    [key: string]: number
  }
  energyImportKwh?: number
  energyCost?: number
  energyCostBreakdown?: LineItemRateDetail[]
  avgTransactionCost?: number
  avgTransactionLength?: number
  avgTransactionEnergyImportKwh?: number
  from?: string
  to?: string
}
export type CostDashboardGroupEntry = {
  entityType?: 'SITE' | 'CHARGER' | 'CONNECTOR' | 'VEHICLE' | 'AUTH_TAG'
  entityId?: string
  entityName?: string
  energyImportKwh?: number
  energyCost?: number
  numTransactions?: number
  avgTransactionCost?: number
  avgTransactionLength?: number
  avgTransactionEnergyImportKwh?: number
  energyCostBreakdown?: LineItemRateDetail[]
}
export type CostDashboardGroupResponse = {
  groupBy?: 'SITE' | 'CHARGER' | 'CONNECTOR' | 'VEHICLE' | 'AUTH_TAG'
  from?: string
  to?: string
  entries?: CostDashboardGroupEntry[]
}
export type SimulatorDetailModel = {
  chargerId?: string
  ocppUrl?: string
  namespace?: string
  creationTime?: string
}
export type SimulatorCreationModel = {
  chargerId: string
  ocppUrl: string
  namespace?: string
}
export type PingPong = {
  ping?: string
}
export const {
  useGetVehiclesQuery,
  useUpdateVehicleMutation,
  useCreateVehicleWithMetadataMutation,
  useUpdateUserMutation,
  useCreateUserMutation,
  useSignOutUserMutation,
  useResendMutation,
  useResetPasswordMutation,
  useEnableUserMutation,
  useDisableUserMutation,
  useUpdateScheduledRouteMutation,
  useCancelScheduledRouteMutation,
  useGetRoutesForFleetQuery,
  useUpdateRouteMutation,
  useCreateRouteMutation,
  useUpdateOrganizationMutation,
  useUpdateFleetTermsMutation,
  useSaveFleetTermsMutation,
  useGetLocationsOfInterestForOrganizationQuery,
  useUpdateLocationOfInterestMutation,
  useCreateLocationOfInterestMutation,
  useEnableOrganizationIntegrationMutation,
  useDisableIntegrationMutation,
  useUpdateFleetMutation,
  useCreateFleet1Mutation,
  useAssignVehicleToFleetMutation,
  useUpdateAlarmMutation,
  useEnableAlarmsForOrganizationMutation,
  useDisableAlarmsForOrganizationMutation,
  useAddVehicleEventMutation,
  useInitiateMfaSetupQuery,
  useVerifySoftwareTokenMutation,
  useCreateTripNoteMutation,
  useGetScheduledRoutesQuery,
  useCreateScheduledRouteMutation,
  useAssignVehicleMutation,
  useOnboardOrganizationToStripeMutation,
  useSaveOrganizationLogoMutation,
  useDeleteOrganizationLogoMutation,
  useGetApiKeysByOrganizationIdQuery,
  useProvisionApiKeyByOrganizationIdMutation,
  useGetIntegrationsByOrganizationIdQuery,
  useSaveOrganizationIntegrationMutation,
  useSaveOrganizationIconMutation,
  useDeleteOrganizationIconMutation,
  useGetOrganizationCustomersQuery,
  useCreateFleetMutation,
  usePublishToSnsMutation,
  useAssignVehicleToBillingOrganizationMutation,
  useSaveNotificationMappingForAlarmsMutation,
  useDeleteNotificationMappingForAlarmsMutation,
  useGetVehicleQuery,
  useGetLatestVehicleWeatherForecastQuery,
  useGetTripsQuery,
  useGetTripQuery,
  useGetVehicleTelematicsQuery,
  useGetDailySummaryForVehicleQuery,
  useGetServiceableRangeForVehicleQuery,
  useGetMetricsForVehicleQuery,
  useGetVehicleFaultsQuery,
  useGetAssetEventsQuery,
  useGetVehicleStatusBreakdownForVehiclesQuery,
  useGetTimespanDataForVehiclesQuery,
  useGetVehiclesBySiteQuery,
  useGetVehicleReportQuery,
  useGetMetricsForVehiclesQuery,
  useGetLatestVehicleStatusesQuery,
  useGetEnergyBreakdownDataForVehiclesQuery,
  useGetMetricsForVehicles1Query,
  useGetVehicleFaults1Query,
  useGetUserQuery,
  useGetUserTagMappingsQuery,
  useGetDropdownOptionsQuery,
  useGetTripNotesQuery,
  useGetTripReportQuery,
  useGetVehicleUtilizationQuery,
  useGetVehicleBreakdownQuery,
  useGetRouteByIdQuery,
  useDeleteRouteMutation,
  useGetRouteScheduleQuery,
  useGetStatusOfGeneratedReportQuery,
  useGetOrganizationQuery,
  useGetVehiclesForOrganizationQuery,
  useGetOrganizationUsersQuery,
  useGetTrips1Query,
  useGetOrganizationTermsQuery,
  useLoginToStripeQuery,
  useGetStripeIntentDetailQuery,
  useGetRoutesForOrganizationQuery,
  useGetIntegrationsByOrganizationIdAndIntegrationTypeQuery,
  useGetAllOcppTagsQuery,
  useGetFleetsQuery,
  useGetAllOrganizationCustomersQuery,
  useGetOrganizationConfigQuery,
  useGetOrganizationApiKeyByApiKeyQuery,
  useGetCurrentOrganizationQuery,
  useGetLocationOfInterestQuery,
  useDeleteLocationOfInterestMutation,
  useGetIntegrationsQuery,
  useGetFleetQuery,
  useGetCustomersQuery,
  useGetChartValuesQuery,
  useGetAlarmsForFleetQuery,
  useGetNotificationMappingForAlarmsQuery,
  useGetTriggeredAlarmsQuery,
  useGetServiceEventQuery,
  useUpdateServiceEventMutation,
  useDeleteServiceEventMutation,
  useGetChargeProfilesQuery,
  useUpdateChargeProfileMutation,
  useSaveChargeProfileMutation,
  useClearChargingProfileMutation,
  useGetDepotsQuery,
  useUpdateDepotMutation,
  useCreateDepotMutation,
  useGetDepotEnergyLimitsQuery,
  useUpdateDepotEnergyLimitsMutation,
  useGetChargersQuery,
  useUpdateChargerMutation,
  useCreateChargerMutation,
  useGetServiceEventsQuery,
  useCreateServiceEventMutation,
  useBulkCommissionDecommissionChargersMutation,
  useSetChargeProfileMutation,
  useCmsOcppTagsGetTagsQuery,
  useCmsOcppTagsAddTagsMutation,
  useCmsOcppConfigurationChangeConfigurationMutation,
  useCmsOcppConfigurationRefreshConfigurationsMutation,
  useCmsChargersGetChargingGroupsQuery,
  useCmsChargersCreateChargingGroupMutation,
  useStopChargingTransactionMutation,
  useStartChargingTransactionMutation,
  useResetChargerMutation,
  useCmsOcppDestinationGetMultipleOcppDestinationsQuery,
  useCmsOcppDestinationCreateOcppDestinationMutation,
  useCmsChargersGetChargingGroupQuery,
  useCmsChargersDeleteChargingGroupMutation,
  useCmsChargersUpdateChargingGroupMutation,
  useCmsOcppDestinationGetOcppDestinationQuery,
  useCmsOcppDestinationDeleteOcppDestinationMutation,
  useCmsOcppDestinationUpdateOcppDestinationMutation,
  useGetChargingTransactionsQuery,
  useGetTransactionByIdQuery,
  useGetTransactionMeterValuesQuery,
  useCmsChargingTransactionsGetMeterValueIntervalDataQuery,
  useGetPowerUtilizationQuery,
  useGetChargingSummaryQuery,
  useGetServiceEventAuditQuery,
  useGetTransactionsReportQuery,
  useCmsReportsGetStatusOfTransactionReportQuery,
  useGetMeterValueIntervalsReportQuery,
  useGetMeterValueIntervalsReportStatusQuery,
  useGetChargeProfileQuery,
  useCmsOcppConfigurationGetLatestConfigurationsForChargerQuery,
  useCmsOcppConfigurationGetRefreshStatusForChargerQuery,
  useCmsOcppConfigurationGetRefreshStatusQuery,
  useCmsOcppConfigurationGetLatestConfigurationsQuery,
  useGetLiveUtilizationQuery,
  useGetDepotQuery,
  useDeleteDepotMutation,
  useGetDepotUtilizationQuery,
  useGetDepotUptimeQuery,
  useGetDepotStatusQuery,
  useGetChargerQuery,
  useDeleteChargerMutation,
  useGetChargerUtilizationTimelineQuery,
  useGetChargerUptimeQuery,
  useGetMappedChargerProfilesQuery,
  useGetChargerLogsReportQuery,
  useGetChargerFaultsQuery,
  useGetChargerConnectorsQuery,
  useCmsChargersGetConfigurationKeysQuery,
  useGetCapabilitySetQuery,
  useCmsUtilizationGetUtilizationQuery,
  useGetChargersTableDataQuery,
  useGetSummaryDataQuery,
  useCmsTimespanGetHistoricalStatusesQuery,
  useCmsChargersGetChargerStatusQuery,
  useCmsOcppLogsGetStatusOfOcppLogsRequestQuery,
  useCmsMeterDataIntervalsQuery,
  useGetLatestStatusSummaryQuery,
  useCmsChargerFaultsGetFaultsQuery,
  useGetManufacturersQuery,
  useGetManufacturerMakeModelsQuery,
  useGetSiteGridProfilesQuery,
  useUpdateSiteGridProfileMutation,
  useCreateSiteGridProfileMutation,
  useGetScheduledTripsQuery,
  useUpdateTripMutation,
  useCreateTripMutation,
  useDeleteTripMutation,
  useGetRecurrenceScheduleQuery,
  useUpdateRecurrenceScheduleMutation,
  useDeleteRecurrenceScheduleMutation,
  useUpdateTripOccurrencesMutation,
  useGetGridEventIntegrationAccountsQuery,
  useUpdateGridEventIntegrationAccountMutation,
  useCreateGridEventIntegrationAccountMutation,
  useGetDepotEventsByDepotIdQuery,
  useUpdateDepotEventMutation,
  useSaveDepotEventMutation,
  useGetScheduledChargerSessionQuery,
  useUpdateScheduledChargerSessionMutation,
  useCancelScheduledChargerSessionMutation,
  useGetChargerEventByIdQuery,
  useUpdateChargerEventMutation,
  useCancelChargerEventMutation,
  useMoveChargerEventMutation,
  useGetTelemetryExclusionPeriodQuery,
  useCreateTelemetryExclusionPeriodMutation,
  useGetSiteEnergyConfigurationsQuery,
  useCreateSiteEnergyConfigurationMutation,
  useCreateRecurrenceScheduleMutation,
  useSetRecurrenceScheduleVehiclesMutation,
  useGetOptimizationsQuery,
  useCreateOptimizationMutation,
  useGetLoadBalancerEventsQuery,
  useCreateLoadBalancerEventMutation,
  useGetEnergyEfficiencyPredictionsQuery,
  useCreateEnergyEfficiencyPredictionMutation,
  useGetTransactionProfileMappings1Query,
  useGetTransactionProfileMappingsMutation,
  useGetProfileHistoriesQuery,
  useCreateProfileHistoryMutation,
  useGetScheduledChargerSessionsQuery,
  useCreateScheduledChargerSessionMutation,
  useGetScheduledChargerSessionDetailsQuery,
  useSaveScheduledChargerSessionDetailsMutation,
  useCreateChargerEventPredictionMutation,
  useGetChargerEventsQuery,
  useCreateChargerEventMutation,
  useGetChargerEventDetailsByIdQuery,
  useAddSessionToChargerEventMutation,
  useGetLinkTelemetryQuery,
  useGetSiteEnergyConfigurationQuery,
  useGetBulkSiteEnergyConfigurationsQuery,
  useGetSiteGridProfileQuery,
  useGetOptimizationQuery,
  useGetGridEventIntegrationsQuery,
  useGetGridEventIntegrationQuery,
  useGetGridEventIntegrationAccountQuery,
  useGetGridEventIntegrationEventByAccountQuery,
  useGetGridEventIntegrationEventQuery,
  useGetLoadBalancerEventQuery,
  useGetEnergyEfficiencyPredictionQuery,
  useGetSmartPricesQuery,
  useGetScheduledDepotPowerQuery,
  useGetDepotEventByIdQuery,
  useCancelDepotEventMutation,
  useGetDepotEventDetailsQuery,
  useGetScheduledChargerSessionsByDepotQuery,
  useGetPredictedChargerEventsQuery,
  useGetDepotEventsQuery,
  useGetScheduledChargerSessionTelemetryQuery,
  useGetProfileHistoryQuery,
  useDeleteTelemetryExclusionPeriodMutation,
  useGetApiVehiclesByIdTimeToSocQuery,
  useGetApiTransactionsTimeToSocQuery,
  useAcknowledgeSentNotificationMutation,
  useGetAlertsByOrganizationIdQuery,
  useUpdateAlertByOrganizationsMutation,
  useCreateAlertByOrganizationsMutation,
  useCreateAlertNotificationMutation,
  useDeactivateNotificationsMutation,
  useGetNotificationsQuery,
  useGetNotificationByIdQuery,
  useGetAlertByAlertIdQuery,
  useGetPropertiesQuery,
  useGetWorkplaceUserQuery,
  useUpdateWorkplaceUserMutation,
  useDeleteWorkplaceUserMutation,
  useGetWorkplaceChargingRateByIdQuery,
  useUpdateWorkplaceChargingRatesMutation,
  useUpdatePushNotificationMutation,
  useUpdateUserTagsMutation,
  useGetSubscriptionByIdQuery,
  useUpdateSubscriptionMutation,
  useGetFleetBillingProductByIdQuery,
  useUpdateFleetBillingProductMutation,
  useGetSessionsQuery,
  usePutChargingPreferencesMutation,
  useGetSessionQuery,
  useUpdateSessionMutation,
  useGetLocationQuery,
  useUpdateLocationMutation,
  useGetInvoiceByIdQuery,
  useUpdateInvoiceMutation,
  useDeleteInvoiceMutation,
  useGetFleetBillingInvoiceByIdQuery,
  useUpdateFleetBillingInvoicesMutation,
  useGetChargingRateByIdQuery,
  useUpdateChargingRatesMutation,
  useGetWorkplaceUsersQuery,
  useSaveWorkplaceUserMutation,
  useGetWorkplaceChargingRatesQuery,
  useCreateWorkplaceChargingRatesMutation,
  useOverrideWorkplaceChargingRateMutation,
  useGetWorkplaceChargingConfigurationQuery,
  useSaveWorkplaceChargingConfigurationMutation,
  useUpdateWorkplaceChargingRecordStatusesMutation,
  useGetSubscriptionsQuery,
  useCreateSubscriptionMutation,
  useResumeSubscriptionMutation,
  usePauseSubscriptionMutation,
  useCancelSubscriptionMutation,
  useProcessStripeEventMutation,
  useGetReimbursementUsersQuery,
  useSaveReimbursementUserMutation,
  useGetReimbursementConfigurationQuery,
  useSaveReimbursementConfigurationMutation,
  useGetFleetBillingProductsQuery,
  useCreateFleetBillingProductMutation,
  useOnboardOrganizationToStripe1Mutation,
  useStopSessionMutation,
  useStartSessionMutation,
  useGetInvoicesQuery,
  useCreateInvoiceMutation,
  useVoidInvoiceMutation,
  useSendInvoiceMutation,
  useFinalizeInvoiceMutation,
  useGetFleetChargingConfigurationsQuery,
  useSaveFleetChargingConfigurationMutation,
  useGetFleetBillingInvoicesQuery,
  useCreateFleetBillingInvoiceMutation,
  useVoidFleetBillingInvoiceMutation,
  useSendFleetBillingInvoiceMutation,
  useFinalizeFleetBillingInvoiceMutation,
  useGetChargingRatesQuery,
  useCreateChargingRatesMutation,
  useOverrideChargingRateMutation,
  useBulkUpdateReimbursementUsersMutation,
  useAuthorizePublicSessionMutation,
  useAuditBillingMutation,
  useAskQuestionMutation,
  useGetWorkplaceChargingUsageQuery,
  useGetWorkplaceSummaryQuery,
  useGetWorkplaceChargingRecordsQuery,
  useGetStripeIntentDetail1Query,
  useGetPaymentMethodQuery,
  useGetPortalLoginQuery,
  useGetUserInvoicesQuery,
  useGetCheckoutSessionQuery,
  useGetReimbursementsQuery,
  useGetReimbursementUserQuery,
  useDeleteReimbursementUserMutation,
  useGetReimbursementUsageSummaryQuery,
  useGetReimbursementSummaryQuery,
  useGetQuotasQuery,
  useGetReimbursements1Query,
  useGetPortalQuery,
  useGetLoginSessionQuery,
  useGetOrganizationBalanceQuery,
  useGetMeterValuesQuery,
  useGetLocationsQuery,
  useGetEvseQuery,
  useGetConnectorQuery,
  useGetCdrQuery,
  useGetCdrByIdQuery,
  useGetLineItemsQuery,
  useGetFleetBillingSummaryQuery,
  useGetFleetBillingPaymentsQuery,
  useGetCostExplorerQuery,
  useGetCostDashboardSummaryQuery,
  useGetCostDashboardGroupedQuery,
  useListSimulatorsQuery,
  useCreateSimulatorMutation,
  useGetContainerQuery,
  useDeleteContainerMutation,
  usePingQuery,
  useSimulatorProxyRequestQuery,
  useSimulatorProxyRequest3Mutation,
  useSimulatorProxyRequest2Mutation,
  useSimulatorProxyRequest5Mutation,
  useSimulatorProxyRequest6Mutation,
  useSimulatorProxyRequest1Mutation,
  useSimulatorProxyRequest4Mutation
} = injectedRtkApi
